/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DiscountCouponsfilterAccordian from './DiscountCouponsfilterAccordian';

const DiscountCouponsFilter = ({filtermenu, setFiltermenu}) => {

  const menuRef = useRef(null);
  const crossmenu = process.env.PUBLIC_URL + '/images/icons/crossmenu.svg';

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setFiltermenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleCloseFilter = () => {
    setFiltermenu(false);
  };

  return(
    <>
      { /* filter code open here */ }

      <div className = { `filtersidebar h-44 overflow-x-auto custom-scroll ${
        filtermenu ? 'filterslide-in' : 'filterslide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end cursor-pointer' onClick = { handleCloseFilter }><img className = 'w-5' src = { crossmenu }  alt = 'crossmenu' /></p>
          <p className = 'text-blacklight text-base mb-5'><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span> Sort discount coupons by</p>
          <ul>
            <li className = 'mt-3'>
              <DiscountCouponsfilterAccordian  question = { <p className = 'text-blacklight text-sm'>Show discount coupons</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "Everyone" id = "Everyone"/>
                    <label  htmlFor = "Everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "Activated" id = "Activated"/>
                    <label  htmlFor = "Activated" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Activated</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Disabled"/>
                    <label  htmlFor = "Disabled" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Disabled</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Theending"/>
                    <label  htmlFor = "Theending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>The ending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "startedyet"/>
                    <label  htmlFor = "startedyet" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>It hasn't started yet</label>
                  </div>
                  
                </div> } />
            </li>
            <li className = 'mt-3'>
              <DiscountCouponsfilterAccordian  question = { <p className = 'text-blacklight text-sm'>Coupon type</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "Everyonetype" id = "Everyonetype"/>
                    <label  htmlFor = "Everyonetype" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "Regularcoupon" id = "Regularcoupon"/>
                    <label  htmlFor = "Regularcoupon" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Regular coupon</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Marketingcoupon"/>
                    <label  htmlFor = "Marketingcoupon" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Marketing coupon</label>
                  </div>
                </div> } />
            </li>
            <li className = 'mt-3'>
              <DiscountCouponsfilterAccordian  question = { <p className = 'text-blacklight text-sm'>Arrange discount coupons</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "addition"/>
                    <label  htmlFor = "addition" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>According to the addition</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "Alphabetically"/>
                    <label  htmlFor = "Alphabetically" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Alphabetically</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "StartDate"/>
                    <label  htmlFor = "StartDate" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Start Date</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "Expirydate"/>
                    <label  htmlFor = "Expirydate" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Expiry date</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "bestseller"/>
                    <label  htmlFor = "bestseller" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Best seller</label>
                  </div>
                </div> } />
            </li>
            <li className = 'mt-3'>
              <DiscountCouponsfilterAccordian  question = { <p className = 'text-blacklight text-sm'>View by</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "Newestfirst"/>
                    <label  htmlFor = "Newestfirst" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Newest first</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = "arrange" id = "Oldestfirst"/>
                    <label  htmlFor = "Oldestfirst" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Oldest first</label>
                  </div>
                  
                </div> } />
            </li>
            <li>
              <div className = 'flex mt-10'>
                <button className = 'bg-[#f0f3f3] px-4 py-1.5 text-sm rounded'>Reset</button>
                <button className = 'bg-primary px-4 py-1.5 text-sm rounded text-white flex-1 ml-3'>Show results</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      { /* filter code closed here */ }
    </>
  );
};

export default DiscountCouponsFilter;