import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchkeyword: ''
};

export const searchkeywordSlice = createSlice({
  initialState,
  name: 'searchkeywordSlice',
  reducers: {
    setSearchkeyword: (state, action) => {
      state.searchkeyword = action.payload;
    }
  }
});

export default searchkeywordSlice.reducer;

export const { setSearchkeyword } = searchkeywordSlice.actions;
