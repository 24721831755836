import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DeleteIcon from '@mui/icons-material/Delete';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useCustomeroverviewQuery } from '../../redux/api/customerApi';
import CustomerList from './CustomerList';

const Customers = () => {

  const [allcustomerGroupModal, setAllcustomerGroupModal] = useState(false);
  const [purchasingcustomerModal, setPurchasingcustomerModal] = useState(false);
  const [loyalcustomerModal, setLoyalcustomerModal] = useState(false);
  const [diamondModal, setDiamondModal] = useState(false);

  const onOpenAllcustomerGroupModal = () => setAllcustomerGroupModal(true);
  const onCloseAllcustomerGroupModal = () => setAllcustomerGroupModal(false);


  const onOpenPurchasingcustomerModal = () => setPurchasingcustomerModal(true);
  const onClosePurchasingcustomerModal = () => setPurchasingcustomerModal(false);


  const onOpenLoyalcustomerModal = () => setLoyalcustomerModal(true);
  const onCloseLoyalcustomerModal = () => setLoyalcustomerModal(false);


  const onOpenDiamondModal = () => setDiamondModal(true);
  const onCloseDiamondModal = () => setDiamondModal(false);


  const [conditions, setConditions] = useState([{ value: '', condition: '', conditionType: '' }]);
  const addCondition = () => {
    setConditions([...conditions, { value: '', condition: '', conditionType: '' }]);
  };

  const deleteCondition = (index) => {
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
  };

  const handleConditionChange = (index, key, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    setConditions(updatedConditions);
  };

  const {error, isLoading, data} = useCustomeroverviewQuery();

  return (
    <>
      <Helmet>
        <meta charset = "utf-8" />
        <title>Customers</title>
        <meta name = "description" content = "order" />
      </Helmet>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <div className = 'border border-borderColor w-width24% rounded-md text-center py-5 cursor-pointer' onClick = { onOpenAllcustomerGroupModal }>
              <span className = 'text-secondary'><GroupAddOutlinedIcon style = { {fontSize: '35px'} } /></span>
              <h3 className = 'text-blacklight text-base font-semibold mt-2'>All customers</h3>
              <p className = 'text-sm text-secondary'>{ data?.data?.total_customer } clients</p>

              { isLoading && <p>Loading...</p> }
              { error &&<p>An error occurred: { error?.data?.message }</p> }
            </div>
            <div className = 'border border-borderColor w-width24% rounded-md text-center py-5 cursor-pointer' onClick = { onOpenPurchasingcustomerModal }>
              <span className = 'text-secondary'><DiscountOutlinedIcon style = { {fontSize: '35px'} } /></span>
              <h3 className = 'text-blacklight text-base font-semibold mt-2'>The most purchasing customers</h3>
              <p className = 'text-sm text-secondary'>{ data?.data?.most_purchasing_customer } clients</p>
            </div>
            <div className = 'border border-borderColor w-width24% rounded-md text-center py-5 cursor-pointer' onClick = { onOpenLoyalcustomerModal }>
              <span className = 'text-secondary'><Diversity1OutlinedIcon style = { {fontSize: '35px'} } /></span>
              <h3 className = 'text-blacklight text-base font-semibold mt-2'>Highly loyal customers</h3>
              <p className = 'text-sm text-secondary'>7014 clients</p>
            </div>
            <div className = 'border border-borderColor w-width24% rounded-md text-center py-5 cursor-pointer' onClick = { onOpenDiamondModal }>
              <span className = 'text-secondary'><CurrencyExchangeIcon style = { {fontSize: '35px'} } /></span>
              <h3 className = 'text-blacklight text-base font-semibold mt-2'>Diamond category</h3>
              <p className = 'text-sm text-secondary'>7014 clients</p>
            </div>
          </div>
          <CustomerList />
        </div>
      </div>


      <Modal open = { allcustomerGroupModal } showCloseIcon = { false } onClose = { onCloseAllcustomerGroupModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>All customers</h3>
            <div className = 'self-center'><span onClick = { onCloseAllcustomerGroupModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><PeopleOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Group name' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-3'>
                <h3 className = 'text-lg font-semibold text-blacklight'>Group advantages</h3>
                <p className = 'text-sm text-blacklight mt-1'>You can assign benefits to the customer group through the store settings. Through the shipping company restrictions, you can control which shipping company appears to the group’s customers. Through the payment method restrictions, you can control any payment methods that appear to the group’s customers.</p>
              </li>
              <li>
                <ul className = 'list-disc ml-5 mt-2'>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Fraud protection</a></li>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Shipping company restrictions</a></li>
                </ul>
              </li>
            </ul>
            <button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-5'>Save</button>
          </div>
        </div>
      </Modal>

      <Modal open = { purchasingcustomerModal } showCloseIcon = { false } onClose = { onClosePurchasingcustomerModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>The most purchasing customers</h3>
            <div className = 'self-center'><span onClick = { onClosePurchasingcustomerModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><DiscountOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'العملاء الاكثر شرائاً' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-5'>
                <div>
                  <label className = 'text-sm text-blacklight'>Automatically add customers to the group when the following condition is met (optional)</label>
                  { conditions.map((condition, index) => (
                    <div key = { index } className = 'flex justify-between mt-2'>
                      <div className = 'w-width30%'>
                        <input
                          placeholder = 'Condition value'
                          value = { condition.value }
                          onChange = { (e) => handleConditionChange(index, 'value', e.target.value) }
                          className = 'w-full border border-borderColor text-sm px-2 py-1 rounded-md outline-0 inline-block'
                        />
                      </div>
                      <div className = 'w-width22%'>
                        <select
                          value = { condition.condition }
                          onChange = { (e) => handleConditionChange(index, 'condition', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition</option>
                          <option>bigger</option>
                          <option>younger than</option>
                          <option>range between</option>
                        </select>
                      </div>
                      <div className = { conditions.length > 1 ? 'w-width40%' : 'w-width44%' }>
                        <select
                          value = { condition.conditionType }
                          onChange = { (e) => handleConditionChange(index, 'conditionType', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition type</option>
                          <option>Total purchases</option>
                          <option>Number of requests</option>
                          <option>Store rating</option>
                          <option>They have no requests</option>
                          <option>They have canceled orders</option>
                          <option>Sex</option>
                          <option>Date of birth</option>
                          <option>Account opening date</option>
                        </select>
                      </div>
                      { conditions.length > 1 && ( // Only render delete button if there's more than one condition
                        <div>
                          <button onClick = { () => deleteCondition(index) } className = 'text-sm bg-[#f0f3f3] inline-block px-1 py-1 rounded-md'>
                            <DeleteIcon style = { { fontSize: '20px' } } />
                          </button>
                        </div>
                      ) }
                    </div>
                  )) }
                  <button onClick = { addCondition } className = 'text-sm bg-[#f0f3f3] inline-block px-4 py-2 rounded-md mt-5'>
                    <span><AddOutlinedIcon style = { { fontSize: '20px' } } /></span> Add a new condition
                  </button>
                </div>
              </li>
              <li className = 'mt-3'>
                <h3 className = 'text-lg font-semibold text-blacklight'>Group advantages</h3>
                <p className = 'text-sm text-blacklight mt-1'>You can assign benefits to the customer group through the store settings. Through the shipping company restrictions, you can control which shipping company appears to the group’s customers. Through the payment method restrictions, you can control any payment methods that appear to the group’s customers.</p>
              </li>
              <li>
                <ul className = 'list-disc ml-5 mt-2'>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Fraud protection</a></li>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Shipping company restrictions</a></li>
                </ul>
              </li>
            </ul>
            <div className = 'flex justify-between'>
              <div><button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-5'>Save</button></div>
              <div><button className = 'text-sm text-red bg-[#f0f3f3] px-5 py-2 rounded mt-5'>Delete</button></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { loyalcustomerModal } showCloseIcon = { false } onClose = { onCloseLoyalcustomerModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>The most purchasing customers</h3>
            <div className = 'self-center'><span onClick = { onCloseLoyalcustomerModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><Diversity1OutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'عملاء اصحاب ولاء مرتفع' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-5'>
                <div>
                  <label className = 'text-sm text-blacklight'>Automatically add customers to the group when the following condition is met (optional)</label>
                  { conditions.map((condition, index) => (
                    <div key = { index } className = 'flex justify-between mt-2'>
                      <div className = 'w-width30%'>
                        <input
                          placeholder = 'Condition value'
                          value = { condition.value }
                          onChange = { (e) => handleConditionChange(index, 'value', e.target.value) }
                          className = 'w-full border border-borderColor text-sm px-2 py-1 rounded-md outline-0 inline-block'
                        />
                      </div>
                      <div className = 'w-width22%'>
                        <select
                          value = { condition.condition }
                          onChange = { (e) => handleConditionChange(index, 'condition', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition</option>
                          <option>bigger</option>
                          <option>younger than</option>
                          <option>range between</option>
                        </select>
                      </div>
                      <div className = { conditions.length > 1 ? 'w-width40%' : 'w-width44%' }>
                        <select
                          value = { condition.conditionType }
                          onChange = { (e) => handleConditionChange(index, 'conditionType', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition type</option>
                          <option>Total purchases</option>
                          <option>Number of requests</option>
                          <option>Store rating</option>
                          <option>They have no requests</option>
                          <option>They have canceled orders</option>
                          <option>Sex</option>
                          <option>Date of birth</option>
                          <option>Account opening date</option>
                        </select>
                      </div>
                      { conditions.length > 1 && ( // Only render delete button if there's more than one condition
                        <div>
                          <button onClick = { () => deleteCondition(index) } className = 'text-sm bg-[#f0f3f3] inline-block px-1 py-1 rounded-md'>
                            <DeleteIcon style = { { fontSize: '20px' } } />
                          </button>
                        </div>
                      ) }
                    </div>
                  )) }
                  <button onClick = { addCondition } className = 'text-sm bg-[#f0f3f3] inline-block px-4 py-2 rounded-md mt-5'>
                    <span><AddOutlinedIcon style = { { fontSize: '20px' } } /></span> Add a new condition
                  </button>
                </div>
              </li>
              <li className = 'mt-3'>
                <h3 className = 'text-lg font-semibold text-blacklight'>Group advantages</h3>
                <p className = 'text-sm text-blacklight mt-1'>You can assign benefits to the customer group through the store settings. Through the shipping company restrictions, you can control which shipping company appears to the group’s customers. Through the payment method restrictions, you can control any payment methods that appear to the group’s customers.</p>
              </li>
              <li>
                <ul className = 'list-disc ml-5 mt-2'>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Fraud protection</a></li>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Shipping company restrictions</a></li>
                </ul>
              </li>
            </ul>
            <div className = 'flex justify-between'>
              <div><button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-5'>Save</button></div>
              <div><button className = 'text-sm text-red bg-[#f0f3f3] px-5 py-2 rounded mt-5'>Delete</button></div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { diamondModal } showCloseIcon = { false } onClose = { onCloseDiamondModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Diamond category</h3>
            <div className = 'self-center'><span onClick = { onCloseDiamondModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><CurrencyExchangeIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'الفئة الماسية' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-5'>
                <div>
                  <label className = 'text-sm text-blacklight'>Automatically add customers to the group when the following condition is met (optional)</label>
                  { conditions.map((condition, index) => (
                    <div key = { index } className = 'flex justify-between mt-2'>
                      <div className = 'w-width30%'>
                        <input
                          placeholder = 'Condition value'
                          value = { condition.value }
                          onChange = { (e) => handleConditionChange(index, 'value', e.target.value) }
                          className = 'w-full border border-borderColor text-sm px-2 py-1 rounded-md outline-0 inline-block'
                        />
                      </div>
                      <div className = 'w-width22%'>
                        <select
                          value = { condition.condition }
                          onChange = { (e) => handleConditionChange(index, 'condition', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition</option>
                          <option value = 'bigger'>bigger</option>
                          <option value = 'younger than'>younger than</option>
                          <option value = 'range between'>range between</option>
                        </select>
                      </div>
                      <div className = { conditions.length > 1 ? 'w-width40%' : 'w-width44%' }>
                        <select
                          value = { condition.conditionType }
                          onChange = { (e) => handleConditionChange(index, 'conditionType', e.target.value) }
                          className = 'text-sm border border-borderColor px-2 py-1 rounded-md outline-none w-full text-secondary'
                        >
                          <option disabled value = "">Condition type</option>
                          <option>Total purchases</option>
                          <option>Number of requests</option>
                          <option>Store rating</option>
                          <option>They have no requests</option>
                          <option>They have canceled orders</option>
                          <option>Sex</option>
                          <option>Date of birth</option>
                          <option>Account opening date</option>
                        </select>
                      </div>
                      { conditions.length > 1 && ( // Only render delete button if there's more than one condition
                        <div>
                          <button onClick = { () => deleteCondition(index) } className = 'text-sm bg-[#f0f3f3] inline-block px-1 py-1 rounded-md'>
                            <DeleteIcon style = { { fontSize: '20px' } } />
                          </button>
                        </div>
                      ) }
                    </div>
                  )) }
                  <button onClick = { addCondition } className = 'text-sm bg-[#f0f3f3] inline-block px-4 py-2 rounded-md mt-5'>
                    <span><AddOutlinedIcon style = { { fontSize: '20px' } } /></span> Add a new condition
                  </button>
                </div>
              </li>
              <li className = 'mt-3'>
                <h3 className = 'text-lg font-semibold text-blacklight'>Group advantages</h3>
                <p className = 'text-sm text-blacklight mt-1'>You can assign benefits to the customer group through the store settings. Through the shipping company restrictions, you can control which shipping company appears to the group’s customers. Through the payment method restrictions, you can control any payment methods that appear to the group’s customers.</p>
              </li>
              <li>
                <ul className = 'list-disc ml-5 mt-2'>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Fraud protection</a></li>
                  <li><a href = '#' className = 'text-blacklight hover:text-primary text-sm'>Shipping company restrictions</a></li>
                </ul>
              </li>
            </ul>
            <div className = 'flex justify-between'>
              <div><button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-5'>Save</button></div>
              <div><button className = 'text-sm text-red bg-[#f0f3f3] px-5 py-2 rounded mt-5'>Delete</button></div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Customers;