/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const Catloglist = ({handleChangecatlog, selectedCatlogOption, catloglist, setInputValue, inputValue}) => {

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary border-0'
        value = { selectedCatlogOption }
        onChange = { handleChangecatlog }
        options = { catloglist }
        inputValue = { inputValue }
        onInputChange = { handleInputChange }
        isMulti
        placeholder = 'Select category'
      />
    </>
  );
};

export default Catloglist;