/* eslint-disable react/prop-types */
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Crop54OutlinedIcon from '@mui/icons-material/Crop54Outlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const Usereadymadetemplate = ({ istemplateOpen,setIstemplateOpen }) => {
  const [userreadymadetemplateModal, setUserreadymadetemplateModal] = useState(false);
  const [tab, setTab] = useState(null);

  const handleTab = (item) => {
    setTab(item);
  };

  useEffect(() => {
    if (istemplateOpen == true) {
      setUserreadymadetemplateModal(true);
    } else {
      setUserreadymadetemplateModal(false);
    }
  },[istemplateOpen]);


  const onCloseUserreadymadetemplateModal = () => {
    setUserreadymadetemplateModal(false);
    setIstemplateOpen(false);
  };

  return (
    <>
      <Modal open = { userreadymadetemplateModal } showCloseIcon = { false } onClose = { onCloseUserreadymadetemplateModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Use a ready-made template</h3>
            <div className = 'self-center'><span onClick = { onCloseUserreadymadetemplateModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
          </div>
          <div className = 'px-4 py-3 mt-3'>
            <h3 className = 'text-base text-blacklight'>Choose the product type</h3>
            <p className = 'text-sm text-secondary'>All models are experimental and are not published on the store</p>
            <ul className = 'flex chooseproduct custom-scroll mt-5'>
              <li onClick = { () => handleTab(0) }>
                <label href = "javascript:void(0)" htmlFor = "readyproduct" className = "text-blacklight px-4 py-1.5 text-sm flex justify-between border border-borderColor rounded-md cursor-pointer w-64">
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><InboxOutlinedIcon style = { {fontSize: '24px'} } /></span>
                  </div>
                  <div className = 'self-center ml-2'>
                    <p className = 'text-sm text-blacklight'>Ready product</p>
                    <p className = 'text-xs text-secondary'>Shippable tangible products</p>
                  </div>
                  <div><input type = 'radio' name = "product" id = "readyproduct" /></div>
                </label>
              </li>
              <li className = 'ml-2' onClick = { () => handleTab(1) }>
                <label href = "javascript:void(0)" htmlFor = "Food" className = "text-blacklight px-4 py-1.5 text-sm flex border border-borderColor rounded-md cursor-pointer w-64">
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><CakeOutlinedIcon style = { {fontSize: '24px'} } /></span>
                  </div>
                  <div className = 'self-center ml-2'>
                    <p className = 'text-sm text-blacklight'>Food</p>
                    <p className = 'text-sm text-secondary'>require special shipping</p>
                  </div>
                  <div><input type = 'radio' name = "product" id = "Food" /></div>
                </label>
              </li>
              <li className = 'ml-2' onClick = { () => handleTab(2) }>
                <label href = "javascript:void(0)" htmlFor = "Digitalcard" className = "text-blacklight px-4 py-1.5 text-sm flex border border-borderColor rounded-md cursor-pointer w-64">
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><DocumentScannerOutlinedIcon style = { {fontSize: '24px'} } /></span>
                  </div>
                  <div className = 'self-center ml-2'>
                    <p className = 'text-sm text-blacklight'>Digital card</p>
                    <p className = 'text-sm text-secondary'>cards, accounts for sale</p>
                  </div>
                  <div><input type = 'radio' name = "product" id = "Digitalcard" /></div>
                </label>
              </li>
              <li className = 'ml-2' onClick = { () => handleTab(3) }>
                <label href = "javascript:void(0)" htmlFor = "Digitalproduct" className = "text-blacklight px-4 py-1.5 text-sm flex border border-borderColor rounded-md cursor-pointer w-64">
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><SportsEsportsOutlinedIcon style = { {fontSize: '24px'} } /></span>
                  </div>
                  <div className = 'self-center ml-2'>
                    <p className = 'text-sm text-blacklight'>Digital product</p>
                    <p className = 'text-xs text-secondary'>courses, files for download</p>
                  </div>
                  <div><input type = 'radio' name = "product" id = "Digitalproduct" /></div>
                </label>
              </li>
              <li className = 'ml-2' onClick = { () => handleTab(4) }>
                <label href = "javascript:void(0)" htmlFor = "Customizedservice" className = "text-blacklight px-4 py-1.5 text-sm flex justify-between border border-borderColor rounded-md cursor-pointer w-64">
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><Crop54OutlinedIcon style = { {fontSize: '24px'} } /></span>
                  </div>
                  <div className = 'self-center ml-2'>
                    <p className = 'text-sm text-blacklight'>Customized service</p>
                    <p className = 'text-xs text-secondary'>Design, printing, bookings</p>
                  </div>
                  <div><input type = 'radio' name = "product" id = "Customizedservice" /></div>
                </label>
              </li>
            </ul>

            {
              tab == null && <div className = 'text-center mt-5'>
                <span className = 'text-secondary'><ProductionQuantityLimitsOutlinedIcon style = { {fontSize: '60px'} } /></span>
                <h3 className = 'text-lg text-blacklight font-medium'>Select the product type</h3>
                <p className = 'text-sm text-secondary'>Choose the product type from the top to display product models</p>
              </div>
            }

            {
              tab == 0 && <div className = 'flex gap-4 mt-5'>
                <label className = 'w-width33% relative border border-borderColor rounded-md' htmlFor = "ready">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/XWew/GNvjb1WBhqk4z2p8swzTbriziTjfBXTb8auJOajA.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = "ready" className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Fresh tomatoes 1 kilo</p>
                    <p className = 'text-sm text-secondary'>10.00</p>
                  </div>
                </label>
                <label className = 'w-width33% relative border border-borderColor rounded-md' htmlFor = "tomatoes">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/XWew/GNvjb1WBhqk4z2p8swzTbriziTjfBXTb8auJOajA.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = "tomatoes" className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Professional logo design</p>
                    <p className = 'text-sm text-secondary'>10.00</p>
                  </div>
                </label>
                <label className = 'w-width33% relative border border-borderColor rounded-md ' htmlFor = "draw">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/PnvqQ/opqL72jBFJciwB4wvP4fRrmxN9yjeSUBGclFuU63.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = 'draw' className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Draw a picture size 40*40</p>
                    <p className = 'text-sm text-secondary'>2000.00</p>
                  </div>
                </label> 
              </div>
            }

            {
              tab == 1 && <div className = 'flex gap-4 mt-5'>
                <label className = 'w-width33% relative border border-borderColor rounded-md ' htmlFor = "draw">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/PnvqQ/opqL72jBFJciwB4wvP4fRrmxN9yjeSUBGclFuU63.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = 'draw' className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Draw a picture size 40*40</p>
                    <p className = 'text-sm text-secondary'>2000.00</p>
                  </div>
                </label> 
              </div>
            }

            {
              tab == 2 && <div className = 'flex gap-4 mt-5'>
                <label className = 'w-width33% relative border border-borderColor rounded-md' htmlFor = "ready">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/XWew/GNvjb1WBhqk4z2p8swzTbriziTjfBXTb8auJOajA.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = "ready" className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Fresh tomatoes 1 kilo</p>
                    <p className = 'text-sm text-secondary'>10.00</p>
                  </div>
                </label>
              </div>
            }
            {
              tab == 3 && <div className = 'flex gap-4 mt-5'>
                <label className = 'w-width33% relative border border-borderColor rounded-md' htmlFor = "ready">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/XWew/GNvjb1WBhqk4z2p8swzTbriziTjfBXTb8auJOajA.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = "ready" className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Fresh tomatoes 1 kilo</p>
                    <p className = 'text-sm text-secondary'>10.00</p>
                  </div>
                </label>
              </div>
            }
            {
              tab == 4 && <div className = 'flex gap-4 mt-5'>
                <label className = 'w-width33% relative border border-borderColor rounded-md' htmlFor = "ready">
                  <div className = 'relative'>
                    <img src = 'https://cdn.salla.sa/XWew/GNvjb1WBhqk4z2p8swzTbriziTjfBXTb8auJOajA.png' className = 'w-full rounded' alt = 'product' />
                    <div className = 'flex justify-between w-full p-3 absolute top-0'>
                      <div className = 'self-center'>
                        <p className = 'text-base text-blacklight'><input type = 'radio' name = "ready" id = "ready" className = 'align-text-bottom inline-block ml-1 bg-transparent' /></p>
                      </div>
                    </div>
                  </div>
                  <div className = 'p-3'>
                    <p className = 'text-sm text-blacklight'>Fresh tomatoes 1 kilo</p>
                    <p className = 'text-sm text-secondary'>10.00</p>
                  </div>
                </label>
              </div>
            } 

            <div className = 'flex justify-between mt-8'>
              <button className = 'bg-[#f0f3f3] text-sm text-blacklight inline-block py-2 px-4 rounded'>Close</button>
              <button className = 'bg-primary rounded text-white py-2 px-4 inline-block hover:bg-primary-400 duration-300'>Add product</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Usereadymadetemplate;
