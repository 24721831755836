import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import AddcatlogimageModal from './AddcatlogimageModal';

const ProductClassifications = () => {
  const [categories, setCategories] = useState([{ id: '1', title: '', isOpen: false }]);
  const addcatlogdropdownRefs = useRef({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  console.log('categories', categories);

  const handleAddCategory = (parentId = null) => {
    const newCategory = { id: String(Date.now()), title: '', isOpen: false, parentId };
    setCategories([...categories, newCategory]);
  };

  const handleDeleteCategory = (id) => {
    setCategories(categories.filter(category => category.id !== id));
  };

  const handleTitleChange = (id, event) => {
    setCategories(categories.map(category => category.id === id ? { ...category, title: event.target.value } : category));
  };

  const handleToggleCategoryMenu = (id) => {
    setCategories(categories.map(category => category.id === id ? { ...category, isOpen: !category.isOpen } : category));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCategories(items);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      for (const key in addcatlogdropdownRefs.current) {
        if (addcatlogdropdownRefs.current[key] && !addcatlogdropdownRefs.current[key].contains(event.target)) {
          setCategories(categories.map(category => ({ ...category, isOpen: false })));
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [categories]);

  const handleimageOpenModal = (id) => {
    setSelectedCategoryId(id);
  };

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>Product classifications</span></li>
            </ul>
            <p className = 'text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><BusinessCenterOutlinedIcon style = { { fontSize: '20px' } } /></span> Services</p>
          </div>
          <div className = 'border border-borderColor rounded mt-5 px-5 py-2'>
            <div className = 'flex justify-between'>
              <h3 className = 'self-center text-base font-medium text-blacklight py-3'><TableViewIcon style = { { fontSize: '20px' } } /> Product classifications</h3>
              <div className = 'self-center'>
                <button className = 'bg-[#f0f3f3] inline-block text-sm py-2 px-4 rounded text-blacklight'>Save Changes</button>
              </div>
            </div>
            <DragDropContext onDragEnd = { handleDragEnd }>
              <Droppable droppableId = "categories">
                { (provided) => (
                  <ul className = 'mt-5' { ...provided.droppableProps } ref = { provided.innerRef }>
                    { categories.map((category, index) => (
                      <Draggable key = { category.id } draggableId = { category.id } index = { index }>
                        { (provided) => (
                          <li ref = { provided.innerRef } { ...provided.draggableProps } { ...provided.dragHandleProps } className = 'mt-2'>
                            <div className = 'flex justify-between border border-borderColor rounded px-5 py-2'>
                              <div className = 'flex-1'>
                                <span className = 'text-blacklight inline-block mr-2'><MenuOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                <span className = 'text-blacklight inline-block mr-5'><StorefrontOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                <input placeholder = 'Add the category title' className = 'w-width85% outline-none' value = { category.title } onChange = { (event) => handleTitleChange(category.id, event) } />
                              </div>
                              <div className = 'relative'>
                                <span className = 'text-sm bg-[#f0f3f3] rounded inline-block px-2 p-1 text-blacklight cursor-pointer' onClick = { () => handleimageOpenModal(category.id) }>Add image</span>
                                <span className = 'text-secondary inline-block ml-1'><VisibilityOffOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                <span className = 'text-secondary' onClick = { () => handleToggleCategoryMenu(category.id) }><MoreVertOutlinedIcon style = { { fontSize: '25px' } } /></span>
                                { category.isOpen && <div
                                  className = "absolute right-0 top-10 z-10 w-80 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  role = "menu"
                                  aria-orientation = "vertical"
                                  aria-labelledby = "menu-button"
                                  tabIndex = "-1"
                                  ref = { (el) => addcatlogdropdownRefs.current[category.id] = el }
                                >
                                  <div className = "py-1" role = "none">
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex" onClick = { () => handleAddCategory(category.id) }>
                                      <div className = 'self-center'>
                                        <span className = 'text-secondary'><ControlPointOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2'>
                                        <p className = 'text-sm text-blacklight'>Add a subcategory</p>
                                      </div>
                                    </a>
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex">
                                      <div className = 'self-center'>
                                        <span className = 'text-secondary'><VisibilityOffOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2'>
                                        <p className = 'text-sm text-blacklight'>Hide classification</p>
                                      </div>
                                    </a>
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex">
                                      <div className = 'self-center'>
                                        <span className = 'text-secondary'><VisibilityOffOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2'>
                                        <p className = 'text-sm text-blacklight'>Hide classification in the application</p>
                                      </div>
                                    </a>
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex">
                                      <div className = 'self-center'>
                                        <span className = 'text-secondary'><VisibilityOffOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2'>
                                        <p className = 'text-sm text-blacklight'>Hide product category</p>
                                      </div>
                                    </a>
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex">
                                      <div className = 'self-center'>
                                        <span className = 'text-secondary'><SettingsOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2'>
                                        <p className = 'text-sm text-blacklight'>SEO improvements</p>
                                      </div>
                                    </a>
                                    <a href = "javascript:void(0)" className = "text-blacklight px-4 py-1.5 text-sm flex">
                                      <div className = 'self-center'>
                                        <span className = 'text-red'><DeleteOutlineOutlinedIcon style = { { fontSize: '20px' } } /></span>
                                      </div>
                                      <div className = 'self-center ml-2' onClick = { () => handleDeleteCategory(category.id) }>
                                        <p className = 'text-sm text-red'> Delete classification</p>
                                      </div>
                                    </a>
                                  </div>
                                </div> }
                              </div>
                            </div>
                          </li>
                        ) }
                      </Draggable>
                    )) }
                    { provided.placeholder }
                  </ul>
                ) }
              </Droppable>
            </DragDropContext>
            <div><button className = 'bg-[#f0f3f3] block text-sm py-2.5 px-4 rounded text-blacklight w-full font-medium mt-5' onClick = { handleAddCategory }><span className = 'text-secondary'><AddCircleOutlinedIcon style = { { fontSize: '20px' } } /></span> Add a new category</button></div>
          </div>
        </div>
      </div>
      { selectedCategoryId && <AddcatlogimageModal categoryId = { selectedCategoryId } setSelectedCategoryId = { setSelectedCategoryId } /> }
    </>
  );
};

export default ProductClassifications;
