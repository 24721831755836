/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const Citylist = ({handlecity, selectedCity, citylistdata}) => {


  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedCity }
        onChange = { handlecity }
        options = { citylistdata }
        isMulti
        placeholder = 'Select city'
      />
    </>
  );
};

export default Citylist;