import React from 'react';
import ChartpaymentMethods from './ChartpaymentMethods';
import ShippingCompaniesChart from './ShippingCompaniesChart';
import ShippingCostProfits from './ShippingCostProfits';



const PaymentShipping = () => {

  return(
    <>
      <div className = 'flex flex-wrap justify-between'>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Payment methods</h3>
            <ChartpaymentMethods />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Shipping companies</h3>
            <ShippingCompaniesChart />
          </div>
        </div>
      </div>
      <div className = 'w-full bg-white shadow-customcard rounded-md mt-5'>
        <div className = 'p-3'>
          <h3 className = 'text-base text-blacklight font-medium pb-3'><span className = 'text-sm text-secondary'>(Total shipping profit: 70 SAR)</span> Shipping costs and profits</h3>
          <ShippingCostProfits />
        </div>
      </div> 
    </>
  );
};

export default PaymentShipping;