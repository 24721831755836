import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
// import { Link } from "react-router-dom";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import toast from 'react-hot-toast';
import { useAddtestimonialListMutation, useDeletetestimonialMutation, usePermissiontestimonialMutation, useTestimonialListQuery } from '../../redux/api/testimonialApi';
import Edittestimonial from './Edittestimonial';


const Testimonials = () => {
  const [AddTestimonials, setAddTestimonials] = useState(false);

  const onOpenAddTestimonials = () => setAddTestimonials(true);
  const onCloseAddTestimonials = () => setAddTestimonials(false);

  const [EditTestimonials, setEditTestimonials] = useState(false);


  const { error, data, isLoading, refetch } = useTestimonialListQuery();

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [position, setPosition] = useState('');
  const [imgurl, setImgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const handleNamechange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleTitlechange = (e) => {
    setTitle(e.target.value);
    setErrorMessge('');
  };

  const handleMessagechange = (e) => {
    setMessage(e.target.value);
    setErrorMessge('');
  };

  const handlePositionchange = (e) => {
    setPosition(e.target.value);
    setErrorMessge('');
  };

  const handleImagechange = (e) => {
    setImgurl(e.target.value);
    setErrorMessge('');
  };

  const [addtestimonialList,{error: erroraddtestimonial, isLoading: isLoadingaddtestimonial, isSuccess: isSuccessaddtestimonial}] = useAddtestimonialListMutation();

  const handleSubmit = async() => {

    const formdata = new FormData();

    formdata.append('user', name);
    formdata.append('title', title);
    formdata.append('message', message);
    formdata.append('position', position);
    formdata.append('image_url', imgurl);
    
    try{
      await addtestimonialList(formdata);

    } catch(error){
      setErrorMessge('Failed to add testimonial', error);
    }

  };

  useEffect(() => {

    if(isSuccessaddtestimonial){
      setErrorMessge('');
      refetch();
      setName('');
      setTitle('');
      setMessage('');
      setPosition('');
      setImgurl('');
      onCloseAddTestimonials();
    }

  },[isSuccessaddtestimonial]);


  const [currentTestimonial, setCurrentTestimonial] = useState(null);

  const onOpenEditTestimonials = (item) => {
    setEditTestimonials(true);
    setCurrentTestimonial(item);
  };

  const [deletetestimonial,{ error: deletetestimonialerror, isSuccess: deletetestimonialisSuccess }] = useDeletetestimonialMutation();

  useEffect(() => {

    if(deletetestimonialerror){
      toast(deletetestimonialerror?.data?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletetestimonialisSuccess){
      refetch();
    }

  }, [deletetestimonialisSuccess]);

  const handletestimonialDelete = async (id) => {
    try {
      await deletetestimonial(id);
    } catch (error) {
      console.error('Error deleting fragrance:', error);
    }
  };

  const [permissiontestimonial,{error: errorpermission,data: datapermission , isSuccess: isSuccesspermission}] = usePermissiontestimonialMutation();

  useEffect(() => {

    if(errorpermission){
      toast(errorpermission?.data?.errorpermission);
    }

  }, [errorpermission]);

  useEffect(() => {

    if(isSuccesspermission){
      // toast(datapermission?.message);
      refetch();
    }
    
  },[isSuccesspermission,datapermission]);

  const handlePermission = async (item) => {

    const formdata = new FormData();

    formdata.append('action', item?.is_approved === false?'approve': 'deapprove');

    try{
      await permissiontestimonial({ body: formdata, id: item?.id });

    } catch(error){
      setErrorMessge('Failed to update testimonial', error);
    }

  };
 

  return (
    <>
      <ToastContainer />
      <Edittestimonial EditTestimonials = { EditTestimonials } setEditTestimonials = { setEditTestimonials } currentTestimonial = { currentTestimonial } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">

            <div className = "mb-8">
              <button
                onClick = { onOpenAddTestimonials }
                className = "bg-[#f0f3f3] px-4 py-2 rounded text-black mt-3"
              >
                <span>
                  <AddOutlinedIcon style = { { fontSize: '20px' } } />
                </span>{ ' ' }
                   Add Testimonial
              </button>
            </div>
            <div className = "testimonialsmodal rounded p-3 border border-borderColor">
              <div className = "mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className = "inline-block min-w-full overflow-hidden">
                  <table className = "min-w-full leading-normal">
                    <thead>
                      <tr>
                       
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        USER
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        TITLE
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        MESSAGE
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        POSITION
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      { error && <p>{ error?.message }</p> }
                      { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
                      { data?.data?.length === 0 && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }

                      {
                        data?.data?.map((item) => (
                          <tr key = { item?.id }>
                            <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                              <p className = "text-blacklight whitespace-no-wrap">
                                { item?.user?item?.user: 'NA' }
                              </p>
                            </td>
                            <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                              <p className = "text-blacklight whitespace-no-wrap">
                                { item?.title?item?.title: 'NA' }
                              </p>
                            </td>
                            <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                              <p className = "text-blacklight whitespace-no-wrap">{ item?.message?item?.message: 'NA' }</p>
                            </td>
                            <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                              <p className = "text-blacklight whitespace-no-wrap">
                                { item?.position?item?.position: 'NA' }
                              </p>
                            </td>
                            <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">

                              <div className = "py-1 flex">
                                <button onClick = { () => onOpenEditTestimonials(item) }><span className = "cursor-pointer text-center text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded mr-2">
                                  <EditOutlinedIcon style = { { fontSize: '20px' } } />
                                </span></button>
                                <span onClick = { () => handlePermission(item) } className = "cursor-pointer text-center text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded mr-2">
                                  { item?.is_approved === false?<DoneIcon style = { { fontSize: '24px' } } />: <DoDisturbAltIcon style = { { fontSize: '20px' } } /> }
                                </span>
                                <span onClick = { () => handletestimonialDelete(item?.id) } className = "cursor-pointer text-center text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded">
                                  <DeleteOutlineOutlinedIcon style = { { fontSize: '20px' } } />
                                </span>                            
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className = "testimonial-modal">
        <Modal open = { AddTestimonials } showCloseIcon = { false } onClose = { onCloseAddTestimonials }>
          <div className = "bannermodal">
            <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
              <h3 className = "text-blacklight text-base font-semibold self-center">
                Add Testimonial
              </h3>
              <div className = "self-center">
                <span
                  onClick = { onCloseAddTestimonials }
                  className = "cursor-pointer text-blacklight"
                >
                  <CloseOutlinedIcon style = { { fontSize: '20px' } } />
                </span>
              </div>
            </div>
            <div className = "flex-col items-center py-8 px-8 text-center ">
              <div className = "text-start">
                
                <div className = "mt-3">
                  <label className = "text-blacklight text-sm self-center block">
                    User
                  </label>
                  <input
                    placeholder = "Enter username"
                    value = { name }
                    onChange = { handleNamechange }
                    className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                  />
                  { erroraddtestimonial && (
                    <div className = "mt-2 text-red text-sm">
                      { erroraddtestimonial?.data?.message?.user }
                    </div>
                  ) }
                </div>
                <div className = "mt-3">
                  <label className = "text-blacklight text-sm self-center block">
                    Title
                  </label>
                  <input
                    placeholder = "Enter title"
                    onChange = { handleTitlechange }
                    value = { title }
                    className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                  />
                  { erroraddtestimonial && (
                    <div className = "mt-2 text-red text-sm">
                      { erroraddtestimonial?.data?.message?.title }
                    </div>
                  ) }
                </div>
                <div className = "mt-3">
                  <label className = "text-blacklight text-sm self-center block">
                    Image url (size: 200 × 200px)
                  </label>
                  <input
                    placeholder = "Enter image url"
                    onChange = { handleImagechange }
                    value = { imgurl }
                    className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                  />
                  { erroraddtestimonial && (
                    <div className = "mt-2 text-red text-sm">
                      { erroraddtestimonial?.data?.message?.image_url }
                    </div>
                  ) }
                </div>
                <div className = "mt-3">
                  <label className = "text-blacklight text-sm self-center block">
                    Message
                  </label>
                  <textarea name = "postContent"
                    placeholder = "Enter meaasge"
                    value = { message }
                    onChange = { handleMessagechange }
                    rows = { 4 } cols = { 40 } className = "mt-1 inline-block text-sm w-full px-2 py-2 rounded outline-0 border border-borderColor" />
                  { erroraddtestimonial && (
                    <div className = "mt-2 text-red text-sm">
                      { erroraddtestimonial?.data?.message?.message }
                    </div>
                  ) }
                </div>
                <div className = "mt-3">
                  <label className = "text-blacklight text-sm self-center block">
                      Position
                  </label>
                  <input
                    placeholder = "Enter position"
                    value = { position }
                    onChange = { handlePositionchange }
                    className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                  />
                  { erroraddtestimonial && (
                    <div className = "mt-2 text-red text-sm">
                      { erroraddtestimonial?.data?.message?.position }
                    </div>
                  ) }
                </div>
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <div className = "mt-3">
                  <button onClick = { handleSubmit } className = "text-sm inline-block text-white bg-primary px-5 py-2 rounded">{ isLoadingaddtestimonial?'Saving': 'Save' }</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Testimonials;
