import React from 'react';
import { Link } from 'react-router-dom';

const ReportProduct = () => {

  return(
    <>
      <h3 className = 'text-base text-blacklight font-medium py-2 px-2'>The most profitable products</h3>
      <div className = 'border border-borderColor rounded bg-white'>
        <div className = 'modifyproduct custom-scroll'>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <div className = 'w-96 p-2'><p className = 'text-blacklight text-base'>the product</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>Product cost</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>selling price</p></div>
            </li>
                
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>Product profits</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-60'><p className = 'text-blacklight text-base'>Number of product requests</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-96 p-2'>
                <div className = 'flex'>
                  <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/Yq1mBYKZljzRbpO4GcHjCoJHXRbKElyteusGJjaV.jpg' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>Prada Luna Rossa Sport Eau de Toilette</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>220 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>455 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>235 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>1</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-96 p-2'>
                <div className = 'flex'>
                  <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/Yq1mBYKZljzRbpO4GcHjCoJHXRbKElyteusGJjaV.jpg' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>Prada Luna Rossa Sport Eau de Toilette</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>220 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>455 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>235 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>1</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-96 p-2'>
                <div className = 'flex'>
                  <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/Yq1mBYKZljzRbpO4GcHjCoJHXRbKElyteusGJjaV.jpg' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>Prada Luna Rossa Sport Eau de Toilette</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>220 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>455 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>235 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>1</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-96 p-2'>
                <div className = 'flex'>
                  <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/Yq1mBYKZljzRbpO4GcHjCoJHXRbKElyteusGJjaV.jpg' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>Prada Luna Rossa Sport Eau de Toilette</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>220 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>455 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>235 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>1</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-96 p-2'>
                <div className = 'flex'>
                  <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/Yq1mBYKZljzRbpO4GcHjCoJHXRbKElyteusGJjaV.jpg' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>Prada Luna Rossa Sport Eau de Toilette</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>220 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>455 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>235 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>1</p></div>
            </li>
          </ul>
          
        </div>
      </div>
    </>
  );
};

export default ReportProduct;