import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const homecatlogApi = createApi({
  reducerPath: 'homecatlogApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    homecatloglist: builder.query({
      query: () => '/store/admin/list-home-category/'
    }),
    addhomecatloglist: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/create-home-category/',
          method: 'POST',
          body
        };
      }
    }),
    updatehomecatlog: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/update-home-category/${id}/`,
          method: 'PUT',
          body
        };
      }
    }),
    deletehomecatlog: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/delete-home-category/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    gethomecatlogbyid: builder.query({
      query: (id) => `/store/admin/retrieve-home-category/${id}/`
    }),
    catloglist: builder.query({
      query: ({size, search}) => {
        let url = `/store/admin/list-categories/?size=${size}`;
        if(search){
          url += `&search=${search}`;
        }
        return url;
      }
    })
  })
});

export const {useCatloglistQuery, useHomecatloglistQuery, useAddhomecatloglistMutation, useUpdatehomecatlogMutation, useGethomecatlogbyidQuery, useDeletehomecatlogMutation } = homecatlogApi;
