import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ProductsQuantityEditor = () => {

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <div>
              <ul className = 'flex text-sm'>
                <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li><span className = 'text-secondary'>Modify quantities</span></li>
              </ul>
            </div>
            <p className = 'text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
          </div>
          <div className = 'border border-borderColor rounded mt-5'>
            <div className = 'flex justify-between px-2'>
              <div className = 'self-center'><button className = 'inline-block px-5 py-2 bg-[#f0f3f3] rounded'>Save modifications</button></div>
              <h3 className = 'self-center text-base font-medium text-blacklight py-3 pl-5'>Modify the quantity of products</h3>
            </div>
            <div className = 'flex border-t border-b border-borderColor py-2'>
              <span className = 'inline-block w-8 text-secondary pl-3'><SearchOutlinedIcon style = { {fontSize: '20px'} } /></span>
              <input placeholder = 'Search by product name or SKU' className = 'flex-1 ml-2 text-sm outline-none' />
            </div>
            <div className = 'modifyproduct custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Product name</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Branches</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Unlimited quantity</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Quantity</p></div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'checkbox' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>50</p></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsQuantityEditor;
