import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddtrendingnowMutation, useAddtrendingnowlistQuery, useCatloglistQuery, useDeletetrendingnowMutation } from '../../redux/api/TrendingnowApi';
import Edittrendingnow from './Edittrendingnow';

const TrendingNow = () => {

  const [Addtrendingnow, setAddtrendingnow] = useState(false);
  const onOpenAddtrendingnow = () => setAddtrendingnow(true);
  const onCloseAddtrendingnow = () => setAddtrendingnow(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const [selectedCategoryid, setSelectedCategoryid] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [Catloglist, setCatloglist] = useState([]);
  
  const { error, data, isLoading, refetch } = useAddtrendingnowlistQuery();
  
  const [name, setname] = useState('');
  const [description, setDescription] = useState('');
  const [imageurl, setImageurl] = useState('');
  const [url, setUrl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: categoryisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (categoryisSuccess) {
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };
      const options = categorydata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setCatloglist([customOption, ...(options || [])]);
    }
  }, [categoryisSuccess, categorydata]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChangeproduct = (selectedOption) => {
    setSelectedCategoryOption(selectedOption);
    setSelectedCategoryid(selectedOption?.label);
  };
  
  const [Addtrendingnowigation, { error: trendingnowerror, isSuccess: trendingnowisSuccess, isLoading: trendingnowisloading }] = useAddtrendingnowMutation();


  const handlenameChange = (e) => {
    setname(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleImageurl = (e) => {
    setImageurl(e.target.value);
    setErrorMessge('');
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {

    if (trendingnowisSuccess) {
      setErrorMessge('');
      onCloseAddtrendingnow();
      refetch();
      setname('');
      setUrl('');
      setImageurl('');
      setDescription('');
      setSelectedCategoryOption([]);
    }
  },[trendingnowisSuccess]);

  const handleSubmit = async () => {
   
    if (!name || !description || !imageurl || (!url && !selectedCategoryid)) {
      setErrorMessge('Please fill all fields');
      return;
    }
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', imageurl);
    formData.append('description', description);
    formData.append('url', selectedCategoryid === 'Custom URL'?url:selectedCategoryid);

    try {
      await Addtrendingnowigation(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add trending now: ', error);
    }
  };


  const [edittrendingnow, setedittrendingnow] = useState(false);
  const [currentTrending, setcurrentTrending] = useState(null);

  const openEdittrendingnow = (item) => {
    setcurrentTrending(item);
    setedittrendingnow(true);
  };


  const [deletetrendingnow,{ error: deletetrendingnowerror, isSuccess: deletetrendingnowisSuccess }] = useDeletetrendingnowMutation();

  useEffect(() => {

    if(deletetrendingnowerror){
      toast(deletetrendingnowerror?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletetrendingnowisSuccess){
      refetch();
    }

  }, [deletetrendingnowisSuccess]);

  const handleCatlogDelete = async (id) => {
    try {
      await deletetrendingnow(id);
    } catch (error) {
      console.error('Error deleting trendingnow:', error);
    }
  };

  return (
    <>
      <Edittrendingnow edittrendingnow = { edittrendingnow } currentTrending = { currentTrending } setedittrendingnow = { setedittrendingnow } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <button
              onClick = { onOpenAddtrendingnow }
              className = "inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded"
            >
              <span>
                <AddOutlinedIcon style = { { fontSize: '20px' } } />
              </span>{ ' ' }
                Add Trending Now
            </button>

            <div className = "border border-borderColor rounded mt-1 p-3">
              <table className = "min-w-full leading-normal ">
                <thead>
                  <tr>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                     Name
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                     Image
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                      URL
                    </th>
                    <th className = "text-right px-5 py-3 border-b-2 border-boderColor-300  text-sm  text-gray-700 font-medium uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { error && <p>{ error?.message }</p> }
                  { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
                  { data?.data?.length === 0 && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }
                  { data?.data.map((item) => (
                    <tr key = { item?.id }>
                      <td className = "px-5 py-3 bg-white text-sm text-left border-b border-boderColor-300">
                        <p className = 'text-sm text-blacklight'>{ item?.name }</p>
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left border-b border-boderColor-300">
                        <img className = 'w-10' src = { item?.image } alt = 'trending img now' />
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left border-b border-boderColor-300">
                        <p className = 'text-sm text-blacklight'>{ item?.url }</p>
                      </td>
                      <td className = "px-2 py-3 bg-white text-right border-b border-boderColor-300">
                        <button
                          onClick = { () => openEdittrendingnow(item) }
                          className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                        >
                          <span>
                            <EditOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </button>
                        <button onClick = { () => handleCatlogDelete(item?.id) } className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                          <span>
                            <DeleteOutlineOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open = { Addtrendingnow }
        showCloseIcon = { false }
        onClose = { onCloseAddtrendingnow }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add Trending Now
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddtrendingnow }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
                Name)
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter name"
                value = { name }
                onChange = { handlenameChange }
              />
              
              { trendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { trendingnowerror?.data?.message?.name }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-2">
                Image(url)
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter image url"
                value = { imageurl }
                onChange = { handleImageurl }
              />
              
              { trendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { trendingnowerror?.data?.message?.image }
                </div>
              ) }


              <label className = "text-blacklight text-sm self-center block mt-2">
              Description
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter description"
                value = { description }
                onChange = { handleDescription }
              />
              
              { trendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { trendingnowerror?.data?.message?.description }
                </div>
              ) }

              <div>
                <Select
                  className = 'inline-block text-sm mt-3 outline-0 w-full text-secondary'
                  value = { selectedCategoryOption }
                  onChange = { handleChangeproduct }
                  options = { Catloglist }
                  inputValue = { inputValue }
                  onInputChange = { handleInputChange }
                  placeholder = 'Choose category'
                />
              </div>
              {
                selectedCategoryid === 'Custom URL' && <input
                  className = "mt-3 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter url"
                  value = { url }
                  onChange = { handleUrlChange }
                />
              }
              
              { trendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { trendingnowerror?.data?.message?.url }
                </div>
              ) }
              
              <div>
                
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <button
                  className = "text-sm inline-block mt-24 text-white bg-primary px-5 py-2 rounded"
                  onClick = { handleSubmit }
                  disabled = { trendingnowisloading }
                >
                  { trendingnowisloading ? 'Saving...' : 'Save' }
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      
    </>
  );
};

export default TrendingNow;
