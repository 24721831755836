/* eslint-disable react/prop-types */
import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDeletebrandMutation, useGetbrandbyidQuery, useUpdatebrandMutation } from '../../redux/api/brandApi';

const Editbrand = ({isopenEditbrand,setIsopenEditbrand,currentBrand,refetch }) => {
  const onCloseEditBrandModal = () => {
    setIsopenEditbrand(false);
  };

  const [selectedLanguage, setSelectedLanguage] = useState('Arabic');
  
  const handleSelectChange = (e) => {
    setSelectedLanguage(e.target.value);
  };


  const [brandPagetitleArabicValue, setBrandPagetitleArabicValue] = useState('');
  const [brandPagetitleEnglishValue, setBrandPagetitleEnglishValue] = useState('');

  const handleBrandPagetitleArabicInputChange = (e) => {
    setBrandPagetitleArabicValue(e.target.value);
  };

  const handleBrandPagetitleEnglishInputChange = (e) => {
    setBrandPagetitleEnglishValue(e.target.value);
  };


  const [seobrandArabicValue, setSeobrandArabicValue] = useState('');
  const [seobrandEnglishValue, setSeobrandEnglishValue] = useState('');

  const handleSeobrandArabicInputChange = (e) => {
    setSeobrandArabicValue(e.target.value);
  };

  const handleSeobrandEnglishInputChange = (e) => {
    setSeobrandEnglishValue(e.target.value);
  };


  const [brandpageDescriptionArValue, setBrandpageDescriptionArValue] = useState('');
  const [brandpageDescriptionEnValue, setBrandpageDescriptionEnValue] = useState('');

  const handleBrandpageDescriptionArInputChange = (e) => {
    setBrandpageDescriptionArValue(e.target.value);
  };

  const handleBrandpageDescriptionEnInputChange = (e) => {
    setBrandpageDescriptionEnValue(e.target.value);
  };


  const [name, setName] = useState('');
  const [bannerurl, setBannerurl] = useState('');
  const [logoimgurl, setlogoimgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  
  const [updatebrand, { error: branderror, data: addbranddatat, isSuccess: brandisSuccess, isLoading: brandisloading }] = useUpdatebrandMutation();


  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setBannerurl(e.target.value);
    setErrorMessge('');
  };

  const handlelogoimgurlChange = (e) => {
    setlogoimgurl(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {

    if (brandisSuccess) {
      setErrorMessge('');
      refetch();
      onCloseEditBrandModal();
    }
  },[brandisSuccess,addbranddatat]);

  const handleSubmit = async () => {
    if (!bannerurl || !logoimgurl || !name) {
      setErrorMessge('Please provide Name, brand image url and banner url.');
      return;
    }
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('banner_image_url', bannerurl);
    formData.append('logo_image_url', logoimgurl);

    try {
      await updatebrand({ body: formData, id: currentBrand?.id });
      
    } catch (error) {
      setErrorMessge('Failed to update brand: ', error);
    }
  };


  const { error: getbrandError, data: getbrandtdata, isLoading: getbrandisloading } = useGetbrandbyidQuery(currentBrand?.id, { skip: !currentBrand?.id });

  useEffect(() => {
    if (getbrandtdata) {
      setName(getbrandtdata?.data?.name);
      setBannerurl(getbrandtdata?.data?.banner_image_url);
      setlogoimgurl(getbrandtdata?.data?.logo_image_url);
    }
  }, [getbrandtdata, currentBrand?.id]);



  const [deletebrand,{error: errordeletebrand, data: datadeletebrand, isLoading: isLoadingdeletebrand, isSuccess: isSuccessdeletebrand}] = useDeletebrandMutation();

  useEffect(() => {

    if(errordeletebrand){
      toast(errordeletebrand?.data?.message);
    }

  },[errordeletebrand]);

  const handledeleteBrand = async (id) => {
    try {
      await deletebrand(id);
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  useEffect(() => {

    if(isSuccessdeletebrand){
      onCloseEditBrandModal();
      refetch();
    }

  },[isSuccessdeletebrand,datadeletebrand]);
  
  return (
    <>
      <ToastContainer />
      <Modal open = { isopenEditbrand } showCloseIcon = { false } onClose = { onCloseEditBrandModal }>
        <div className = 'brandmodal'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3>Edit brand</h3>
            <div className = 'self-center'><span onClick = { onCloseEditBrandModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = "flex-col items-center border border-borderColor rounded py-4 px-4 text-center">
              { getbrandError && <p className = 'text-sm text-red'>{ getbrandError?.data?.message }</p> }
              { getbrandisloading && <p className = 'text-sm'>{ getbrandisloading ? 'Brand data loading...' : '' }</p> }
              <div className = "text-start">
                <label className = "text-blacklight text-sm self-center block">
                    Name
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter brand name"
                  value = { name }
                  onChange = { handleNameChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.name }
                  </div>
                ) }

                <label className = "text-blacklight text-sm self-center block mt-3">
              Banner url
                </label>
                <input
                  placeholder = "Enter banner url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { bannerurl }
                  onChange = { handleUrlChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.banner_image_url }
                  </div>
                ) }
                <label className = "text-blacklight text-sm self-center block mt-3">
              Brand image url
                </label>
                <input
                  placeholder = "Enter brand image url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { logoimgurl }
                  onChange = { handlelogoimgurlChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.logo_image_url }
                  </div>
                ) }
              
                <div>
               
                
                  <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                  <button
                    className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                    onClick = { handleSubmit }
                    disabled = { brandisloading }
                  >
                    { brandisloading ? 'Saving...' : 'Save' }
                  </button>
                </div>
              </div>
            </div>
            <ul className = 'border border-borderColor rounded p-4 mt-5'>
              <li>
                <h3 className = 'text-sabe font-medium text-blacklight'>SEO improvements</h3>
                <label className = 'text-sm text-blacklight inline-block mt-3'>Brand Page Title</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <BurstModeOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <input
                      placeholder = "Brand Page Title"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandPagetitleArabicValue }
                      onChange = { handleBrandPagetitleArabicInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <input
                      placeholder = "Brand Page Title"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandPagetitleEnglishValue }
                      onChange = { handleBrandPagetitleEnglishInputChange }
                    />
                  ) }
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block'>Brand Page URL (SEO Page URL)</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <InsertLinkOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <input
                      placeholder = "Brand Page URL (SEO Page URL)"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { seobrandArabicValue }
                      onChange = { handleSeobrandArabicInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <input
                      placeholder = "Brand Page URL (SEO Page URL)"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { seobrandEnglishValue }
                      onChange = { handleSeobrandEnglishInputChange }
                    />
                  ) }
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block'>Brand Page Description</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <DescriptionOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <textarea
                      placeholder = "Brand Page Description"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandpageDescriptionArValue }
                      onChange = { handleBrandpageDescriptionArInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <textarea
                      placeholder = "Brand Page Description"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandpageDescriptionEnValue }
                      onChange = { handleBrandpageDescriptionEnInputChange }
                    />
                  ) }
                </div>
              </li>
            </ul>
            <div className = 'flex justify-between mt-8'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Addition</button>
              <button onClick = { () => handledeleteBrand(getbrandtdata?.data?.id) } className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-red'>{ isLoadingdeletebrand?'Deleting brand': 'Delete the brand permanently' }</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Editbrand;
