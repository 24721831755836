/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */


import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import BentoOutlinedIcon from '@mui/icons-material/BentoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import LaptopWindowsOutlinedIcon from '@mui/icons-material/LaptopWindowsOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useGetshipingcompanylistbyidQuery } from '../../redux/api/ShippingApi';
import ShippingAcordian from './ShippingAcordian';
import ShippingPrice from './ShippingPrice';


const ShippingModalView = ({isaramexOpen, setIsaramexOpen, editshipcompanyid}) => {

  const [aramexModal, setAramexModal] = useState(false);
  const [shippingtab, setShippingtab] = useState(1);

  const handleShipingtab = (item) => {
    setShippingtab(item);
  };

  useEffect(() => {

    if(isaramexOpen == true) {
      setAramexModal(true);
    }else{
      setAramexModal(false);
    }

  },[isaramexOpen]);

  const onCloseAramexModal = () => {
    setAramexModal(false);
    setIsaramexOpen(false);
  };


  const {error: shippingerror, data: shippingdata} = useGetshipingcompanylistbyidQuery( editshipcompanyid,
    {
      skip: !editshipcompanyid
    });
  
  useEffect(() => {

    if(shippingerror){
      toast(shippingerror?.data?.message);
    }

  },[shippingerror]);


  return(
    <>
      <ToastContainer />
      <Modal open = { aramexModal } showCloseIcon = { false } onClose = { onCloseAramexModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>smsa</h3>
            <div className = 'self-center'><span onClick = { onCloseAramexModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = 'flex'>
              <div className = "w-12 self-center"><img className = "w-full" src = { shippingdata?.data?.image } alt = 'shippinglogimg' /></div>
              <div className = "ml-3 self-center">
                <h3 className = "text-base text-blacklight font-medium">{ shippingdata?.data?.name }</h3>
              </div>
            </div>
            <div className = 'mt-8'>
              <div className = 'flex border border-borderColor rounded justify-between'>
                <div className = { `w-1/5 text-center py-3 cursor-pointer ${shippingtab === 1? 'bg-[#f0f3f3]': ''}` } onClick = { () => handleShipingtab(1) }>
                  <span className = 'text-blacklight'><LaptopWindowsOutlinedIcon style = { {fontSize: '20px'} } /></span>
                  <span className = 'text-sm text-blacklight inline-block ml-1'>Display data</span>
                </div>
               
                <div className = { `w-1/5 text-center py-3 cursor-pointer ${shippingtab === 3? 'bg-[#f0f3f3]': ''}` } onClick = { () => handleShipingtab(3) }>
                  <span className = 'text-blacklight'><RequestQuoteOutlinedIcon style = { {fontSize: '20px'} } /></span>
                  <span className = 'text-sm text-blacklight inline-block ml-1'>Shipping price</span>
                </div>
                <div className = { `w-1/4 text-center py-3 cursor-pointer ${shippingtab === 4? 'bg-[#f0f3f3]': ''}` } onClick = { () => handleShipingtab(4) }>
                  <span className = 'text-blacklight '><FolderZipOutlinedIcon style = { {fontSize: '20px'} } /></span>
                  <span className = 'text-sm text-blacklight inline-block ml-1'>additional information</span>
                </div>
                
              </div>

              {
                shippingtab === 1 && <>
                  <h3 className = 'text-sm text-blacklight font-semibold mt-5'>Brief</h3>
                  <p className = 'text-sm text-secondary mt-1'>SMSA Express is the first Saudi leading express transportation company.</p>
                  <h3 className = 'text-sm text-blacklight font-semibold mt-5'>Display name</h3>
                  <p className = 'text-sm text-secondary mt-1'>You can customize the name of the shipping company that is displayed to customers in the list of shipping companies available to each customer on the order checkout page</p>
                  <ul>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><BentoOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'Fast shipping company' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <h3 className = 'text-blacklight text-base font-semibold'>Offer data in the policy</h3>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Store Name</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><ApartmentOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'Store Name' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>The sender's name</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><PersonOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'The senders name' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Mobile number</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalPhoneOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'Contact number in the policy' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    
                    <li className = 'mt-3'>
                      <p><input type = 'checkbox' id = "sama" /> <label htmlFor = "sama" className = 'text-sm text-blacklight align-text-top ml-1'>Request to print the policy automatically after sending the request to SMSA</label></p>
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between mt-8'>
                        <div>
                          <button className = 'text-sm bg-primary py-2 px-4 rounded text-white inline-block'>Save</button>
                        </div>
                        <div>
                          <button className = 'text-sm py-1.5 px-8 rounded text-blacklight inline-block border border-borderColor'>Exit</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </>
              }

              {
                shippingtab === 3 && <ShippingPrice editshipcompanyid = { editshipcompanyid } />
              }

              {
                shippingtab === 4 && <>
                  <ul className = 'mt-5'>
                    <li>
                      <div className = 'flex justify-between'>
                        <p className = 'text-sm text-blacklight'>Phone number:</p>
                        <p className = 'text-sm text-blacklight'>920027447</p>
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between'>
                        <p className = 'text-sm text-blacklight'>Mobile number:</p>
                        <p className = 'text-sm text-blacklight'>8001000880</p>
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between'>
                        <p className = 'text-sm text-blacklight'>Email:</p>
                        <p className = 'text-sm text-blacklight'><Link to = '#'>support@salla.sa </Link></p>
                      </div>
                    </li>
                  </ul>

                  <h3 className = 'text-base text-blacklight mt-4'>Common questions</h3>
                  <ul>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>Does smsa accept shipping liquid products?</p> }
                        answer = { 
                          <p className = 'text-blacklight text-sm mt-2'>At the present time, it is not possible to ship liquids through discounted packages. To ship liquids,
                           you must open a special account with smsa, know their conditions for shipping liquids, and obtain
                            special approval from them.
                          </p>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>Do you support domestic and international shipping?</p> }
                        answer = { 
                          <p className = 'text-blacklight text-sm mt-2'>Currently, basket policies support local shipping only, and as for customers with their own accounts, shipping varies according to the offer presented by the company ?
                          </p>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>Is a representative available to receive orders?</p> }
                        answer = { 
                          <p className = 'text-blacklight text-sm mt-2'>Yes
                          </p>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>How is the shipping price calculated?</p> }
                        answer = { 
                          <p className = 'text-blacklight text-sm mt-2'>For discounted policies that currently support local shipping, the first
                           15 kilograms will cost 24 Saudi riyals, and each additional kilo will be 2 riyals. As for private 
                           accounts, it will be according to the agreement with smsa.
                          </p>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>Does smsa provide packaging materials?</p> }
                        answer = { 
                          <>
                            <p className = 'text-blacklight text-sm mt-2'>
                            Yes, you can get packaging cartons for free according to the following sizes
                            </p>
                            <p className = 'text-blacklight text-sm'>Note: The BioCare Box is intended for laboratories and medical purposes, and there are dedicated procedures for requesting it.</p>
                          </>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>How can I obtain compensation for damaged shipments?</p> }
                        answer = { 
                          <>
                            <p className = 'text-blacklight text-sm mt-2'>
                            Send a message to the following email:
                            </p>
                            <p className = 'text-blacklight text-sm mt-2'>ALL_JED_CMT_Sallah_Support@smsa.com</p>
                            <p className = 'text-blacklight text-sm mt-2'>Explaining the following information in the message:</p>
                            <p className = 'text-blacklight text-sm mt-2'>Policy number: ?</p>
                            <p className = 'text-blacklight text-sm mt-2'>Mobile number: ?</p>
                            <p className = 'text-blacklight text-sm mt-2'>Basket account number in smsa: 165623</p>
                          </>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>What is the best time to contact smsa technical support?</p> }
                        answer = { 
                          <>
                            <p className = 'text-blacklight text-sm mt-2'>
                            From 8 am to 2 pm during working days, to ensure a quick response on the same day
                            </p>
                           
                          </>
                        }
                      />
                    </li>
                    <li className = 'mt-3'>
                      <ShippingAcordian question = { <p className = 'text-blacklight text-sm font-semibold'>What is the method of changing the client’s address after issuing the policy?</p> }
                        answer = { 
                          <>
                            <p className = 'text-blacklight text-sm mt-2'>
                            Send a message to the following email:
                            </p>
                            <p className = 'text-blacklight text-sm mt-2'>Send a message to the following email:</p>
                            <p className = 'text-blacklight text-sm mt-2'>ALL_JED_CMT_Sallah_Support@smsa.com</p>
                            <p className = 'text-blacklight text-sm mt-2'>With the following information explained in the message:</p>
                            <p className = 'text-blacklight text-sm mt-2'>Policy number: ??</p>
                            <p className = 'text-blacklight text-sm mt-2'>The correct address: ??</p>
                            <p className = 'text-blacklight text-sm mt-2'>smsa basket account number: 165623</p>
                            <p className = 'text-blacklight text-sm mt-2'>The address modification process will take 1-2 business days</p>
                           
                          </>
                        }
                      />
                    </li>
                  </ul>
                </>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShippingModalView;