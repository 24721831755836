import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useProductlistQuery, useUploadcsvMutation } from '../../redux/api/productApi';
import AddProduct from './AddProduct';
import CustomProductpagination from './CustomProductpagination';
import FilterAcordian from './FilterAcordian';
import Productlist from './Productlist';
import QuantityManagement from './QuantityManagement';
import Usereadymadetemplate from './Usereadymadetemplate';

const Product = () => {

  const crossmenu = process.env.PUBLIC_URL + '/images/icons/crossmenu.svg';
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const servicemenuRef = useRef(null);
  const [isOpenServicemenu, setIsOpenServicemenu] = useState(false);
  const [istemplateOpen, setIstemplateOpen] = useState(false);
  const [messagedata, setMessageData] = useState('');


  const toggleServicemenu = () => {
    setIsOpenServicemenu(!isOpenServicemenu);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (servicemenuRef.current && !servicemenuRef.current.contains(event.target)) {
        setIsOpenServicemenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  

  const [selectedProductId, setSelectedProductId] = useState(null);

  const [deleteAllProductModal, setDeleteAllProductModal] = useState(false);
  const onOpenDeleteAllProductModal = () => setDeleteAllProductModal(true);
  const onCloseDeleteAllProductModal = () => setDeleteAllProductModal(false);


  const [importModal, setImportModal] = useState(false);
  const onOpenImportModal = () => setImportModal(true);
  const onCloseOpenImportModal = () => setImportModal(false);

  const [exportModal, setExportModal] = useState(false);
  const onOpenExportModal = () => setExportModal(true);
  const onCloseExportModal = () => setExportModal(false);


  // upload csv file functionality

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadcsv, { error, isLoading, isSuccess }] = useUploadcsvMutation();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setMessageData('');
    if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setMessageData('Please upload a valid xlsx file.');
    }
  };


  useEffect(() => {

    if (isSuccess) {
      setMessageData('File uploaded successfully');
      onCloseOpenImportModal();
      refetch();
    }

  },[isSuccess]);

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('csv', selectedFile);

      try {
        await uploadcsv(formData).unwrap();
      } catch (err) {
        console.error('Failed to upload the file:', err);
      }
    } else {
      setMessageData('No file selected or invalid file format.');
    }
  };

  const [query, setQuery] = useState('');

  const resPerPage = 50;

  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {error: producterror,data: productdata,isLoading: productisLoading,refetch} = useProductlistQuery({ size: resPerPage, page: currentPage, search: query });

 


  return (
    <>
      <Helmet>
        <meta charset = "utf-8" />
        <title>Product</title>
        <meta name = "description" content = "Product details" />
      </Helmet>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <QuantityManagement selectedProductId = { selectedProductId } setSelectedProductId = { setSelectedProductId } />
          <Usereadymadetemplate istemplateOpen = { istemplateOpen } setIstemplateOpen = { setIstemplateOpen } />
          <div className = 'flex justify-between mb-5'>
            <div className = 'flex self-center cursor-pointer relative'>
              <p className = 'text-sm text-blacklight' onClick = { toggleServicemenu }><span className = 'text-secondary align-text-bottom'><BusinessCenterOutlinedIcon style = { {fontSize: '20px'} } /></span> Services</p>
              <p className = 'text-sm text-blacklight ml-5' onClick = { toggleMenu }><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
              { isOpenServicemenu && (
                <div
                  className = "absolute left-0 top-10 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role = "menu"
                  aria-orientation = "vertical"
                  aria-labelledby = "menu-button"
                  tabIndex = "-1"
                  ref = { servicemenuRef }
                >
                  <div className = "py-1" role = "none">
                    <Link to = "/product-classifications" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><DynamicFeedOutlinedIcon style = { {fontSize: '18px'} } /></span> Product classifications</Link>
                    <Link to = "/manage-filter" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '18px'} } /></span> Manage filters (beta)</Link>
                    <Link to = "/brands" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><WorkspacePremiumOutlinedIcon style = { {fontSize: '18px'} } /></span> Brands</ Link>
                    <Link to = "/arrange-products" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><SortOutlinedIcon style = { {fontSize: '18px'} } /></span> Arrange products</Link>
                    <Link to = "/products-bulk-editor" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><DriveFileRenameOutlineOutlinedIcon style = { {fontSize: '18px'} } /></span> Modify products</Link>
                    <Link to = "/products-quantity-editor" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><DriveFileRenameOutlineOutlinedIcon style = { {fontSize: '18px'} } /></span> Modify quantities</Link>
                    <Link to = "/products-bulk-pricing" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><DriveFileRenameOutlineOutlinedIcon style = { {fontSize: '18px'} } /></span> Price adjustment</Link>
                    <Link to = "/product-inventory" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><Inventory2OutlinedIcon style = { {fontSize: '18px'} } /></span> Product inventory</Link>
                    <Link to = "/products-log" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><Inventory2OutlinedIcon style = { {fontSize: '18px'} } /></span> Record quantities</Link>
                    <a href = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { onOpenImportModal }><span className = 'text-secondary'><CloudUploadOutlinedIcon style = { {fontSize: '18px'} } /></span> Import products</a>
                    <a href = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { onOpenExportModal }><span className = 'text-secondary'><CloudDownloadOutlinedIcon style = { {fontSize: '18px'} } /></span> Export products</a>
                    <a href = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { onOpenDeleteAllProductModal }><span className = 'text-secondary'><CancelOutlinedIcon style = { {fontSize: '18px'} } /></span> Delete all products</a>
                  </div>
                </div>
              ) }
            </div>
            <div className = 'self-center relative'>
              <div className = 'w-80'>
                <input value = { query } onChange = { (e) => setQuery(e.target.value) } placeholder = 'Search product' className = 'w-full border border-primary block py-2 rounded-full px-8 text-sm outline-primary' />
              </div>
              <button className = 'bg-primary block w-8 h-8 border-0 rounded-full text-white absolute top-0.5 right-1.5'><SearchOutlinedIcon /></button>
            </div>
          </div>
          <AddProduct />
          { producterror && <p>An error occurred: { producterror?.data?.message }</p> }
          { productisLoading && <p>Loading...</p> }
          { productdata?.data.length === 0 && 'No product found' }
          <div className = 'flex flex-wrap gap-x-1'>
            {
              productdata?.data?.map((item) => (
                <Productlist key = { item?.id } item = { item } query = { query } refetch = { refetch } />
              ))
            }
          </div>
          
          <CustomProductpagination
            resPerPage = { resPerPage }
            filteredProductsCount = { productdata?.total || 0 }
            onChange = { setCurrentPageNo }
          />
        
        </div>
      </div>

      <Modal open = { deleteAllProductModal } showCloseIcon = { false } onClose = { onCloseDeleteAllProductModal }>
        <div className = 'normalmodal'>
          <div className = 'flex justify-end bg-[#f0f3f3] px-4 py-3'>
            <div className = 'self-center'><span onClick = { onCloseDeleteAllProductModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3 text-center'>
            <span className = 'text-green'><ReportOutlinedIcon style = { {fontSize: '80px'} } /></span>
            <h3 className = 'text-black text-2xl font-semibold mt-3'>Alert</h3>
            <p className = 'text-sm text-blacklight mt-2'>All store products will be deleted. This step is irreversible</p>
            <div className = 'mt-5'>
              <button className = 'bg-[#f0f3f3] text-sm text-blacklight inline-block py-1.5 px-4 rounded' onClick = { onCloseDeleteAllProductModal }>Cancellation</button>
              <button className = 'bg-red text-sm text-white inline-block py-1.5 px-4 rounded ml-2'>Ok</button>
            </div>
          </div>
        </div>
      </Modal>

      { /* filter code open here */ }

      <div className = { `filtersidebar h-44 overflow-x-auto custom-scroll ${
        isMenuOpen ? 'filterslide-in' : 'filterslide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end cursor-pointer'><img className = 'w-5' src = { crossmenu } onClick = { toggleMenu } alt = 'crossmenu' /></p>
          <p className = 'text-blacklight text-base mb-5'><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span> Sort products by</p>
          <ul>
            <li className = 'mt-3'>
              <FilterAcordian  question = { <p className = 'text-blacklight text-sm'>Product status</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "gender_all"/>
                    <label  htmlFor = "gender_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "purchasing-all"/>
                    <label  htmlFor = "purchasing-all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Unpriced products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "loyal_all"/>
                    <label  htmlFor = "loyal_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Proven products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Hidden products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Hidden products from the store app</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Discounted products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Out of stock products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Products for sale</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Uncategorized products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Taxable products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Products require shipping</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Products are running out</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Products without description</label>
                  </div>
                 
                </div> } />
            </li>
            <li className = 'mt-3'>
              <FilterAcordian  question = {
                <p className = 'text-blacklight text-sm'>Product brand</p>
              }
              answer = {
                <ul>
                  <li className = 'mt-4'>
                    <select className = 'mt-1 border border-borderColor px-2 py-0.5 rounded w-full text-sm text-blacklight outline-none'>
                      <option>Brand </option>
                      <option>Everyone</option>
                    </select>
                  </li>
                </ul>
              }/>
            </li>
            <li className = 'mt-3'>
              <FilterAcordian  question = { <p className = 'text-blacklight text-sm'>Product Type</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "gender_all"/>
                    <label  htmlFor = "gender_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "purchasing-all"/>
                    <label  htmlFor = "purchasing-all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Ready product</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "loyal_all"/>
                    <label  htmlFor = "loyal_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Customized service</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>food</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Digital product</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Digital card</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Out of stock products</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Diamond_all"/>
                    <label  htmlFor = "Diamond_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Product range</label>
                  </div>
                </div> } />
            </li>
            <li className = 'mt-3'>
              <FilterAcordian  question = {
                <p className = 'text-blacklight text-sm'>Product classification</p>
              }
              answer = {
                <ul>
                  <li className = 'mt-4'>
                    <select className = 'mt-1 border border-borderColor px-2 py-0.5 rounded w-full text-sm text-blacklight outline-none'>
                      <option>Category</option>
                      <option>Women</option>
                      <option>Child</option>
                    </select>
                  </li>
                </ul>
              }/>
            </li>
            <li>
              <div className = 'flex mt-10'>
                <button className = 'bg-[#f0f3f3] px-4 py-1.5 text-sm rounded'>Reset</button>
                <button className = 'bg-primary px-4 py-1.5 text-sm rounded text-white flex-1 ml-3'>Show results</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      { /* filter code closed here */ }

      <Modal open = { importModal } showCloseIcon = { false } onClose = { onCloseOpenImportModal }>
        <div className = "modalboximport p-4">
          <div className = "flex justify-between">
            <h3 className = "text-blacklight text-base font-semibold self-center">Import products by xlsx</h3>
            <div className = "self-center">
              <span onClick = { onCloseOpenImportModal } className = "cursor-pointer text-blacklight">
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          { error && <p className = 'text-sm text-black mt-5'>An error occurred: <span className = 'text-red'>{ error?.data?.message }</span></p> }
          {
            <p className = 'text-sm text-red mt-5 '>{ messagedata }</p>
          }
          <div className = "w-full border-dashed border-2 border-boderColor-300 rounded px-5 py-8 text-center mt-5">
            <input
              className = "text-sm"
              type = "file"
              accept = ".xlsx"
              onChange = { handleFileChange }
            />
          </div>
          <div className = "flex justify-between mt-5">
            <div className = "self-center">
              <a href = "#" className = "text-sm text-blue underline" download>Download a sample xlsx</a>
            </div>
            <div className = "self-center">
              <button className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded" onClick = { onCloseOpenImportModal }>Cancel</button>
              <button
                className = "text-sm text-white bg-primary px-3 py-1 rounded ml-2"
                onClick = { handleUpload }
                disabled = { isLoading }
              >
                { isLoading ? 'Uploading...' : 'Import products' }
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { exportModal } showCloseIcon = { false } onClose = { onCloseExportModal }>
        <div className = 'modalboximport p-4'>
          <div className = 'flex justify-between'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Export products</h3>
            <div className = 'self-center'><span onClick = { onCloseExportModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full mt-5'>
           
            <p>
              <input type = 'radio' name = 'product' id = 'currentproduct' className = 'inline-block mr-2' />
              <label className = 'align-text-top text-sm text-blacklight' htmlFor = 'currentproduct'>Current page</label>
            </p>
            <p>
              <input type = 'radio' name = 'product' className = 'inline-block mr-2' id = 'allproduct' />
              <label className = 'align-text-top text-sm text-blacklight' htmlFor = 'allproduct' >All products</label>
            </p>
           
          </div>
          <div className = 'flex justify-between mt-5'>
            <div className = 'self-center'>
              <p className = 'text-sm text-blacklight'>File format xlsx</p>
            </div>
            <div className = 'self-center'>
              <button className = 'text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded' onClick = { onCloseExportModal }>Cancel</button>
              <button className = 'text-sm text-white bg-primary px-3 py-1 rounded ml-2'>Export products</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Product;