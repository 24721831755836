/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const AddcatlogimageModal = ({categoryId, setSelectedCategoryId}) => {
  const [addimgModal, setAddimgModal] = useState(false);
  const [images, setImages] = useState([]);


  useEffect(() => {
    if (categoryId == null) {
      setAddimgModal(false);
    } else {
      setAddimgModal(true);
    }
  },[categoryId]);

  const onCloseAddimgModal = () => {
    setAddimgModal(false);
    setSelectedCategoryId(null);
  };

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles) {
      const imageArray = selectedFiles.map(file => URL.createObjectURL(file));
      setImages(prevImages => [...prevImages, ...imageArray]);
    }
  };

  return (
    <>
      <Modal open = { addimgModal } showCloseIcon = { false } onClose = { onCloseAddimgModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Product photos</h3>
            <div className = 'self-center'><span onClick = { onCloseAddimgModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <h3 className = 'text-black text-base'>Product pictures</h3>
            <p className = 'text-sm text-secondary mt-2'>The size is not less than (100px height * 250px width) of the type (jpg, jpeg, png, gif) and does not exceed 5 megabytes for each image, with a maximum of 10 images</p>
            <div>
              <div className = 'mt-4'>
                <label htmlFor = "upload-input" style = { { cursor: 'pointer' } } className = 'block bg-[#f0f3f3] rounded text-center py-4 text-base text-blacklight'>
                  Click to upload images
                </label>
                <input
                  id = "upload-input"
                  type = "file"
                  accept = "image/*"
                  multiple
                  onChange = { handleImageChange }
                  style = { { display: 'none' } }
                />
              </div>
              <div className = 'flex gap-3 flex-wrap mt-3'>
                { images.map((image, index) => (
                  <div key = { index } className = 'bg-[#f0f3f3] rounded-es rounded-br'>
                    <img src = { image } alt = { `Uploaded ${index}` } style = { { maxWidth: '200px', maxHeight: '200px' } } />
                    <div className = 'flex justify-between px-2 py-2'>
                      <div className = 'self-center'><span className = 'cursor-pointer text-red'><DeleteOutlinedIcon style = { {fontSize: '18px'} } /></span></div>
                     
                    </div>
                  </div>
                )) }
              </div>
              <button className = 'text-sm text-blacklight bg-[#f0f3f3] px-5 py-2 rounded mt-5' onClick = { onCloseAddimgModal }>Close</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddcatlogimageModal;
