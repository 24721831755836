import { configureStore } from '@reduxjs/toolkit';
import { addcategoryApi } from './api/addcategoryApi';
import { AddnavigationApi } from './api/AddnavigationApi';
import { authApi } from './api/authApi';
import { bannerApi } from './api/bannerApi';
import { brandApi } from './api/brandApi';
import { catlogfragranceApi } from './api/catlogfragranceApi';
import { customerApi } from './api/customerApi';
import { dashbaordApi } from './api/dashbaordApi';
import { discountcouponApi } from './api/discountcouponApi';
import { giftcardApi } from './api/giftcardApi';
import { homecatlogApi } from './api/homecatlogApi';
import { managereviewsApi } from './api/managereviewsApi';
import { newproductApi } from './api/newproductApi';
import { orderApi } from './api/orderApi';
import { popularitemApi } from './api/popularitemApi';
import { productApi } from './api/productApi';
import { saleofferApi } from './api/saleofferApi';
import { scentfamilyApi } from './api/scentfamilyApi';
import { ShippingApi } from './api/ShippingApi';
import { testimonialApi } from './api/testimonialApi';
import { trendingApi } from './api/trendingApi';
import { TrendingnowApi } from './api/TrendingnowApi';
import logoutReducer from './features/authSlice';
import searchkeywordReducer from './features/searchkeywordSlice';

export const store = configureStore({ 
  reducer: {
    logout: logoutReducer,
    searchkeyword: searchkeywordReducer,
    [authApi.reducerPath]: authApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [discountcouponApi.reducerPath]: discountcouponApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [trendingApi.reducerPath]: trendingApi.reducer,
    [popularitemApi.reducerPath]: popularitemApi.reducer,
    [newproductApi.reducerPath]: newproductApi.reducer,
    [homecatlogApi.reducerPath]: homecatlogApi.reducer,
    [giftcardApi.reducerPath]: giftcardApi.reducer,
    [saleofferApi.reducerPath]: saleofferApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [catlogfragranceApi.reducerPath]: catlogfragranceApi.reducer,
    [testimonialApi.reducerPath]: testimonialApi.reducer,
    [scentfamilyApi.reducerPath]: scentfamilyApi.reducer,
    [addcategoryApi.reducerPath]: addcategoryApi.reducer,
    [AddnavigationApi.reducerPath]: AddnavigationApi.reducer,
    [managereviewsApi.reducerPath]: managereviewsApi.reducer,
    [TrendingnowApi.reducerPath]: TrendingnowApi.reducer,
    [ShippingApi.reducerPath]: ShippingApi.reducer,
    [dashbaordApi.reducerPath]: dashbaordApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([authApi.middleware, customerApi.middleware, orderApi.middleware, discountcouponApi.middleware, productApi.middleware, bannerApi.middleware, trendingApi.middleware, popularitemApi.middleware, newproductApi.middleware, homecatlogApi.middleware, giftcardApi.middleware,saleofferApi.middleware, brandApi.middleware,catlogfragranceApi.middleware, testimonialApi.middleware,scentfamilyApi.middleware,addcategoryApi.middleware,AddnavigationApi.middleware, managereviewsApi.middleware, TrendingnowApi.middleware, ShippingApi.middleware, dashbaordApi.middleware])
});
