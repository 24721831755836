import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ProductInventory = () => {

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <div className = 'self-center'>
              <ul className = 'flex text-sm'>
                <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li><span className = 'text-secondary'>Product inventory</span></li>
              </ul>
            </div>
            <p className = 'self-center text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
          </div>
          
          <div className = 'border border-borderColor rounded mt-5'>
            <div className = 'flex justify-between px-3'>
              <h3 className = 'self-center text-base font-medium text-blacklight py-3'><span className = 'text-secondary'><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span> Product inventory</h3>
            </div>
            <div className = 'flex border-t border-b border-borderColor py-2'>
              <span className = 'inline-block w-8 text-secondary pl-3'><SearchOutlinedIcon style = { {fontSize: '20px'} } /></span>
              <input placeholder = 'Search by product name or SKU' className = 'flex-1 ml-2 text-sm outline-none' />
            </div>
            <div className = 'modifyproduct custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Product name</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Total quantity</p></div>
                </li>
                
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Quantity sold</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>The remaining quantity</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>the price</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><Link to = "#" className = 'bold text-blacklight text-base'>Royal Canin for adult cats</Link></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <p className = 'text-blacklight text-base'>4059729288578</p>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>96</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>1 SAR</p></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInventory;
