/* eslint-disable react/prop-types */
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import {
  useAddshippingpriceMutation,
  useCitylistQuery,
  useCountrylistQuery,
  useDeleteShippingPriceMutation,
  useGetshippingcompanypricelistQuery
} from '../../redux/api/ShippingApi';

const ShippingPrice = ({editshipcompanyid}) => {

  const {error: errorshippingpricelist, data: shippingpricelistdata, refetch, isSuccess: isSuccessshippingpricelistdata} = useGetshippingcompanypricelistQuery(editshipcompanyid, {skip: !editshipcompanyid});
  const [activeIndex, setActiveIndex] = useState(null);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
  
    if(isSuccessshippingpricelistdata){
      setShowModal(shippingpricelistdata?.data?.length === 0?true: false);
    }

  },[isSuccessshippingpricelistdata, shippingpricelistdata]);

  const handleaddpricemodal = (buean) => {
    setShowModal(buean);
  };

  const onTitleClick = (id) => {
    setActiveIndex(id === activeIndex ? null : id);
  };

  const initialPriceState = [
    {
      shipping_company: editshipcompanyid,
      country: '',
      pricing_type: 'Fixed',
      cost_fixed_pricing: null,
      base_cost_weight_pricing: null,
      base_weight: null,
      weight_increment_interval: null,
      cost_increment_interval: null,
      shipping_time_in_days_min: '',
      shipping_time_in_days_max: '',
      cash_on_delivery_accepted: false
    }
  ];
  const [countryid, setCountryid] = useState(null);
  const [errorMessage, setErrorMassage] = useState('');
  const [prices, setPrices] = useState(initialPriceState);
  const [updatePrices, setUpdatePrices] = useState([]);
  const [updateinputErrors, setUpdateinputErrors] = useState({});
  const updateinputErrorslength = Object.keys(updateinputErrors).length;

  console.log('prices',prices);

  useEffect(() => {
    if (shippingpricelistdata) {
      setUpdatePrices(shippingpricelistdata?.data);
    }
  }, [shippingpricelistdata?.data]);


  const validateUpdateInput = (index, key, value) => {
    let errorMsg = '';

    if (!value) {
      errorMsg = 'This field is required.';
    } else if (isNaN(value) || parseFloat(value) <= 0) {
      errorMsg = 'Please enter a valid positive number.';
    }

    // Update errors state
    setUpdateinputErrors((prevErrors) => ({
      ...prevErrors,
      [`${index}-${key}`]: errorMsg
    }));
  };

  
  const handleupdateInputChange = (index, key, value) => {
    setUpdatePrices((prevPrices) => {
      const updatedPrices = [...prevPrices];
      updatedPrices[index] = {
        ...updatedPrices[index],
        [key]: value
      };
      
      validateUpdateInput(index, key, value);

      return updatedPrices;
    });
  };

  const { error, data: datacountry } = useCountrylistQuery();

  useEffect(() => {
    if (error) {
      toast(error?.data?.message);
    }
  }, [error]);


  const { error: errorcity, data: datacity } = useCitylistQuery(countryid, {
    skip: !countryid
  });

  useEffect(() => {
    if (errorcity) {
      toast(errorcity?.data?.message);
    }
  }, [errorcity]);

  const [addshippingprice, { error: erroraddshipingprice, isLoading, isSuccess }] =
    useAddshippingpriceMutation();

  const handleInputChange = (index, field, value) => {
    const updatedPrices = [...prices];
    updatedPrices[index][field] = value === '' ? null : value;
    setPrices(updatedPrices);

    if (field === 'country') {
      setCountryid(value);
    }
  };

  const addPrice = () => {
    setPrices([
      ...prices,
      {
        shipping_company: editshipcompanyid,
        country: '',
        pricing_type: 'Fixed',
        cost_fixed_pricing: null,
        base_cost_weight_pricing: null,
        base_weight: null,
        weight_increment_interval: null,
        cost_increment_interval: null,
        shipping_time_in_days_min: '',
        shipping_time_in_days_max: '',
        cash_on_delivery_accepted: false
      }
    ]);
  };

  const removePrice = (index) => {
    const updatedPrices = prices.filter((_, i) => i !== index);
    setPrices(updatedPrices);
  };


  const validatePrices = () => {
    // Ensure all required fields are filled
    for (let price of prices) {
      if (!price.pricing_type) return false;
      if (price.pricing_type === 'Fixed' && (price.cost_fixed_pricing === null || price.cost_fixed_pricing === '')) return false;
      if (price.pricing_type === 'Weight') {
        if (
          price.base_cost_weight_pricing === null ||
          price.base_weight === null ||
          price.weight_increment_interval === null ||
          price.cost_increment_interval === null
        ) {
          return false;
        }
      }
      if (!price.country) return false;
    }
    return true;
  };

  const resetForm = () => {
    setPrices(initialPriceState);
    setCountryid(null);
    setErrorMassage('');
  };

  const handleSubmit = async () => {

    if (!validatePrices()) {
      setErrorMassage('Please fill all required fields');
      return;
    }

    try {
      await addshippingprice(prices).unwrap();
    } catch (error) {
      setErrorMassage('Failed to add shipping prices');
    }
  };

  useEffect(() => {

    if(isSuccess){
      resetForm();
      refetch();
      setShowModal(false);
    }

  },[isSuccess]);


  const handleUpdate = () => {


    if (!validateUpdateInput()) {
      console.log('hi');
      return false;
    }

    addshippingprice(updatePrices)
      .then((response) => {
        console.log('Prices updated successfully', response);
      })
      .catch((error) => {
        console.error('Error updating prices', error);
      });
  };



  const [deleteShippingPrice, { error: errordeletepricingdata, isSuccess: deleleisSuccess }] = useDeleteShippingPriceMutation();

  const handledelete = (id) => {
    deleteShippingPrice(id);
  };


  useEffect(() => {
  
    if(errordeletepricingdata){
      toast(errordeletepricingdata?.data?.message);
    }
  
  },[errordeletepricingdata]);

  useEffect(() => {

    if(deleleisSuccess){
      refetch();
    }

  },[deleleisSuccess]);
  

  return (
    <>
      <ToastContainer />
      <div>
        {
          errorshippingpricelist?.data?.message && <p className = 'text-sm text-red py-2'>{ errorshippingpricelist }</p>
        }
       
        { updatePrices?.length > 0 && updatePrices?.map((price, index) => (
          <div key = { price?.id }>
            <div
              className = "cursor-pointer border border-borderColor rounded-md pl-2 mt-3"
            >
              <div className = 'flex justify-between'>
                <div  className = 'flex justify-between self-center flex-1' onClick = { () => onTitleClick(price?.id) }>
                  <div>
                    <span className = 'text-secondary'><MenuOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-sm text-black inline-block ml-2'>{ datacountry?.data?.find((country) => country.id === price.country)?.name }</span>
                  </div>
                  <div className = 'self-center text-secondary'><span style = { {fontSize: '15px'} }><KeyboardArrowDownOutlinedIcon /></span></div>
                </div>
                <div className = 'bg-red py-2 px-2 rounded-r-md self-center' onClick = { () => handledelete(price?.id) }>
                  <span className = 'text-white'><DeleteOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span>
                </div>
              </div>
            </div>
            <ul>
              { activeIndex === price?.id && <div>
                <li  className = "mt-5 border border-borderColor bg-[#f6fbfb] p-4 rounded-md">
                  <div>
              
                    <label className = 'text-sm text-blacklight inline-block'>Pricing type <span className = 'text-red'>*</span></label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <select
                        value = { price.pricing_type }
                        onChange = { (e) => handleupdateInputChange(index, 'pricing_type', e.target.value) }
                        className = "inline-block text-sm outline-0 w-width97% text-secondary"
                      >
                        <option value = "Fixed">Fixed</option>
                        <option value = "Weight">Weight</option>
                      </select>
                    </div>
                  </div>
                  { price.pricing_type === 'Fixed' ? (
                    <div className = "mt-2">
                      <label className = "text-sm text-blacklight">
      Shipping fees to the customer <span className = "text-red">*</span>
                      </label>
                      <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                        <div className = "w-width3%">
                          <span className = "text-secondary">
                            <StorefrontOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </div>
                        <input
                          placeholder = "0"
                          className = "inline-block text-sm outline-0 w-width97% ml-1"
                          value = { price.cost_fixed_pricing }
                          onChange = { (e) => handleupdateInputChange(index, 'cost_fixed_pricing', e.target.value) }
                        />
                        <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                      </div>
                      { updateinputErrors[`${index}-cost_fixed_pricing`] && (
                        <span className = "text-red text-sm">{ updateinputErrors[`${index}-cost_fixed_pricing`] }</span>
                      ) }
                    </div>
                  ) : price.pricing_type === '' ? null : (
                    <>
                      <div className = "mt-2">
                        <label className = "text-sm text-blacklight">
        Shipping fees to the customer weight <span className = "text-red">*</span>
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                          <div className = "w-width3%">
                            <span className = "text-secondary">
                              <StorefrontOutlinedIcon style = { { fontSize: '20px' } } />
                            </span>
                          </div>
                          <input
                            placeholder = "0"
                            className = "inline-block text-sm outline-0 w-width97% ml-1"
                            value = { price.base_cost_weight_pricing }
                            onChange = { (e) => handleupdateInputChange(index, 'base_cost_weight_pricing', e.target.value) }
                            required
                          />
                          <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                        </div>
                      </div>
                      <div className = "mt-3">
                        <label className = "text-sm text-blacklight">
        Shipping Weight <span className = "text-red">*</span>
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                          <div className = "w-width3%">
                            <span className = "text-secondary">
                              <ScaleOutlinedIcon style = { { fontSize: '20px' } } />
                            </span>
                          </div>
                          <input
                            value = { price.base_weight }
                            onChange = { (e) => handleupdateInputChange(index, 'base_weight', e.target.value) }
                            placeholder = "Enter shipping weight"
                            className = "inline-block text-sm outline-0 w-width97% ml-1"
                            required
                          />
                          <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">Kg</span>
                        </div>
                      </div>
                      <div className = "mt-3">
                        <label className = "text-sm text-blacklight">
        Cost of increase <span className = "text-red">*</span>
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                          <div className = "w-width3%">
                            <span className = "text-secondary">
                              <MonetizationOnOutlinedIcon style = { { fontSize: '20px' } } />
                            </span>
                          </div>
                          <input
                            value = { price.cost_increment_interval }
                            onChange = { (e) => handleupdateInputChange(index, 'cost_increment_interval', e.target.value) }
                            placeholder = "Enter the cost of the increase"
                            className = "inline-block text-sm outline-0 w-width97% ml-1"
                            required
                          />
                          <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                        </div>
                      </div>
                      <div className = "mt-3">
                        <label className = "text-sm text-blacklight">
        for every <span className = "text-red">*</span>
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                          <div className = "w-width3%">
                            <span className = "text-secondary">
                              <MonetizationOnOutlinedIcon style = { { fontSize: '20px' } } />
                            </span>
                          </div>
                          <input
                            value = { price.weight_increment_interval }
                            onChange = { (e) => handleupdateInputChange(index, 'weight_increment_interval', e.target.value) }
                            placeholder = "Enter for each"
                            className = "inline-block text-sm outline-0 w-width97% ml-1"
                            required
                          />
                          <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">Kg</span>
                        </div>
                      </div>
                      <div className = "border border-borderColor rounded-md mt-8">
                        <div className = "bg-[#f0f3f3] w-40 mx-auto text-center py-2 rounded-full text-sm pricesummary">
        Pricing Summary
                        </div>
                        <div className = "flex justify-around py-4">
                          <div>
                            <p className = "text-black text-sm">
                              <span className = "text-secondary">Shipping cost</span> 28.00 SAR
                            </p>
                            <p className = "text-black text-sm mt-3">
                              <span className = "text-secondary">The cost of the increase is</span> 2 SAR
                            </p>
                          </div>
                          <div className = "self-center">
                            <p className = "bg-[#f0f3f3] w-7 h-7 rounded-full flex prices-center justify-center">
                              <span className = "text-secondary">
                                <ArrowForwardIcon style = { { fontSize: '18px' } } />
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className = "text-black text-sm">
                              <span className = "text-secondary">For the first</span> 15 kg
                            </p>
                            <p className = "text-black text-sm mt-3">
                              <span className = "text-secondary">For every</span> additional 1 kg
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) }
                  <div className = "mt-2">
                    <h3 className = 'text-blacklight text-base font-medium mt-5'>Areas covered</h3>
                    <div>
                      <label className = 'text-sm text-blacklight inline-block mt-3'>Country <span className = 'text-red'>*</span></label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocationOnOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <select
                          value = { price.country }
                          onChange = { (e) => handleupdateInputChange(index, 'country', e.target.value) }
                          className = "inline-block text-sm outline-0 w-full text-secondary"
                          required
                        >
                          <option value = "">Select Country</option>
                          { datacountry?.data?.map((price) => (
                            <option key = { price.id } value = { price.id }>
                              { price.name }
                            </option>
                          )) }
                        </select>
                      </div>
                    </div>
                    <div className = 'mt-2'>
                      <label className = 'text-sm text-blacklight'>Supported Cities</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                        <div className = 'w-width3% self-center'>
                          <span className = 'text-secondary align-text-bottom'>
                            <LocationOnOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </div>
                        <select
                          className = 'inline-block text-sm outline-0 w-width97% text-secondary'
                          value = { price.supported_city }
                          onChange = { (e) => handleupdateInputChange(index, 'supported_city', e.target.value) }
                        >
                          <option>All cities</option>
                          { datacity?.data?.map((price) => (
                            <option key = { price?.id } value = { price?.id }>
                              { price?.name }
                            </option>
                          )) }
                        </select>
                      </div>
                    </div>
                    <div className = 'mt-2'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Shipping minimum time <span className = 'text-red'>*</span></label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><TimerOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input value = { price.shipping_time_in_days_min }
                          onChange = { (e) => handleupdateInputChange(index, 'shipping_time_in_days_min', e.target.value) }
                          placeholder = '1 business days' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </div>
                    <div className = 'mt-2'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Shipping maximum time <span className = 'text-red'>*</span></label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><TimerOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input value = { price.shipping_time_in_days_max }
                          onChange = { (e) => handleupdateInputChange(index, 'shipping_time_in_days_max', e.target.value) }
                          placeholder = '2 business days' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </div>
                    <div className = 'flex justify-between mt-8'>
                      <div>
                        <h3 className = 'text-blacklight text-base font-medium'>Additional options</h3>
                        <div className = 'mt-5'>
                          <input type = 'checkbox' className = 'align-middle inline-block mr-2' id = "delivery" />
                          <label className = 'text-sm align-middle' htmlFor = "delivery">Activate cash on delivery</label>
                        </div>
                      </div>
                
                      <div>
                        <button onClick = { () => removePrice(index) } className = 'border border-red bg-white text-red rounded inline-block px-5 py-2 text-sm'> <span><ClearOutlinedIcon style = { {fontSize: '20px'} } /></span>Delete the quote</button>
                      </div>
                    </div>
                  </div>
            
                </li>
              </div> }
            </ul>
          </div>
        )) }
      </div>

      {
        (showModal && activeIndex === null)?<ul>
          { prices.map((price, index) => (
            <li key = { index } className = "mt-5 border border-borderColor bg-[#f6fbfb] p-4 rounded-md">
              <div>
                <label className = 'text-sm text-blacklight inline-block'>Pricing type <span className = 'text-red'>*</span></label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <select
                    value = { price.pricing_type }
                    onChange = { (e) => handleInputChange(index, 'pricing_type', e.target.value) }
                    className = "inline-block text-sm outline-0 w-width97% text-secondary"
                    required
                  >
                    <option value = "Fixed">Fixed</option>
                    <option value = "Weight">Weight</option>
                  </select>
                </div>
              </div>
              { price.pricing_type === 'Fixed' ? (
                <div className = "mt-2">
                  <label className = "text-sm text-blacklight">
      Shipping fees to the customer <span className = "text-red">*</span>
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <StorefrontOutlinedIcon style = { { fontSize: '20px' } } />
                      </span>
                    </div>
                    <input
                      placeholder = "0"
                      className = "inline-block text-sm outline-0 w-width97% ml-1"
                      value = { price.cost_fixed_pricing }
                      onChange = { (e) => handleInputChange(index, 'cost_fixed_pricing', e.target.value) }
                      required
                    />
                    <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                  </div>
                </div>
              ) : price.pricing_type === '' ? null : (
                <>
                  <div className = "mt-2">
                    <label className = "text-sm text-blacklight">
        Shipping fees to the customer weight <span className = "text-red">*</span>
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <StorefrontOutlinedIcon style = { { fontSize: '20px' } } />
                        </span>
                      </div>
                      <input
                        placeholder = "0"
                        className = "inline-block text-sm outline-0 w-width97% ml-1"
                        value = { price.base_cost_weight_pricing }
                        onChange = { (e) => handleInputChange(index, 'base_cost_weight_pricing', e.target.value) }
                        required
                      />
                      <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                    </div>
                  </div>
                  <div className = "mt-3">
                    <label className = "text-sm text-blacklight">
        Shipping Weight <span className = "text-red">*</span>
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <ScaleOutlinedIcon style = { { fontSize: '20px' } } />
                        </span>
                      </div>
                      <input
                        value = { price.base_weight }
                        onChange = { (e) => handleInputChange(index, 'base_weight', e.target.value) }
                        placeholder = "Enter shipping weight"
                        className = "inline-block text-sm outline-0 w-width97% ml-1"
                        required
                      />
                      <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">Kg</span>
                    </div>
                  </div>
                  <div className = "mt-3">
                    <label className = "text-sm text-blacklight">
        Cost of increase <span className = "text-red">*</span>
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <MonetizationOnOutlinedIcon style = { { fontSize: '20px' } } />
                        </span>
                      </div>
                      <input
                        value = { price.cost_increment_interval }
                        onChange = { (e) => handleInputChange(index, 'cost_increment_interval', e.target.value) }
                        placeholder = "Enter the cost of the increase"
                        className = "inline-block text-sm outline-0 w-width97% ml-1"
                        required
                      />
                      <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">SAR</span>
                    </div>
                  </div>
                  <div className = "mt-3">
                    <label className = "text-sm text-blacklight">
        for every <span className = "text-red">*</span>
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <MonetizationOnOutlinedIcon style = { { fontSize: '20px' } } />
                        </span>
                      </div>
                      <input
                        value = { price.weight_increment_interval }
                        onChange = { (e) => handleInputChange(index, 'weight_increment_interval', e.target.value) }
                        placeholder = "Enter for each"
                        className = "inline-block text-sm outline-0 w-width97% ml-1"
                        required
                      />
                      <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">Kg</span>
                    </div>
                  </div>
                  <div className = "border border-borderColor rounded-md mt-8">
                    <div className = "bg-[#f0f3f3] w-40 mx-auto text-center py-2 rounded-full text-sm pricesummary">
        Pricing Summary
                    </div>
                    <div className = "flex justify-around py-4">
                      <div>
                        <p className = "text-black text-sm">
                          <span className = "text-secondary">Shipping cost</span> 28.00 SAR
                        </p>
                        <p className = "text-black text-sm mt-3">
                          <span className = "text-secondary">The cost of the increase is</span> 2 SAR
                        </p>
                      </div>
                      <div className = "self-center">
                        <p className = "bg-[#f0f3f3] w-7 h-7 rounded-full flex prices-center justify-center">
                          <span className = "text-secondary">
                            <ArrowForwardIcon style = { { fontSize: '18px' } } />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className = "text-black text-sm">
                          <span className = "text-secondary">For the first</span> 15 kg
                        </p>
                        <p className = "text-black text-sm mt-3">
                          <span className = "text-secondary">For every</span> additional 1 kg
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) }
              <div className = "mt-2">
                <h3 className = 'text-blacklight text-base font-medium mt-5'>Areas covered</h3>
                <div>
                  <label className = 'text-sm text-blacklight inline-block mt-3'>Country <span className = 'text-red'>*</span></label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocationOnOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <select
                      value = { price.country }
                      onChange = { (e) => handleInputChange(index, 'country', e.target.value) }
                      className = "inline-block text-sm outline-0 w-full text-secondary"
                      required
                    >
                      <option value = "">Select Country</option>
                      { datacountry?.data?.map((price) => (
                        <option key = { price.id } value = { price.id }>
                          { price.name }
                        </option>
                      )) }
                    </select>
                  </div>
                </div>
                <div className = 'mt-2'>
                  <label className = 'text-sm text-blacklight'>Supported Cities</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                    <div className = 'w-width3% self-center'>
                      <span className = 'text-secondary align-text-bottom'>
                        <LocationOnOutlinedIcon style = { { fontSize: '20px' } } />
                      </span>
                    </div>
                    <select
                      className = 'inline-block text-sm outline-0 w-width97% text-secondary'
                      value = { price.supported_city }
                      onChange = { (e) => handleInputChange(index, 'supported_city', e.target.value) }
                    >
                      <option>All cities</option>
                      { datacity?.data?.map((price) => (
                        <option key = { price?.id } value = { price?.id }>
                          { price?.name }
                        </option>
                      )) }
                    </select>
                  </div>
                </div>
                <div className = 'mt-2'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Shipping minimum time <span className = 'text-red'>*</span></label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><TimerOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <input value = { price.shipping_time_in_days_min }
                      onChange = { (e) => handleInputChange(index, 'shipping_time_in_days_min', e.target.value) }
                      placeholder = '1 business days' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </div>
                <div className = 'mt-2'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Shipping maximum time <span className = 'text-red'>*</span></label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><TimerOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <input value = { price.shipping_time_in_days_max }
                      onChange = { (e) => handleInputChange(index, 'shipping_time_in_days_max', e.target.value) }
                      placeholder = '2 business days' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </div>
                <div className = 'flex justify-between mt-8'>
                  <div>
                    <h3 className = 'text-blacklight text-base font-medium'>Additional options</h3>
                    <div className = 'mt-5'>
                      <input type = 'checkbox' className = 'align-middle inline-block mr-2' id = "delivery" />
                      <label className = 'text-sm align-middle' htmlFor = "delivery">Activate cash on delivery</label>
                    </div>
                    {
                      errorMessage && <p className = 'text-sm text-red mt-5'>{ errorMessage }</p>
                    }
                    {
                      erroraddshipingprice && <p className = 'text-sm text-red mt-5'>{ erroraddshipingprice?.data?.message }</p>
                    }
                  </div>
                
                  <div>
                    <button onClick = { () => removePrice(index) } className = 'border border-red bg-white text-red rounded inline-block px-5 py-2 text-sm'> <span><ClearOutlinedIcon style = { {fontSize: '20px'} } /></span>Delete the quote</button>
                  </div>
                </div>
              </div>
            
            </li>
          )) }
        </ul>: null
      }
      
      <div>
        
        <button onClick = { () => {addPrice(); handleaddpricemodal(true);} } className = { `block ${activeIndex !== null?'bg-primary-25': 'bg-primary'} w-full py-2 rounded text-white text-sm mt-8` } disabled = { activeIndex !== null }>
          <span>
            <AddOutlinedIcon />
          </span>
          Add a price
        </button>
      </div>
      <div className = "flex justify-between mt-8">
        <div>

          {
            activeIndex !== null?
              <button className = { `text-sm ${updateinputErrorslength !== 0?'bg-primary-50': 'bg-primary'} py-2 px-4 rounded text-white inline-block` } disabled = { updateinputErrorslength !== 0 } onClick = { handleUpdate }>Update</button>
              : <button
                onClick = { handleSubmit }
                className = "text-sm bg-primary py-2 px-4 rounded text-white inline-block"
              >
                { isLoading?'Saving..': 'Save' }
              </button>
          }
          
        </div>
        <div>
          <button className = "text-sm py-1.5 px-8 rounded text-blacklight inline-block border border-borderColor">
            Exit
          </button>
        </div>
      </div>
    </>
  );
};

export default ShippingPrice;
