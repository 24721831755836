import React from 'react';
import { Link } from 'react-router-dom';

const ShippingCostProfits = () => {

  return(
    <>
      <div className = 'border border-borderColor rounded bg-white'>
        <div className = 'modifyproduct custom-scroll'>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <div className = 'w-60 p-2'><p className = 'text-blacklight text-base'>Shipping company</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>Shipping charges</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>Shipping revenue</p></div>
            </li>
                
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>Shipping profits</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-60'><p className = 'text-blacklight text-base'>Number of requests</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-60 p-2'>
                <div className = 'flex'>
                  <div className = 'w-10'><img src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/gJDzXHGbhYIVuRSuRqZr8otHc8vsetkap4Z9Souo.png' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>smsa</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>0 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>28 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>28 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-60'><p className = 'text-blacklight text-base'>9 requests</p></div>
            </li>
          </ul>
          <ul className = 'flex w-full'>
            <li className = 'border-b border-borderColor'>
              <Link className = 'block w-60 p-2'>
                <div className = 'flex'>
                  <div className = 'w-10'><img src = 'https://cdn.salla.sa/XTZj2dbvFUGb1rnobc3rJpMLLmHD4MNJXM3yOhot.png' /></div>
                  <h3 className = 'text-blacklight text-sm self-center ml-5'>redbox</h3>
                </div>
              </Link>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'>
                <p className = 'text-blacklight text-base'>0 SAR</p>
              </div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>42 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-36'><p className = 'text-blacklight text-base'>42 SAR</p></div>
            </li>
            <li className = 'pl-2 border-l border-b border-borderColor p-2'>
              <div className = 'w-60'><p className = 'text-blacklight text-base'>5 requests</p></div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ShippingCostProfits;