import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddnewproductMutation, useDeletenewproductMutation, useNewproductlistQuery, usePermissionNewitemMutation, useProductlistallforNewitemQuery } from '../../redux/api/newproductApi';

const Newarrival = () => {
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [selectedProductid, setSelectedProductid] = useState(null);
  const [Productlist, setProductlist] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: producterror, data: productdata, isSuccess: productisSuccess } = useProductlistallforNewitemQuery(searchParams);

  useEffect(() => {
    if (producterror) {
      toast(producterror?.data?.message);
    }
  }, [producterror]);

  useEffect(() => {
    if (productisSuccess) {
      const options = productdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setProductlist(options || []);
    }
  }, [productisSuccess, productdata]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChangeproduct = (selectedOption) => {
    setSelectedProductOption(selectedOption);
    const transformedOption = selectedOption.map(option => ({
      product_id: option.value
    }));
  
    setSelectedProductid(transformedOption);
  };

  const { error: errornewproductlist, data: datanewproductlist, isLoading: isLoadingnewproductlist, refetch } = useNewproductlistQuery();

  const [addtrendingitem, { error: erroraddnewproduct, data: dataaddnewproduct, isLoading: isLoadingaddnewproduct, isSuccess: isSuccessaddnewproduct }] = useAddnewproductMutation();

  useEffect(() => {
    if (erroraddnewproduct) {
      toast(erroraddnewproduct?.message);
    }
  }, [erroraddnewproduct]);

  const handleaddtrendingitem = (e) => {
    e.preventDefault();

    const data = {
      'products': selectedProductid
    };

    addtrendingitem(data);
  };

  useEffect(() => {
    if (dataaddnewproduct) {
      toast(dataaddnewproduct?.message);
      refetch();
      setSelectedProductOption(null);
      setSelectedProductid(null); 
    }
  }, [isSuccessaddnewproduct, dataaddnewproduct, refetch]);

  const [deletepopularitem, { error: errordeleteProductitem, data: datadeleteProductitem, isSuccess: deleteProductitemisSuccess }] = useDeletenewproductMutation();

  useEffect(() => {
    if (errordeleteProductitem) {
      toast(errordeleteProductitem?.data?.message);
    }
  }, [errordeleteProductitem]);

  const handleDeleteproductitem = (itemid) => {
    deletepopularitem(itemid);
  };

  useEffect(() => {
    if (deleteProductitemisSuccess) {
      toast(datadeleteProductitem?.data?.message);
      refetch();
    }
  }, [deleteProductitemisSuccess, datadeleteProductitem, refetch]);

  const [permissionNewitem,{error: errorpermission,data: datapermission , isSuccess: isSuccesspermission}] = usePermissionNewitemMutation();

  useEffect(() => {
    if(errorpermission){
      toast.error(errorpermission?.data?.message);
    }
  }, [errorpermission]);

  useEffect(() => {
    if(isSuccesspermission){
      // toast.success(datapermission?.message);
      refetch();
    }
  },[isSuccesspermission,datapermission]);

  const handlePermission = async (item) => {
    const formdata = new FormData();
    formdata.append('action', item?.is_approved === false ? 'approve' : 'deapprove');

    try {
      await permissionNewitem({ body: formdata, id: item?.item_id });
    } catch(error) {
      console.log('Failed to update permission', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <Select
              className = 'inline-block text-sm outline-0 w-full text-secondary'
              value = { selectedProductOption }
              onChange = { handleChangeproduct }
              options = { Productlist }
              inputValue = { inputValue }
              onInputChange = { handleInputChange }
              isMulti
              placeholder = 'Choose product'
            />
            <div className = "mb-8">
              <form onSubmit = { handleaddtrendingitem }>
                <button className = { `${!selectedProductOption?.length ? 'bg-primary-300' : 'bg-primary'} px-4 py-2 text-sm rounded text-white mt-3` } disabled = { !selectedProductOption?.length }>
                  { isLoadingaddnewproduct ? 'Loading...' : 'Add Product' }
                </button>
              </form>
            </div>
            <h3 className = "text-blacklight text-lg font-semibold uppercase pb-2">New Arrival</h3>
            <div className = "shadow-customcard rounded p-3">
              <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className = "inline-block min-w-full overflow-hidden">
                  <table className = "min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Product
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Name
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Brand
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        errornewproductlist && <p className = 'text-sm text-red'>{ errornewproductlist?.data?.message }</p>
                      }
                      {
                        isLoadingnewproductlist && <p className = 'text-sm text-blacklight'>{ isLoadingnewproductlist ? '...Loading' : '' }</p>
                      }
                      {
                        datanewproductlist && <p className = 'text-sm text-blacklight mt-2 ml-5'>{ datanewproductlist?.data?.length === 0 && 'No data found' }</p>
                      }
                      { datanewproductlist?.data?.map((item) => (
                        <tr key = { item?.id }>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <img className = "text-blacklight whitespace-no-wrap w-20" src = { item?.product?.images[0] } alt = "productimg" />
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <p className = "text-blacklight whitespace-no-wrap">
                              { item?.product?.name }
                            </p>
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <p className = "text-blacklight whitespace-no-wrap">
                              { item?.product?.brand ? item?.product?.brand : 'NA' }
                            </p>
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <span onClick = { () => handleDeleteproductitem(item?.item_id) } className = "cursor-pointer text-primary inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                              <ClearOutlinedIcon style = { { fontSize: '20px' } } />
                            </span>
                            <span onClick = { () => handlePermission(item) } className = { `cursor-pointer inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2 ${item?.is_approved === false?'text-primary': 'text-red'}` }>
                              { item?.is_approved === false ?<VisibilityOutlinedIcon className = 'text-primary' style = { {fontSize: '20px'} } /> : <VisibilityOffOutlinedIcon className = 'text-red-400' style = { {fontSize: '20px'} } /> }
                            </span>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newarrival;