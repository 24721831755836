/* eslint-disable react/prop-types */
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { useCatloglistQuery, useCitylistQuery, useCountrylistQuery, useGetfreeshippinglistbyidQuery, useGetshipingcompanylistQuery, useProductlistQuery, useUpdatefreeshippingMutation } from '../../redux/api/ShippingApi';
import Citylist from './Citylist';
import Companyshipinglist from './Companyshipinglist';
import ShippingProductlist from './ShippingProductlist';
import Shippingcategorylist from './Shippingcategorylist';

const Editfreeshipping = ({freeShippingid, onTitleClick, refetch}) => {

  const [selectedCity, setSelectedcity] = useState(null);
  const [countryid, setCountryid] = useState(null);
  const [citylistdata, setCitylistdata] = useState([]);
  const [getshippinglistdata, setGetshippinglistdata] = useState([]);
  const [selectedshippingcompany, setSelectedshippingcompany] = useState(null);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState('');
  const [maximumShipmentWeight, setMaximumShipmentWeight] = useState('');
  const [supportedShippingCompanies, setSupportedShippingCompanies] = useState([]);
  const [supportedCategories, setSupportedCategories] = useState([]);
  const [supportedProduct, setSupportedProduct] = useState([]);

  const [supportedproductval, setSupportedproductval] = useState(1);

  const handlesupportedproduct = (e) => {
    setSupportedproductval(e.target.value);
  };


  const {error: errorcoutry, data: datacountry} = useCountrylistQuery();


  const handlecountry = (e) => {
    setCountryid(e.target.value);
  };

  const {error: errorcity, data: citydata, isSuccess: isSuccesscity} = useCitylistQuery(countryid,{skip: !countryid});

  const handlecity = (selecteditem) => {
    setSelectedcity(selecteditem);
  };

  useEffect(() => {
    
    if(isSuccesscity){

      const citydataoption = citydata?.data.map((c) => (
        {
          label: c.name,
          value: c.id
        }
      ));

      setCitylistdata(citydataoption || []);
      setSelectedcity(null);

    }
  },[isSuccesscity, citydata]);


  const {error: errorgetshippinglist, data: datagetshippinglist, isSuccess: isSuccessgetshippinglist} = useGetshipingcompanylistQuery();


  useEffect(() => {

    if(isSuccessgetshippinglist){

      const shippinglistdata = datagetshippinglist?.data?.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setGetshippinglistdata(shippinglistdata);
      setSelectedshippingcompany(null);

    }

  },[datagetshippinglist, isSuccessgetshippinglist]);

  const handleshippingcompanylist = (selecteditems) => {
    setSelectedshippingcompany(selecteditems);
    setSupportedShippingCompanies(selecteditems.map(item => item.value));
  };



  const [productinputValue, setProductinputValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productdata, setProductdata] = useState([]);

  const searchParams = productinputValue ? { size: 30, search: productinputValue } : { size: 30 };

  const {error: errorproductlist, data: dataproductlist, isSuccess: isSuccessproductlist} = useProductlistQuery(searchParams);

  useEffect(() => {

    if(isSuccessproductlist){
      const productoptiondata = dataproductlist?.data?.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setProductdata(productoptiondata || []);
    }

  },[dataproductlist, isSuccessproductlist]);

  const handleproductdata = (selecteditem) => {
    setSelectedProduct(selecteditem);
    setSupportedProduct(selecteditem.map(item => item.value));
  };

  const handleproductinputval = (newval) => {
    setProductinputValue(newval);
  };



  const [catloginputValue, setCatloginputValue] = useState('');
  const [selectedCatlog, setSelectedCatlog] = useState([]);
  const [catlogdata, setCatlogdata] = useState([]);

  const searchParamscatlog = productinputValue ? { size: 30, search: productinputValue } : { size: 30 };

  const {error: errorcatlog, data: datacatlog, isSuccess: isSuccesscatlog} = useCatloglistQuery(searchParamscatlog);

  useEffect(() => {

    if(isSuccesscatlog){
      const productoptiondata = datacatlog?.data?.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setCatlogdata(productoptiondata || []);
    }

  },[isSuccesscatlog, datacatlog]);

  const handlecatlogtdata = (selecteditem) => {
    setSelectedCatlog(selecteditem);
    setSupportedCategories(selecteditem.map(item => item.value));
  };

  const handlecatloginputval = (newval) => {
    setCatloginputValue(newval);
  };


  const [updatefreeshipping, {error: errorcreateshipping, isSuccess: isSuccessupdateshipping}] = useUpdatefreeshippingMutation();

  const handleMinimumPurchaseChange = (e) => {
    setMinimumPurchaseAmount(e.target.value);
  };

  const handleMaximumShipmentWeightChange = (e) => {
    setMaximumShipmentWeight(e.target.value);
  };

  useEffect(() => {
    if (isSuccessupdateshipping) {
      refetch();
      onTitleClick();
    }
  },[isSuccessupdateshipping]);


  const handleUpdateFreeShipping = async () => {
    const requestData = {
      id: freeShippingid,
      country_id: countryid,
      minimum_purchase_amount: parseFloat(minimumPurchaseAmount),
      maximum_shipment_weight: parseFloat(maximumShipmentWeight),
      supported_products:supportedProduct?supportedProduct: [],
      supported_shipping_companies: supportedShippingCompanies,
      supported_categories: supportedCategories?supportedCategories: [],
      supported_cities: selectedCity?selectedCity: []
    };

    try {
      await updatefreeshipping(requestData);
      
    } catch (error) {
      console.error('Error creating free shipping:', error);
    }
  };

  const {error: errorfreeshippinglistid, data: datafreeshippinglistdata,refetch: refechbyshippingid} = useGetfreeshippinglistbyidQuery(freeShippingid,{skip: !freeShippingid});

  const datadetails = datafreeshippinglistdata?.data;

  useEffect(() => {

    refechbyshippingid();
    
  },[freeShippingid]);

  useEffect(() => {

    if(datadetails){
      setMinimumPurchaseAmount(datadetails?.minimum_purchase_amount);
      setMaximumShipmentWeight(datadetails?.maximum_shipment_weight);
      const supportedShippingCompanies = datadetails?.supported_shipping_companies || [];
      const filteredShippingCompanies = datagetshippinglist?.data.filter(company => 
        supportedShippingCompanies.includes(company.id)
      );
      const shippingCompanies = filteredShippingCompanies.map((company) => ({
        label: company.name,
        value: company.id
      }));

      setSelectedshippingcompany(shippingCompanies);

      const defaultCountryId = datacountry?.data?.find(country => 
        country.id === datadetails?.country_id
      )?.id;

      setCountryid(defaultCountryId || '');
    }

    const supportedCategories = datadetails?.supported_categories || [];
    const filteredCategories = datacatlog?.data.filter(company => 
      supportedCategories.includes(company.id)
    );
    const selectedcatlog = filteredCategories.map((company) => ({
      label: company.name_en,
      value: company.id
    }));

    setSelectedCatlog(selectedcatlog);

  },[datadetails, datacountry]);

  return(
    <>
      <ul className = 'text-left border-b border-borderColor pb-5'>
        <li className = 'mt-3'>
          {
            errorfreeshippinglistid?.data?.message && <p className = 'text-sm text-red'>{ errorfreeshippinglistid?.data?.message }</p>
          }
          <label className = 'text-sm text-blacklight inline-block mt-2'>Countries</label>
          { errorcoutry?.data?.message && <p className = 'text-sm text-red my-1'>{ errorcoutry?.data?.message }</p> }
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><MapOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <select onClick = { handlecountry }    className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
              <option value = "null">Select countries</option>
              {
                datacountry?.data?.length > 0 && datacountry?.data?.map((item) => {
                  return(
                    <option selected = { countryid == item?.id }  key = { item?.id } value = { item?.id }>{ item?.name }</option>
                  );
                })
              }
            </select>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-blacklight inline-block mt-2'>The cities</label>
          {
            errorcity?.data?.message && <p className = 'text-sm text-red my-1'></p>
          }
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><FmdGoodOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <div className = 'w-width97% '>
              <Citylist handlecity = { handlecity } citylistdata = { citylistdata } selectedCity = { selectedCity } />
            </div>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-secondary'>Minimum purchases</label>
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3%'><span className = 'text-secondary'><AttachMoneyOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <input 
              type = 'number'
              placeholder = 'Minimum purchases' 
              className = 'inline-block text-sm outline-0 w-full ml-1'
              value = { minimumPurchaseAmount }
              onChange = { handleMinimumPurchaseChange } 
            />
            <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>SAR</span>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-blacklight inline-block mt-2'>Supported shipping companies</label>
          {
            errorgetshippinglist?.data?.message && <p className = 'text-sm text-red'>{ errorgetshippinglist?.data?.message }</p>
          }
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><DirectionsBusFilledOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <div className = 'text-sm outline-0 w-width97% text-secondary '>
              <Companyshipinglist handleshippingcompanylist = { handleshippingcompanylist } getshippinglistdata = { getshippinglistdata } selectedshippingcompany = { selectedshippingcompany } />
            </div>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-blacklight'>Maximum shipment weight</label>
          <p className = 'text-sm text-secondary'>Specify a maximum shipment weight to support free shipping</p>
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3%'><span className = 'text-secondary'><InboxOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <input 
              type = 'number' 
              placeholder = 'Maximum Shipment Weight' 
              className = 'inline-block text-sm outline-0 w-full ml-1'
              value = { maximumShipmentWeight }
              onChange = { handleMaximumShipmentWeightChange } 
            />
            <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>Kg</span>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-blacklight inline-block mt-2'>Select the customer group</label>
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><GroupOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
              <option>Select the customer group</option>
              <option>The most purchasing customers</option>
              { /* <option>Diamond category</option>
                        <option>Highly loyal customers</option> */ }
            </select>
          </div>
        </li>
        <li className = 'mt-3'>
          <label className = 'text-sm text-blacklight inline-block mt-2'>Supported Products</label>
          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
            <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <select onChange = { handlesupportedproduct } className = 'inline-block text-sm outline-0 w-width97% text-secondary'>
              <option value = "1">Select by products</option>
              <option value = "2">Choose by categories</option>
            </select>
          </div>
        </li>
        {
          supportedproductval == 1 && <li className = 'mt-3'>
            <label className = 'text-sm text-blacklight'>Select supported products</label>
            {
              errorproductlist?.data?.message && <p className = 'text-sm text-red'>{ errorproductlist?.data?.message }</p>
            }
            <div className = 'inline-block text-sm outline-0 w-full text-secondary mt-1'>
              <ShippingProductlist handleproductdata = { handleproductdata } selectedProduct = { selectedProduct } productdata = { productdata } handleproductinputval = { handleproductinputval } productinputValue = { productinputValue } />
            </div>
          </li>
        }
        {
          supportedproductval == 2 && <li className = 'mt-3'>
            <label className = 'text-sm text-blacklight'>Select supported categories</label>
            {
              errorcatlog?.data?.message && <p className = 'text-sm text-red'>{ errorcatlog?.data?.message }</p>
            }
            <div className = ' inline-block text-sm outline-0 w-full text-secondary mt-1'>
              <Shippingcategorylist catloginputValue = { catloginputValue } selectedCatlog = { selectedCatlog } catlogdata = { catlogdata } handlecatlogtdata = { handlecatlogtdata } handlecatloginputval = { handlecatloginputval } />
            </div>
          </li>
        }
                  
        <li>
          <div>
            <div className = 'mt-5'><input type = 'checkbox' id = 'carts'  /> <label className = 'align-text-bottom text-base text-blacklight' htmlFor = 'carts'>Free shipping applies to all products in the cart</label></div>
            <p className = 'text-sm text-secondary'>Free shipping is applied to all products in the cart if the customer adds products that are not included in free</p>
            <p className = 'text-sm text-secondary'>shipping with products that are included in free shipping in the same order</p>
          </div>
        </li>
      </ul>
      {
        errorcreateshipping?.data?.message && <p className = 'text-sm text-red my-1'>{ errorcreateshipping?.data?.message }</p>
      }
      <div className = 'flex justify-between mt-8'>
        <button onClick = { handleUpdateFreeShipping } className = 'inline-block text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
      </div>
    </>
  );
};

export default Editfreeshipping;