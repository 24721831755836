
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import { useCreatecustomerGroupMutation, useCustomerlistQuery } from '../../redux/api/customerApi';
import FilterAcordian from './FilterAcordian';

const CustomerList = () => {
  const crossmenu = process.env.PUBLIC_URL + '/images/icons/crossmenu.svg';
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [importModal, setImportModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [newCustomerModal, setNewCustomerModal] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const {searchkeyword} = useSelector((state) => state.searchkeyword);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const onOpenImportModal = () => setImportModal(true);
  const onCloseOpenImportModal = () => setImportModal(false);

  const onOpenExportModal = () => setExportModal(true);
  const onCloseExportModal = () => setExportModal(false);

  const onOpenNewCustomerModal = () => setNewCustomerModal(true);
  const onCloseNewCustomerModal = () => setNewCustomerModal(false);

  const onOpenAddGroupModal = () => setAddGroupModal(true);
  const onCloseAddGroupModal = () => setAddGroupModal(false);
  const [groupName, setGroupName] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  const [
    createcustomerGroup,
    { error: grouperror, isLoading: groupisLoading, isSuccess: groupsSuccess }
  ] = useCreatecustomerGroupMutation();

  const [conditions, setConditions] = useState([
    { value: '', condition: '', condition_type: '' }
  ]);
  const addCondition = () => {
    setConditions([
      ...conditions,
      { value: '', condition: '', condition_type: '' }
    ]);
  };

  const deleteCondition = (index) => {
    const updatedConditions = [...conditions];
    updatedConditions.splice(index, 1);
    setConditions(updatedConditions);
  };

  const handleConditionChange = (index, key, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index][key] = value;
    setConditions(updatedConditions);
  };

  useEffect(() => {
    if (grouperror) {
      toast(grouperror?.data?.message?.group_rules);
    }
  }, [grouperror]);

  useEffect(() => {
    if (groupsSuccess) {
      toast('Group created successfully!');
      onCloseAddGroupModal();
    }
  }, [groupsSuccess]);

  const validateForm = () => {
    if (!groupName) {
      setErrorMessge('Group name is required.');
      return false;
    }

    for (const condition of conditions) {
      if (
        !condition?.value ||
        !condition?.condition ||
        !condition?.condition_type
      ) {
        setErrorMessge('All condition fields are required.');
        return false;
      }
    }

    setErrorMessge('');
    return true;
  };

  const submitgroupdata = async () => {
    if (!validateForm()) {
      return;
    }

    const formData = {
      name: groupName,
      group_rules: conditions
    };

    try {
      await createcustomerGroup(formData);
    } catch (error) {
      setErrorMessge('Failed to add group: ' + error?.message);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDatewithto, setStartDatewithto] = useState(new Date());

  const { error, data, isLoading } = useCustomerlistQuery(searchkeyword);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>An error occurred: { error?.data?.message }</p>;

  return (
    <>
      <div className = "flex justify-between mt-10 mb-3">
        <div>
          <p className = "text-sm cursor-pointer" onClick = { toggleMenu }>
            <span className = "text-secondary">
              <FilterAltOutlinedIcon style = { { fontSize: '22px' } } />
            </span>
            Filtering
          </p>
        </div>
        <div>
          <ul className = "flex">
            <li>
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded"
                onClick = { onOpenExportModal }
              >
                Export
              </button>
            </li>
            <li>
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded ml-2"
                onClick = { onOpenImportModal }
              >
                Import
              </button>
            </li>
            <li>
              <Link
                to = "#"
                className = "text-sm text-white bg-primary px-3 py-1 rounded ml-2"
                onClick = { onOpenNewCustomerModal }
              >
                Add customer
              </Link>
            </li>
            <li>
              <Link
                to = "#"
                className = "text-sm text-white bg-primary px-3 py-1 rounded ml-2"
                onClick = { onOpenAddGroupModal }
              >
                New group
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className = "shadow-customcard rounded p-3">
        <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
          <div className = "inline-block min-w-full overflow-hidden">
            <table className = "min-w-full leading-normal">
              <thead>
                <tr>
                  <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Phone
                  </th>
                  <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Name
                  </th>
                  <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Email
                  </th>
                  <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                { data?.data.length === 0 && 'No customer found' }
                { data?.data?.map((customer) => (
                  <tr key = { customer.id }>
                    <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                      <p className = "text-blacklight whitespace-no-wrap">
                        { customer.phone }
                      </p>
                    </td>
                    <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                      <p className = "text-blacklight whitespace-no-wrap">
                        { customer.name }
                      </p>
                    </td>
                    <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                      <p className = "text-blacklight whitespace-no-wrap">
                        { customer.email }
                      </p>
                    </td>
                    <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                      <Link
                        to = { `/view-customer/${customer.id}` }
                        className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                      >
                        <EditOutlinedIcon />
                      </Link>
                      <span className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                        <DeleteOutlinedIcon style = { { fontSize: '20px' } } />
                      </span>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        open = { importModal }
        showCloseIcon = { false }
        onClose = { onCloseOpenImportModal }
      >
        <div className = "modalboximport p-4">
          <div className = "flex justify-between">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Import customers by CSV
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseOpenImportModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "w-full border-dashed border-2 border-boderColor-300 rounded px-5 py-8 text-center mt-5">
            <input className = "text-sm" type = "file" accept = ".csv" />
          </div>
          <div className = "flex justify-between mt-5">
            <div className = "self-center">
              <a href = "#" className = "text-sm text-blue underline">
                Download a sample CSV
              </a>
            </div>
            <div className = "self-center">
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded"
                onClick = { onCloseOpenImportModal }
              >
                Cancel
              </button>
              <button className = "text-sm text-white bg-primary px-3 py-1 rounded ml-2">
                Import customers
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { exportModal }
        showCloseIcon = { false }
        onClose = { onCloseExportModal }
      >
        <div className = "modalboximport p-4">
          <div className = "flex justify-between">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Export customers
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseExportModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "w-full mt-5">
            <h3 className = "text-sm text-blacklight font-bold mb-3">
              Customers selected
            </h3>
            <p>
              <input
                type = "radio"
                name = "customer"
                className = "inline-block mr-2"
              />
              <span className = "align-text-top text-sm text-blacklight">
                Current page
              </span>
            </p>
            <p>
              <input
                type = "radio"
                name = "customer"
                className = "inline-block mr-2"
              />
              <span className = "align-text-top text-sm text-blacklight">
                All customers
              </span>
            </p>
            <p>
              <input
                type = "radio"
                name = "customer"
                className = "inline-block mr-2"
              />
              <span className = "align-text-top text-sm text-blacklight">
                Selected customer
              </span>
            </p>
          </div>
          <div className = "flex justify-between mt-5">
            <div className = "self-center">
              <p className = "text-sm text-blacklight">File format CSV</p>
            </div>
            <div className = "self-center">
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded"
                onClick = { onCloseExportModal }
              >
                Cancel
              </button>
              <button className = "text-sm text-white bg-primary px-3 py-1 rounded ml-2">
                Export customers
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { newCustomerModal }
        showCloseIcon = { false }
        onClose = { onCloseNewCustomerModal }
      >
        <div className = "customermodal">
          <div className = "flex justify-between bg-[#f0f3f3] py-3 px-4">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Create a new customer
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseNewCustomerModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <ul className = "py-3 px-4">
            <li>
              <label className = "text-sm text-blacklight">First Name</label>
              <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                <div className = "width5%">
                  <span className = "text-secondary">
                    <PersonOutlineOutlinedIcon style = { { fontSize: '20px' } } />
                  </span>
                </div>
                <input
                  placeholder = "First Name"
                  className = "inline-block text-sm outline-0 w-width95%"
                />
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">family name</label>
              <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                <div className = "width5%">
                  <span className = "text-secondary">
                    <PeopleAltOutlinedIcon style = { { fontSize: '20px' } } />
                  </span>
                </div>
                <input
                  placeholder = "family name"
                  className = "inline-block text-sm outline-0 w-width95%"
                />
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">E-mail</label>
              <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                <div className = "width5%">
                  <span className = "text-secondary">
                    <EmailOutlinedIcon style = { { fontSize: '20px' } } />
                  </span>
                </div>
                <input
                  placeholder = "E-mail"
                  className = "inline-block text-sm outline-0 w-width95%"
                />
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">Select country</label>
              <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                <select className = "inline-block text-sm outline-0 w-full text-secondary">
                  <option>Saudi Arabia</option>
                  <option>Kuwait</option>
                  <option>Qatar</option>
                  <option>Iraq</option>
                </select>
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">Phone</label>
              <div className = "flex justify-between border border-borderColor px-2 rounded mt-1">
                <div className = "width5%">
                  <span className = "text-blacklight inline-block text-sm border-r mr-2 border-borderColor w-full py-1.5">
                    +966
                  </span>
                </div>
                <input
                  placeholder = "Phone"
                  type = "number"
                  className = "inline-block text-sm outline-0 w-width95% ml-2 py-1.5"
                />
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">Date of birth</label>
              <div className = " border border-borderColor px-2 py-1 rounded mt-1">
                <div>
                  <input
                    type = "date"
                    className = "inline-block text-sm text-secondary outline-0 w-full"
                  />
                </div>
              </div>
            </li>
            <li className = "mt-3">
              <label className = "text-sm text-blacklight">Select gender</label>
              <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                <select className = "inline-block text-sm outline-0 w-full text-secondary">
                  <option>Select gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </li>
          </ul>
          <div className = "py-5 px-4">
            <button className = "text-sm text-white bg-primary px-3 py-1 rounded">
              Create customer
            </button>
            <button
              className = "text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded ml-2"
              onClick = { onCloseNewCustomerModal }
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open = { addGroupModal }
        showCloseIcon = { false }
        onClose = { onCloseAddGroupModal }
      >
        <div className = "modalboxsendingmessege">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Create a new group
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddGroupModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "w-full px-4 py-3">
            <ul>
              <li className = "mt-3">
                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                  <div className = "width5%">
                    <span className = "text-secondary">
                      <PeopleOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>
                  </div>
                  <input
                    placeholder = "Group name"
                    className = "inline-block text-sm outline-0 w-width95%"
                    value = { groupName }
                    onChange = { (e) => setGroupName(e.target.value) }
                  />
                </div>
              </li>
              <li className = "mt-5">
                <div>
                  <label className = "text-sm text-blacklight">
                    Automatically add customers to the group when the following
                    condition is met (optional)
                  </label>
                  { conditions.map((condition, index) => (
                    <div key = { index } className = "flex justify-between mt-2">
                      <div className = "w-width30%">
                        <input
                          placeholder = "Condition value"
                          value = { condition.value }
                          onChange = { (e) =>
                            handleConditionChange(
                              index,
                              'value',
                              e.target.value
                            )
                          }
                          className = "w-full border border-borderColor text-sm px-2 py-1 rounded outline-0 inline-block"
                        />
                      </div>
                      <div className = "w-width22%">
                        <select
                          value = { condition.condition }
                          onChange = { (e) =>
                            handleConditionChange(
                              index,
                              'condition',
                              e.target.value
                            )
                          }
                          className = "text-sm border border-borderColor px-2 py-1 rounded outline-none w-full text-secondary"
                        >
                          <option disabled value = "">
                            Condition
                          </option>
                          <option>bigger</option>
                          <option>younger than</option>
                          <option>range between</option>
                        </select>
                      </div>
                      <div
                        className = {
                          conditions.length > 1 ? 'w-width40%' : 'w-width44%'
                        }
                      >
                        <select
                          value = { condition.condition_type }
                          onChange = { (e) =>
                            handleConditionChange(
                              index,
                              'condition_type',
                              e.target.value
                            )
                          }
                          className = "text-sm border border-borderColor px-2 py-1 rounded outline-none w-full text-secondary"
                        >
                          <option disabled value = "">
                            Condition type
                          </option>
                          <option>Total purchases</option>
                          <option>Number of requests</option>
                          <option>Store rating</option>
                          <option>They have no requests</option>
                          <option>They have canceled orders</option>
                          <option>Sex</option>
                          <option>Date of birth</option>
                          <option>Account opening date</option>
                        </select>
                      </div>
                      { conditions.length > 1 && ( // Only render delete button if there's more than one condition
                        <div>
                          <button
                            onClick = { () => deleteCondition(index) }
                            className = "text-sm bg-[#f0f3f3] inline-block px-1 py-1 rounded"
                          >
                            <DeleteIcon style = { { fontSize: '20px' } } />
                          </button>
                        </div>
                      ) }
                    </div>
                  )) }
                  <button
                    onClick = { addCondition }
                    className = "text-sm bg-[#f0f3f3] inline-block px-4 py-2 rounded mt-5"
                  >
                    <span>
                      <AddOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>{ ' ' }
                    Add a new condition
                  </button>
                </div>
              </li>
              <li className = "mt-3">
                <h3 className = "text-lg font-semibold text-blacklight">
                  Group advantages
                </h3>
                <p className = "text-sm text-blacklight mt-1">
                  You can assign benefits to the customer group through the
                  store settings. Through the shipping company restrictions, you
                  can control which shipping company appears to the group’s
                  customers. Through the payment method restrictions, you can
                  control any payment methods that appear to the group’s
                  customers.
                </p>
              </li>
              <li>
                <ul className = "list-disc ml-5 mt-2">
                  <li>
                    <a
                      href = "#"
                      className = "text-blacklight hover:text-primary text-sm"
                    >
                      Fraud protection
                    </a>
                  </li>
                  <li>
                    <a
                      href = "#"
                      className = "text-blacklight hover:text-primary text-sm"
                    >
                      Shipping company restrictions
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <p className = "text-sm text-red">{ errorMessage }</p>
            <button
              className = "text-sm text-white bg-primary px-5 py-2 rounded mt-5"
              onClick = { submitgroupdata }
            >
              { groupisLoading ? 'Saving...' : 'Save' }
            </button>
          </div>
        </div>
      </Modal>

      { /* filter code open here */ }

      <div
        className = { `filtersidebar h-44 overflow-x-auto custom-scroll ${
          isMenuOpen ? 'filterslide-in' : 'filterslide-out'
        } transition-transform duration-300 drop-shadow-lg` }
        ref = { menuRef }
      >
        <div>
          <p className = "flex justify-end cursor-pointer">
            <img
              className = "w-5"
              src = { crossmenu }
              onClick = { toggleMenu }
              alt = "crossmenu"
            />
          </p>
          <p className = "text-blacklight text-base mb-5">
            <span className = "text-secondary">
              <FilterAltOutlinedIcon style = { { fontSize: '20px' } } />
            </span>{ ' ' }
            Sort customers by
          </p>
          <p className = "text-blacklight text-sm">new applications</p>
          <p className = "text-blacklight text-sm">
            Name or mobile number contains
          </p>
          <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-2">
            <div className = "width5%">
              <span className = "text-secondary align-super inline-block mr-1">
                <PersonOutlineOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
            <input
              placeholder = "Find what you want"
              className = "inline-block text-xs outline-0 w-width95%"
            />
          </div>
          <ul>
            <li className = "mt-4">
              <FilterAcordian
                question = {
                  <p className = "text-blacklight text-sm">
                    <span className = "align-text-top text-secondary">
                      <PersonOutlineOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>{ ' ' }
                    Sex
                  </p>
                }
                answer = {
                  <div className = "mt-4">
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm"
                        type = "radio"
                        name = "gender"
                        id = "gender_all"
                      />
                      <label
                        htmlFor = "gender_all"
                        className = "text-sm text-blacklight ml-2 align-text-top"
                      >
                        Everyone
                      </label>
                    </div>
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm"
                        type = "radio"
                        name = "gender"
                        id = "male-all"
                      />
                      <label
                        htmlFor = "male-all"
                        className = "text-sm text-blacklight ml-2 align-text-top"
                      >
                        Male
                      </label>
                    </div>
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm"
                        type = "radio"
                        name = "gender"
                        id = "female_all"
                      />
                      <label
                        htmlFor = "female_all"
                        className = "text-sm text-blacklight ml-2 align-text-top"
                      >
                        Females
                      </label>
                    </div>
                  </div>
                }
              />
            </li>
            <li className = "mt-3">
              <FilterAcordian
                question = {
                  <p className = "text-blacklight text-sm">
                    <span className = "align-text-top text-secondary">
                      <FmdGoodOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>{ ' ' }
                    The address
                  </p>
                }
                answer = {
                  <ul>
                    <li className = "mt-4">
                      <label className = "text-sm text-blacklight block">
                        Country
                      </label>
                      <select className = "mt-1 border border-borderColor px-2 py-0.5 rounded w-full text-sm text-blacklight outline-none">
                        <option>All country</option>
                        <option>Saudi Arabia</option>
                        <option>Kuwait</option>
                      </select>
                    </li>
                    <li className = "mt-3">
                      <label className = "text-sm text-blacklight block">
                        City
                      </label>
                      <select className = "mt-1 border border-borderColor px-2 py-0.5 rounded w-full text-sm text-blacklight outline-none">
                        <option>All city</option>
                        <option>Saudi Arabia</option>
                        <option>Kuwait</option>
                      </select>
                    </li>
                  </ul>
                }
              />
            </li>
            <li className = "mt-3">
              <FilterAcordian
                question = {
                  <p className = "text-blacklight text-sm">
                    <span className = "align-text-top text-secondary">
                      <PeopleOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>{ ' ' }
                    Groups
                  </p>
                }
                answer = {
                  <div className = "mt-4">
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm cursor-pointer"
                        type = "checkbox"
                        name = "gender"
                        id = "gender_all"
                      />
                      <label
                        htmlFor = "gender_all"
                        className = "text-sm text-blacklight ml-2 align-text-top cursor-pointer"
                      >
                        Everyone
                      </label>
                    </div>
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm cursor-pointer"
                        type = "checkbox"
                        name = "gender"
                        id = "purchasing-all"
                      />
                      <label
                        htmlFor = "purchasing-all"
                        className = "text-sm text-blacklight ml-2 align-text-top cursor-pointer"
                      >
                        The most purchasing customers
                      </label>
                    </div>
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm cursor-pointer"
                        type = "checkbox"
                        name = "gender"
                        id = "loyal_all"
                      />
                      <label
                        htmlFor = "loyal_all"
                        className = "text-sm text-blacklight ml-2 align-text-top cursor-pointer"
                      >
                        Highly loyal customers
                      </label>
                    </div>
                    <div className = "ml-1 mt-1">
                      <input
                        className = "text-sm cursor-pointer"
                        type = "checkbox"
                        name = "gender"
                        id = "Diamond_all"
                      />
                      <label
                        htmlFor = "Diamond_all"
                        className = "text-sm text-blacklight ml-2 align-text-top cursor-pointer"
                      >
                        Diamond category
                      </label>
                    </div>
                  </div>
                }
              />
            </li>
            <li className = "mt-3">
              <FilterAcordian
                question = {
                  <p className = "text-blacklight text-sm">
                    <span className = "align-text-top text-secondary">
                      <CalendarMonthOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>{ ' ' }
                    Account opening date
                  </p>
                }
                answer = {
                  <div className = "mt-4 relative">
                    <lebel className = "text-sm text-blacklight">From</lebel>
                    <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded">
                      <div className = "width5%">
                        <span className = "text-secondary align-text-top inline-block mr-1">
                          <CalendarMonthOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <div className = "w-width95%">
                        <DatePicker
                          selected = { startDate }
                          onChange = { (date) => setStartDate(date) }
                          className = "outline-none"
                        />
                      </div>
                    </div>

                    <lebel className = "text-sm text-blacklight inline-block mt-2">
                      to
                    </lebel>
                    <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded">
                      <div className = "width5%">
                        <span className = "text-secondary align-text-top inline-block mr-1">
                          <CalendarMonthOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <div className = "w-width95%">
                        <DatePicker
                          selected = { startDatewithto }
                          onChange = { (date) => setStartDatewithto(date) }
                          className = "outline-none"
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </li>
            <li className = "mt-3">
              <FilterAcordian
                question = {
                  <p className = "text-blacklight text-sm">
                    <span className = "align-text-top text-secondary">
                      <SellOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>{ ' ' }
                    Tags
                  </p>
                }
                answer = {
                  <ul>
                    <li className = "mt-4">
                      <select className = "mt-1 border border-borderColor px-2 py-0.5 rounded w-full text-sm text-blacklight outline-none">
                        <option>Choose one of the tags</option>
                      </select>
                    </li>
                  </ul>
                }
              />
            </li>
            <li>
              <div className = "flex mt-10">
                <button className = "bg-[#f0f3f3] px-4 py-1.5 text-sm rounded">
                  Reset
                </button>
                <button className = "bg-primary px-4 py-1.5 text-sm rounded text-white flex-1 ml-3">
                  Show results
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      { /* filter code closed here */ }
    </>
  );
};

export default CustomerList;
