import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';

const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    customerlist: builder.query({
      query: (search) => {
        let url = '/users/admin/';
        if(search){
          url += `?search=${search}`;
        }
        return url;
      }
    }),
    customerlistdetails: builder.query({
      query: (id) => `/users/admin/${id}`
    }),
    customeroverview: builder.query({
      query: () => '/users/admin/overview/'
    }),
    createcustomerGroup: builder.mutation({
      query(body) {
        return {
          url: '/coupons/admin/create-group/',
          method: 'POST',
          body
        };
      }
    })
  })
});


export const { 
  useCustomerlistQuery,
  useCustomerlistdetailsQuery,
  useCustomeroverviewQuery,
  useCreatecustomerGroupMutation

} = customerApi;