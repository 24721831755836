/* eslint-disable react/prop-types */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import OrderFilterAccordian from './OrderFilterAccordian';

const OrderFilter = ({filtermenu, setFiltermenu}) => {

  const menuRef = useRef(null);
  const crossmenu = process.env.PUBLIC_URL + '/images/icons/crossmenu.svg';

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setFiltermenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleCloseFilter = () => {
    setFiltermenu(false);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [startDatewithto, setStartDatewithto] = useState(new Date());

  return(
    <>
      { /* filter code open here */ }

      <div className = { `filtersidebar h-44 overflow-x-auto custom-scroll ${
        filtermenu ? 'filterslide-in' : 'filterslide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end cursor-pointer' onClick = { handleCloseFilter }><img className = 'w-5' src = { crossmenu }  alt = 'crossmenu' /></p>
          <p className = 'text-blacklight text-base mb-5'><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span> Sort orders by</p>
          <ul>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = { <p className = 'text-blacklight text-sm'>Order status</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "gender_all"/>
                    <label  htmlFor = "gender_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "Draft"/>
                    <label  htmlFor = "Draft" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Draft</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Deleted"/>
                    <label  htmlFor = "Deleted" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Deleted</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Waitingforpayment"/>
                    <label  htmlFor = "Waitingforpayment" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Waiting for payment</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Waitingforreview"/>
                    <label  htmlFor = "Waitingforreview" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Waiting for review</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Underway"/>
                    <label  htmlFor = "Underway" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Underway</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Implemented"/>
                    <label  htmlFor = "Implemented" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Implemented</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Deliveryisinprogress"/>
                    <label  htmlFor = "Deliveryisinprogress" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Delivery is in progress</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Delivered"/>
                    <label  htmlFor = "Delivered" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Delivered</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "charged"/>
                    <label  htmlFor = "charged" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>charged</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Canceled"/>
                    <label  htmlFor = "Canceled" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Canceled</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Retrieved"/>
                    <label  htmlFor = "Retrieved" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Retrieved</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "charged"/>
                    <label  htmlFor = "charged" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Being returned</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "charged"/>
                    <label  htmlFor = "charged" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Request for Quotation</label>
                  </div>
                </div> } />
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Shipping company</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "everyone"/>
                    <label  htmlFor = "everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "representative"/>
                    <label  htmlFor = "representative" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Beauty Icon store representative</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Aramex"/>
                    <label  htmlFor = "Aramex" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Aramex</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "DHLExpress"/>
                    <label  htmlFor = "DHLExpress" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>DHL Express</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Fastlo"/>
                    <label  htmlFor = "Fastlo" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Fastlo</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Anywhere"/>
                    <label  htmlFor = "Anywhere" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Anywhere</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Lightning"/>
                    <label  htmlFor = "Lightning" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Lightning</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Tookan"/>
                    <label  htmlFor = "Tookan" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Tookan</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "generous"/>
                    <label  htmlFor = "generous" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>generous</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "LastPointShipping"/>
                    <label  htmlFor = "LastPointShipping" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Last Point Shipping</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "GTExpress"/>
                    <label  htmlFor = "GTExpress" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>G&T Express</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "smsa"/>
                    <label  htmlFor = "smsa" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>smsa</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "UPS"/>
                    <label  htmlFor = "UPS" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>UPS</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "redbox"/>
                    <label  htmlFor = "redbox" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>redbox</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Smooth"/>
                    <label  htmlFor = "Smooth" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Smooth</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Esnad Express"/>
                    <label  htmlFor = "Esnad Express" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Esnad Express</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Attribution"/>
                    <label  htmlFor = "Attribution" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Attribution</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "D"/>
                    <label  htmlFor = "D" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>D</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = { <p className = 'text-blacklight text-sm'>Type of Request</p> }
                answer = { <div className = 'mt-4'>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" name = "gender" id = "gender_all"/>
                    <label  htmlFor = "gender_all" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Directorder"/>
                    <label  htmlFor = "Directorder" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Direct order</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Donatequickly"/>
                    <label  htmlFor = "Donatequickly" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Donate quickly</label>
                  </div>
                  
                </div> } />
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>It has not been read</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input type = 'radio' className = 'text-sm cursor-pointer' id = 'beenread' />
                    <label className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer' htmlFor = 'beenread'>Yes</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Payment method</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Everyone"/>
                    <label  htmlFor = "Everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Paiementwhenrecieving"/>
                    <label  htmlFor = "Paiementwhenrecieving" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Paiement when recieving</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Creditcard"/>
                    <label  htmlFor = "Creditcard" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Credit card</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Bezel"/>
                    <label  htmlFor = "Bezel" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Bezel</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Tamara"/>
                    <label  htmlFor = "Tamara" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Tamara</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Tabby"/>
                    <label  htmlFor = "Tabby" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Tabby</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "MESBay"/>
                    <label  htmlFor = "MESBay" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>MES Bay</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Knet"/>
                    <label  htmlFor = "Knet" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Knet</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "DimasAl"/>
                    <label  htmlFor = "DimasAl" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Dimas Al-Jazeera Trading Est</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>State and city</p>
              }
              answer = {
                <>
                  <div className = 'mt-1'>
                    <label className = 'block text-sm text-blacklight ml-1 align-text-top cursor-pointer'>Country</label>
                    <select className = 'text-sm text-blacklight ml-1 inline-block border border-borderColor rounded outline-none py-1 px-2 mt-1 w-full'>
                      <option>Everyone</option>
                      <option>Saudi Arabia</option>
                      <option>Kuwait</option>
                      <option>Oman</option>
                      <option>Egypt</option>
                    </select>
                  </div>
                  <div className = 'mt-3'>
                    <label className = 'block text-sm text-blacklight ml-1 align-text-top cursor-pointer'>City</label>
                    <select className = 'text-sm text-blacklight ml-1 inline-block border border-borderColor rounded outline-none py-1 px-2 mt-1 w-full'>
                      <option>All cities</option>
                    </select>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Branch</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "everyone"/>
                    <label  htmlFor = "everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "themain"/>
                    <label  htmlFor = "themain" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>the main</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Arrange orders</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "descending"/>
                    <label  htmlFor = "descending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Order number - descending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "ascending"/>
                    <label  htmlFor = "ascending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Order number - ascending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "Aggregate"/>
                    <label  htmlFor = "Aggregate" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Aggregate Demand - Descending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "updateascending"/>
                    <label  htmlFor = "updateascending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Date of last update - ascending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "updatedescending"/>
                    <label  htmlFor = "updatedescending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Date of last update - descending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "dateascending"/>
                    <label  htmlFor = "dateascending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Order date - ascending</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "radio" name = 'descending' id = "datedescending"/>
                    <label  htmlFor = "datedescending" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Order date - descending order</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Employee requests</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "everyone"/>
                    <label  htmlFor = "everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "assignedreqst"/>
                    <label  htmlFor = "assignedreqst" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>My assigned requests</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Sales channels</p>
              }
              answer = {
                <>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "everyone"/>
                    <label  htmlFor = "everyone" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Everyone</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Mobilebrowser"/>
                    <label  htmlFor = "Mobilebrowser" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Mobile browser</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "Computerbrowser"/>
                    <label  htmlFor = "Computerbrowser" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Computer browser</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "marketing"/>
                    <label  htmlFor = "marketing" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Affiliate marketing</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "application"/>
                    <label  htmlFor = "application" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Local application</label>
                  </div>
                  <div className = 'ml-1 mt-1'>
                    <input className = 'text-sm cursor-pointer' type = "checkbox" id = "unknown"/>
                    <label  htmlFor = "unknown" className = 'text-sm text-blacklight ml-2 align-text-top cursor-pointer'>Unknown</label>
                  </div>
                </>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = { <p className = 'text-blacklight text-sm'>Account opening date</p> }
                answer = { <div className = 'mt-4 relative'>
                  <lebel className = 'text-sm text-blacklight'>From</lebel>
                  <div className = 'flex justify-between border border-borderColor px-2 py-0.5 rounded'>
                    <div className = 'width5%'><span className = 'text-secondary align-text-top inline-block mr-1'><CalendarMonthOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <div className = 'w-width95%'><DatePicker selected = { startDate } onChange = { (date) => setStartDate(date) } className = 'outline-none'  /></div>
                  </div>

                  <lebel className = 'text-sm text-blacklight inline-block mt-2'>to</lebel>
                  <div className = 'flex justify-between border border-borderColor px-2 py-0.5 rounded'>
                    <div className = 'width5%'><span className = 'text-secondary align-text-top inline-block mr-1'><CalendarMonthOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <div className = 'w-width95%'><DatePicker selected = { startDatewithto } onChange = { (date) => setStartDatewithto(date) } className = 'outline-none'  /></div>
                  </div>
                </div> } />
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Tags</p>
              }
              answer = {
                <ul>
                  <li className = 'mt-2'>
                    <select className = 'mt-1 border border-borderColor inline-block px-2 py-1.5 rounded w-full text-sm text-blacklight outline-none'>
                      <option>Choose one of the tags</option>
                    </select>
                  </li>
                </ul>
              }/>
            </li>
            <li className = 'mt-3'>
              <OrderFilterAccordian  question = {
                <p className = 'text-blacklight text-sm'>Products</p>
              }
              answer = {
                <ul>
                  <li className = 'mt-2'>
                    <input className = 'mt-1 border border-borderColor inline-block px-2 py-1.5 rounded w-full text-sm text-blacklight outline-none' placeholder = 'Search for a product...' />
                  </li>
                </ul>
              }/>
            </li>
            <li>
              <div className = 'flex mt-10'>
                <button className = 'bg-[#f0f3f3] px-4 py-1.5 text-sm rounded'>Reset</button>
                <button className = 'bg-primary px-4 py-1.5 text-sm rounded text-white flex-1 ml-3'>Show results</button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      { /* filter code closed here */ }
    </>
  );
};

export default OrderFilter;