/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const Scentfamilycatloglist = ({handleChangeScentcatlog, selectedScentcatlogOption, scentcatloglist, setScentinputValue, scentinputValue}) => {

  const handleInputChange = (newValue) => {
    setScentinputValue(newValue);
  };

  console.log('scentcatloglist',scentcatloglist);

  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary border-0'
        value = { selectedScentcatlogOption }
        onChange = { handleChangeScentcatlog }
        options = { scentcatloglist }
        scentinputValue = { scentinputValue }
        onInputChange = { handleInputChange }
        isMulti
        placeholder = 'Select scent category'
      />
    </>
  );
};

export default Scentfamilycatloglist;