import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const testimonialApi = createApi({
  reducerPath: 'testimonialApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    testimonialList: builder.query({
      query: () => '/store/admin/list-testimonials/'
    }),
    addtestimonialList: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/create-testimonials/',
          method: 'POST',
          body
        };
      }
    }),
    updatetestimonial: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/update-testimonials/${id}/`,
          method: 'PUT',
          body
        };
      }
    }),
    deletetestimonial: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/delete-testimonials/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    gettestimonialbyid: builder.query({
      query: (id) => `/store/admin/retrieve-testimonials/${id}/`
    }),
    permissiontestimonial: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/approve-testimonials/${id}/`,
          method: 'POST',
          body
        };
      }
    })
  })
});

export const { useAddtestimonialListMutation, useTestimonialListQuery, useUpdatetestimonialMutation, useDeletetestimonialMutation, useGettestimonialbyidQuery, usePermissiontestimonialMutation } = testimonialApi;
