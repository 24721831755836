import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ArrangeProducts = () => {
  const [productsArray, setProductsArray] = useState([
    {
      id: 'product1',
      name: 'Mens T-shirt',
      quantity: 50,
      img: 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/placeholder.png'
    },
    {
      id: 'product2',
      name: 'I sweetened the oryx',
      quantity: 15,
      img: 'https://cdn.salla.sa/XWew/yq7pIMATFEjIpIENBBNJuqFMzpP5YfV5OLvGj6eq.jpg'
    },
    {
      id: 'product3',
      name: 'Leno ball pen',
      quantity: 10,
      img: 'https://cdn.salla.sa/XWew/9qbfquUmywu3SsawNKMGKISqrKXcqhd7NNZJmSCM.png'
    },
    {
      id: 'product4',
      name: 'Imported cotton T-shirt',
      quantity: 50,
      img: 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/placeholder.png'
    }
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(productsArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProductsArray(items);
  };

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>arrange products</span></li>
            </ul>
            <p className = 'text-sm text-blacklight'>Arrange products</p>
          </div>
          <div>
            <div className = 'flex mt-5'>
              <div><button className = 'bg-[#f0f3f3] inline-block text-sm py-2.5 px-4 rounded text-blacklight'>Save the order</button></div>
              <div className = 'flex-1 ml-5'>
                <select className = 'border border-borderColor rounded px-2 py-2 w-full outline-none'>
                  <option>Select Categories</option>
                  <option>Clothing department</option>
                  <option>Perfumes section</option>
                  <option>Appliances</option>
                  <option>Abdullah</option>
                </select>
              </div>
            </div>
          </div>
          <div className = 'mt-8'>
            <DragDropContext onDragEnd = { onDragEnd }>
              <Droppable droppableId = "products" direction = "horizontal">
                { (provided) => (
                  <div ref = { provided.innerRef } { ...provided.droppableProps } className = 'flex gap-3'>
                    { productsArray.map((product, index) => (
                      <Draggable key = { product.id } draggableId = { product.id } index = { index }>
                        { (provided) => (
                          <div
                            ref = { provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                            className = 'w-width24% border border-borderColor rounded p-2'
                          >
                            <img className = 'w-full h-56' src = { product.img } alt = 'product' />
                            <h3 className = 'text-base text-black font-medium mt-2'>{ product.name }</h3>
                            <p className = 'text-sm text-blacklight'>Quantity: { product.quantity }</p>
                          </div>
                        ) }
                      </Draggable>
                    )) }
                    { provided.placeholder }
                  </div>
                ) }
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArrangeProducts;
