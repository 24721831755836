/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Switch from 'react-switch';



const ShippingCompanyOptions = ({shippingCompany,setShippingCompany}) => {

  const [shippingCompanyModal, setShippingCompanyModal] = useState(false);

  useEffect(() => {

    if(shippingCompany == true){
      setShippingCompanyModal(true);
    }else{
      setShippingCompanyModal(false);
    }
    
  },[shippingCompany]);

  const closeShippingCompany = () => {
    setShippingCompany(false);
    setShippingCompanyModal(false);
  };

  

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  return(
    <>
      <Modal open = { shippingCompanyModal } showCloseIcon = { false } onClose = { closeShippingCompany }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Shipping company options</h3>
            <div className = 'self-center'><span onClick = { closeShippingCompany } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <ul>
              <li>
                <div className = 'flex'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Allow shipping companies to change order status</h3>
                    <p className = 'text-sm text-secondary mt-1'>Some shipping companies provide a feature to automatically change the status of orders, based on the shipping status, such as Fastlaw and Salasa companies.</p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Allow shipping companies to synchronize quantities</h3>
                    <p className = 'text-sm text-secondary mt-1'>Some shipping companies provide the feature of synchronizing quantities with a basket, such as Digipacks, Esnad, and Track.</p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Display one of the private shipping companies when the shipping fees match on the order finalization page</h3>
                    <p className = 'text-sm text-secondary mt-1'>When the option is activated and there is more than one shipping company serving the same city or country of the customer and the shipping prices are identical, only one company is displayed. This applies to the companies manually added only.</p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Submit data for sub-products within a product of type “ Product Group ”</h3>
                    <p className = 'text-sm text-secondary mt-1'>When this option is activated, the details of the combined product with the details of the sub-products will be sent to the shipping company.
                    </p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Allow the system to automatically identify the warehouse according to the customer's city</h3>
                    <p className = 'text-sm text-secondary mt-1'>When the option is activated, the system will select the warehouse (if any) according to the city in the shipping address when the order is converted to “Executed”.</p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <div>
                    <h3 className = 'text-sm text-blacklight font-medium'>Allow shippers to export a bill for an incomplete order</h3>
                    <p className = 'text-sm text-secondary mt-1'>Some shipping companies issue a policy for the order as soon as it is created (before its status is fulfilled).</p>
                  </div>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShippingCompanyOptions;