/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const StatisticsModal = ({ statisticsModal, setStatisticsModal }) => {

  const handleCloseStatisticsModal = () => {

    setStatisticsModal(false);
  };

  const [startDate, setStartDate] = useState(new Date());

  const [dateselected, setDateselected] = useState(1);

  const handleDateSelection = (e) => {
    setDateselected(e.target.value);
  };

  return (
    <Modal open = { statisticsModal } showCloseIcon = { false } onClose = { handleCloseStatisticsModal }>
      <div className = 'modaladdproduct'>
        <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
          <h3 className = 'text-blacklight text-base font-semibold self-center'>SH91</h3>
          <div className = 'self-center'><span onClick = { handleCloseStatisticsModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
        </div>
        <div className = 'px-4 py-4'>
          <div className = 'border border-borderColor rounded flex px-4 py-2 bg-[#f0f3f3]'>
            <div>
              <span className = 'text-red'><InfoOutlinedIcon style = { {fontSize: '32px'} } /></span>
            </div>
            <div className = 'ml-2'>
              <p className = 'text-sm text-red'>Coupon statistics are displayed only for the following order statuses:</p>
              <p className = 'text-sm text-red'>In progress - completed - being delivered - delivered - shipped</p>
            </div>
          </div>
          <div className = 'flex justify-between mt-5'>
            <div className = 'w-width15% bg-[#f0f3f3] rounded px-4 py-1'>
              <p className = 'text-sm text-black'>an offer</p>
            </div>
            <div className = { `${dateselected == 5?'': 'w-3/5'}` }>
              {
                dateselected == 1 &&  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded'>
                  <div className = 'w-width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <div className = 'w-width95% text-sm self-center'>
                    <DatePicker
                      selected = { startDate }
                      onChange = { (date) => setStartDate(date) }
                      className = 'outline-none'
                    />
                  </div>
                </div>
              }

              {
                dateselected == 2 &&  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded'>
                  <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <div className = 'w-width95% text-sm self-center'>
                    <select className = 'text-sm w-full outline-none text-black'>
                      <option>Current week (2024-04-14 / 2024-04-20)</option>
                      <option>Last week (2024-04-07 / 2024-04-13)</option>
                    </select>
                  </div>
                </div>
              }

              {
                dateselected == 3 &&  <div>
                  <div className = 'w-full text-sm self-center flex justify-between'>
                    <select className = 'text-sm w-width48% outline-none text-black border border-borderColor px-2 py-1 rounded'>
                      <option>2024</option>
                      <option>2023</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                    </select>
                    <select className = 'text-sm w-width48% outline-none text-black border border-borderColor px-2 py-1 rounded'>
                      <option>January</option>
                      <option>February</option>
                      <option>March</option>
                      <option>April</option>
                      <option>May</option>
                      <option>June</option>
                      <option>July</option>
                      <option>August</option>
                      <option>September</option>
                      <option>October</option>
                    </select>
                  </div>
                </div>
              }

              {
                dateselected == 4 &&  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded'>
                  <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <div className = 'w-width95% text-sm self-center'>
                    <select className = 'text-sm w-full outline-none text-black'>
                      <option>2024</option>
                      <option>2023</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                    </select>
                  </div>
                </div>
              }

              
             
            </div>
            <div className = { `${dateselected == 5?'w-full': 'w-1/5'}` }>
              <div className = 'w-full flex border border-borderColor rounded'>
                <div className = { `${dateselected == 5?'w-full': 'w-4/5'}` }>
                  <select className = 'w-full px-3 py-1 text-sm outline-none' onClick = { handleDateSelection }>
                    <option value = '1'>Daily</option>
                    <option value = '2'>weekly</option>
                    <option value = '3'>Monthly</option>
                    <option value = '4'>annual</option>
                    <option value = '5'>Since the beginning</option>
                  </select>
                </div>
                <div>
                  <span className = 'text-secondary'><CalendarTodayOutlinedIcon style = { {fontSize: '20px'} } /></span>
                </div>
              </div>
            </div>
          </div>
          <div className = 'flex justify-between mt-8'>
            <div className = 'w-width32% text-center'>
              <span className = 'text-primary-400'><LocalAtmOutlinedIcon style = { {fontSize: '40px'} } /></span>
              <h3 className = 'text-black font-medium'>0 SAR</h3>
              <p className = 'text-secondary text-sm'>Coupon sales</p>
            </div>
            <div className = 'w-width32% text-center'>
              <span className = 'text-primary-400'><GroupOutlinedIcon style = { {fontSize: '40px'} } /></span>
              <h3 className = 'text-black font-medium'>0</h3>
              <p className = 'text-secondary text-sm'>Number of clients</p>
            </div>
            <div className = 'w-width32% text-center'>
              <span className = 'text-primary-400'><ConfirmationNumberOutlinedIcon style = { {fontSize: '40px'} } /></span>
              <h3 className = 'text-black font-medium'>0 SAR</h3>
              <p className = 'text-secondary text-sm'>Coupon sales</p>
            </div>
          </div>
          <div className = 'mt-10'>
            <h3 className = 'text-lg text-black font-medium'>Coupon requests</h3>
            <p className = 'text-sm text-blacklight mt-5 text-center'>There are no requests yet</p>
          </div>
          <div className = 'flex justify-between mt-10'>
            <button className = "bg-primary rounded text-white text-sm py-2 px-4 inline-block hover:bg-primary-400 duration-300">Export statistics into Excel file</button>
            <button onClose = { handleCloseStatisticsModal } className = "bg-[#f0f3f3] text-sm text-blacklight inline-block py-2 px-4 rounded">Close</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StatisticsModal;
