/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import { useCatloglistQuery, useGethomecatlogbyidQuery, useUpdatehomecatlogMutation } from '../../redux/api/homecatlogApi';

const EdithomecatlogModal = ({EditCategory, currentCatlog, setEditCategory,refetch}) => {

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [imgurl, setImgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const [selectedCategoryid, setSelectedCategoryid] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [Catloglist, setCatloglist] = useState([]);



  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: categoryisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (categoryisSuccess) {
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };
      const options = categorydata?.data.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setCatloglist([customOption, ...(options || [])]);
    }
  }, [categoryisSuccess, categorydata]);

  const handleChangeproduct = (selectedOption) => {
    setSelectedCategoryOption(selectedOption);
    setSelectedCategoryid(selectedOption?.label);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const [updatehomecatlog, { error: updatecatlogerror, isSuccess: updatecatlogisSuccess, isLoading: updatecatlogisloading }] = useUpdatehomecatlogMutation();

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleimgUrlChange = (e) => {
    setImgurl(e.target.value);
    setErrorMessge('');
  };

  useEffect(() => {
    if (updatecatlogisSuccess) {
      setErrorMessge('');
      setEditCategory(false);
      refetch();
    }
  }, [updatecatlogisSuccess]);

  const handleUpdateSubmit = async () => {
    if ( !imgurl || !name || (!url && !selectedCategoryid)) {
      setErrorMessge('Please fill all fields.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image_url', imgurl);
    formData.append('category_render_url', selectedCategoryid === 'Custom URL'?url:selectedCategoryid);

    try {
      await updatehomecatlog({ body: formData, id: currentCatlog?.id });
    } catch (error) {
      setErrorMessge('Failed to add home category: ', error);
    }
  };

  const { error: getcatlogError, data: getcatlogdata, isLoading: getcatlogisloading } = useGethomecatlogbyidQuery(currentCatlog?.id,{skip: !currentCatlog?.id});

  useEffect(() => {
    if (getcatlogdata) {
      setName(getcatlogdata?.data?.name);
      setImgurl(getcatlogdata?.data?.image_url);
      setUrl(getcatlogdata?.data?.category_render_url);
    }

    const matchedOption = Catloglist.find(option => option.label === getcatlogdata?.data.url);
    if (matchedOption) {
      setSelectedCategoryOption(matchedOption);
      setSelectedCategoryid(matchedOption?.label);
    }else{
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };

      setSelectedCategoryOption(customOption);
      setSelectedCategoryid(customOption?.label);
    }



  }, [getcatlogdata, currentCatlog?.id, Catloglist]);

  
  return (
    <>


      <Modal
        open = { EditCategory }
        showCloseIcon = { false }
        onClose = { () => setEditCategory(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Edit Category
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setEditCategory(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { getcatlogError && <p className = 'text-sm text-red'>{ getcatlogError?.data?.message }</p> }
            { getcatlogisloading && <p className = 'text-sm'>{ getcatlogisloading ? 'Categories data loading...' : '' }</p> }
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
                    Name
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter categories name"
                value = { name }
                onChange = { handleNameChange }
              />
              { updatecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatecatlogerror?.data?.message?.name }
                </div>
              ) }
              <label className = "text-blacklight text-sm self-center block mt-3">
              Image url (size: 540 × 540px)
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { imgurl }
                onChange = { handleimgUrlChange }
              />
              { updatecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatecatlogerror?.data?.message?.image_url }
                </div>
              ) }

              <div>
                <Select
                  className = 'inline-block text-sm mt-3 outline-0 w-full text-secondary'
                  value = { selectedCategoryOption }
                  onChange = { handleChangeproduct }
                  options = { Catloglist }
                  inputValue = { inputValue }
                  onInputChange = { handleInputChange }
                  placeholder = 'Choose category'
                />
              </div>
              {
                selectedCategoryid === 'Custom URL' && <input
                  className = "mt-3 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter url"
                  value = { url }
                  onChange = { handleUrlChange }
                />
              }
              
              { updatecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatecatlogerror?.data?.message?.category_render_url }
                </div>
              ) }
             
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatecatlogisloading }
              >
                { updatecatlogisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EdithomecatlogModal;
