/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetshipingcompanylistbyidQuery, useUpdateshippingcompanyMutation } from '../../redux/api/ShippingApi';

const Editshippingcompany = ({editshipcompany, setEditshipcompany, editshipcompanyid, refetch}) => {

  const [errorMessage, setErrorMessge] = useState('');
  const onCloseEditShippingcompany = () => setEditshipcompany(false);

  const [name, setName] = useState();
  const [imgurl, setImgurl] = useState();
  
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleimgurl = (e) => {
    setImgurl(e.target.value);
  };


  const {error: shippingerror, data: shippingdata} = useGetshipingcompanylistbyidQuery( editshipcompanyid,
    {
      skip: !editshipcompanyid
    });

  useEffect(() => {
    
    if(shippingdata){
      setName(shippingdata?.data?.name);
      setImgurl(shippingdata?.data?.image);
    }
    
  },[shippingdata, editshipcompanyid]);
  
  useEffect(() => {

    if(shippingerror){
      toast(shippingerror?.data?.message);
    }

  },[shippingerror]);

  const [updateshippingcompany, {error, data, isloading, isSuccess}] = useUpdateshippingcompanyMutation();


  useEffect(() => {

    if(isSuccess){
      refetch();
      onCloseEditShippingcompany();
    }

  },[data, isSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    const formdata = new FormData();
    formdata.append('id', editshipcompanyid);
    formdata.append('name', name);
    formdata.append('image', imgurl);


    try{
      await updateshippingcompany(formdata);

    } catch(error){
      setErrorMessge('Failed to update shipping company', error);
    }
  };


  const isFormValid = name && imgurl;



  return (
    <>
      <ToastContainer />
      
      <Modal
        open = { editshipcompany }
        showCloseIcon = { false }
        onClose = { onCloseEditShippingcompany }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Edit Shipping Company
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseEditShippingcompany }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <form onSubmit = { handleSubmit }>
              <div className = "text-start">
                {
                  error && <p className = 'text-sm text-red'>{ error?.data?.message }</p>
                }
                <p className = 'text-sm text-red'>{ errorMessage }</p>
                <label className = "text-blacklight text-sm self-center block">
                Name
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter company name"
                  value = { name }
                  onChange = { handleName }
                />
                <label className = "text-blacklight text-sm self-center block mt-3">
              Image url
                </label>
                <input
                  placeholder = "Enter image url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { imgurl }
                  onChange = { handleimgurl }
                />
                <div>
                  <button 
                    disabled = { !isFormValid }
                    type = 'submit'
                    className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded">
                    { isloading?'Saving...': 'Save' }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Editshippingcompany;
