/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useGetgiftcardbyidQuery, useUpdategiftcardMutation } from '../../redux/api/giftcardApi';


const Editgiftcard = ({EditGift, currentGiftcard, setEditGift,refetch}) => {

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [redirecturl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const [updategiftcard, { error: updategiftcarderror, isSuccess: updatecatlogisSuccess, isLoading: updatecatlogisloading }] = useUpdategiftcardMutation();

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleRedirecturlChange = (e) => {
    setRedirectUrl(e.target.value);
    setErrorMessge('');
  };

  useEffect(() => {
    if (updatecatlogisSuccess) {
      setErrorMessge('');
      setEditGift(false);
      refetch();
    }
  }, [updatecatlogisSuccess]);

  const handleUpdateSubmit = async () => {
    if (!url || !redirecturl) {
      setErrorMessge('Please provide Name, Image url and Redirect url.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image_url', url);
    formData.append('render_url', redirecturl);

    try {
      await updategiftcard({ body: formData, id: currentGiftcard?.id });
    } catch (error) {
      setErrorMessge('Failed to update gift card: ', error);
    }
  };

  const { error: getcatlogError, data: getgiftdata, isLoading: getgiftcardisloading } = useGetgiftcardbyidQuery(currentGiftcard?.id, {skip: !currentGiftcard?.id});

  useEffect(() => {
    if (getgiftdata) {
      setName(getgiftdata?.data?.name);
      setUrl(getgiftdata?.data?.image_url);
      setRedirectUrl(getgiftdata?.data?.render_url);
    }
  }, [getgiftdata, currentGiftcard?.id]);

  
  return (
    <>


      <Modal
        open = { EditGift }
        showCloseIcon = { false }
        onClose = { () => setEditGift(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Edit Giftcard
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setEditGift(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { getcatlogError && <p className = 'text-sm text-red'>{ getcatlogError?.data?.message }</p> }
            { getgiftcardisloading && <p className = 'text-sm'>{ getgiftcardisloading ? 'Gift card data loading...' : '' }</p> }
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
                    Name
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter categories name"
                value = { name }
                onChange = { handleNameChange }
              />
              { updategiftcarderror && (
                <div className = "mt-2 text-red text-sm">
                  { updategiftcarderror?.data?.message?.name }
                </div>
              ) }
              <label className = "text-blacklight text-sm self-center block mt-3">
              Image url
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { url }
                onChange = { handleUrlChange }
              />
              { updategiftcarderror && (
                <div className = "mt-2 text-red text-sm">
                  { updategiftcarderror?.data?.message?.image_url }
                </div>
              ) }
              <label className = "text-blacklight text-sm self-center block mt-3">
                Redirect url
              </label>
              <input
                placeholder = "Enter redirect url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { redirecturl }
                onChange = { handleRedirecturlChange }
              />
              { updategiftcarderror && (
                <div className = "mt-2 text-red text-sm">
                  { updategiftcarderror?.data?.message?.render_url }
                </div>
              ) }
             
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatecatlogisloading }
              >
                { updatecatlogisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Editgiftcard;
