import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddategoryMutation, useCategoryelistQuery, useDeletecategoryMutation, usePermissionMutation } from '../../redux/api/addcategoryApi';
import Editcategory from './Editcategory';
const Addcategory = () => {

  const [Addcategory, setAddcategory] = useState(false);
  const onOpenAddcategory = () => setAddcategory(true);
  const onCloseAddcategory = () => setAddcategory(false);
  const { error, data, isLoading, refetch } = useCategoryelistQuery();

  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  
  const [addategory, { error: categoryerror, isSuccess: gragranceisSuccess, isLoading: categoryisloading }] = useAddategoryMutation();


  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
    setErrorMessge('');
  };

  const handleARNameChange = (e) => {
    setArName(e.target.value);
    setErrorMessge('');
  };

  useEffect(() => {

    if (gragranceisSuccess) {
      setErrorMessge('');
      onCloseAddcategory();
      refetch();
      setEnName('');
      setArName('');
    }
  },[gragranceisSuccess]);

  const handleSubmit = async () => {
    if (!enName || !arName) {
      setErrorMessge('Please provide name arabic and english both.');
      return;
    }
    
    const formData = new FormData();
    formData.append('name_en', enName);
    formData.append('name_ar', arName);

    try {
      await addategory(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add category: ', error);
    }
  };


  const [editcategory, setEditcategory] = useState(false);
  const [currentcategory, setCurrentcategory] = useState(null);

  const OnopenEditGift = (item) => {
    setCurrentcategory(item);
    setEditcategory(true);
  };


  const [deletecategory,{ error: deletecategoryerror, isSuccess: deletecategoryisSuccess }] = useDeletecategoryMutation();

  useEffect(() => {

    if(deletecategoryerror){
      toast(deletecategoryerror?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletecategoryisSuccess){
      refetch();
    }

  }, [deletecategoryisSuccess]);

  const handleCatlogDelete = async (id) => {
    try {
      await deletecategory(id);
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const [permission,{error: errorpermission,data: datapermission , isSuccess: isSuccesspermission}] = usePermissionMutation();

  useEffect(() => {
    if(errorpermission){
      toast.error(errorpermission?.data?.message);
    }
  }, [errorpermission]);

  useEffect(() => {
    if(isSuccesspermission){
      // toast.success(datapermission?.message);
      refetch();
    }
  },[isSuccesspermission,datapermission]);

  const handlePermission = async (item) => {
    const formdata = new FormData();
    formdata.append('action', item?.is_active === false ? 'active' : 'inactive');

    try {
      await permission({ body: formdata, id: item?.id });
    } catch(error) {
      console.log('Failed to update permission', error);
    }
  };

  return (
    <>
      <Editcategory editcategory = { editcategory } currentcategory = { currentcategory } setEditcategory = { setEditcategory } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <button
              onClick = { onOpenAddcategory }
              className = "inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded"
            >
              <span>
                <AddOutlinedIcon style = { { fontSize: '20px' } } />
              </span>{ ' ' }
                Add Category
            </button>

            <div className = "border border-borderColor rounded mt-1 p-3">
              <table className = "min-w-full leading-normal ">
                <thead>
                  <tr>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                      EN (NAME)
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                      AR (NAME)
                    </th>
                    <th className = "text-right px-5 py-3 border-b-2 border-boderColor-300  text-sm  text-gray-700 font-medium uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { error && <p>{ error?.message }</p> }
                  { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
                  { data?.data?.length === 0 && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }
                  { data?.data.map((item) => (
                    <tr key = { item?.id }>
                      <td className = "px-5 py-3 bg-white text-sm text-left border-b border-boderColor-300">
                        <p className = 'text-sm text-blacklight'>{ item?.name_en }</p>
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left border-b border-boderColor-300">
                        <p className = 'text-sm text-blacklight'>{ item?.name_ar }</p>
                      </td>
                      <td className = "px-2 py-3 bg-white text-right border-b border-boderColor-300">
                        <button
                          onClick = { () => OnopenEditGift(item) }
                          className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                        >
                          <span>
                            <EditOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </button>
                        <button onClick = { () => handleCatlogDelete(item?.id) } className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                          <span>
                            <DeleteOutlineOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </button>
                        <button>
                          <span onClick = { () => handlePermission(item) } className = { `cursor-pointer inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2 ${item?.is_approved === false?'text-primary': 'text-red'}` }>
                            { item?.is_active === false ?<VisibilityOutlinedIcon className = 'text-primary' style = { {fontSize: '20px'} } /> : <VisibilityOffOutlinedIcon className = 'text-red-400' style = { {fontSize: '20px'} } /> }
                          </span>
                        </button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open = { Addcategory }
        showCloseIcon = { false }
        onClose = { onCloseAddcategory }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add Category
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddcategory }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <div className = "text-start">
              
              <div>
                <label className = "text-blacklight text-sm self-center block">
                EN (Name)
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter category name"
                  value = { enName }
                  onChange = { handleEnNameChange }
                />
                { categoryerror && (
                  <div className = "mt-2 text-red text-sm">
                    { categoryerror?.data?.message?.name_en }
                  </div>
                ) }
              </div>
              <div className = 'mt-2'>
                <label className = "text-blacklight text-sm self-center block">
                AR (Name)
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter category name"
                  value = { arName }
                  onChange = { handleARNameChange }
                />
                { categoryerror && (
                  <div className = "mt-2 text-red text-sm">
                    { categoryerror?.data?.message?.name_ar }
                  </div>
                ) }
              </div>
              <div>
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <button
                  className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                  onClick = { handleSubmit }
                  disabled = { categoryisloading }
                >
                  { categoryisloading ? 'Saving...' : 'Save' }
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      
    </>
  );
};

export default Addcategory;
