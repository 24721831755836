/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';

const BrandName = ({handleChangebrand, selectedBrandOption, brandlist}) => {


  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        value = { selectedBrandOption }
        onChange = { handleChangebrand }
        options = { brandlist }
        placeholder = 'Choose brand'
    
      />
    </>
  );
};

export default BrandName;