import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarPurple500OutlinedIcon from '@mui/icons-material/StarPurple500Outlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import VignetteOutlinedIcon from '@mui/icons-material/VignetteOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Chart, registerables } from 'chart.js';
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import OrderFilter from '../order/OrderFilter';
Chart.register(...registerables);

const BasketappStore = () => {
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (index) => {
    setActiveItem(index);
  };


  const [isOpenServicemenu, setIsOpenServicemenu] = useState(false);

  const toggleServicemenu = () => {
    setIsOpenServicemenu(!isOpenServicemenu);
  };

  const [filtermenu, setFiltermenu] = useState(false);

  const handleFiltermenu = () => {
    setFiltermenu(true);
  };
  

  return (
    <>
     
      <Header />
      <OrderFilter filtermenu = { filtermenu } setFiltermenu = { setFiltermenu } />
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between mb-5'>
            <div className = 'flex self-center relative'>
              <p className = 'text-sm text-blacklight cursor-pointer' onClick = { toggleServicemenu }><span className = 'text-secondary'><BusinessCenterOutlinedIcon style = { {fontSize: '20px'} } /></span> Services</p>
              <p className = 'text-sm text-blacklight ml-5 cursor-pointer' onClick = { handleFiltermenu }><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span> filtering</p>       
            </div>
            <div className = 'self-center'>
              <h3 className = 'text-lg text-blacklight font-semibold'>All applications (23)</h3>
            </div>
          </div>
          <div>
            <div className = 'ordertabbox custom-scroll'>
              <div className = 'inline-flex mb-5'>
                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 0 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(0) }
                >
                  <p><span className = 'text-secondary'><DownloadDoneIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 0 ?'text-white': ''} ` }>14</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 0 ?'text-white': ''}` }>Activated applications</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 1 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(1) }
                >
                  <p><span className = 'text-secondary'><VisibilityOffOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 1 ?'text-white': ''} ` }>5</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 1 ?'text-white': ''}` }>Inactive applications</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 2 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(2) }
                >
                  <p><span className = 'text-secondary'><VignetteOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 2 ?'text-white': ''} ` }>0</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 2 ?'text-white': ''}` }>Waiting for payment</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 3 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(3) }
                >
                  <p><span className = 'text-secondary'><SystemUpdateAltOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 3 ?'text-white': ''} ` }>0</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 3 ?'text-white': ''}` }>Updates</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 4 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(4) }
                >
                  <p><span className = 'text-secondary'><SyncOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 4 ?'text-white': ''} ` }>4</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 4 ?'text-white': ''}` }>Subscription renewal</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 6 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(6) }
                >
                  <p><span className = 'text-secondary'><InfoOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 6 ?'text-white': ''} ` }>0</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 6 ?'text-white': ''}` }>Subscription ends soon</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 7 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(7) }
                >
                  <p><span className = 'text-secondary'><StarBorderOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 7 ?'text-white': ''} ` }>0</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 7 ?'text-white': ''}` }>Evaluation of applications</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>

                <div className = { `w-52 mr-3 border border-borderColor rounded-md text-center py-5 cursor-pointer ${
                  activeItem === 8 ? 'bg-primary text-white' : ''
                }` } onClick = { () => handleClick(8) }
                >
                  <p><span className = 'text-secondary'><DeleteOutlineOutlinedIcon style = { {fontSize: '30px'} } /></span></p>
                  <h3 className = { `text-blacklight text-base font-bold ${activeItem === 8 ?'text-white': ''} ` }>0</h3>
                  <div className = 'flex justify-center'>
                    <p className = { `text-blacklight self-center text-sm ${activeItem === 8 ?'text-white': ''}` }>Deleted applications</p>
                    <p className = 'self-center ml-2'><span className = 'inline-block w-2 h-2 bg-secondary rounded-full'></span></p>
                  </div>
                </div>
              </div>
            </div>

            <div className = 'flex justify-between mt-10'>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/YhpKwRcNpcwF6gNgqzLEWgQZnqTrZ6sB1CeiBBdG.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>	TikTok Pixel - Catalog</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>(478 ratings)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Basket</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                    { /* <div
                      className = "absolute left-0 top-10 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role = "menu"
                      aria-orientation = "vertical"
                      aria-labelledby = "menu-button"
                      tabIndex = "-1"
                    >
                      <div className = "py-1" role = "none">
                        <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><SettingsOutlinedIcon style = { {fontSize: '18px'} } /></span> Activate the application</Link> 
                      </div>
                    </div> */ }
                  </div>
                </div>
              </div>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/9GEi6u6tuIaWRDKTRaoqHRRlhDqs5V0BzalpH8PZ.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>Zoho Mail</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>5 (60 reviews) App evaluation</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Basket</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
            </div>
            <div className = 'flex justify-between mt-5'>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/dUOlNVpwrn53Td9dnSpZ8pacTsJFk5PMp1jyjx5o.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>	Snap Pixel</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>5 (53 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Wadi Balsam Information Technology</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/h16auzjJZ1AgURQRyLbtRxWG1Tj2W51f9V1G97b4.jpg' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>X Pixel</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>(50 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Basket</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
            </div>
            <div className = 'flex justify-between mt-5'>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/p17SgMZ8ytYnN7m3tddDDFHcge0yCgyZ91FfvQ1F.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>Google Ads</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>5 (53 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Wadi Balsam Information Technology</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/6bXLPj7Gg2RlUmeJuDKf9Hn7luwB0yDvSvtjULaf.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>UPS</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>(50 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Basket</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
            </div>
            <div className = 'flex justify-between mt-5'>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/p17SgMZ8ytYnN7m3tddDDFHcge0yCgyZ91FfvQ1F.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>Google Ads</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>5 (53 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Wadi Balsam Information Technology</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
              <div className = 'w-width49% border border-borderColor rounded'>
                <div className = 'flex justify-between'>
                  <div className = 'flex py-5 pl-5'>
                    <div className = 'w-20'>
                      <Link to = '#'>
                        <img className = 'w-full' src = 'https://salla-dev-portal.s3.eu-central-1.amazonaws.com/uploads/6bXLPj7Gg2RlUmeJuDKf9Hn7luwB0yDvSvtjULaf.png' alt = 'brand logo' />
                      </Link>
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium ml-1'>UPS</h3>
                      <p className = 'text-sm text-secondary'><span className = 'text-[#e6cc00]'><StarPurple500OutlinedIcon style = { {fontSize: '22px'} } /></span>(50 reviews)</p>
                      <p className = 'text-sm text-secondary'><span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span> Basket</p>
                    </div>
                  </div>
                  <div className = 'py-5 pr-5'>
                    <div className = 'w-8 h-8 rounded-full bg-[#f0f3f3] text-secondary text-center pt-1'><VisibilityOffOutlinedIcon style = { {fontSize: '18px'} } /></div>
                  </div>
                </div>
                <div className = 'border-t border-borderColor'></div>
                <div className = 'flex p-5 justify-between'>
                  <div className = 'w-10 h-10 border border-borderColor text-center rounded pt-1'>
                    <span className = 'text-blacklight'><HeadphonesOutlinedIcon style = { {fontSize: '22px'} } /></span>
                  </div>
                  <div>
                    <button className = 'bg-[#f0f3f3] inline-block py-2 px-3 rounded-md text-sm'>Application options <span><ArrowDropDownOutlinedIcon style = { {fontSize: '20px'} } /></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </>
  );
};

export default BasketappStore;