import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import dummyimg from '../../images/dummyimg.png';
import { useAddbrandlistMutation, useBrandlistQuery, useUpdatestatusMutation } from '../../redux/api/brandApi';
import Brandoptions from './Brandoptions';
import CustomBrandpagination from './CustomBrandpagination';
import Editbrand from './Editbrand';

const Brands = () => {

  const servicemenuRef = useRef(null);

  const [addBrandModal, setAddBrandModal] = useState(false);
  
  const onOpenAddBrandModal = () => setAddBrandModal(true);
  const onCloseAddBrandModal = () => setAddBrandModal(false);


  const [selectedLanguage, setSelectedLanguage] = useState('Arabic');
  
  const handleSelectChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const [brandPagetitleArabicValue, setBrandPagetitleArabicValue] = useState('');
  const [brandPagetitleEnglishValue, setBrandPagetitleEnglishValue] = useState('');

  const handleBrandPagetitleArabicInputChange = (e) => {
    setBrandPagetitleArabicValue(e.target.value);
  };

  const handleBrandPagetitleEnglishInputChange = (e) => {
    setBrandPagetitleEnglishValue(e.target.value);
  };


  const [seobrandArabicValue, setSeobrandArabicValue] = useState('');
  const [seobrandEnglishValue, setSeobrandEnglishValue] = useState('');

  const handleSeobrandArabicInputChange = (e) => {
    setSeobrandArabicValue(e.target.value);
  };

  const handleSeobrandEnglishInputChange = (e) => {
    setSeobrandEnglishValue(e.target.value);
  };


  const [brandpageDescriptionArValue, setBrandpageDescriptionArValue] = useState('');
  const [brandpageDescriptionEnValue, setBrandpageDescriptionEnValue] = useState('');

  const handleBrandpageDescriptionArInputChange = (e) => {
    setBrandpageDescriptionArValue(e.target.value);
  };

  const handleBrandpageDescriptionEnInputChange = (e) => {
    setBrandpageDescriptionEnValue(e.target.value);
  };


  const [isopenEditbrand, setIsopenEditbrand] = useState(false);
  const [currentBrand, setCurrentBrand] = useState(null);

  const handleEditbrand = (item) => {
    setIsopenEditbrand(true);
    setCurrentBrand(item);
  };


  const [isOpenServicemenu, setIsOpenServicemenu] = useState(false);

  const toggleServicemenu = () => {
    setIsOpenServicemenu(!isOpenServicemenu);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (servicemenuRef.current && !servicemenuRef.current.contains(event.target)) {
        setIsOpenServicemenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const [isBrandsettingOpen, setIsBrandsettingOpen] = useState(false);

  const handleBrandSetting = () => {
    setIsBrandsettingOpen(true);
  };


  const [exportModal, setExportModal] = useState(false);
  const onOpenExportModal = () => setExportModal(true);
  const onCloseExportModal = () => setExportModal(false);


  const [importbrandModal, setImportbrandModal] = useState(false);
  const onOpenImportbrandModal = () => setImportbrandModal(true);
  const onCloseImportbrandModal = () => setImportbrandModal(false);

  const resPerPage = 20;

  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const { error, data, isLoading, refetch } = useBrandlistQuery({ size: resPerPage, page: currentPage });

  const [checkedState, setCheckedState] = useState({});
  
  useEffect(() => {
    if (data) {
      const initialStatus = data?.data.reduce((acc, item) => {
        acc[item.id] = item.is_active === true;
        return acc;
      }, {});
      setCheckedState(initialStatus);
    }
  }, [data]);



  const [updateStatus,{error: errorupdatestatus,data: dataupdatestatus, isSuccess: isSuccessupdatestatus}] = useUpdatestatusMutation();

  useEffect(() => {

    if(errorupdatestatus){
      toast(errorupdatestatus?.data?.message);
    }

  },[errorupdatestatus]);

  useEffect(() => {

    if(isSuccessupdatestatus){
      toast(dataupdatestatus?.message);
    }

  },[isSuccessupdatestatus,dataupdatestatus]);


  const handleChange = async (id, nextChecked) => {
    setCheckedState(prevState => ({
      ...prevState,
      [id]: nextChecked
    }));

    try {
      await updateStatus ({ id, active: nextChecked?'activate':'deactivate' });
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  const [name, setName] = useState('');
  const [bannerurl, setBannerurl] = useState('');
  const [logoimgurl, setlogoimgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  
  const [addbrandlist, { error: branderror, isSuccess: brandisSuccess, isLoading: brandisloading }] = useAddbrandlistMutation();


  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setBannerurl(e.target.value);
    setErrorMessge('');
  };

  const handlelogoimgurlChange = (e) => {
    setlogoimgurl(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {

    if (brandisSuccess) {
      setErrorMessge('');
      refetch();
      setName('');
      setBannerurl('');
      setlogoimgurl('');
      onCloseAddBrandModal();
    }
  },[brandisSuccess]);

  const handleSubmit = async () => {
    if (!bannerurl || !logoimgurl || !name) {
      setErrorMessge('Please provide Name, Image url and Redirect url.');
      return;
    }
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('is_active', true);
    formData.append('banner_image_url', bannerurl);
    formData.append('logo_image_url', logoimgurl);

    try {
      await addbrandlist(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add brand: ', error);
    }
  };

  
  return (
    <>
      <ToastContainer />
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Brands</span></li>
          </ul>
          <div className = 'flex justify-between mt-4 pb-10'>
            <div className = 'self-center'>
              <Link to = '#' className = 'inline-block px-5 py-2 bg-[#f0f3f3] rounded' onClick = { onOpenAddBrandModal }>+ Add a new brand</Link>
            </div>
            <div className = 'self-center relative'>
              <p className = 'text-sm text-blacklight cursor-pointer' onClick = { toggleServicemenu }><span className = 'text-secondary align-text-bottom'><BusinessCenterOutlinedIcon style = { { fontSize: '20px' } } /></span> Services</p>
              { isOpenServicemenu && (
                <div
                  className = "absolute right-0 top-8 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role = "menu"
                  aria-orientation = "vertical"
                  aria-labelledby = "menu-button"
                  tabIndex = "-1"
                  ref = { servicemenuRef }
                >
                  <div className = "py-1" role = "none">
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm cursor-pointer" onClick = { handleBrandSetting }><span className = 'text-secondary'><SettingsOutlinedIcon style = { {fontSize: '18px'} } /></span> Settings</Link>
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm"><span className = 'text-secondary'><CancelOutlinedIcon style = { {fontSize: '18px'} } /></span> Disable the feature</Link>
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { onOpenExportModal }><span className = 'text-secondary'><CloudDownloadOutlinedIcon style = { {fontSize: '18px'} } /></span> Export brands</Link>
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { onOpenImportbrandModal }><span className = 'text-secondary'><CloudUploadOutlinedIcon style = { {fontSize: '18px'} } /></span> Import brands</Link> 
                  </div>
                </div>
              ) }
            </div>
          </div>
          <div className = 'flex flex-wrap gap-3'>
            { error && <p>{ error?.message }</p> }
            { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
            { data?.data?.length === 0 && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }
            {
              data?.data?.map((item) => {
                return(
                  <div key = { item?.id } className = 'border border-borderColor rounded p-3 w-width24%'>
                    <div className = 'w-20 h-20 mx-auto mt-2'><img src = { item?.logo_image_url?item?.logo_image_url: dummyimg } /></div>
                    <h3 className = 'text-lg font-semibold text-secondary mt-2 text-center'>{ item?.name }</h3>
                    <div className = 'flex justify-between mt-6'>
                      <div className = 'self-center'>
                        <p className = 'text-sm text-secondary cursor-pointer' onClick = { () => handleEditbrand(item) }><span><SettingsOutlinedIcon style = { {fontSize: '15px'} } /></span><span> amendment</span></p>
                      </div>
                      <div className = 'self-center mt-1'>
                        <Switch
                          onChange = { (nextChecked) => handleChange(item?.id, nextChecked) }
                          checked = { checkedState[item?.id] === true }
                          className = "react-switch"
                          onColor = "#474091"
                          width = { 40 }
                          height = { 20 }
                          handleDiameter = { 16 }
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          <CustomBrandpagination
            resPerPage = { resPerPage }
            filteredProductsCount = { data?.total || 0 }
            onChange = { setCurrentPageNo }
          />
        </div>
      </div>
      <Modal open = { addBrandModal } showCloseIcon = { false } onClose = { onCloseAddBrandModal }>
        <div className = 'brandmodal'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3>Add a new brand</h3>
            <div className = 'self-center'><span onClick = { onCloseAddBrandModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = "flex-col items-center border border-borderColor rounded py-4 px-4 text-center">
              <div className = "text-start">
                <label className = "text-blacklight text-sm self-center block">
                    Name
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter brand name"
                  value = { name }
                  onChange = { handleNameChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.name }
                  </div>
                ) }

                <label className = "text-blacklight text-sm self-center block mt-3">
              Banner url
                </label>
                <input
                  placeholder = "Enter banner url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { bannerurl }
                  onChange = { handleUrlChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.banner_image_url }
                  </div>
                ) }
                <label className = "text-blacklight text-sm self-center block mt-3">
              Brand image url (size: 360 × 120px)
                </label>
                <input
                  placeholder = "Enter brand image url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { logoimgurl }
                  onChange = { handlelogoimgurlChange }
                />
                { branderror && (
                  <div className = "mt-2 text-red text-sm">
                    { branderror?.data?.message?.logo_image_url }
                  </div>
                ) }
              
                <div>
               
                
                  <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                  <button
                    className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                    onClick = { handleSubmit }
                    disabled = { brandisloading }
                  >
                    { brandisloading ? 'Saving...' : 'Save' }
                  </button>
                </div>
              </div>
            </div>
           
            <ul className = 'border border-borderColor rounded p-4 mt-5'>
              <li>
                <h3 className = 'text-sabe font-medium text-blacklight'>SEO improvements</h3>
                <label className = 'text-sm text-blacklight inline-block mt-3'>Brand Page Title</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <BurstModeOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <input
                      placeholder = "Brand Page Title"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandPagetitleArabicValue }
                      onChange = { handleBrandPagetitleArabicInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <input
                      placeholder = "Brand Page Title"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandPagetitleEnglishValue }
                      onChange = { handleBrandPagetitleEnglishInputChange }
                    />
                  ) }
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block'>Brand Page URL (SEO Page URL)</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <InsertLinkOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <input
                      placeholder = "Brand Page URL (SEO Page URL)"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { seobrandArabicValue }
                      onChange = { handleSeobrandArabicInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <input
                      placeholder = "Brand Page URL (SEO Page URL)"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { seobrandEnglishValue }
                      onChange = { handleSeobrandEnglishInputChange }
                    />
                  ) }
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block'>Brand Page Description</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <DescriptionOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <textarea
                      placeholder = "Brand Page Description"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandpageDescriptionArValue }
                      onChange = { handleBrandpageDescriptionArInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <textarea
                      placeholder = "Brand Page Description"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandpageDescriptionEnValue }
                      onChange = { handleBrandpageDescriptionEnInputChange }
                    />
                  ) }
                </div>
              </li>
            </ul>
            <div className = 'flex justify-between mt-8'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Addition</button>
              <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-black'>Close</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { exportModal } showCloseIcon = { false } onClose = { onCloseExportModal }>
        <div className = 'modalboximport p-4'>
          <div className = 'flex justify-end'>
            <div className = 'self-center'><span onClick = { onCloseExportModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'text-center'>
            <span className = 'text-green'><CheckCircleOutlineOutlinedIcon style = { {fontSize: '80px'} } /></span>
            <p className = "text-base text-blacklight">The trademark file will be sent to your email shortly</p>
          </div>
        </div>
      </Modal>

      <Modal open = { importbrandModal } showCloseIcon = { false } onClose = { onCloseImportbrandModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3>Import brands</h3>
            <div className = 'self-center'><span onClick = { onCloseImportbrandModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <h3 className = 'text-lg text-black'>Follow the steps below to import store brands</h3>
            <ul>
              <li>
                <p className = 'text-sm text-blacklight mt-3'>1 Enter brands using the basket form <span className = 'inline-block bg-primary text-white py-1 px-2 rounded'>Download the form <span><SimCardDownloadOutlinedIcon style = { {fontSize: '16px'} } /></span></span></p>
              </li>
              <li>
                <p className = 'text-sm text-blacklight mt-2'>2 Upload the file by clicking OK</p>
              </li>
              
              <li className = 'mt-5'>
                <label htmlFor = "upload-input" style = { { cursor: 'pointer' } } className = 'block bg-[#f0f3f3] rounded px-3 py-2 text-sm text-blacklight'>
                Choose a location for your trademark file
                </label>
                <input 
                  id = "upload-input"
                  type = "file"
                  style = { { display: 'none' } }
                />
                <p className = 'text-xs text-secondary mt-2'>To import brands via an out-of-cart form, we will be happy to serve you via our customer service</p>
              </li>
            </ul>
            <div className = 'mt-8 flex justify-between'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Ok</button>
              <button onClick = { onCloseImportbrandModal } className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-blacklight'>Close</button>
            </div>
          </div>
        </div>
      </Modal>

      <Editbrand isopenEditbrand = { isopenEditbrand } setIsopenEditbrand = { setIsopenEditbrand } currentBrand = { currentBrand } refetch = { refetch } />
      <Brandoptions isBrandsettingOpen = { isBrandsettingOpen } setisBrandsettingOpen = { setIsBrandsettingOpen } />
      
    </>
  );
};

export default Brands;
