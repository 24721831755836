import React from 'react';
import CustomersDoutnutChart from './CustomersDoutnutChart';
import CustomersSatisfactionchart from './CustomersSatisfactionchart';
import HighestPayingCustomers from './HighestPayingCustomers';

const Customers = () => {

  return(
    <>
      <div className = 'flex flex-wrap justify-between'>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Customers satisfaction</h3>
            <CustomersSatisfactionchart />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Customers</h3>
            <CustomersDoutnutChart />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md mt-5'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Highest paying customers</h3>
            <HighestPayingCustomers />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;