/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useGettestimonialbyidQuery, useUpdatetestimonialMutation } from '../../redux/api/testimonialApi';

const Edittestimonial = ({EditTestimonials, setEditTestimonials,currentTestimonial,refetch}) => {

  
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [position, setPosition] = useState('');
  const [imgurl, setImgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const handleNamechange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleTitlechange = (e) => {
    setTitle(e.target.value);
    setErrorMessge('');
  };

  const handleMessagechange = (e) => {
    setMessage(e.target.value);
    setErrorMessge('');
  };

  const handlePositionchange = (e) => {
    setPosition(e.target.value);
    setErrorMessge('');
  };

  const handleImagechange = (e) => {
    setImgurl(e.target.value);
    setErrorMessge('');
  };

  const [updatetestimonial, {error: errorupdatetestimonial, isSuccess: isSuccessupdatetestimonial, isLoading: isLoadingupdatetestimonial }] = useUpdatetestimonialMutation();

  useEffect(() => {
    if (isSuccessupdatetestimonial) {
      setErrorMessge('');
      setEditTestimonials(false);
      refetch();
    }
  }, [isSuccessupdatetestimonial]);

  const handleSubmit = async() => {

    const formdata = new FormData();

    formdata.append('user', name);
    formdata.append('title', title);
    formdata.append('message', message);
    formdata.append('position', position);
    formdata.append('image_url', imgurl);

    try{
      await updatetestimonial({ body: formdata, id: currentTestimonial?.id });

    } catch(error){
      setErrorMessge('Failed to update testimonial', error);
    }

  };


  const {error: errorgettestimonial, data: datagettestimonial, isLoading: isLoadingtestimonial} = useGettestimonialbyidQuery(currentTestimonial?.id,{skip: !currentTestimonial?.id});

  useEffect(() => {
    if (datagettestimonial) {
      setName(datagettestimonial?.data?.user);
      setTitle(datagettestimonial?.data?.title);
      setMessage(datagettestimonial?.data?.message);
      setPosition(datagettestimonial?.data?.position);
      setImgurl(datagettestimonial?.data?.image_url);
    }
  }, [datagettestimonial, currentTestimonial?.id]);

  const onCloseEditTestimonials = () => {
    setEditTestimonials(false);
  };


  return(
    <div className = "testimonial-modal">
      <Modal open = { EditTestimonials } showCloseIcon = { false } onClose = { onCloseEditTestimonials }>
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
                Edit Testimonial
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseEditTestimonials }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center ">
            { errorgettestimonial && <p className = 'text-sm text-red'>{ errorgettestimonial?.data?.message }</p> }
            { isLoadingtestimonial && <p className = 'text-sm'>{ isLoadingtestimonial ? 'Fragrance data loading...' : '' }</p> }
            <div className = "text-start">
             
              <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                    User
                </label>
                <input
                  placeholder = "Enter username"
                  value = { name }
                  onChange = { handleNamechange }
                  className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                />
                { errorupdatetestimonial && (
                  <div className = "mt-2 text-red text-sm">
                    { errorupdatetestimonial?.data?.message?.user }
                  </div>
                ) }
              </div>
              <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                    Title
                </label>
                <input
                  placeholder = "Enter title"
                  onChange = { handleTitlechange }
                  value = { title }
                  className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                />
                { errorupdatetestimonial && (
                  <div className = "mt-2 text-red text-sm">
                    { errorupdatetestimonial?.data?.message?.title }
                  </div>
                ) }
              </div>
              <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                Image url
                </label>
                <input
                  placeholder = "Enter image url"
                  onChange = { handleImagechange }
                  value = { imgurl }
                  className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                />
                { errorupdatetestimonial && (
                  <div className = "mt-2 text-red text-sm">
                    { errorupdatetestimonial?.data?.message?.image_url }
                  </div>
                ) }
              </div>
              <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                    Message
                </label>
                <textarea name = "postContent"
                  placeholder = "Enter meaasge"
                  value = { message }
                  onChange = { handleMessagechange }
                  rows = { 4 } cols = { 40 } className = "mt-1 inline-block text-sm w-full px-2 py-2 rounded outline-0 border border-borderColor" />
                { errorupdatetestimonial && (
                  <div className = "mt-2 text-red text-sm">
                    { errorupdatetestimonial?.data?.message?.message }
                  </div>
                ) }
              </div>
              <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                      Position
                </label>
                <input
                  placeholder = "Enter position"
                  value = { position }
                  onChange = { handlePositionchange }
                  className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor text-sm"
                />
                { errorupdatetestimonial && (
                  <div className = "mt-2 text-red text-sm">
                    { errorupdatetestimonial?.data?.message?.position }
                  </div>
                ) }
              </div>
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <div className = "mt-3">
                <button onClick = { handleSubmit } className = "text-sm inline-block text-white bg-primary px-5 py-2 rounded">{ isLoadingupdatetestimonial?'Updating': 'Update' }</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Edittestimonial;