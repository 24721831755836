import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const ShippingApi = createApi({
  reducerPath: 'ShippingApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getshipingcompanylist: builder.query({
      query: () => '/orders/admin/get-shipping-companies/'
    }),
    addshippingcompany: builder.mutation({
      query(body) {
        return {
          url: '/orders/admin/create-shipping-company/',
          method: 'POST',
          body
        };
      }
    }),
    updateshippingcompany: builder.mutation({
      query(body) {
        return {
          url: '/orders/admin/update-shipping-company/',
          method: 'PUT',
          body
        };
      }
    }),
    getshipingcompanylistbyid: builder.query({
      query: (id) => `/orders/admin/get-shipping-company/${id}/`
    }),
    countrylist: builder.query({
      query: () => '/countries/'
    }),
    citylist: builder.query({
      query: (id) => `/countries/${id}/cities/`
    }),
    addshippingprice: builder.mutation({
      query(body) {
        return {
          url: '/orders/admin/create-or-update-shipping-company-pricing/',
          method: 'POST',
          body
        };
      }
    }),
    getshippingcompanypricelist: builder.query({
      query: (id) => `/orders/admin/get-shipping-company-pricing/${id}`
    }),
    deleteShippingPrice: builder.mutation({
      query: (id) => ({
        url: `/orders/admin/delete-shipping-company-pricing/${id}/`,
        method: 'DELETE'
      })
    }),
    productlist: builder.query({
      query: ({ size, search }) => {
        let url = `/store/admin/list-products/?size=${size}`;
        if (search) {
          url += `&search=${search}`;
        }
        return url;
      }
    }),
    catloglist: builder.query({
      query: ({size, search}) => {
        let url = `/store/admin/list-categories/?size=${size}`;
        if(search){
          url += `&search=${search}`;
        }
        return url;
      }
    }),
    createfreeshipping: builder.mutation({
      query(body) {
        return {
          url: '/orders/admin/create-free-shipping-condition/',
          method: 'POST',
          body
        };
      }
    }),
    freeshippinglist: builder.query({
      query: () => '/orders/admin/get-free-shipping-conditions/'
    }),
    getfreeshippinglistbyid: builder.query({
      query: (id) => `/orders/admin/get-free-shipping-condition/${id}/`
    }),
    updatefreeshipping: builder.mutation({
      query(body) {
        return {
          url: '/orders/admin/update-free-shipping-condition/',
          method: 'PUT',
          body
        };
      }
    }),
    deletefreeshipping: builder.mutation({
      query: (id) => ({
        url: `/orders/admin/delete-free-shipping-condition/${id}/`,
        method: 'DELETE'
      })
    })
  })
});

export const {useDeletefreeshippingMutation,  useUpdatefreeshippingMutation, useGetfreeshippinglistbyidQuery, useFreeshippinglistQuery, useCreatefreeshippingMutation, useAddshippingcompanyMutation, useGetshipingcompanylistQuery, useUpdateshippingcompanyMutation, useGetshipingcompanylistbyidQuery, useCountrylistQuery, useCitylistQuery, useAddshippingpriceMutation, useGetshippingcompanypricelistQuery, useDeleteShippingPriceMutation, useProductlistQuery, useCatloglistQuery} = ShippingApi;
