
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import React, { useEffect, useRef, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menu = process.env.PUBLIC_URL + '/images/icons/menu.svg';
  const crossmenu = process.env.PUBLIC_URL + '/images/icons/crossmenu.svg';

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (
    <>
      <Toaster
        position = "top-right"
        reverseOrder = { false }
      />
      <div className = 'block lg:hidden  border-b border-boderColor-300 bg-transparent'>
        <div className = 'container mx-auto px-5 lg:px-10 py-3 flex justify-between text-black'>
          <div className = 'self-center'>
            <Link className = 'block w-20' to = '/' ><img src = 'https://cdn.salla.sa/KVlQp/YWFUbETQIUmVmzxQbje7oanjayllgiRNbpFOhKb3.png' alt = 'logo' /></Link>
          </div> 
          <div className = 'self-center block lg:hidden w-10 h-10 rounded-full bg-primary-50'>
            <img className = 'text-center mx-auto mt-3' onClick = { toggleMenu } src = { menu } alt = 'menu' />
          </div>
          
        </div>
      </div>
      <div className = { `mobile-menu ${
        isMenuOpen ? 'slide-in' : 'slide-out'
      } transition-transform duration-300 drop-shadow-lg` } ref = { menuRef }>
        <div>
          <p className = 'flex justify-end'><img src = { crossmenu } onClick = { toggleMenu } alt = 'crossmenu' /></p>
         
          <div>
            <h3 className = 'text-lg text-secondary'>DASHBOARD</h3>
            <ul className = 'p-0 m-0 list-none bg-white rounded-lg pb-6 pt-3'>
              <li>
                <Link to = "#" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><LocalMallOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Dashboard</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className = 'text-lg text-secondary uppercase'>Pages</h3>
            <ul className = 'p-0 m-0 list-none bg-white rounded-lg pb-6 pt-3'>
              <li>
                <Link to = "/dashboard" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><ShoppingCartOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Orders</Link>
              </li>
              <li>
                <Link to = "/product" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><LabelOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Products</Link>
              </li>
              <li>
                <Link to = "/customers" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><GroupOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Customers</Link>
              </li>
              <li>
                <Link to = "#" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><PermIdentityOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Profile</Link>
              </li>
              <li>
                <Link to = "/shipping" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><LocalShippingOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Shipping</Link>
              </li>
              <li>
                <Link to = "/reports" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><PieChartOutlineOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Reports</Link>
              </li>
              <li>
                <Link to = "/wallet" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><AccountBalanceWalletOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Wallet and bills</Link>
              </li>
              <li>
                <Link to = "/marketing-tool" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><CampaignOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Marketing tools</Link>
              </li>
              <li>
                <Link to = "#" className = 'text-blacklight text-lg block py-2 px-3 hover:bg-lightwhite hover:duration-300'> <spna className = "inline-block align-text-bottom mr-3"><ExitToAppOutlinedIcon  style = { { fontSize: '20px' } } /></spna>Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
