import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import { React } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const Sidebar = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');

  };

  return (
    <>
      <Toaster position = "top-right" reverseOrder = { false } />
      <div className = "hidden lg:block w-64 bg-white shadow-sidebar min-h-screen px-5 py-5 box-border">
        <Link to = "/dashboard" className = "block pb-8">
          <img
            src = "https://cdn.salla.sa/KVlQp/YWFUbETQIUmVmzxQbje7oanjayllgiRNbpFOhKb3.png"
            className = "w-3/5"
            alt = "logo"
          />
        </Link>
        <div>
          <h3 className = "text-base text-secondary">DASHBOARD</h3>
          <ul className = "p-0 m-0 list-none bg-white rounded-lg pb-6 pt-3">
            <li>
              <Link
                to = "/dashboard"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/dashboard' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <LocalMallOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Dashboard
              </Link>
            </li>
          </ul>
        </div>
        <div>
          <h3 className = "text-base text-secondary uppercase">Pages</h3>
          <ul className = "p-0 m-0 list-none bg-white rounded-lg pb-6 pt-3 sidebarscroll custom-scroll">
           
            <li>
              <Link
                to = "/banner"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/banner' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <ViewCarouselOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Banner
              </Link>
            </li>
            <li>
              <Link
                to = "/add-navigation"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/add-navigation' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <MenuOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Add Navigation
              </Link>
            </li>
            <li>
              <Link
                to = "/product"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/product' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <LabelOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Products
              </Link>
            </li>
            <li>
              <Link
                to = "/scent-family"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/scent-family' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Scent Family
              </Link>
            </li>
            <li>
              <Link
                to = "/add-category"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/add-category' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <CategoryOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Add Category
              </Link>
            </li>
            <li>
              <Link
                to = "/categories"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/categories' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <CategoryOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Home categories
              </Link>
            </li>
            <li>
              <Link
                to = "/gift-cards"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/gift-cards' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <CardGiftcardIcon style = { { fontSize: '20px' } } />
                </spna>
                Gift cards
              </Link>
            </li>
            <li>
              <Link
                to = "/sale-offers"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/sale-offers' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <LocalOfferOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Sale & offers
              </Link>
            </li>
            { /* <li>
              <Link
                to = "/fragrance-category"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/fragrance-category' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Fragrance Category
              </Link>
            </li> */ }
            
            <li>
              <Link
                to = "/order"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/order' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <ShoppingCartOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Orders
              </Link>
            </li>
            
            <li>
              <Link
                to = "/manage-reviews"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/manage-reviews' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <RateReviewOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Manage Reviews
              </Link>
            </li>
            <li>
              <Link
                to = "/customers"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/customers' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <GroupOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Customers
              </Link>
            </li>
            <li>
              <Link
                to = "#"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '#' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <PermIdentityOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Profile
              </Link>
            </li>
            <li>
              <Link
                to = "/shipping"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/shipping' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <LocalShippingOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Shipping
              </Link>
            </li>
            <li>
              <Link
                to = "/reports"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/reports' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <PieChartOutlineOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Reports
              </Link>
            </li>
            <li>
              <Link
                to = "/wallet"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/wallet' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <AccountBalanceWalletOutlinedIcon
                    style = { { fontSize: '20px' } }
                  />
                </spna>
                Wallet and bills
              </Link>
            </li>
            <li>
              <Link
                to = "/marketing-tool"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/marketing-tool' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <CampaignOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Marketing tools
              </Link>
            </li>
            <li>
              <Link
                to = "/new-arrival"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/new-arrival' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <CampaignOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                New arrivals
              </Link>
            </li>
            <li>
              <Link
                to = "/trending-now"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/trending-now' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <TrendingUpIcon style = { { fontSize: '20px' } } />
                </spna>
                Trending Now
              </Link>
            </li>

            <li>
              <Link
                to = "/testimonials"
                className = { `text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300 ${location.pathname === '/testimonials' ? 'bg-primary text-white hover:bg-primary hover:text-white' : ''}` }
              >
                { ' ' }
                <spna className = "inline-block align-text-bottom mr-3">
                  <Groups2OutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Testimonials
              </Link>
            </li>
            <li>
              <Link
                onClick = { handleLogout }
                to = "/login"
                className = "text-blacklight text-base block py-2 px-3 hover:bg-lightwhite hover:duration-300"
              >
                <spna className = "inline-block align-text-bottom mr-3">
                  <ExitToAppOutlinedIcon style = { { fontSize: '20px' } } />
                </spna>
                Logout
              </Link>  
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
