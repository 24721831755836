/* eslint-disable no-useless-escape */
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddbannerMutation, useBannerlistQuery, useDeletebannerMutation } from '../../redux/api/bannerApi';
import EditbannerModal from './EditbannerModal';
const Banner = () => {
  const [addBanner, setAddBanner] = useState(false);
  const onOpenAddBanner = () => setAddBanner(true);
  const onCloseAddBanner = () => setAddBanner(false);


  const { error, data, isLoading, refetch } = useBannerlistQuery();

  const [url, setUrl] = useState('');
  const [mobileimgurl, setMobileimgurl] = useState('');
  const [redirecturl, setRedirectUrl] = useState('');
  // const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessge] = useState('');
  
  const [addbanner, { error: bannererror, isSuccess: bannerisSuccess, isLoading: bannerisloading }] = useAddbannerMutation();


  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleMobileimgUrlChange = (e) => {
    setMobileimgurl(e.target.value);
    setErrorMessge('');
  };

  const handleRedirecturlChange = (e) => {
    setRedirectUrl(e.target.value);
    setErrorMessge('');
  };


  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  //   setErrorMessge('');
  // };

  useEffect(() => {

    if (bannerisSuccess) {
      setErrorMessge('');
      onCloseAddBanner();
      refetch();
      // setImage(null);
      setUrl('');
      setRedirectUrl('');
      setMobileimgurl('');
    }
  },[bannerisSuccess]);

  const handleSubmit = async () => {
    if (!url || !redirecturl) {
      setErrorMessge('Please provide both Image url and Redirect url.');
      return;
    }
    
    const formData = new FormData();
    formData.append('title', null);
    formData.append('image_url', url);
    formData.append('mobile_image_url', mobileimgurl);
    formData.append('banner_render_url', redirecturl);
    formData.append('image', '');

    try {
      await addbanner(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add banner: ', error);
    }
  };

 

  const [deletebanner,{ error: deletebannererror, isSuccess: deletebannerisSuccess }] = useDeletebannerMutation();

  useEffect(() => {

    if(deletebannererror){
      toast(deletebannererror?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletebannerisSuccess){
      refetch();
    }

  }, [deletebannerisSuccess]);

  const handleBannerDelete = async (id) => {
    try {
      await deletebanner(id);
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };
  const [EditBannerProduct, setEditBannerProduct] = useState(false);
  const [currentBanner, setCurrentBanner] = useState(null);

  const onOpenEditBannerProduct = (banner) => {
    setCurrentBanner(banner);
    setEditBannerProduct(true);
  };

  

  return (
    <>
      <EditbannerModal EditBannerProduct = { EditBannerProduct } currentBanner = { currentBanner } setEditBannerProduct = { setEditBannerProduct } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <button
              onClick = { onOpenAddBanner }
              className = "inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded"
            >
              <span>
                <AddOutlinedIcon style = { { fontSize: '20px' } } />
              </span>{ ' ' }
              Add Banner
            </button>

            <div className = "border border-borderColor rounded mt-1">
              <table className = "min-w-full leading-normal ">
                <thead>
                  <tr>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                    Images
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                    Mobile images
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                    Redirect URL
                    </th>

                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300  text-sm  text-gray-700 font-medium uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { error && <p>{ error?.message }</p> }
                  { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }

                  { data?.data.map((item) => (
                    <tr key = { item?.id }>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        <img
                          className = "w-16 self-center"
                          src = { item?.image_url }
                          alt = "product"
                        />
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        {
                          item?.mobile_image_url?<img
                            className = "w-16 self-center"
                            src = { item?.mobile_image_url }
                            alt = "product"
                          />: 'NA'
                        }
                        
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm">
                        <a target = '_blank'
                          href = { item?.banner_render_url }
                          className = "text-blacklight text-left hover:underline cursor-pointer" rel = "noreferrer"
                        >
                          { item?.banner_render_url === null?'NA': item?.banner_render_url }
                        </a>
                      </td>
                      <td className = "px-5 py-3 bg-white text-left">
                        <button
                          onClick = { () => onOpenEditBannerProduct(item) }
                          className = "cursor-pointer text-primary inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                        >
                          <span>
                            <EditOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </button>
                        <button onClick = { () => handleBannerDelete(item?.id) } className = "cursor-pointer text-primary inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                          <span>
                            <DeleteOutlineOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open = { addBanner }
        showCloseIcon = { false }
        onClose = { onCloseAddBanner }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add banner
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddBanner }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
              Image url (size: 2400 × 1350px)
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { url }
                onChange = { handleUrlChange }
              />
              { bannererror && (
                <div className = "mt-2 text-red text-sm">
                  { bannererror?.data?.message?.image_url }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-3">
              Mobile image url (size: 1200 × 1800px)
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { mobileimgurl }
                onChange = { handleMobileimgUrlChange }
              />
              { bannererror && (
                <div className = "mt-2 text-red text-sm">
                  { bannererror?.data?.message?.mobile_image_url }
                </div>
              ) }
              
              <label className = "text-blacklight text-sm self-center block mt-3">
              Redirect url
              </label>
              <input
                placeholder = "Enter redirect url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { redirecturl }
                onChange = { handleRedirecturlChange }
              />
              { bannererror && (
                <div className = "mt-2 text-red text-sm">
                  { bannererror?.data?.message?.banner_render_url }
                </div>
              ) }
              { /* <div className = "mt-3">
                <label className = "text-blacklight text-sm self-center block">
                Images
                </label>
                <input
                  className = "mt-1 inline-block w-full px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "file"
                  onChange = { handleImageChange }
                />
              </div> */ }
              <div>
               
                
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <button
                  className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                  onClick = { handleSubmit }
                  disabled = { bannerisloading }
                >
                  { bannerisloading ? 'Saving...' : 'Save' }
                </button>
              
              </div>
            </div>
          </div>
        </div>
      </Modal>

      
    </>
  );
};

export default Banner;
