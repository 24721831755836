import { Chart, registerables } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
Chart.register(...registerables);

const MostwantedBarchart = () => {

  const Days = ['1/3','2/3','3/3','4/3','5/3','6/3','7/3'];


  const sales = [5, 30, 20, 30, 40, 10, 15];
  
  const data = {
    labels: Days,
    datasets: [
      {
        label: 'Sales',
        data: sales,
        backgroundColor: '#474091',
        yAxisID: 'y1',
        order: 2 // Set a lower order value
      }
  
    ]
  };
  
  const options = {
    scales: {
      y1: {
        display: false,
        position: 'left'
         
      },
      y2: {
        display: false, // Hide the y2 axis
        position: 'right',
         
        grid: {
          display: false // Hide the gridlines for y2 axis
        }
      }
    }
  };

  return(
    <>
      <div style = { { height: '260px'} } >
        <Bar data = { data } options = { options } />
      </div> 
    </>
  );
};

export default MostwantedBarchart;