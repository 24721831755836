import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const scentfamilyApi = createApi({
  reducerPath: 'scentfamilyApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fragrancelist: builder.query({
      query: () => '/store/admin/list-scent-family/'
    }),
    fragrancelistfilter: builder.query({
      query: ({size, search}) => {
        let url = `/store/admin/list-scent-family/?size=${size}`;
        if(search){
          url += `&search=${search}`;
        }
        return url;
      }
    }),
    addfragrancelist: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/create-scent-family/',
          method: 'POST',
          body
        };
      }
    }),
    updatefragrancelist: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/update-scent-family/${id}/`,
          method: 'PUT',
          body
        };
      }
    }),

    deletefragrance: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/delete-scent-family/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    fragrancelistbyid: builder.query({
      query: (id) => `/store/admin/retrieve-scent-family/${id}`
    }),
    permission: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/approve-scent-family/${id}/`,
          method: 'POST',
          body
        };
      }
    })
  })
});

export const {usePermissionMutation,useFragrancelistfilterQuery, useFragrancelistbyidQuery, useFragrancelistQuery, useAddfragrancelistMutation, useDeletefragranceMutation, useUpdatefragrancelistMutation } = scentfamilyApi;
