import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const EditCustomer = () => {

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <h3 className = 'text-blacklight text-lg font-semibold uppercase'>Edit CUSTOMERS</h3>
            <div>
              <ul className = 'flex'>
                <li><Link to = '/view-customer' className = 'text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded'><span className = 'align-text-bottom' ><ArrowBackIosNewOutlinedIcon style = { {fontSize: '16px'} } /></span>Back</Link></li>
              </ul>
            </div>
          </div>
          <ul>
            <li className = 'mt-3'>
              <div className = 'flex justify-between'>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>First name</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                    <div className = 'width5%'><span className = 'text-secondary'><PersonOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <input placeholder = 'First name' className = 'inline-block text-sm outline-0 w-width95%' />
                  </div>
                </div>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>family name</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                    <div className = 'width5%'><span className = 'text-secondary'><PeopleAltOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <input placeholder = 'family name' className = 'inline-block text-sm outline-0 w-width95%' />
                  </div>
                </div>
              </div>
            </li>
            <li className = 'mt-3'>
              <div className = 'flex justify-between'>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>E-mail</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                    <div className = 'width5%'><span className = 'text-secondary'><EmailOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <input placeholder = 'E-mail' className = 'inline-block text-sm outline-0 w-width95%' />
                  </div>
                </div>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>Select country</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary'>
                      <option>Saudi Arabia</option>
                      <option>Kuwait</option>
                      <option>Qatar</option>
                      <option>Iraq</option>
                    </select>
                  </div>
                </div>
              </div>
            </li>
            <li className = 'mt-3'>
              <div className = 'flex justify-between'>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>Phone</label>
                  <div className = 'flex justify-between border border-borderColor px-2 rounded-md mt-1'>
                    <div className = 'width5%'><span className = 'text-blacklight inline-block text-sm border-r mr-2 border-borderColor w-full py-1.5'>+966</span></div>
                    <input placeholder = 'Phone' type = 'number' className = 'inline-block text-sm outline-0 w-width95% ml-2 py-1.5' />
                  </div>
                </div>
                <div className = 'w-width49%'>
                  <label className = 'text-sm text-blacklight'>Date of birth</label>
                  <div className = ' border border-borderColor px-2 py-1 rounded-md mt-1'>
                    <div><input type = 'date' className = 'inline-block text-sm text-secondary outline-0 w-full' /></div>
                  </div>
                </div>
              </div>
            </li>
            <li className = 'mt-3'>
              <label className = 'text-sm text-blacklight'>Select gender</label>
              <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded-md mt-1'>
                <select className = 'inline-block text-sm outline-0 w-full text-secondary'>
                  <option>Select gender</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </li>
          </ul>
          <div className = 'mt-6'>
            <button className = 'text-sm text-white bg-primary px-5 py-2 rounded'>Update</button>
            <Link to = "/view-customer" className = 'text-sm text-blacklight bg-[#f0f3f3] px-5 py-2 rounded ml-2'>Back</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;