/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import { useCatloglistQuery, useGettreingnowbyidQuery, useUpdatetrendingnowMutation } from '../../redux/api/TrendingnowApi';


const Edittrendingnow = ({edittrendingnow, currentTrending, setedittrendingnow,refetch}) => {

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const [selectedCategoryid, setSelectedCategoryid] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [Catloglist, setCatloglist] = useState([]);
  const [errorMessage, setErrorMessge] = useState('');
  const [description, setDescription] = useState('');
  const [imageurl, setImageurl] = useState('');
  const [updatecategory, { error: updatetrendingnowerror, isSuccess: updatetrendingnowisSuccess, isLoading: updatetrendingnowisloading }] = useUpdatetrendingnowMutation();


  const handlenameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };


  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleImageurl = (e) => {
    setImageurl(e.target.value);
    setErrorMessge('');
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {
    if (updatetrendingnowisSuccess) {
      setErrorMessge('');
      setedittrendingnow(false);
      refetch();
    }
  }, [updatetrendingnowisSuccess]);


  const handleUpdateSubmit = async () => {
    if (!name || !description || !imageurl || (!url && !selectedCategoryid)) {
      setErrorMessge('Please fill all fields');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('image', imageurl);
    formData.append('description', description);
    formData.append('url', selectedCategoryid === 'Custom URL'?url:selectedCategoryid);

    try {
      await updatecategory({ body: formData, id: currentTrending?.id });
    } catch (error) {
      setErrorMessge('Failed to update trendingnow: ', error);
    }
  };


  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: categoryisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (categoryisSuccess) {
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };
      const options = categorydata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setCatloglist([customOption, ...(options || [])]);
    }
  }, [categoryisSuccess, categorydata]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChangecategory = (selectedOption) => {
    setSelectedCategoryOption(selectedOption);
    setSelectedCategoryid(selectedOption?.label);
  };

  const { error: gettrendingnowError, data: gettrendingnowdata, isLoading: gettrendingnowisloading } = useGettreingnowbyidQuery(currentTrending?.id, {skip: !currentTrending?.id});
  useEffect(() => {
    if (gettrendingnowdata) {
      setName(gettrendingnowdata?.data?.name);
      setImageurl(gettrendingnowdata?.data?.image);
      setDescription(gettrendingnowdata?.data?.description);
      setUrl(gettrendingnowdata?.data?.url);

      const matchedOption = Catloglist.find(option => option.label === gettrendingnowdata?.data.url);
      if (matchedOption) {
        setSelectedCategoryOption(matchedOption);
        setSelectedCategoryid(matchedOption?.label);
      }else{
        const customOption = {
          label: 'Custom URL',
          value: 'customurl'
        };

        setSelectedCategoryOption(customOption);
        setSelectedCategoryid(customOption?.label);
      }
    }
  }, [gettrendingnowdata, currentTrending?.id, Catloglist]);
  
  return (
    <>


      <Modal
        open = { edittrendingnow }
        showCloseIcon = { false }
        onClose = { () => setedittrendingnow(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Edit Fragrance Category
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setedittrendingnow(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { gettrendingnowError && <p className = 'text-sm text-red'>{ gettrendingnowError?.data?.message }</p> }
            { gettrendingnowisloading && <p className = 'text-sm'>{ gettrendingnowisloading ? 'Fragrance data loading...' : '' }</p> }
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
              Name
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter trendingnow name"
                value = { name }
                onChange = { handlenameChange }
              />
              { updatetrendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatetrendingnowerror?.data?.message?.name }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-2">
                Image(url)
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter image url"
                value = { imageurl }
                onChange = { handleImageurl }
              />
              
              { updatetrendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatetrendingnowerror?.data?.message?.image }
                </div>
              ) }


              <label className = "text-blacklight text-sm self-center block mt-2">
              Description
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter description"
                value = { description }
                onChange = { handleDescription }
              />
              
              { updatetrendingnowerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatetrendingnowerror?.data?.message?.description }
                </div>
              ) }

              <div>
                <Select
                  className = 'inline-block text-sm mt-3 outline-0 w-full text-secondary'
                  value = { selectedCategoryOption }
                  onChange = { handleChangecategory }
                  options = { Catloglist }
                  inputValue = { inputValue }
                  onInputChange = { handleInputChange }
                  placeholder = 'Choose category'
                />
              </div>
              {
                selectedCategoryid === 'Custom URL' && <input
                  className = "mt-3 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter url"
                  value = { url }
                  onChange = { handleUrlChange }
                />
              }
              
              
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatetrendingnowisloading }
              >
                { updatetrendingnowisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Edittrendingnow;
