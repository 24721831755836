import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';

const getToken = () => localStorage.getItem('token');


const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};


export const discountcouponApi = createApi({
  reducerPath: 'discountcouponApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    couponlist: builder.query({
      query: ({ size, page }) => `/coupons/admin/list/?size=${size}&page=${page}`
    }),
    createCoupon:builder.mutation({
      query(body){
        return {
          url: '/coupons/admin/create/',
          method: 'POST',
          body
        };
      }
    }),
    updateCoupondata: builder.mutation({
      query({ id, body }) {
        return {
          url: `/coupons/admin/update/${id}/`,
          method: 'PUT',
          body
        };
      }
    }),
    catloglist: builder.query({
      query: () => '/store/admin/list-categories/'
    }),
    productlist: builder.query({
      query: () => '/store/admin/list-products/'
    }),
    paymentlist: builder.query({
      query: () => '/coupons/admin/list-payment-method/'
    }),
    brandlist: builder.query({
      query: () => '/store/admin/list-brands/'
    }),
    coupondetails: builder.query({
      query: (id) => `/coupons/admin/retrieve/${id}`
    })
  })
});


export const { 
  useCouponlistQuery,
  useCreateCouponMutation,
  useCatloglistQuery,
  useProductlistQuery,
  usePaymentlistQuery,
  useBrandlistQuery,
  useUpdateCoupondataMutation,
  useCoupondetailsQuery

} = discountcouponApi;