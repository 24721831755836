/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Switch from 'react-switch';

const Brandoptions = ({isBrandsettingOpen,setisBrandsettingOpen}) => {

  const onCloseBrandsettingModal = () => {
    setisBrandsettingOpen(false);
  };

  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const [selectedLanguage, setSelectedLanguage] = useState('Arabic');
  
  const handleSelectChange = (e) => {
    setSelectedLanguage(e.target.value);
  };


  const [brandnameArabicValue, setBrandnameArabicValue] = useState('');
  const [brandnameEnglishValue, setBrandnameeEnglishValue] = useState('');

  const handleArabicInputChange = (e) => {
    setBrandnameArabicValue(e.target.value);
  };

  const handleEnglishInputChange = (e) => {
    setBrandnameeEnglishValue(e.target.value);
  };
  
  return (
    <>
      <Modal open = { isBrandsettingOpen } showCloseIcon = { false } onClose = { onCloseBrandsettingModal }>
        <div className = 'customermodal'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3>Brand options</h3>
            <div className = 'self-center'><span onClick = { onCloseBrandsettingModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <ul>
              <li>
                <div className = 'flex'>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      id = "bannercheckbox"
                      handleDiameter = { 16 }
                    />
                  </div>
                  <label htmlFor = 'bannercheckbox' className = 'text-sm text-secondary inline-block ml-3'>Display the prominent banner at the top of the brands details page</label>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex'>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      id = "brandlink"
                      handleDiameter = { 16 }
                    />
                  </div>
                  <label htmlFor = 'brandlink' className = 'text-sm text-secondary inline-block ml-3'>Add the brands link to the site list</label>
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-blacklight text-sm'>Arrange the brands item in the site menu</label>
                <input type = 'text' className = 'inline-block w-full border border-borderColor rounded py-1.5 px-2 outline-none mt-2' />
              </li>
              <li className = 'mt-3'>
                <label className = 'text-blacklight text-sm'>Name of the brands page in the site menu</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5'>
                  <select
                    className = 'inline-block text-sm outline-0 w-width10% border-r border-borderColor'
                    value = { selectedLanguage }
                    onChange = { handleSelectChange }
                  >
                    <option value = "Arabic">Arabic</option>
                    <option value = "English">English</option>
                  </select>
                  <div className = 'w-width3% ml-1'>
                    <span className = 'text-secondary'>
                      <WorkspacePremiumOutlinedIcon style = { { fontSize: '18px' } } />
                    </span>
                  </div>
                  { selectedLanguage === 'Arabic' && (
                    <input
                      placeholder = "Brands"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandnameArabicValue }
                      onChange = { handleArabicInputChange }
                    />
                  ) }
                  { selectedLanguage === 'English' && (
                    <input
                      placeholder = "Brands"
                      className = "inline-block text-sm outline-0 w-width85%"
                      value = { brandnameEnglishValue }
                      onChange = { handleEnglishInputChange }
                    />
                  ) }
                </div>
              </li>
            </ul>
            <div className = 'flex justify-between mt-8'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
              <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-blacklight'>Close</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Brandoptions;
