import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const MarketingSettings = () => {

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  
  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li className = 'mr-2'><Link to = "/marketing-tool" className = 'text-blacklight align-middle'> Marketing tools</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Marketing settings</span></li>
          </ul>
          
          <div className = 'border border-borderColor p-5 rounded-md mt-5'>
            <h3 className = 'text-blacklight text-base font-medium'><span><DiscountOutlinedIcon style = { {fontSize: '20px'} } /></span> Discount coupons</h3>
            <ul>
              <li className = 'mt-6'>
                <div className = 'flex justify-between'>
                  <p className = 'text-base text-blacklight'>Show the discounts tab</p>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between'>
                  <p className = 'text-base text-blacklight'>Activate discount coupons</p>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
              <li className = 'mt-6'>
                <div className = 'flex justify-between'>
                  <p className = 'text-base text-blacklight'>Activate the coupon application in the cart</p>
                  <div>
                    <Switch
                      onChange = { handleChange }
                      checked = { checked }
                      className = "react-switch"
                      onColor = "#474091"
                      width = { 40 }
                      height = { 20 }
                      handleDiameter = { 16 }
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingSettings;
