import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ProductsbulkPricing = () => {

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <div className = 'self-center'>
              <ul className = 'flex text-sm'>
                <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li><span className = 'text-secondary'>Price adjustment</span></li>
              </ul>
            </div>
            <p className = 'self-center text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
          </div>
          <div className = 'p-4 border border-borderColor rounded mt-3'>
            <h3 className = 'text-base font-medium text-blacklight'>Price adjustment options</h3>
            <p className = 'text-sm text-secondary'>You can select an option to modify product prices and options</p>
            <div className = 'flex mt-5'>
              <div>
                <select className = 'border border-borderColor px-2 py-1.5 rounded text-sm outline-none text-blacklight'>
                  <option>Apply to</option>
                  <option>Product options only</option>
                  <option>Product options only</option>
                  <option>Product options only</option>
                </select>
              </div>
              <div className = 'border border-borderColor px-2 py-1.5 rounded text-sm outline-none ml-4'>
                <label className = 'inline-block mr-2 text-blacklight'>SAR</label>
                <input placeholder = 'Enter the value' className = 'outline-none text-sm' />
              </div>
              <div className = 'ml-4'>
                <select className = 'border border-borderColor px-2 py-1.5 rounded text-sm outline-none text-blacklight'>
                  <option>Fixed amount to the cost price</option>
                  <option>Fixed amount to the cost price</option>
                  <option>Fixed amount to the cost price</option>
                  <option>Fixed amount to the cost price</option>
                </select>
              </div>
              <div className = 'ml-4'>
                <select className = 'border border-borderColor px-2 py-1.5 rounded text-sm outline-none text-blacklight'>
                  <option>Adjust the discount price</option>
                  <option>Cost price adjustment</option>
                  <option>Adjust the selling price</option>
                </select>
              </div>
            </div>
          </div>
          <div className = 'border border-borderColor rounded mt-5'>
            <div className = 'flex justify-between px-5'>
              <h3 className = 'self-center text-base font-medium text-blacklight py-3'>Price adjustment</h3>
              <div className = 'self-center'><button className = 'bg-[#f0f3f3] px-4 py-1.5 text-sm rounded'>Save</button></div>
            </div>
            <div className = 'flex border-t border-b border-borderColor py-2'>
              <span className = 'inline-block w-8 text-secondary pl-3'><SearchOutlinedIcon style = { {fontSize: '20px'} } /></span>
              <input placeholder = 'Search by product name or SKU' className = 'flex-1 ml-2 text-sm outline-none' />
            </div>
            <div className = 'modifyproduct custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Product name</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product image</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Cost price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Discount price</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Guerlain Moon Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-12 h-12 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>23</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>52.67</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Guerlain Moon Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-12 h-12 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>23</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>52.67</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Guerlain Moon Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-12 h-12 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>23</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>52.67</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Guerlain Moon Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-12 h-12 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>23</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>52.67</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Guerlain Moon Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-12 h-12 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>O1 1-2-4</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>23</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>0</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>52.67</p></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsbulkPricing;
