import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const Productsbulkeditor = () => {

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <div>
              <ul className = 'flex text-sm'>
                <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
                <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
                <li><span className = 'text-secondary'>Modify products</span></li>
              </ul>
            </div>
            <p className = 'text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
          </div>
          <div className = 'border border-borderColor rounded mt-5 px-4'>
            <div className = 'flex justify-between'>
              <div className = 'self-center'><button className = 'inline-block px-5 py-2 bg-[#f0f3f3] rounded'>Save modifications</button></div>
              <h3 className = 'self-center text-base font-medium text-blacklight py-3 pl-5'>Modify products</h3>
            </div>
            <div className = 'flex border-t border-b border-borderColor py-2'>
              <span className = 'inline-block w-8 text-secondary pl-3'><SearchOutlinedIcon style = { {fontSize: '20px'} } /></span>
              <input placeholder = 'Search by product name or SKU' className = 'flex-1 ml-2 text-sm outline-none' />
            </div>
            <div className = 'modifyproduct custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Product name</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product image</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>MPN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>GTIN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Cost price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Discount price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product image</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>MPN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>GTIN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Cost price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Discount price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product image</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>MNP</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>GTIN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Cost price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Discount price</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Product image</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>SKU</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>MPN</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>GTIN</p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'border-b border-borderColor'>
                  <div className = 'w-80 p-2'><p className = 'text-blacklight text-base'>Azzaro Chrome Extreme Eau de Parfum</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'>
                    <div className = 'w-16 h-16 border border-borderColor'>
                      <img className = 'w-full h-full' src = 'https://cdn.salla.sa/KVlQp/dTeyQlPOE0Tf0n23XqzMeTkHO1epHj1kPQubwk5V.jpg' alt = 'product' />
                    </div>
                  </div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>16808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input type = 'date' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>3351500016808</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'><input placeholder = 'MPN' value = '0.1' /></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>Kg</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'>204</p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
                <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                  <div className = 'w-44'><p className = 'text-blacklight text-base'></p></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productsbulkeditor;
