/* eslint-disable react/no-unescaped-entities */

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddshippingcompanyMutation, useGetshipingcompanylistQuery, useUpdateshippingcompanyMutation } from '../../redux/api/ShippingApi';
import Editshippingcompany from './Editshippingcompany';
import FreeShippingSettings from './FreeShippingSettings';
import PaymentRecieving from './PaymentRecieving';
import ShippingCompanyOptions from './ShippingCompanyOptions';
import ShippingModalView from './ShippingModalView';
import ShippingRateCalculator from './ShippingRateCalculator';

const Shiping = () => {


  const [editshipcompany, setEditshipcompany] = useState(false);
  const [editshipcompanyid, setEditshipcompanyid] = useState(null);

  const handleeditshipcomapny = (item) => {
    setEditshipcompany(true);
    setEditshipcompanyid(item);
  };

  const [isaramexOpen, setIsaramexOpen] = useState(false);
  const [errorMessage, setErrorMessge] = useState('');

  const handleSmsaModal = (item) => {
    setIsaramexOpen(true);
    setEditshipcompanyid(item);
  };


  const [isfreeshipingSettingOpen, setIsfreeshipingSettingOpen] = useState(false);

  const handleFreeshippingSettingModal = () => {
    setIsfreeshipingSettingOpen(true);
  };


  const [paymentRecieving, setPaymentRecieving] = useState(false);

  const handlepaymentRecieving = () => {
    setPaymentRecieving(true);
  };

  const [shippingCompany, setShippingCompany] = useState(false);

  const handleShippingCompany = () => {
    setShippingCompany(true);
  };

  const [shippingRate, setShippingRate] = useState(false);

  const handleShippingRate = () => {
    setShippingRate(true);
  };


  const [AddShippingcompany, setAddShippingcompany] = useState(false);
  const onOpenAddShippingcompany = () => setAddShippingcompany(true);
  const onCloseAddShippingcompany = () => setAddShippingcompany(false);

  const [name, setName] = useState();
  const [imgurl, setImgurl] = useState();
  

  const {error:compnaylistdataerror, data: companylistdata, isloading: companydataisloading, refetch} = useGetshipingcompanylistQuery();

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleimgurl = (e) => {
    setImgurl(e.target.value);
  };

  const [addshippingcompany, {error, data, isloading, isSuccess}] = useAddshippingcompanyMutation();


  useEffect(() => {

    if(isSuccess){
      setName('');
      setImgurl('');
      refetch();
      onCloseAddShippingcompany();
    }

  },[data, isSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
   
    const addshipingdata = {
      name: name,
      image: imgurl
    };

    addshippingcompany(addshipingdata);

  };


  const isFormValid = name && imgurl;


  const [updateshippingcompany,{error: errorpermission, data: datapermission, isSuccess: isSuccesspermission }] = useUpdateshippingcompanyMutation();

  useEffect(() => {

    if(errorpermission){
      toast(errorpermission?.data?.message || 'An error occurred');
    }

  }, [errorpermission]);

  useEffect(() => {

    if(isSuccesspermission){
      refetch();
    }
    
  },[isSuccesspermission,datapermission]);
  
  const handlePermission = async (item) => {

    const formdata = new FormData();

    formdata.append('id', item?.id);
    formdata.append('is_active', item?.is_active === false?'true': 'false');

    try{
      await updateshippingcompany(formdata);

    } catch(error){
      setErrorMessge('Failed to update status', error);
    }

  };


  return (
    <>
      <ToastContainer />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <ShippingModalView editshipcompanyid = { editshipcompanyid } isaramexOpen = { isaramexOpen } setIsaramexOpen = { setIsaramexOpen } />
          <FreeShippingSettings isfreeshipingSettingOpen = { isfreeshipingSettingOpen } setIsfreeshipingSettingOpen = { setIsfreeshipingSettingOpen } />
          <PaymentRecieving paymentRecieving = { paymentRecieving } setPaymentRecieving = { setPaymentRecieving } />
          <ShippingCompanyOptions shippingCompany = { shippingCompany } setShippingCompany = { setShippingCompany } />
          <ShippingRateCalculator shippingRate = { shippingRate } setShippingRate = { setShippingRate } />
          <Editshippingcompany editshipcompany = { editshipcompany } setEditshipcompany = { setEditshipcompany } editshipcompanyid = { editshipcompanyid } refetch = { refetch } />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li className = 'mr-2'><Link to = "#" className = 'text-blacklight'>Store settings</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Shipping and delivery options</span></li>
          </ul>
          <div className = 'px-5 pt-6 pb-12 border border-borderColor rounded mt-3'>
            <h3 className = 'text-lg text-blacklight font-semibold'>Save more with basket policies</h3>
            <p className = 'text-base text-blacklight mt-1'>Enjoy a reduced shipping cost the more policies issued monthly from your store. <Link to = "#" className = 'underline'>Learn more</Link></p>

            <div className = 'w-4/5 mx-auto pt-6'>
              <div className = 'policystick mx-auto h-2 bg-[#f0f3f3]'></div>
              <div className = 'flex justify-between'>
                <div className = 'text-center'>
                  <span className = 'text-secondary inline-block w-8 h-8 text-center bg-white border border-borderColor rounded-full relative z-10'><HowToRegOutlinedIcon style = { {fontSize: '25px'} } /></span>
                  <h3 className = 'text-blacklight text-base'>SAR 18</h3>
                  <Link to = "#" className = 'inline-block text-sm text-blacklight bg-[#dfe4e4] px-3 py-1 rounded mt-2'>+3000 policies</Link>
                </div>
                <div className = 'text-center'>
                  <span className = 'text-secondary inline-block w-8 h-8 text-center bg-white border border-borderColor rounded-full relative z-10'><HowToRegOutlinedIcon style = { {fontSize: '25px'} } /></span>
                  <h3 className = 'text-blacklight text-base'>20 SAR</h3>
                  <Link to = "#" className = 'inline-block text-sm text-blacklight bg-[#dfe4e4] px-3 py-1 rounded mt-2'>Up to 3000 policies</Link>
                </div>
                <div className = 'text-center'>
                  <span className = 'text-secondary inline-block w-8 h-8 text-center bg-white border border-borderColor rounded-full relative z-10'><HowToRegOutlinedIcon style = { {fontSize: '25px'} } /></span>
                  <h3 className = 'text-blacklight text-base'>24 SAR</h3>
                  <Link to = "#" className = 'inline-block text-sm text-blacklight bg-[#dfe4e4] px-3 py-1 rounded mt-2'>Less than 1000 policies</Link>
                </div>
              </div>
            </div>
          </div>
          <div className = 'mt-8'>
            <button onClick = { onOpenAddShippingcompany } className = 'inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded'>Add shipping company</button>
          </div>
          <div className = 'p-5 border border-borderColor rounded'>
            <ul>
              <li>
                <div className = 'flex justify-between'>
                  <div className = 'flex'>
                    <div className = 'w-12'>
                      <img className = 'w-full' src = 'https://cdn.salla.sa/dRWP/3qyWTSA1lAivrucjsr9Tl5XiiFzhGIFCUmmj2cV9.png' />
                    </div>
                    <div className = 'ml-3'>
                      <h3 className = 'text-base text-blacklight font-medium'>Basket policies</h3>
                      <p className = 'text-sm text-secondary'>Facilitating the shipping process with the lowest fees</p>
                    </div>
                  </div>
                  <div>
                    <Link to = '/shipment-history' className = 'block border border-borderColor p-2 rounded'>
                      <span className = 'text-sm text-blacklight'><ArchiveOutlinedIcon style = { {fontSize: '20px'} } /></span> <span className = 'align-middle text-sm text-blacklight'>Policy archive</span>
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                {
                  compnaylistdataerror && <p className = 'text-sm text-red'>{ compnaylistdataerror?.data?.message }</p>
                }
                {
                  companydataisloading && <p className = 'text-sm text-blacklight'>{ companydataisloading?'Shipping company data loading...': '' }</p>
                }
                {
                  companylistdata?.data?.length === 0 && <p className = 'text-sm'>No shipping company data found</p>
                }
                <p className = 'text-sm text-red'>{ errorMessage }</p>
               
              </li>
              {
                companylistdata?.data?.length > 0 && companylistdata?.data?.map((item) => {
                  return(
                    <li key = { item?.id } className = 'mt-8 border-b border-borderColor pb-4'>
                      <div className = 'flex justify-between'>
                        <div className = 'flex w-11/12 cursor-pointer' onClick = { () => handleSmsaModal(item?.id) }>
                          <div className = 'w-12 self-center'>
                            <img className = 'w-full' src = { item?.image } />
                          </div>
                          <div className = 'ml-3 self-center'>
                            <h3 className = 'text-base text-blacklight font-medium'>{ item?.name }</h3>
                          </div>
                        </div>
                        <div className = 'flex'>
                          <div className = 'cursor-pointer self-center text-blacklight border border-boderColor-300 w-8 h-8 p-1 rounded' onClick = { () => handleeditshipcomapny(item?.id) }>
                            <EditOutlinedIcon style = { {fontSize: '20px'} } />
                          </div>
                          <div className = 'self-center'>
                            <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
                          </div>
                          <div className = 'self-center mt-2 ml-2'>
                            <Switch
                              onChange = { () => handlePermission(item) }
                              checked = { item?.is_active || false }
                              className = 'react-switch'
                              onColor = '#474091'
                              width = { 40 }
                              height = { 20 }
                              handleDiameter = { 16 }
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              }
              
              
            </ul>
          </div>
          <Link to = "/shipping/ticket" className = 'block p-5 border border-borderColor rounded mt-8 cursor-pointer'>
            <div className = 'flex justify-between'>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><ForumOutlinedIcon style = { {fontSize: '28px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Quality Gate</h3>
                  <p className = 'text-sm text-secondary'>Contact shipping companies in Basket policies to raise shipping complaints and follow them up.</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </Link>
          <div className = 'p-5 border border-borderColor rounded mt-8'>
            <div className = 'flex justify-between'>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '28px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Representatives and private shipping companies</h3>
                  <p className = 'text-sm text-secondary'>Add a delivery representative or private shipping company to the shipping options for your customers</p>
                </div>
              </div>
              <div className = 'self-center'>
                <Link to = '#' className = 'text-blacklight text-sm'>Show the delegates</Link>
              </div>
            </div>
          </div>
          <div className = 'p-5 border border-borderColor rounded mt-8'>
            <div className = 'pb-8'><span className = 'text-secondary'><SettingsOutlinedIcon style = { {fontSize: '24px'} } /></span> <span className = 'text-base text-blacklight font-medium'>Shipping company settings</span></div>
            <div className = 'flex justify-between cursor-pointer' onClick = { handleFreeshippingSettingModal }>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '24px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Free shipping</h3>
                  <p className = 'text-sm text-secondary'>Control the settings and terms of free shipping for your store customers</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </div>
          <div className = 'p-5 border border-borderColor rounded mt-4'>
            <div className = 'flex justify-between cursor-pointer' onClick = { handlepaymentRecieving }>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><PaymentsOutlinedIcon style = { {fontSize: '24px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Paiement when recieving</h3>
                  <p className = 'text-sm text-secondary'>Specify the terms of payment upon delivery and the products or categories excluded from the service</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </div>
          <div className = 'p-5 border border-borderColor rounded mt-4'>
            <div className = 'flex justify-between cursor-pointer' onClick = { handleShippingCompany }>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '24px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Shipping company options</h3>
                  <p className = 'text-sm text-secondary'>Give shipping companies permission to update order status, synchronize quantities, and other permissions</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </div>
          <div className = 'p-5 border border-borderColor rounded mt-4'>
            <div className = 'flex justify-between cursor-pointer'>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><EventNoteOutlinedIcon style = { {fontSize: '24px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Shipping company restrictions</h3>
                  <p className = 'text-sm text-secondary'>Add specific restrictions for the shipping company supported in your store</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </div>
          <div className = 'p-5 border border-borderColor rounded mt-4'>
            <div className = 'flex justify-between cursor-pointer' onClick = { handleShippingRate }>
              <div className = 'flex'>
                <div className = 'mr-2 self-center'><span className = 'text-secondary'><CalculateOutlinedIcon style = { {fontSize: '24px'} } /></span></div>
                <div className = 'self-center'>
                  <h3 className = 'text-base text-blacklight font-medium'>Shipping rates calculator</h3>
                  <p className = 'text-sm text-secondary'>Specify the weight and destination of the shipment to calculate the approximate shipping cost</p>
                </div>
              </div>
              <div className = 'self-center'>
                <span className = 'text-secondary'><ArrowBackIosOutlinedIcon style = { {fontSize: '20px'} } /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open = { AddShippingcompany }
        showCloseIcon = { false }
        onClose = { onCloseAddShippingcompany }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add Shiping Company
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddShippingcompany }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <form onSubmit = { handleSubmit }>
              <div className = "text-start">
                {
                  error && <p className = 'text-sm text-red'>{ error?.data?.message }</p>
                }
                <label className = "text-blacklight text-sm self-center block">
                Name
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter company name"
                  value = { name }
                  onChange = { handleName }
                />
                <label className = "text-blacklight text-sm self-center block mt-3">
              Image url
                </label>
                <input
                  placeholder = "Enter image url"
                  className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                  value = { imgurl }
                  onChange = { handleimgurl }
                />
                <div>
                  <button 
                    disabled = { !isFormValid }
                    type = 'submit'
                    className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded">
                    { isloading?'Saving...': 'Save' }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Shiping;
