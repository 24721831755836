/* eslint-disable react/prop-types */
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useCatloglistQuery, useCitylistQuery, useCountrylistQuery, useCreatefreeshippingMutation, useDeletefreeshippingMutation, useFreeshippinglistQuery, useGetshipingcompanylistQuery, useProductlistQuery } from '../../redux/api/ShippingApi';
import Citylist from './Citylist';
import Companyshipinglist from './Companyshipinglist';
import Editfreeshipping from './Editfreeshipping';
import ShippingProductlist from './ShippingProductlist';
import Shippingcategorylist from './Shippingcategorylist';

const FreeShippingSettings = ({isfreeshipingSettingOpen, setIsfreeshipingSettingOpen}) => {

  const [freeshipingSetting, setFreeshipingSetting] = useState(false);
  const [addcondition, setAddcondition] = useState(false);
  const [selectedCity, setSelectedcity] = useState(null);
  const [supportedcity, setSupportedcity] = useState([]);
  const [countryid, setCountryid] = useState(null);
  const [citylistdata, setCitylistdata] = useState([]);
  const [getshippinglistdata, setGetshippinglistdata] = useState([]);
  const [selectedshippingcompany, setSelectedshippingcompany] = useState(null);
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState('');
  const [maximumShipmentWeight, setMaximumShipmentWeight] = useState('');
  const [supportedShippingCompanies, setSupportedShippingCompanies] = useState([]);
  const [supportedCategories, setSupportedCategories] = useState([]);
  const [supportedProduct, setSupportedProduct] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [freeShippingid, setFreeShippingid] = useState(null);

  useEffect(() => {

    if(isfreeshipingSettingOpen == true){
      setFreeshipingSetting(true);
    }else{
      setFreeshipingSetting(false);
    }
    
  },[isfreeshipingSettingOpen]);

  const closeFreeshipingSetting = () => {
    setIsfreeshipingSettingOpen(false);
    setFreeshipingSetting(false);
    setAddcondition(false);
  };

  const handleAddcondition = () => {
    setAddcondition(true);
  };

  const [supportedproductval, setSupportedproductval] = useState(1);

  const handlesupportedproduct = (e) => {
    setSupportedproductval(e.target.value);
  };


  const {error: errorcoutry, data: datacountry} = useCountrylistQuery();

  const handlecountry = (e) => {
    setCountryid(e.target.value);
  };

  const {error: errorcity, data: citydata, isSuccess: isSuccesscity} = useCitylistQuery(countryid,{skip: !countryid});

  const handlecity = (selecteditem) => {
    setSelectedcity(selecteditem);
    setSupportedcity(selecteditem.map(item => item.value));
  };

  useEffect(() => {
    
    if(isSuccesscity){

      const citydataoption = citydata?.data.map((c) => (
        {
          label: c.name,
          value: c.id
        }
      ));

      setCitylistdata(citydataoption || []);
      setSelectedcity(null);

    }
  },[isSuccesscity, citydata]);

  const {error: errorgetshippinglist, data: datagetshippinglist, isSuccess: isSuccessgetshippinglist} = useGetshipingcompanylistQuery();

  useEffect(() => {

    if(isSuccessgetshippinglist){

      const shippinglistdata = datagetshippinglist?.data?.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setGetshippinglistdata(shippinglistdata);
      setSelectedshippingcompany(null);
    }

  },[datagetshippinglist, isSuccessgetshippinglist]);

  const handleshippingcompanylist = (selecteditems) => {
    setSelectedshippingcompany(selecteditems);
    setSupportedShippingCompanies(selecteditems.map(item => item.value));
  };

  const [productinputValue, setProductinputValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productdata, setProductdata] = useState([]);

  const searchParams = productinputValue ? { size: 30, search: productinputValue } : { size: 30 };

  const {error: errorproductlist, data: dataproductlist, isSuccess: isSuccessproductlist} = useProductlistQuery(searchParams);

  useEffect(() => {

    if(isSuccessproductlist){
      const productoptiondata = dataproductlist?.data?.map((c) => ({
        label: c.name,
        value: c.id
      }));

      setProductdata(productoptiondata || []);
    }

  },[dataproductlist, isSuccessproductlist]);

  const handleproductdata = (selecteditem) => {
    setSelectedProduct(selecteditem);
    setSupportedProduct(selecteditem.map(item => item.value));
  };

  const handleproductinputval = (newval) => {
    setProductinputValue(newval);
  };



  const [catloginputValue, setCatloginputValue] = useState('');
  const [selectedCatlog, setSelectedCatlog] = useState([]);
  const [catlogdata, setCatlogdata] = useState([]);

  const searchParamscatlog = productinputValue ? { size: 30, search: productinputValue } : { size: 30 };

  const {error: errorcatlog, data: datacatlog, isSuccess: isSuccesscatlog} = useCatloglistQuery(searchParamscatlog);

  useEffect(() => {

    if(isSuccesscatlog){
      const productoptiondata = datacatlog?.data?.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setCatlogdata(productoptiondata || []);
    }

  },[isSuccesscatlog, datacatlog]);

  const handlecatlogtdata = (selecteditem) => {
    setSelectedCatlog(selecteditem);
    setSupportedCategories(selecteditem.map(item => item.value));
  };

  const handlecatloginputval = (newval) => {
    setCatloginputValue(newval);
  };


  const [createfreeshipping, {error: errorcreateshipping, isSuccess: isSuccesscreateshipping}] = useCreatefreeshippingMutation();

  const handleMinimumPurchaseChange = (e) => {
    setMinimumPurchaseAmount(e.target.value);
  };

  const handleMaximumShipmentWeightChange = (e) => {
    setMaximumShipmentWeight(e.target.value);
  };

  useEffect(() => {
    if (isSuccesscreateshipping) {
      setAddcondition(false);
      refetch();
      setCountryid(null);
      setMinimumPurchaseAmount('');
      setSelectedshippingcompany([]);
      setMaximumShipmentWeight('');
      setSelectedCatlog([]);
      setSelectedProduct([]);
      setSelectedcity([]);

    }
  },[isSuccesscreateshipping]);


  const handleCreateFreeShipping = async () => {
    const requestData = {
      country_id: countryid,
      minimum_purchase_amount: parseFloat(minimumPurchaseAmount),
      maximum_shipment_weight: parseFloat(maximumShipmentWeight),
      supported_products:supportedProduct?supportedProduct: [],
      supported_shipping_companies: supportedShippingCompanies,
      supported_categories: supportedCategories?supportedCategories: [],
      most_purchasing_customer: true,
      supported_cities: supportedcity?supportedcity: []
    };

    try {
      await createfreeshipping(requestData);
      
    } catch (error) {
      console.error('Error creating free shipping:', error);
    }
  };

  const {error: errorfreeshippingdata, data: datafreeshipping, refetch} = useFreeshippinglistQuery();

  const onTitleClick = (id) => {
    setActiveIndex(id === activeIndex ? null : id);
    setFreeShippingid(id);
  };

  const [deletefreeshipping, { error: errordeletepricingdata, isSuccess: deleleisSuccess }] = useDeletefreeshippingMutation();

  const handledelete = (id) => {
    deletefreeshipping(id);
  };


  useEffect(() => {

    if(deleleisSuccess){
      refetch();
    }

  },[deleleisSuccess]);


  return(
    <>
      <Modal open = { freeshipingSetting } showCloseIcon = { false } onClose = { closeFreeshipingSetting }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Free shipping settings</h3>
            <div className = 'self-center'><span onClick = { closeFreeshipingSetting } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3 text-center'>
            {
              errordeletepricingdata?.data?.message && <p>{ errordeletepricingdata?.data?.message }</p>
            }
            <div className = 'p-4 bg-[#fff7ec] rounded border border-borderColor mt-5'>
              <ul className = 'list-disc pl-5'>
                <li className = 'text-[#aa6100]'>
                  <p className = 'text-sm text-left'>If you select a specific country or specific cities, free shipping will appear after the customer logs in and chooses the shipping address.</p>
                </li>
                <li className = 'text-[#aa6100]'>
                  <p className = 'text-sm text-left'>If you select specific shipping companies, free shipping will appear when you select the specific shipping company.</p>
                </li>
                <li className = 'text-[#aa6100]'>
                  <p className = 'text-sm text-left'>You can specify the city or country that supports free shipping if purchases exceed the minimum limit that you specify.</p>
                </li>
              </ul>
            </div>

            {
              datafreeshipping?.data?.length === 0 &&  <>
                <span className = 'text-secondary'><FolderOffOutlinedIcon style = { {fontSize: '48px'} } /></span>
                <h3 className = 'text-blacklight text-base font-semibold mt-3'>There are currently no terms for free shipping</h3>
                <p className = 'text-sm text-secondary mt-1'>You can add shipping terms using this button</p>
              </>
            }

            {
              errorfreeshippingdata?.data?.message && <p className = 'text-sm text-red'>{ errorfreeshippingdata?.data?.message }</p>
            }

            {
              datafreeshipping?.data?.length > 0 && datafreeshipping?.data?.map((item) => {
                return(
                  <div key = { item?.id }>
                    <div
                      className = "cursor-pointer border border-borderColor rounded-md pl-2 mt-3"
                    >
                      <div className = 'flex justify-between'>
                        <div  className = 'flex justify-between self-center flex-1' onClick = { () => onTitleClick(item?.id) }>
                          <div>
                            <span className = 'text-secondary'><MenuOutlinedIcon style = { {fontSize: '20px'} } /></span>
                            <span className = 'text-sm text-black inline-block ml-2'>{ datacountry?.data?.find((country) => country.id === item.country_id)?.name }</span>
                          </div>
                          <div className = 'self-center text-secondary'><span style = { {fontSize: '15px'} }><KeyboardArrowDownOutlinedIcon /></span></div>
                        </div>
                        <div className = 'bg-red py-2 px-2 rounded-r-md self-center' onClick = { () => handledelete(item?.id) }>
                          <span className = 'text-white'><DeleteOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span>
                        </div>
                      </div>
                    </div>
                    <div>
                      {
                        activeIndex === item?.id && <Editfreeshipping freeShippingid = { freeShippingid } onTitleClick = { onTitleClick } refetch = { refetch } />
                      }
                    </div>
                  </div>
                );
              })
            }

          
            {
              addcondition && <>
              
                <ul className = 'text-left border-b border-borderColor pb-5'>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Countries</label>
                    { errorcoutry?.data?.message && <p className = 'text-sm text-red my-1'>{ errorcoutry?.data?.message }</p> }
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><MapOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <select onClick = { handlecountry } className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                        <option value = "null">Select countries</option>
                        {
                          datacountry?.data?.length > 0 && datacountry?.data?.map((item) => {
                            return(
                              <option key = { item?.id } value = { item?.id }>{ item?.name }</option>
                            );
                          })
                        }
                      </select>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>The cities</label>
                    {
                      errorcity?.data?.message && <p className = 'text-sm text-red my-1'></p>
                    }
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><FmdGoodOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <div className = 'w-width97% '>
                        <Citylist handlecity = { handlecity } citylistdata = { citylistdata } selectedCity = { selectedCity } />
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-secondary'>Minimum purchases</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3%'><span className = 'text-secondary'><AttachMoneyOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input 
                        type = 'number'
                        placeholder = 'Minimum purchases' 
                        className = 'inline-block text-sm outline-0 w-full ml-1'
                        value = { minimumPurchaseAmount }
                        onChange = { handleMinimumPurchaseChange } 
                      />
                      <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>SAR</span>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Supported shipping companies</label>
                    {
                      errorgetshippinglist?.data?.message && <p className = 'text-sm text-red'>{ errorgetshippinglist?.data?.message }</p>
                    }
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><DirectionsBusFilledOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <div className = 'text-sm outline-0 w-width97% text-secondary '>
                        <Companyshipinglist handleshippingcompanylist = { handleshippingcompanylist } getshippinglistdata = { getshippinglistdata } selectedshippingcompany = { selectedshippingcompany } />
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight'>Maximum shipment weight</label>
                    <p className = 'text-sm text-secondary'>Specify a maximum shipment weight to support free shipping</p>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3%'><span className = 'text-secondary'><InboxOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input 
                        type = 'number' 
                        placeholder = 'Maximum Shipment Weight' 
                        className = 'inline-block text-sm outline-0 w-full ml-1'
                        value = { maximumShipmentWeight }
                        onChange = { handleMaximumShipmentWeightChange } 
                      />
                      <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>Kg</span>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Select the customer group</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><GroupOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                        <option>Select the customer group</option>
                        <option>The most purchasing customers</option>
                        { /* <option>Diamond category</option>
                        <option>Highly loyal customers</option> */ }
                      </select>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Supported Products</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <select onChange = { handlesupportedproduct } className = 'inline-block text-sm outline-0 w-width97% text-secondary'>
                        <option value = "1">Select by products</option>
                        <option value = "2">Choose by categories</option>
                      </select>
                    </div>
                  </li>
                  {
                    supportedproductval == 1 && <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight'>Select supported products</label>
                      {
                        errorproductlist?.data?.message && <p className = 'text-sm text-red'>{ errorproductlist?.data?.message }</p>
                      }
                      <div className = 'inline-block text-sm outline-0 w-full text-secondary mt-1'>
                        <ShippingProductlist handleproductdata = { handleproductdata } selectedProduct = { selectedProduct } productdata = { productdata } handleproductinputval = { handleproductinputval } productinputValue = { productinputValue } />
                      </div>
                    </li>
                  }
                  {
                    supportedproductval == 2 && <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight'>Select supported categories</label>
                      {
                        errorcatlog?.data?.message && <p className = 'text-sm text-red'>{ errorcatlog?.data?.message }</p>
                      }
                      <div className = ' inline-block text-sm outline-0 w-full text-secondary mt-1'>
                        <Shippingcategorylist catloginputValue = { catloginputValue } selectedCatlog = { selectedCatlog } catlogdata = { catlogdata } handlecatlogtdata = { handlecatlogtdata } handlecatloginputval = { handlecatloginputval } />
                      </div>
                    </li>
                  }
                  
                  <li>
                    <div>
                      <div className = 'mt-5'><input type = 'checkbox' id = 'carts'  /> <label className = 'align-text-bottom text-base text-blacklight' htmlFor = 'carts'>Free shipping applies to all products in the cart</label></div>
                      <p className = 'text-sm text-secondary'>Free shipping is applied to all products in the cart if the customer adds products that are not included in free</p>
                      <p className = 'text-sm text-secondary'>shipping with products that are included in free shipping in the same order</p>
                     
                    </div>
                  </li>
                </ul>
                {
                  errorcreateshipping?.data?.message && <p className = 'text-sm text-red my-1'>{ errorcreateshipping?.data?.message }</p>
                }
                <div className = 'flex justify-between mt-8'>
                  <button onClick = { handleCreateFreeShipping } className = 'inline-block text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                  <button className = 'bg-[#f0f3f3] inline-block text-sm py-2 px-4 rounded text-blacklight' onClick = { () => setAddcondition(false) }>Close</button>
                </div>
              </>
           
           
            }

            <div className = 'text-left'>
              <button className = 'bg-[#f0f3f3] inline-block px-5 py-2 text-sm rounded text-blacklight mt-5 hover:bg-[#e5e7e7] duration-300' onClick = { handleAddcondition }>Add a condition</button>
            </div>
            
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FreeShippingSettings;