/* eslint-disable react/no-unescaped-entities */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import TopHeader from '../../../components/TopHeader';

const CreateSpecialoffer = () => {

  const [startDate, setStartDate] = useState(null);
  const [startDatewithto, setStartDatewithto] = useState(null);


  return (
    <>
     
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'w-full py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li className = 'mr-2'><Link to = "/special-offer" className = 'text-blacklight align-middle'>Special offers</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary align-middle'>Create a special offer</span></li>
          </ul>
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-base font-medium text-blacklight'>Display data</h3>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <div className = 'w-width49%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Presentation title</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width5% self-center'><span className = 'text-secondary align-text-bottom'><ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width95% text-secondary '>
                        <option>Browser and App Store</option>
                        <option>Store browser</option>
                        <option>Store application</option>
                      </select>
                    </div>
                  </div>
                  <div className = 'w-width49%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Display platform (select the platform on which you want to publish the offer)</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width5% self-center'><span className = 'text-secondary align-text-bottom'><DevicesOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <input placeholder = 'Coupon code (English letters, numbers, without spaces)' className = 'inline-block text-sm outline-0 w-width95% self-center ml-1' />
                    </div>
                  </div>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <div className = 'w-width49%'>
                    <label className = 'text-sm text-blacklight inline-block'>Offer start date</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <div className = 'w-width95% text-sm self-center'>
                        <DatePicker
                          selected = { startDate }
                          onChange = { (date) => setStartDate(date) }
                          className = 'outline-none'
                          placeholderText = 'Offer start date'
                        />
                      </div>
                    </div>
                  </div>
                  <div className = 'w-width49%'>
                    <label className = 'text-sm text-blacklight inline-block'>Offer expiration date</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <div className = 'w-width95% text-sm self-center'>
                        <DatePicker
                          selected = { startDatewithto }
                          onChange = { (date) => setStartDatewithto(date) }
                          className = 'outline-none'
                          placeholderText = 'Offer end date'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className = 'mt-3'>
                <h3 className = 'text-base text-blacklight'>Display type</h3>
                <p className = 'text-xs text-secondary'>Select the type of discount to be applied to the shopping cart</p>
                <label className = 'block text-sm text-blacklight mt-5'><span className = 'align-middle inline-block'><input type = 'radio' name = 'buys' /></span> If a customer buys X, he gets Y</label>
                <label className = 'block text-sm text-blacklight mt-1'><span className = 'align-middle inline-block'><input type = 'radio' name = 'buys' /></span> A fixed amount of the customer's purchase value</label>
                <label className = 'block text-sm text-blacklight mt-1'><span className = 'align-middle inline-block'><input type = 'radio' name = 'buys' /></span> A percentage of the value of the customer’s purchases</label>
              </li>
            </ul>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-base font-medium text-blacklight'>
                Display options -
              <span className = 'text-secondary text-sm font-normal'> If a customer buys X, he gets Y</span>
            </h3>
            <ul className = 'mt-6'>
              <li>
                <h3 className = 'text-base font-medium text-blacklight'>If the customer buys</h3>
                <p className = 'text-sm text-secondary'>Select the products and quantity required to be available in the shopping cart to apply the discount</p>
                <div className = 'flex justify-between'>
                  <div className = 'w-width38%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Quantity</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width6%'><span className = 'text-secondary align-text-bottom'><Inventory2OutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <input placeholder = 'quantify' className = 'inline-block text-sm outline-0 w-width94% self-center ml-1' />
                    </div>
                  </div>
                  <div className = 'w-width60%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Any product from</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width5% self-center'><span className = 'text-secondary align-text-bottom'><ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width95% text-secondary '>
                        <option>Browser and App Store</option>
                        <option>Store browser</option>
                        <option>Store application</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-4'>
                  <div><span className = 'text-secondary align-text-bottom'><SearchOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <input placeholder = 'quantify' className = 'inline-block text-sm outline-0 w-width98% self-center ml-1' />
                </div>
              </li>
              <li className = 'mt-8'>
                <h3 className = 'text-base font-medium text-blacklight'>The customer gets</h3>
                <p className = 'text-sm text-secondary'>Determine what the customer gets when the previous condition is met</p>
                <div className = 'flex justify-between'>
                  <div className = 'w-width38%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Quantity</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width6%'><span className = 'text-secondary align-text-bottom'><Inventory2OutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <input placeholder = 'quantify' className = 'inline-block text-sm outline-0 w-width94% self-center ml-1' />
                    </div>
                  </div>
                  <div className = 'w-width60%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Any product from</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width5% self-center'><span className = 'text-secondary align-text-bottom'><ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width95% text-secondary '>
                        <option>Browser and App Store</option>
                        <option>Store browser</option>
                        <option>Store application</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-4'>
                  <div><span className = 'text-secondary align-text-bottom'><SearchOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <input placeholder = 'quantify' className = 'inline-block text-sm outline-0 w-width98% self-center ml-1' />
                </div>
              </li>
              <li className = 'mt-8'>
                <div className = 'flex justify-between'>
                  <div className = 'w-width38%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Discount percentage</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width6%'><span className = 'text-secondary align-text-bottom'><PercentOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <input placeholder = 'quantify' className = 'inline-block text-sm outline-0 w-width94% self-center ml-1' />
                    </div>
                  </div>
                  <div className = 'w-width60%'>
                    <label className = 'text-sm text-blacklight inline-block mt-2'>Discount type</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width5% self-center'><span className = 'text-secondary align-text-bottom'><ReorderOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width95% text-secondary '>
                        <option>Percentage discount</option>
                        <option>Free product</option>
                      </select>
                    </div>
                  </div>
                </div>
               
              </li>
              <li>
                <div className = 'text-center mt-6 relative top-5'>
                  <button className = 'bg-[#f0f3f3] inline-block py-2 px-5 rounded-full text-blacklight'><span><CardGiftcardOutlinedIcon style = { {fontSize: '20px'} } /></span> Presentation summary</button>
                </div>
                <div className = 'border border-borderColor rounded pt-12 px-5 pb-5'>
                  <h3 className = 'text-base font-medium text-secondary text-center'>Complete the display options to see the summary here</h3>
                </div>
              </li>
              <li className = 'mt-5'>
                <p className = 'text-sm text-blacklight'>The text of the offer message</p>
                <textarea className = 'block text-sm mt-1 h-24 text-black w-full outline-none border border-borderColor rounded px-2 py-1' placeholder = 'Add the text of the offer message'></textarea>
                <p className = 'text-sm text-secondary mt-3'><span className = 'text-red'>Note:</span> If there is a shared product in more than one offer, the offer will be randomly selected for the product If a coupon is added to the shopping cart, the offer will not be applied</p>
              </li>
              <li className = 'mt-5'>
                <button className = 'text-sm bg-primary py-2.5 px-4 rounded-full text-white w-full'>Save</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSpecialoffer;
