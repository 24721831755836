import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRegisterMutation } from '../redux/api/authApi';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  
  const navigate = useNavigate();

  const [register, { isLoading, error, data, isSuccess }] = useRegisterMutation();

  useEffect(() => {
    if (error) {
      toast(error?.data?.message?.email[0]);
      toast(error?.data?.message);
    }
    
    if (isSuccess) {
      toast.success(data?.message);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    }
  }, [error, data, isSuccess, navigate]);

  const handleRegister = (e) => {
    e.preventDefault();

    const registerdata = {
      name,
      email,
      password,
      confirm_password : confirmPassword
    };

    register(registerdata);
  };

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setErrorConfirmPassword(
      newPassword === confirmPassword ? '' : 'Passwords do not match'
    );
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setErrorConfirmPassword(
      newConfirmPassword === password ? '' : 'Passwords do not match'
    );
  };

  const isFormValid = name && isEmailValid && password && confirmPassword === password;

  useEffect(() => {
    document.title = 'Sign Up';
  }, []);

  

  return (
    <>
      <ToastContainer />
      <div className = 'lg:bg-grey-50'>
        <div>
          <div className = 'flex py-11 lg:py-0 w-full min-h-screen mx-auto box-border justify-center'>
            <div className = 'w-6/12 bg-purple hidden lg:flex justify-end items-center'>
              <img className = 'w-width72% pt-10' src = 'https://cdn.salla.sa/KVlQp/4oA7jtVyZCsqf1LG9ckyhqR117YySPPwSFyjPZ8V.png' alt = 'perfume' />
            </div>
            <div className = 'lg:bg-white box-border w-full lg:w-6/12 lg:pl-20 lg:pr-12 flex items-center'>
              <div className = 'w-11/12 mx-auto lg:mx-0 md:w-3/5'>
                <Link to = "/" className = 'block w-52 mx-auto'>
                  <img className = 'w-52 mx-auto' src = 'https://cdn.salla.sa/KVlQp/YWFUbETQIUmVmzxQbje7oanjayllgiRNbpFOhKb3.png' alt = 'logo' />
                </Link>
                <h3 className = { 'text-2xl font-bold text-black text-center pt-4 ' }>Sign up</h3>
                <form onSubmit = { handleRegister }>
                  <ul> 
                    <li className = 'pt-8 lg:pt-2.5'>
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Name</label>
                      <input type = 'text' className = { 'block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600 ' } onChange = { (e) => setName(e.target.value) } value = { name } placeholder = 'name' />
                    </li>
                   
                    <li className = 'pt-8 lg:pt-2.5'>
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Email</label>
                      <input className = { `block w-full border border-grey rounded-md px-4 py-2 text-sm  ${errorEmail ? 'outline-red' : 'outline-primary-600'}` } onChange = { handleEmail } onBlur = { handleEmailBlur } value = { email } placeholder = 'Enter email' />
                      { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
                    </li>
                    <li className = 'relative pt-6 lg:pt-4'>
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Password</label>
                      <input
                        onChange = { handlePasswordChange }
                        value = { password }
                        type = { showPassword ? 'text' : 'password' }
                        className = { 'block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600 ' }
                        placeholder = 'Enter password' 
                      />
                      { password && <a href = 'javascript:void(0)' className = 'block absolute bottom-2 right-3.5 w-4' onClick = { toggleShowPassword }>{ showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon className = 'eyecolor' /> }</a> }
                    </li>
                    <li className = "relative pt-6 lg:pt-4">
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800 ' }>Confirm Password</label>
                      <input
                        onChange = { handleConfirmPasswordChange }
                        value = { confirmPassword }
                        type = { showPassword ? 'text' : 'password' }
                        className = { `block w-full lg:border border-grey lg:rounded-md lg:px-4 py-2 text-sm  ${errorConfirmPassword ? 'outline-red' : 'outline-primary-600'}` }
                        placeholder = 'Confirm password'
                      />
                      { errorConfirmPassword && (
                        <p className = "text-xs text-red">{ errorConfirmPassword }</p>
                      ) }
                    </li>
                    <li className = 'pt-6'>
                      <button
                        className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm ${isFormValid ? 'bg-gradient-primary' : 'bg-gradient-primary-light'}` }
                        disabled = { !isFormValid || isLoading }
                      >
                        { isLoading ? 'Creating...' : 'Sign up' }
                      </button>
                    </li>
                  </ul>
                </form>
                <ul className = 'pb-5'>
                  <li>
                    <p className = { 'text-sm text-grey text-center pt-4' }>Already have an account<Link className = { 'text-primary font-bold ' } to = "/login"> Login</Link></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
