
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React from 'react';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const BasketPolicy = () => {
  
  return(
    <>
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <p className = 'text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { {fontSize: '20px'} } /></span>filtering</p>
          <h3 className = 'text-base font-semibold text-blacklight mt-3'>Basket policies archive</h3>
          <div className = 'border border-borderColor rounded mt-3 p-3'>
            <table className = "min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                      Order number
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     Policy issuance date
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     Delivery number
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                      Shipment fees
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     Tax
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     Total
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     Branch/address	
                  </th>
                  <th
                    className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                  >
                     comments
                  </th>      
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
                <tr>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <a href = '#' className = 'text-sm text-blacklight'>#88768621</a>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = 'text-sm text-blacklight'>Saturday, November 4, 2023 | 03:55 global.m	</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">49256853332</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-24 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-3.6 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-green whitespace-no-wrap">-27.60 SAR</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">-</p>
                  </td>
                  <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                    <p className = "text-blacklight whitespace-no-wrap">Bill of lading</p>
                  </td>   
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasketPolicy;