import React from 'react';
import NumberofRequestsChart from './NumberofRequestsChart';
import Totalsaleschart from './Totalsaleschart';

const SalesChannels = () => {

  return(
    <>
      <div className = 'flex flex-wrap justify-between'>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Number of requests</h3>
            <p className = 'text-blacklight'><span className = 'text-xl font-semibold inline-block mt-5'>16</span> requests</p>
            <NumberofRequestsChart />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Total sales</h3>
            <p className = 'text-2xl text-blacklight mt-5'>8,223 SAR</p>
            <Totalsaleschart />
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesChannels;