import { Chart, registerables } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
Chart.register(...registerables);

const Totalsaleschart = () => {
  const Days = ['غير معروف','تطبيق محلي','مستعرض كمبيوتر','مستعرض جوال','تطبيق جوال'];
  const sales = [0, 0, 1, 15, 0];

  const data = {
    labels: Days,
    datasets: [
      {
        label: 'Sales',
        data: sales,
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF'
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#4BC0C0',
          '#9966FF'
        ]
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      }
    }
  };

  return (
    <>
      <div style = { { width: '230px', height: '230px', margin: 'auto' } }> { /* Adjust width and height here */ }
        <Doughnut data = { data } options = { options } />
      </div>
    </>
  );
};

export default Totalsaleschart;
