import React from 'react';
import Mostrequestedcustomers from './Mostrequestedcustomers';
import MostwantedBarchart from './MostwantedBarchart';
import Mostwanteddoutnutchart from './Mostwanteddoutnutchart';

const Themostwanted = () => {

  return(
    <>
      <div className = 'flex flex-wrap justify-between'>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Most popular days</h3>
            <MostwantedBarchart />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Most requested watches</h3>
            <Mostwanteddoutnutchart />
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md mt-5'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Most requested customers</h3>
            <Mostrequestedcustomers />
          </div>
        </div>
      </div>
    </>
  );
};

export default Themostwanted;