import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include'
  }),
  endpoints: (builder) => ({
    register: builder.mutation({
      query(body) {
        return {
          url: '/users/admin/signup/',
          method: 'POST',
          body
        };
      }
    }),
    login: builder.mutation({
      query(body) {
        return {
          url: '/users/admin/login/',
          method: 'POST',
          body
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          localStorage.setItem('token', result?.data?.data?.access_token); // Save token in local storage
          localStorage.setItem('userid', result?.data?.data?.user_id);
        } catch (error) {
          console.log(error);
        }
      }
    })
  })
});

export const { useLoginMutation, useRegisterMutation } = authApi;
