/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const ProductInclude = ({handleChangeproduct, selectedProductOption,Productlist}) => {

 
  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedProductOption }
        onChange = { handleChangeproduct }
        options = { Productlist }
        isMulti
        placeholder = 'Choose product'
      />
    </>
  );
};

export default ProductInclude;