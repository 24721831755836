/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';

const MarketingCouponModal = ({ marketingCoupon, setMarketingCoupon }) => {

  const handleMarketingCouponModal = () => {

    setMarketingCoupon(false);
  };

  return (
    <Modal open = { marketingCoupon } showCloseIcon = { false } onClose = { handleMarketingCouponModal }>
      <div className = 'modaladdproduct'>
        <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
          <h3 className = 'text-blacklight text-base font-semibold self-center'>Marketing coupon</h3>
          <div className = 'self-center'><span onClick = { handleMarketingCouponModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
        </div>
        <div className = 'px-4 py-3'>
          <h3 className = 'text-base font-medium text-blacklight'>Share this message with your marketer to start marketing your store</h3>
          <div className = 'bg-[#f0f3f3] p-4 rounded mt-6'>
            <h3 className = 'text-black text-base'>Hello shatha</h3>
            <p className = 'text-black text-sm mt-3'>Congratulations 🎉 An affiliate marketing account has been created with us. You will get 8.00% of any order made through you</p>

            <p className = 'text-base mt-3'>Customers can order in two ways:</p>
            <p className = 'text-sm mt-3'>1. Marketing Coupon: By using your following coupon: (Shahad)</p>
            <p className = 'text-sm'>to order from our store:</p>
            <Link to = '#' className = 'text-sm text-blacklight'>https://salla.sa/sau57</Link>

            <p className = 'text-base mt-3'>2. Or through the marketing link:</p>
            <Link to = '#' className = 'text-sm text-blacklight'>https://mtjr.at/XWUxkUBU3e</Link>
            <p className = 'text-sm mt-3'>You can also view your sales statistics via the following link:</p>
            <Link to = '#' className = 'text-sm text-blacklight'>https://salla.sa/fashion.house/affiliate-coupon-3f840dc2-9444-4dc7-9d66-a7efb3563baf</Link>
            <p className = 'mt-4'>*We recommend not sharing the statistics link with anyone</p>
            <p>You have profits 💰</p>
          </div>
          <div>
            <button className = 'text-sm text-white bg-primary inline-block w-full rounded py-2 px-5 mt-5'><ContentCopyOutlinedIcon style = { {fontSize: '18px'} } /> Copy the message</button>
          </div>
          <div className = 'mt-6'>
            <h3 className = 'text-blacklight text-base font-semibold'>Coupon details</h3>
            <div className = 'border border-borderColor rounded p-4 mt-5'>
              <div className = 'flex justify-between py-2'>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight'><ConfirmationNumberOutlinedIcon style = { {fontSize: '16px'} } /> Marketing coupon</p>
                </div>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight border border-borderColor py-1 px-2 rounded'><ContentCopyOutlinedIcon  style = { {fontSize: '16px'} } /> copies</p>
                </div>
              </div>
              <div className = 'flex justify-between border-t border-borderColor py-2'>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight'><PersonOutlinedIcon style = { {fontSize: '16px'} } /> Statistics link</p>
                </div>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight border border-borderColor py-1 px-2 rounded'><ContentCopyOutlinedIcon  style = { {fontSize: '16px'} } /> copies</p>
                </div>
              </div>
              <div className = 'flex justify-between border-t border-borderColor py-2'>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight'><GroupOutlinedIcon style = { {fontSize: '16px'} } /> Marketing link</p>
                </div>
                <div className = 'self-center'>
                  <p className = 'text-sm text-blacklight border border-borderColor py-1 px-2 rounded'><ContentCopyOutlinedIcon  style = { {fontSize: '16px'} } /> copies</p>
                </div>
              </div>
            </div>
            <div className = 'mt-5'><button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-blacklight'>Close</button></div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MarketingCouponModal;
