import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const trendingApi = createApi({
  reducerPath: 'trendingApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    trendingitemlist: builder.query({
      query: () => '/store/admin/trending-items/'
    }),
    addtrendingitem: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/trending-items/',
          method: 'POST',
          body
        };
      }
    }),
    deletetrendingitem: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/trending-items-delete/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    permissiontrendingitem: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/approve-trending-item/${id}/`,
          method: 'POST',
          body
        };
      }
    }),
    productlistallfortrending: builder.query({
      query: ({ size, search }) => {
        let url = `/store/admin/list-products/?size=${size}`;
        if (search) {
          url += `&search=${search}`;
        }
        return url;
      }
    })
    
  })
});

export const { useTrendingitemlistQuery, useAddtrendingitemMutation, useDeletetrendingitemMutation, usePermissiontrendingitemMutation, useProductlistallfortrendingQuery } = trendingApi;
