/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import EMobiledataOutlinedIcon from '@mui/icons-material/EMobiledataOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

import { format } from 'date-fns';
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummyimg from '../../images/dummyimg.png';
import {
  useBrandlistQuery,
  useCatloglistQuery,
  useDeleteProductMutation,
  useGetproductbyidQuery,
  usePermissionProductMutation,
  useUpdateProductMutation
} from '../../redux/api/productApi';
import { useFragrancelistfilterQuery } from '../../redux/api/scentfamilyApi';
import BrandName from './BrandName';
import Catloglist from './Catloglist';
import QuantityManagement from './QuantityManagement';
import Scentfamilycatloglist from './Scentfamilycatloglist';


const Productlist = ({query, refetch,item}) => {

  const moremenuRef = useRef(null);
  const [addimgModal, setAddimgModal] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [images, setImages] = useState([]);
  const [productTab, setProductTab] = useState(2);
  const [storagecode, setStoragecode] = useState('');
  const [requiresshipping, setRequiresshipping] = useState(true);
  const [productid, setProductid] = useState(null);
  const [productweight, setProductweight] = useState('');
  const [unit, setUnit] = useState('');
  const [gtin, setGtin] = useState('');
  const [mpn, setMpn] = useState('');
  const [maxqntperCustomer, setMaxqntperCustomer] = useState('');

  // Function to format date to YYYY-MM-DD
  const formatDate = (date) => date ? format(date, 'yyyy-MM-dd') : '';

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles) {
      const imageArray = selectedFiles.map((file) => URL.createObjectURL(file));
      setImages((prevImages) => [...prevImages, ...imageArray]);
      setImagesChanged(true);
    }
  };

  const [isOpenMoremenu, setIsOpenMoremenu] = useState(null);

  const toggleIsOpenMoremenu = (productId) => {
    if (isOpenMoremenu === productId) {
      setIsOpenMoremenu(null);
    } else {
      setIsOpenMoremenu(productId);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (moremenuRef.current && !moremenuRef.current.contains(event.target)) {
        setIsOpenMoremenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const onOpenAddimgModal = () => setAddimgModal(true);
  const onCloseAddimgModal = () => setAddimgModal(false);

  const onOpenAddProductModal = (id) => {
    setProductid(id);
    setAddProductModal(true);
  };
  const onCloseAddProductModal = () => setAddProductModal(false);

  const handleproducttab = (item) => {
    setProductTab(item);
  };

  const [startDate, setStartDate] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState('Arabic');
  const [subtitleEnglishValue, setSubtitleEnglishValue] = useState('');
  const [promotionalArabic, setPromotionalArabic] = useState('');
  const [promotionalEnglish, setPromotionalEnglish] = useState('');

  const [productTitlleArabic, setProductTitlleArabic] = useState('');
  const [productTitlleEnglish, setProductTitlleEnglish] = useState('');
  const [selectedCatlogOption, setSelectedCatlogOption] = useState({});
  const [catloglist, setCatloglist] = useState([]);
  const [selectedScentcatlogOption, setSelectedScentcatlogOption] = useState({});
  const [scentcatloglist, setScentcatloglist] = useState([]);
  const [ispublished, setIspublished] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [scentinputValue, setScentinputValue] = useState('');
  const [subtitleArabicValue, setSubtitleArabicValue] = useState('');



  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [description, setDescription] = useState('');
  const handleSelectChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleArabicInputChange = (e) => {
    setSubtitleArabicValue(e.target.value);
    setSubtitleArabicChanged(true);
  };

  const handleEnglishInputChange = (e) => {
    setSubtitleEnglishValue(e.target.value);
  };

  const handlePromotionalArabic = (e) => {
    setPromotionalArabic(e.target.value);
  };

  const handlePromotionalEnglish = (e) => {
    setPromotionalEnglish(e.target.value);
  };

  const handleProductTitlleArabic = (e) => {
    setProductTitlleArabic(e.target.value);
  };

  const handleProductTitlleEnglish = (e) => {
    setProductTitlleEnglish(e.target.value);
  };

  const [productUrlArabic, setProductUrlArabic] = useState('');
  const [productUrlEnglish, setProductUrlEnglish] = useState('');

  const handleProductUrlArabic = (e) => {
    setProductUrlArabic(e.target.value);
  };

  const handleProductUrlEnglish = (e) => {
    setProductUrlEnglish(e.target.value);
  };

  const [productDesArabic, setProductDesArabic] = useState('');
  const [productDesEnglish, setProductDesEnglish] = useState('');

  const handleProductDesArabic = (e) => {
    setProductDesArabic(e.target.value);
  };

  const handleProductDesEnglish = (e) => {
    setProductDesEnglish(e.target.value);
  };

  const [tags, setTags] = useState([]);
  const [inputTagValue, setInputTagValue] = useState('');

  const handleInputTagChange = (event) => {
    setInputTagValue(event.target.value);
  };

  const handleAddTag = () => {
    if (inputTagValue.trim() !== '') {
      setTags([...tags, inputTagValue.trim()]);
      setInputTagValue('');
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  const [selectedProductId, setSelectedProductId] = useState(null);

  const handleSelectedProductId = (productId) => {
    setSelectedProductId(productId);
  };

  const [productAlertModal, setProductAlertModall] = useState(false);

  const onOpenProductAlertModal = () => setProductAlertModall(true);
  const onCloseProductAlertModal = () => setProductAlertModall(false);

  const [copyproductlinkModal, setCopyproductlinkModal] = useState(false);

  const onOpenCopyproductlinkModal = () => setCopyproductlinkModal(true);
  const onCloseCopyproductlinkModal = () => setCopyproductlinkModal(false);

  const [copyproductModal, setCopyproductModal] = useState(false);

  const onOpenCopyproductModal = () => setCopyproductModal(true);
  const onCloseCopyproductModal = () => setCopyproductModal(false);

  const [productStatisticModal, setProductStatisticModal] = useState(false);

  const onOpenProductStatisticModal = () => setProductStatisticModal(true);
  const onCloseProductStatisticModal = () => setProductStatisticModal(false);

  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const onOpenDeleteProductModal = () => setDeleteProductModal(true);
  const onCloseDeleteProductModal = () => setDeleteProductModal(false);

  const [addacategoryModal, setAddacategoryModal] = useState(false);
  const onOpenAddacategoryModal = () => setAddacategoryModal(true);
  const onCloseAddacategoryModal = () => setAddacategoryModal(false);

  const [addcatlogCheckbox, setAddcatlogCheckbox] = useState(false);

  const handleCatlogCheckbox = () => {
    setAddcatlogCheckbox(!addcatlogCheckbox);
  };

  useEffect(() => {
    if (item) {
      const options = item?.categories.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setSelectedCatlogOption((prev) => ({
        ...prev,
        [item.id]: options || []
      }));
    }
  }, [item?.id]);

  useEffect(() => {
    if (item) {
      const options = item?.scent_families.map((c) => ({
        label: c.name_en,
        value: c.item_id
      }));

      setSelectedScentcatlogOption((prev) => ({
        ...prev,
        [item.id]: options || []
      }));
    }
  }, [item?.id]);


  
  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };

  const {
    error: catlogerror,
    data: catlogdata,
    isSuccess: catlogisSuccess
  } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (catlogerror) {
      toast(catlogerror?.data?.message);
    }
  }, [catlogerror]);

  useEffect(() => {
    if (catlogisSuccess) {

      const activeCategories = catlogdata?.data.filter((c) => c.is_active);

      const options = activeCategories?.map((c) => ({
        label: c.name_en,
        value: c.id
      }));
      setCatloglist(options || []);
      // setSelectedCatlogOption(null);
    }
  }, [catlogisSuccess, catlogdata]);

  const handleChangecatlog = (productId,selectedOption) => {
    setCategorychanged(true);

    setSelectedCatlogOption((prevCategories) => ({
      ...prevCategories,
      [productId]: selectedOption
    }));
  };


  // scent family catlog

  const scentSearchParams = inputValue ? { size: 30, search: scentinputValue } : { size: 30 };
  const {error: errorscent, data: datascent, isSuccess: isSuccessscent} = useFragrancelistfilterQuery(scentSearchParams);

  useEffect(() => {
    if (errorscent) {
      toast(errorscent?.data?.message);
    }
  }, [errorscent]);


  useEffect(() => {
    if (isSuccessscent) {

      const activedatascent = datascent?.data.filter((c) => c.is_approved);

      const options = activedatascent?.map((c) => ({
        label: c.name_en,
        value: c.item_id
      }));
      setScentcatloglist(options || []);
    }
  }, [isSuccessscent, datascent]);

  const handleChangeScentcatlog = (productId,selectedOption) => {
    console.log('selectedOption',selectedOption);
    setScentCategorychanged(true);
    setSelectedScentcatlogOption((prevCategories) => ({
      ...prevCategories,
      [productId]: selectedOption
    }));
  };


  // handle brand open

  const {
    error: branderror,
    data: branddata,
    isSuccess: brandisSuccess
  } = useBrandlistQuery();

  const [selectedBrandOption, setSelectedBrandOption] = useState(null);
  const [brandlist, setBrandlist] = useState([]);
  useEffect(() => {
    if (branderror) {
      toast(branderror?.data?.message);
    }
  }, [branderror]);

  useEffect(() => {
    if (brandisSuccess) {
      const options = branddata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setBrandlist(options || []);
      setSelectedBrandOption(null);
    }
  }, [brandisSuccess, branddata]);

  const handleChangebrand = (selectedOption) => {
    setSelectedBrandOption(selectedOption);
  };


  const { error: getproducterror, data: getproductdata, refetch: productbyidrefech } = useGetproductbyidQuery(productid,{skip: !productid});

  useEffect(() => {
    if (getproducterror) {
      toast(getproducterror?.message);
    }
  }, [getproducterror]);

  useEffect(() => {
    if (getproductdata) {
      const details = getproductdata?.data;
      setStoragecode(details?.sku);
      setIspublished(details?.is_published);
      setRequiresshipping(details?.shipping_required);
      setProductweight(details?.weight);
      setUnit(details?.unit);
      setGtin(details?.gtin);
      setMpn(details?.mpn);
      setMaxqntperCustomer(details?.max_quantity_per_customer);
      setStartDate( details?.discount_end_date === null?'': new Date(details?.discount_end_date));
      if (details.description) {
        const blocksFromHTML = convertFromHTML(details.description);
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        setEditorState(EditorState.createWithContent(contentState));
        setDescription(details.description);
      } else {
        setEditorState(EditorState.createEmpty());
        setDescription('');
      }
      if (details?.brand) {
        const brandscollection = {
          label: details.brand.name,
          value: details.brand.id
        };
        setSelectedBrandOption([brandscollection]);
      } else {
        setSelectedBrandOption([]);
      }

      // const catlogoptions = details?.categories?.map((c) => ({
      //   label: c?.name,
      //   value: c?.id
      // }));
      // setSelectedCatlogOption(catlogoptions);

    }
  }, [getproductdata,productid]);


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setDescription(contentHtml);
  };
  

  const [
    UpdateProduct,
    {
      error: upadetproducterror,
      isLoading: isloadingupdateproduct,
      isSuccess: updateproductisSuccess
    }
  ] = useUpdateProductMutation();

  const [updatingProductId, setUpdatingProductId] = useState(null);
  const [subtitleArabicChanged, setSubtitleArabicChanged] = useState(false);
  const [imagesChanged, setImagesChanged] = useState(false);
  const [categorychanged, setCategorychanged] = useState(false);
  const [scentcategorychanged, setScentCategorychanged] = useState(false);

  useEffect(() => {
    if (updateproductisSuccess) {

      setImages([]);
      refetch();
      onCloseAddProductModal();
      setUpdatingProductId(null);
      setSubtitleArabicChanged(false);
      setCategorychanged(false);
      setScentCategorychanged(false);
      setImagesChanged(false);
    }
  }, [updateproductisSuccess]);
  

  const handleSave = (item) => {
    const valuesArraycategory = Object.values(selectedCatlogOption).flatMap(option => option?.map(item => item.value));

    const valuesArrayscentcategory = Object.values(selectedScentcatlogOption).flatMap(option => option?.map(item => item.value));
  
    const updatedData = {};
  
    if (imagesChanged) {
      updatedData.images = images;
    }
  
    if (subtitleArabicChanged) {
      updatedData.name = subtitleArabicValue;
    }
  
    if(categorychanged){
      updatedData.categories = valuesArraycategory;
    }

    if(scentcategorychanged){
      updatedData.scent_families = valuesArrayscentcategory;
    }

    if (imagesChanged || subtitleArabicChanged || categorychanged || scentcategorychanged) {
      setUpdatingProductId(item?.id);
      UpdateProduct({ body: updatedData, id: item?.id });
    }
  };

  const handleUpdateProduct = () => {
    const updatedProductData = {
      sku: storagecode,
      shipping_required: requiresshipping,
      brand_id: selectedBrandOption?.value,
      description: description,
      weight: productweight,
      unit: unit,
      gtin: gtin,
      mpn: mpn,
      max_quantity_per_customer: maxqntperCustomer
      // categories: selectedCatlogOption?.map((option) => option.value)
    };

    const formattedDate = formatDate(startDate);
    if (formattedDate) {
      updatedProductData.discount_end_date = formattedDate;
    }

    UpdateProduct({ body: updatedProductData, id: productid });
  };

  const [
    deleteProduct,
    {
      error: errorProductdelete,
      isSuccess: deleteProductisSuccess,
      isLoading: deleteisLoading
    }
  ] = useDeleteProductMutation();

  useEffect(() => {
    if (errorProductdelete) {
      toast(errorProductdelete?.data?.message);
    }
  }, [errorProductdelete]);

  useEffect(() => {
    if (deleteProductisSuccess) {
      refetch();
      onCloseDeleteconfirmModal();
    }
  }, [deleteProductisSuccess]);

  const [deleteProductid, setDeleteProductid] = useState(null);
  const [deleteconfirmModal, setDeleteconfirmModal] = useState(false);
  const onOpenDeleteconfirmModal = (id) => {
    setDeleteProductid(id);
    setDeleteconfirmModal(true);
  };
  const onCloseDeleteconfirmModal = () => setDeleteconfirmModal(false);

  const handleDeleteproduct = async () => {
    try {
      await deleteProduct(deleteProductid);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const [permissionProduct,{error: errorpermission,data: datapermission , isSuccess: isSuccesspermission}] = usePermissionProductMutation();

  useEffect(() => {
    if(errorpermission){
      toast.error(errorpermission?.data?.message);
    }
  }, [errorpermission]);

  useEffect(() => {
    if(isSuccesspermission){
      onCloseAddProductModal();
      refetch();
      productbyidrefech();
    }
  },[isSuccesspermission,datapermission]);

  const handlePermission = async () => {
    const formdata = new FormData();
    formdata.append('action', ispublished === false ? 'publish' : 'unpublish');

    try {
      await permissionProduct({ body: formdata, id: productid });
    } catch(error) {
      console.log('Failed to update permission', error);
    }
  };
  
  return (
    <>
      <ToastContainer />
      <QuantityManagement
        selectedProductId = { selectedProductId }
        setSelectedProductId = { setSelectedProductId }
      />
      
      <div
        key = { item?.id }
        className = "w-width32% mb-5 relative border border-borderColor rounded-md"
      >
        <div className = "relative">
          <img
            src = { item?.images[0] ? item?.images[0] : dummyimg }
            className = "w-full rounded"
            alt = "product"
          />
          <div className = "flex justify-between w-full p-3 absolute top-0">
            <div
              className = "self-center"
              onClick = { () => onOpenDeleteconfirmModal(item?.id) }
            >
              <span className = "text-secondary inline-block w-8 h-8 bg-[#f0f3f3] rounded-full text-center cursor-pointer">
                <DeleteOutlineIcon
                  style = { {
                    fontSize: '20px',
                    display: 'inline-block',
                    marginTop: '3px'
                  } }
                />
              </span>
            </div>
            <div className = "self-center">
              <p className = "text-base text-blacklight">
                <input
                  type = "checkbox"
                  className = "align-text-bottom inline-block ml-1 bg-transparent"
                />
              </p>
            </div>
          </div>
          <div
            className = "bg-white rounded-full inline-block absolute bottom-3 left-3 py-1 px-2 cursor-pointer"
            onClick = { onOpenAddimgModal }
          >
            <span className = "text-secondary">
              <AddPhotoAlternateOutlinedIcon style = { { fontSize: '16px' } } />
            </span>
            <span className = "text-blacklight text-xs">
                Add a photo or video
            </span>
          </div>
        </div>

        <div className = "p-3">
          <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
            <select
              className = "inline-block text-sm outline-0 w-width15% border-r border-borderColor"
              value = { selectedLanguage }
              onChange = { handleSelectChange }
            >
              <option value = "Arabic">Ar</option>
              <option value = "English">En</option>
            </select>

            { selectedLanguage === 'Arabic' && (
              <input
                placeholder = "أدخل اسم المنتج"
                className = "inline-block text-sm text-right outline-0 w-width80%"
                defaultValue = { item?.name ? item?.name : subtitleArabicValue }
                onChange = { handleArabicInputChange }
              />
            ) }
            { selectedLanguage === 'English' && (
              <input
                placeholder = "Enter the product name"
                className = "inline-block text-sm text-right outline-0 w-width80%"
                value = { subtitleEnglishValue }
                onChange = { handleEnglishInputChange }
              />
            ) }
            <div className = "w-width5% ml-1">
              <span className = "text-secondary">
                <span className = "text-secondary inline-block">
                  <InboxOutlinedIcon style = { { fontSize: '20px' } } />
                </span>
              </span>
            </div>
          </div>
          <div className = "flex justify-between border border-borderColor rounded p-1 mt-3">
            <p className = "w-8 text-blacklight text-sm border-r border-borderColor text-center self-center">
                SAR
            </p>
            <div className = "flex-1 border-borderColor rounded-md self-center">
              <input
                defaultValue = { item?.options[0]?.selling_price }
                type = "text"
                placeholder = "The price"
                className = "text-sm text-blacklight inline-block w-full text-right outline-none"
              />
            </div>
            <div className = "w-7 self-center">
              <span className = "text-secondary inline-block ml-1">
                <PaymentsOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex justify-between border border-borderColor rounded p-1 mt-3">
            <button
              className = " text-blacklight text-sm border-r border-borderColor text-center self-center p-1"
              onClick = { () => handleSelectedProductId(item?.id) }
            >
                Options and quantity
            </button>
            <button className = "text-secondary border-r border-borderColor inline-block px-1.5">
              <InsertLinkOutlinedIcon style = { { fontSize: '20px' } } />
            </button>
            <button
              className = "text-secondary border-r border-borderColor inline-block px-1.5"
              onClick = { onOpenProductAlertModal }
            >
              <CircleNotificationsOutlinedIcon style = { { fontSize: '20px' } } />
            </button>
            <div className = "flex-1 border-borderColor rounded-md self-center">
              <input
                defaultValue = { item?.options?.[0]?.stock }
                type = "text"
                placeholder = "Quantity"
                className = "text-sm text-blacklight inline-block w-full text-right outline-none"
              />
            </div>
            <div className = "w-7 self-center">
              <span className = "text-secondary inline-block ml-1">
                <Inventory2OutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "mt-2">
            <label className = "text-sm text-blacklight">
                    Add a category
            </label>
            <div className = "rounded mt-1">
                 
              <Catloglist
                handleChangecatlog = { (selectedCategory) => handleChangecatlog(item?.id, selectedCategory) }
                selectedCatlogOption = { selectedCatlogOption[item?.id] || null }
                catloglist = { catloglist }
                setInputValue = { setInputValue }
                inputValue = { inputValue }
                        
              />
            </div>
          </div>

          <div className = "mt-2">
            <label className = "text-sm text-blacklight">
                    Add a scent family category
            </label>
            <div className = "rounded mt-1">
                 
              <Scentfamilycatloglist
                handleChangeScentcatlog = { (selectedCategory) => handleChangeScentcatlog(item?.id, selectedCategory) }
                selectedScentcatlogOption = { selectedScentcatlogOption[item?.id] || null }
                scentcatloglist = { scentcatloglist }
                setScentinputValue = { setScentinputValue }
                scentinputValue = { scentinputValue }
                        
              />
            </div>
          </div>
        </div>
        <div className = "relative">
          <div className = "flex border-t border-borderColor">
            <div
              className = "w-6/12 text-center border-r border-borderColor py-2 cursor-pointer"
              onClick = { () => toggleIsOpenMoremenu(item?.id) }
            >
              <span>
                <KeyboardArrowDownOutlinedIcon style = { { fontSize: '16px' } } />
              </span>
              <span className = "text-sm text-blacklight">More</span>
            </div>
            <div
              className = "w-6/12 text-center py-2 cursor-pointer"
              onClick = { () => onOpenAddProductModal(item?.id) }
            >
              <span>
                <TuneOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
              <span className = "text-sm text-blacklight">Product data</span>
            </div>
          </div>
          { isOpenMoremenu === item?.id && (
            <div
              className = "absolute left-0 top-9 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              role = "menu"
              aria-orientation = "vertical"
              aria-labelledby = "menu-button"
              tabIndex = "-1"
              ref = { moremenuRef }
            >
              <div className = "py-1" role = "none">
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                  onClick = { onOpenCopyproductlinkModal }
                >
                  <span className = "text-secondary">
                    <InsertLinkOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Copy product link
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                  onClick = { onOpenCopyproductModal }
                >
                  <span className = "text-secondary">
                    <ContentCopyOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Product duplication
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                  onClick = { onOpenProductStatisticModal }
                >
                  <span className = "text-secondary">
                    <InsertChartOutlinedOutlinedIcon
                      style = { { fontSize: '18px' } }
                    />
                  </span>{ ' ' }
                    Product statistics
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                >
                  <span className = "text-secondary">
                    <PreviewOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Review product requests
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                >
                  <span className = "text-secondary">
                    <GradeOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Browse product ratings
                </a>

                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                >
                  <span className = "text-secondary">
                    <CloudUploadOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Product display channels
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                >
                  <span className = "text-secondary">
                    <CloudDownloadOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Export product orders
                </a>
                <a
                  href = "#"
                  className = "text-blacklight block px-4 py-1.5 text-sm"
                  onClick = { onOpenDeleteProductModal }
                >
                  <span className = "text-secondary">
                    <CancelOutlinedIcon style = { { fontSize: '18px' } } />
                  </span>{ ' ' }
                    Permanent deletion
                </a>
              </div>
            </div>
          ) }
        </div>

        <button
          className = "bg-primary text-white w-full py-2 mt-3"
          onClick = { () => handleSave(item) }
          disabled = { updatingProductId === item?.id && isloadingupdateproduct }
        >
          { updatingProductId === item?.id && isloadingupdateproduct ? (
            'Saving'
          ) : (
            'Save'
          ) }
        </button>
      </div>

      <Modal
        open = { addimgModal }
        showCloseIcon = { false }
        onClose = { onCloseAddimgModal }
      >
        <div className = "modalboxsendingmessege">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Product images
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddimgModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <h3 className = "text-black text-base">Product pictures</h3>
            <p className = "text-sm text-secondary mt-2">
              Size: 1024 × 1024px
            </p>
            <div>
              <div className = "mt-4">
                <label
                  htmlFor = "upload-input"
                  style = { { cursor: 'pointer' } }
                  className = "block bg-[#f0f3f3] rounded text-center py-4 text-base text-blacklight"
                >
                  Click to upload images
                </label>
                <input
                  id = "upload-input"
                  type = "file"
                  accept = "image/*"
                  multiple
                  onChange = { handleImageChange }
                  style = { { display: 'none' } }
                />
              </div>
              <div className = "flex gap-3 flex-wrap mt-3">
                { images.map((image, index) => (
                  <div
                    key = { index }
                    className = "bg-[#f0f3f3] rounded-es rounded-br"
                  >
                    <img
                      src = { image }
                      alt = { `Uploaded ${index}` }
                      style = { { maxWidth: '200px', maxHeight: '200px' } }
                    />
                    <div className = "flex justify-between px-2 py-2">
                      <div className = "self-center">
                        <span className = "cursor-pointer text-red">
                          <DeleteOutlinedIcon style = { { fontSize: '18px' } } />
                        </span>
                      </div>
                      <div className = "self-center">
                        <span className = "text-sm text-blacklight">
                          Basic images
                        </span>
                        <input
                          type = "radio"
                          name = "text"
                          className = "align-text-bottom inline-block ml-1"
                        />
                      </div>
                    </div>
                  </div>
                )) }
              </div>
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-5 py-2 rounded mt-5"
                onClick = { onCloseAddimgModal }
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { addProductModal }
        showCloseIcon = { false }
        onClose = { onCloseAddProductModal }
      >
        <div className = "modaladdproduct">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Product data (sa)
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddProductModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <div className = "flex border border-borderColor rounded">
              <div
                className = { `w-full cursor-pointer py-2 text-center ${
                  productTab === 2 ? 'bg-[#f0f3f3]' : ''
                }` }
                onClick = { () => handleproducttab(2) }
              >
                <span className = "text-blacklight">
                  <DescriptionOutlinedIcon style = { { fontSize: '20px' } } />
                </span>
                <span className = "text-blacklight text-sm font-medium">
                  Product data
                </span>
              </div>
            </div>
            
            { productTab === 2 && (
              <div>
                <ul>
                  <li className = "mt-5">
                    <label className = "text-sm text-blacklight">
                      Requires shipping/delivery?{ ' ' }
                      <span className = "text-red">*</span>
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <LocalShippingOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <select
                        onChange = { (e) => setRequiresshipping(e.target.value) }
                        className = "inline-block text-sm outline-0 w-width97%"
                      >
                        <option
                          selected = { requiresshipping === true ? true : false }
                          value = "true"
                        >
                          Yes, it requires charging
                        </option>
                        <option
                          selected = { requiresshipping === false ? true : false }
                          value = "false"
                        >
                          No charging required
                        </option>
                      </select>
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Product weight
                    </label>
                    <p className = "text-xs text-secondary">
                      Enter the actual weight of the product so that it is
                      processed correctly by the shipping company. If your
                      products are large, please pay attention to the volumetric
                      weight.{ ' ' }
                      <a href = "#" className = "text-primary text-xs underline">
                        More details
                      </a>
                    </p>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                      <select onChange = { (e) => setUnit(e.target.value) } className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor">
                        <option selected = { unit?.unit === 'ml' } value = "ml">ML</option>
                        <option selected = { unit?.unit === 'gm' } value = "gm">GM</option>
                        <option selected = { unit?.unit === 'l' } value = "l">L</option>
                        <option selected = { unit?.unit === 'oz' } value = "oz">OZ</option>
                        <option selected = { unit?.unit === 'kg' } value = "kg">KG</option>
                        <option selected = { unit?.unit === 'lb' } value = "lb">LB</option>
                      </select>
                      <div className = "w-width3% ml-1">
                        <span className = "text-secondary">
                          <ScaleOutlinedIcon style = { { fontSize: '18px' } } />
                        </span>
                      </div>
                      <input
                        onChange = { (e) => setProductweight(e.target.value) }
                        value = { productweight }
                        placeholder = "Product weight"
                        className = "inline-block text-sm outline-0 w-width85%"
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Cost price
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <MonetizationOnOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <input
                        placeholder = "Cost price"
                        className = "inline-block text-sm outline-0 w-width97%"
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <div className = "flex gap-4">
                      <div className = "w-6/12">
                        <label className = "text-sm text-blacklight">
                          End of reduction
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                          <div className = "width5%">
                            <span className = "text-secondary align-text-top inline-block mr-1">
                              <CalendarMonthOutlinedIcon
                                style = { { fontSize: '20px' } }
                              />
                            </span>
                          </div>
                          <div className = "w-width95%">
                            <DatePicker
                              selected = { startDate }
                              onChange = { (date) => setStartDate(date) }
                              placeholderText = "Select a date"
                              className = "outline-none text-sm"
                            />
                          </div>
                        </div>
                      </div>
                      <div className = "w-6/12">
                        <label className = "text-sm text-blacklight">
                          Discounted price
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                          <div className = "w-width5%">
                            <span className = "text-secondary align-text-top inline-block mr-1">
                              <DiscountOutlinedIcon
                                style = { { fontSize: '20px' } }
                              />
                            </span>
                          </div>
                          <div className = "w-width95%">
                            <input
                              className = "text-sm"
                              placeholder = "Discounted price"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className = "mt-4">
                    <div className = "flex gap-4">
                      <div className = "w-4/12">
                        <label className = "text-sm text-blacklight">GTIN</label>
                        <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                          <div>
                            <span className = "text-secondary align-text-top inline-block mr-1">
                              <InsertChartOutlinedIcon
                                style = { { fontSize: '20px' } }
                              />
                            </span>
                          </div>
                          <div className = "w-width92%">
                            <input onChange = { (e) => setGtin(e.target.value) } value = { gtin } className = "text-sm" placeholder = "GTIN" />
                          </div>
                        </div>
                      </div>
                      <div className = "w-4/12">
                        <label className = "text-sm text-blacklight">MPN</label>
                        <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                          <div>
                            <span className = "text-secondary inline-block mr-1">
                              <InsertChartOutlinedIcon
                                style = { { fontSize: '20px' } }
                              />
                            </span>
                          </div>
                          <div className = "w-width92%">
                            <input onChange = { (e) => setMpn(e.target.value) } value = { mpn } className = "text-sm" placeholder = "MPN" />
                          </div>
                        </div>
                      </div>
                      <div className = "w-4/12">
                        <label className = "text-sm text-blacklight">
                          SKU <span className = "text-red">*</span>
                        </label>
                        <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                          <div>
                            <span className = "text-secondary inline-block mr-1">
                              <InsertChartOutlinedIcon
                                style = { { fontSize: '20px' } }
                              />
                            </span>
                          </div>
                          <div className = "w-width92%">
                            <input
                              className = "text-sm"
                              placeholder = "SKU"
                              value = { storagecode }
                              onChange = { (e) => setStoragecode(e.target.value) }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Maximum quantity per customer
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3% self-center">
                        <span className = "text-secondary align-text-bottom">
                          <PersonOutlineOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <input
                        onChange = { (e) => setMaxqntperCustomer(e.target.value) }
                        value = { maxqntperCustomer }
                        placeholder = "Maximum quantity per customer"
                        className = "inline-block text-sm outline-0 w-width97% self-center"
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Customs coordination code (HS Code)
                    </label>
                    <p className = "text-xs text-secondary">
                      The code consists of numbers only and is required when
                      shipping the product internationally.{ ' ' }
                      <a href = "#" className = "text-primary text-xs underline">
                        Learn more
                      </a>
                    </p>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3% self-center">
                        <span className = "text-secondary align-text-bottom">
                          <PersonOutlineOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <input
                        placeholder = "Customs coordination code (HS Code)"
                        className = "inline-block text-sm outline-0 w-width97% self-center"
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Customs coordination code (HS Code)
                    </label>
                    <p className = "text-xs text-secondary">
                      The code consists of numbers only and is required when
                      shipping the product internationally.{ ' ' }
                      <a href = "#" className = "text-primary text-xs underline">
                        Learn more
                      </a>
                    </p>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3% self-center">
                        <span className = "text-secondary align-text-bottom">
                          <PersonOutlineOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <input
                        placeholder = "Customs coordination code (HS Code)"
                        className = "inline-block text-sm outline-0 w-width97% self-center"
                      />
                    </div>
                  </li>
                  
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Identify the brand
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <WorkspacePremiumOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <BrandName
                        handleChangebrand = { handleChangebrand }
                        selectedBrandOption = { selectedBrandOption }
                        brandlist = { brandlist }
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">Subtitle</label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                      <select
                        className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor"
                        value = { selectedLanguage }
                        onChange = { handleSelectChange }
                      >
                        <option value = "Arabic">Arabic</option>
                        <option value = "English">English</option>
                      </select>
                      <div className = "w-width3% ml-1">
                        <span className = "text-secondary">
                          <SubtitlesOutlinedIcon style = { { fontSize: '18px' } } />
                        </span>
                      </div>
                      { selectedLanguage === 'Arabic' && (
                        <input
                          placeholder = "Arabic Subtitle"
                          className = "inline-block text-sm outline-0 w-width85%"
                          value = ""
                          onChange = { handleArabicInputChange }
                        />
                      ) }
                      { selectedLanguage === 'English' && (
                        <input
                          placeholder = "English Subtitle"
                          className = "inline-block text-sm outline-0 w-width85%"
                          value = { subtitleEnglishValue }
                          onChange = { handleEnglishInputChange }
                        />
                      ) }
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Promotional title
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                      <select
                        className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor"
                        value = { selectedLanguage }
                        onChange = { handleSelectChange }
                      >
                        <option value = "Arabic">Arabic</option>
                        <option value = "English">English</option>
                      </select>
                      <div className = "w-width3% ml-1">
                        <span className = "text-secondary">
                          <SubtitlesOutlinedIcon style = { { fontSize: '18px' } } />
                        </span>
                      </div>
                      { selectedLanguage === 'Arabic' && (
                        <input
                          placeholder = "Arabic Subtitle"
                          className = "inline-block text-sm outline-0 w-width85%"
                          value = { promotionalArabic }
                          onChange = { handlePromotionalArabic }
                        />
                      ) }
                      { selectedLanguage === 'English' && (
                        <input
                          placeholder = "English Subtitle"
                          className = "inline-block text-sm outline-0 w-width85%"
                          value = { promotionalEnglish }
                          onChange = { handlePromotionalEnglish }
                        />
                      ) }
                    </div>
                  </li>
                  <li className = "mt-4">
                    <label className = "text-sm text-blacklight">
                      Determine the quantity of the product
                    </label>
                    <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                      <div className = "w-width3%">
                        <span className = "text-secondary">
                          <IndeterminateCheckBoxOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <select className = "inline-block text-sm outline-0 w-width97%">
                        <option>Determine the quantity of the product</option>
                        <option>
                          Activate the option to specify the quantity
                        </option>
                        <option>Disable the option to specify quantity</option>
                      </select>
                    </div>
                  </li>
                  <li className = "mt-4">
                    <p className = "text-sm text-blacklight">
                      <span>Attach a file upon request</span>{ ' ' }
                      <input
                        className = "align-sub inline-block ml-2"
                        type = "checkbox"
                      />
                    </p>
                  </li>
                  <li className = "mt-3">
                    <p className = "text-sm text-blacklight">
                      <span>Possibility of writing a note</span>{ ' ' }
                      <input
                        className = "align-sub inline-block ml-2"
                        type = "checkbox"
                      />
                    </p>
                  </li>
                  <li className = "mt-3">
                    <p className = "text-sm text-blacklight">
                      <span>The product is subject to tax</span>{ ' ' }
                      <input
                        className = "align-sub inline-block ml-2"
                        type = "checkbox"
                      />
                    </p>
                  </li>
                  <li>
                    <div className = "border border-borderColor mt-5">
                      <Editor
                        editorState = { editorState }
                        toolbarClassName = "toolbarClassName"
                        wrapperClassName = "wrapperClassName"
                        editorClassName = "editorClassName"
                        onEditorStateChange = { onEditorStateChange }
                      />
                    </div>
                  </li>
                  <li className = "mt-4">
                    <div className = "flex justify-between border border-borderColor rounded mt-1.5">
                      <button
                        className = "text-sm bg-primary inline-block px-3 py-1.5 rounded text-white"
                        onClick = { handleAddTag }
                      >
                        Addition
                      </button>
                      <input
                        placeholder = "Enter the tag here, then press the Add button or press Enter"
                        className = "inline-block text-sm outline-0 w-11/12 ml-3"
                        value = { inputTagValue }
                        onChange = { handleInputTagChange }
                        onKeyPress = { (event) => {
                          if (event.key === 'Enter') {
                            handleAddTag();
                          }
                        } }
                      />
                    </div>
                    <ul className = "flex">
                      { tags.map((tag, index) => (
                        <li
                          key = { index }
                          className = "flex justify-between border border-borderColor rounded-full mt-1.5 mr-1 px-2"
                        >
                          <span className = "inline-block text-sm mr-2 self-center">
                            { tag }
                          </span>
                          <button
                            className = "text-red self-center"
                            onClick = { () => handleDeleteTag(tag) }
                          >
                            <HighlightOffOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </button>
                        </li>
                      )) }
                    </ul>
                  </li>
                  <li>
                    <div className = "border border-borderColor rounded p-4 mt-3">
                      <h3 className = "text-sm text-blacklight font-medium">
                        SEO improvements
                      </h3>

                      <ul>
                        <li className = "mt-2">
                          <label className = "text-sm text-blacklight">
                            Product Page Title
                          </label>
                          <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                            <select
                              className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor"
                              value = { selectedLanguage }
                              onChange = { handleSelectChange }
                            >
                              <option value = "Arabic">Arabic</option>
                              <option value = "English">English</option>
                            </select>
                            <div className = "w-width3% ml-1">
                              <span className = "text-secondary">
                                <SubtitlesOutlinedIcon
                                  style = { { fontSize: '18px' } }
                                />
                              </span>
                            </div>
                            { selectedLanguage === 'Arabic' && (
                              <input
                                placeholder = "Arabic product page title"
                                className = "inline-block text-sm outline-0 w-width85%"
                                value = { productTitlleArabic }
                                onChange = { handleProductTitlleArabic }
                              />
                            ) }
                            { selectedLanguage === 'English' && (
                              <input
                                placeholder = "English product page title"
                                className = "inline-block text-sm outline-0 w-width85%"
                                value = { productTitlleEnglish }
                                onChange = { handleProductTitlleEnglish }
                              />
                            ) }
                          </div>
                        </li>
                        <li className = "mt-4">
                          <label className = "text-sm text-blacklight">
                            Product Page URL (SEO Page URL)
                          </label>
                          <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                            <select
                              className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor"
                              value = { selectedLanguage }
                              onChange = { handleSelectChange }
                            >
                              <option value = "Arabic">Arabic</option>
                              <option value = "English">English</option>
                            </select>
                            <div className = "w-width3% ml-1">
                              <span className = "text-secondary">
                                <InsertLinkOutlinedIcon
                                  style = { { fontSize: '18px' } }
                                />
                              </span>
                            </div>
                            { selectedLanguage === 'Arabic' && (
                              <input
                                placeholder = "Product Page URL (SEO Page URL)"
                                className = "inline-block text-sm outline-0 w-width85%"
                                value = { productUrlArabic }
                                onChange = { handleProductUrlArabic }
                              />
                            ) }
                            { selectedLanguage === 'English' && (
                              <input
                                placeholder = "Product Page URL (SEO Page URL)"
                                className = "inline-block text-sm outline-0 w-width85%"
                                value = { productUrlEnglish }
                                onChange = { handleProductUrlEnglish }
                              />
                            ) }
                          </div>
                        </li>
                        <li className = "mt-4">
                          <label className = "text-sm text-blacklight">
                            Product Page Description
                          </label>
                          <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                            <select
                              className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor"
                              value = { selectedLanguage }
                              onChange = { handleSelectChange }
                            >
                              <option value = "Arabic">Arabic</option>
                              <option value = "English">English</option>
                            </select>
                            <div className = "w-width3% ml-1">
                              <span className = "text-secondary">
                                <NoteOutlinedIcon
                                  style = { { fontSize: '18px' } }
                                />
                              </span>
                            </div>
                            { selectedLanguage === 'Arabic' && (
                              <textarea
                                placeholder = "Product Page Description"
                                className = "inline-block text-sm outline-0 mt-1 w-width85%"
                                value = { productDesArabic }
                                onChange = { handleProductDesArabic }
                              />
                            ) }
                            { selectedLanguage === 'English' && (
                              <textarea
                                placeholder = "Product Page Description"
                                className = "inline-block text-sm outline-0 mt-1 w-width85%"
                                value = { productDesEnglish }
                                onChange = { handleProductDesEnglish }
                              />
                            ) }
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    {
                      upadetproducterror?.data?.message && (
                        <>
                          { upadetproducterror.data.message.discount_end_date && (
                            <p className = 'text-sm text-red'>
                              { upadetproducterror.data.message.discount_end_date[0] }
                            </p>
                          ) }
                          { upadetproducterror.data.message.sku && upadetproducterror.data.message.sku.length > 0 && (
                            <p className = 'text-sm text-red'>
                              SKU { upadetproducterror.data.message.sku[0] }
                            </p>
                          ) }
                          { upadetproducterror.data.message.sku && upadetproducterror.data.message.sku.length > 0 && (
                            <p className = 'text-sm text-red'>
                              Weight { upadetproducterror.data.message.weight[0] }
                            </p>
                          ) }
                        </>
                      )
                    }

                    <div className = "flex justify-between mt-10">
                      <div>
                        <button
                          className = "bg-[#f0f3f3] px-4 py-1.5 text-sm rounded"
                          onClick = { onCloseAddProductModal }
                        >
                        Cancellation
                        </button>
                        <button onClick = { () => handlePermission() } className = 'bg-primary px-4 py-1.5 text-sm rounded text-white ml-3'>{ (ispublished === false || ispublished === null)? 'Publish' : 'UnPublish' } Product</button>
                      </div>
                      <button
                        className = "bg-primary px-4 py-1.5 text-sm rounded text-white ml-3"
                        onClick = { () => handleUpdateProduct() }
                      >
                        Save product data
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            ) }
          </div>
        </div>
      </Modal>

      <Modal
        open = { copyproductlinkModal }
        showCloseIcon = { false }
        onClose = { onCloseCopyproductlinkModal }
      >
        <div className = "normalmodal">
          <div className = "flex justify-end bg-[#f0f3f3] px-4 py-3">
            <div className = "self-center">
              <span
                onClick = { onCloseCopyproductlinkModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3 text-center">
            <span className = "text-green">
              <CheckCircleOutlineOutlinedIcon style = { { fontSize: '80px' } } />
            </span>
            <p className = "text-base text-blacklight">Product link copied</p>
          </div>
        </div>
      </Modal>

      <Modal
        open = { copyproductModal }
        showCloseIcon = { false }
        onClose = { onCloseCopyproductModal }
      >
        <div className = "normalmodal">
          <div className = "flex justify-end bg-[#f0f3f3] px-4 py-3">
            <div className = "self-center">
              <span
                onClick = { onCloseCopyproductModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3 text-center">
            <span className = "text-green">
              <ReportOutlinedIcon style = { { fontSize: '80px' } } />
            </span>
            <h3 className = "text-black text-2xl font-semibold mt-3">alert</h3>
            <p className = "text-sm text-blacklight mt-2">
              Are you sure that by copying the product, a product will be
              created with the same data
            </p>
            <div className = "mt-5">
              <button className = "bg-[#f0f3f3] text-sm text-blacklight inline-block py-1.5 px-4 rounded">
                Cancellation
              </button>
              <button className = "bg-primary text-sm text-white inline-block py-1.5 px-4 rounded ml-2">
                To be sure
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { productStatisticModal }
        showCloseIcon = { false }
        onClose = { onCloseProductStatisticModal }
      >
        <div className = "normalmodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Statistics (sa)
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseProductStatisticModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3 text-center">
            <div className = "flex justify-between">
              <button className = "bg-[#f0f3f3] text-sm inline-block rounded py-2 px-5">
                an offer
              </button>
              <select className = "text-sm border border-borderColor px-2 py-1 rounded text-blacklight outline-none">
                <option>2024</option>
                <option>2023</option>
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
                <option>2018</option>
                <option>2017</option>
                <option>2016</option>
              </select>
              <select className = "text-sm border border-borderColor px-2 py-1 rounded text-blacklight outline-none">
                <option>January</option>
                <option>February</option>
                <option>March</option>
                <option>April</option>
                <option>May</option>
                <option>June</option>
                <option>July</option>
                <option>August</option>
                <option>September</option>
                <option>October</option>
                <option>November</option>
                <option>December</option>
              </select>
              <select className = "text-sm border border-borderColor px-2 py-1 rounded text-blacklight outline-none">
                <option>Daily</option>
                <option>Weekly</option>
                <option>Monthly</option>
                <option>Annual</option>
                <option>Since the beginning</option>
              </select>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { deleteProductModal }
        showCloseIcon = { false }
        onClose = { onCloseDeleteProductModal }
      >
        <div className = "normalmodal">
          <div className = "flex justify-end bg-[#f0f3f3] px-4 py-3">
            <div className = "self-center">
              <span
                onClick = { onCloseDeleteProductModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3 text-center">
            <span className = "text-red">
              <ReportOutlinedIcon style = { { fontSize: '80px' } } />
            </span>
            <h3 className = "text-black text-2xl font-semibold mt-3">warning</h3>
            <p className = "text-sm text-blacklight mt-2">
              The product will be permanently deleted
            </p>
            <div className = "mt-5">
              <button
                className = "bg-[#f0f3f3] text-sm text-blacklight inline-block py-1.5 px-4 rounded"
                onClick = { onCloseDeleteProductModal }
              >
                Cancellation
              </button>
              <button className = "bg-primary text-sm text-white inline-block py-1.5 px-4 rounded ml-2">
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open = { addacategoryModal }
        showCloseIcon = { false }
        onClose = { onCloseAddacategoryModal }
      >
        <div className = "modaladdproduct">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Add a new category
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddacategoryModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <ul>
              <li>
                <label className = "text-sm text-blacklight">Category name</label>
                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                  <div className = "w-width3%">
                    <span className = "text-secondary">
                      <EMobiledataOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>
                  </div>
                  <input
                    placeholder = "Enter the category name"
                    className = "inline-block text-sm outline-0 w-width97%"
                  />
                </div>
              </li>
              <li className = "mt-3">
                <p>
                  <label
                    htmlFor = "add_to_main"
                    className = "text-sm text-blacklight"
                  >
                    Add to main category
                  </label>{ ' ' }
                  <input
                    id = "add_to_main"
                    name = "add_to_main"
                    type = "checkbox"
                    className = "align-middle"
                    onClick = { handleCatlogCheckbox }
                    checked = { addcatlogCheckbox }
                  />
                </p>

                { addcatlogCheckbox && (
                  <select className = "border border-borderColor px-2 py-1.5 rounded inline-block w-full text-blacklight text-sm outline-none mt-2">
                    <option>Clothing department</option>
                    <option>Means wear</option>
                    <option>Women wear</option>
                  </select>
                ) }
              </li>
              <li className = "mt-5">
                <div className = "flex justify-between">
                  <button className = "bg-[#f0f3f3] text-sm text-blacklight inline-block py-2 px-4 rounded">
                    Cancellation
                  </button>
                  <button className = "bg-primary rounded text-white py-2 px-4 inline-block hover:bg-primary-400 duration-300">
                    Add classification
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal
        open = { productAlertModal }
        showCloseIcon = { false }
        onClose = { onCloseProductAlertModal }
      >
        <div className = "modaladdproduct">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Product Alerts - sa
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseProductAlertModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <ul>
              <li className = "mt-4">
                <p className = "text-base text-blacklight">
                  Notify me when the product quantity reaches
                </p>
                <label className = "text-sm text-secondary">
                  You will be notified before the product runs out
                </label>
                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                  <div className = "w-width3%">
                    <span className = "text-secondary">
                      <AddShoppingCartOutlinedIcon
                        style = { { fontSize: '20px' } }
                      />
                    </span>
                  </div>
                  <input
                    placeholder = "0"
                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                  />
                  <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                    Piece
                  </span>
                </div>
              </li>
              <li className = "mt-4">
                <p className = "text-base text-blacklight">
                  Alert customers subscribed to “Notify me when available” when
                  a product quantity greater than is available
                </p>
                <label className = "text-sm text-secondary">
                  To avoid sending notifications to customers when a small
                  amount of product is available, you can specify the minimum
                  availability required to start sending notifications to
                  customers, the default value is 15
                </label>
                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                  <div className = "w-width3%">
                    <span className = "text-secondary">
                      <CampaignOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>
                  </div>
                  <input
                    placeholder = "15"
                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                  />
                  <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                    Piece
                  </span>
                </div>
              </li>
              <li className = "mt-4">
                <p className = "text-base text-blacklight">
                  Percentage of customers to be notified
                </p>
                <label className = "text-sm text-secondary">
                  Product availability notification will be sent to a percentage
                  of customers subscribed to “Notify me when available”. The
                  number of subscribed customers is now 0 customers
                </label>
                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                  <div className = "w-width3%">
                    <span className = "text-secondary">
                      <PercentOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>
                  </div>
                  <input
                    placeholder = "100"
                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                  />
                  <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                    %
                  </span>
                </div>
              </li>
              <li>
                <button className = "bg-primary rounded text-white py-2 px-5 mt-5 block w-full hover:bg-primary-400 duration-300">
                  Save
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal
        open = { deleteconfirmModal }
        showCloseIcon = { false }
        onClose = { onCloseDeleteconfirmModal }
      >
        <div className = " bg-[#f0f3f3] px-4 py-3">
          <h3 className = "text-blacklight text-base font-semibold self-center">
            Confirm Deletion
          </h3>
        </div>
        <div className = "p-8">
          <p className = "mb-6 text-sm">
            Are you sure you want to delete this product?
          </p>
          <div className = "flex justify-end space-x-4 mt-12">
            <button
              className = "bg-red text-white px-4 py-1 rounded outline-none"
              onClick = { handleDeleteproduct }
            >
              { deleteisLoading ? 'Deleting..' : 'Yes, delete' }
            </button>
            <button
              className = "border border-boderColor-300 text-black px-4 py-1 rounded outline-none"
              onClick = { onCloseDeleteconfirmModal }
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      
    </>
  );
};

export default Productlist;