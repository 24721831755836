import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useCouponlistQuery } from '../../redux/api/discountcouponApi';
import DiscountCustomPagination from '../products/DiscountCustomPagination';
import CreateDiscountModal from './CreateDiscountModal';
import CreatedisCouponcollectionModal from './CreatedisCouponcollectionModal';
import DiscountCouponsFilter from './DiscountCouponsFilter';
import MarketingCouponModal from './MarketingCouponModal';
import StatisticsModal from './StatisticsModal';
import UpdateDiscountModal from './UpdateDiscountModal';


const DiscountCoupons = () => {

  const aditionalmenu = useRef(null);

  const [additionMenu, setAdditionMenu] = useState(false);

  const handleAdditionMenu = () => {
    setAdditionMenu(!additionMenu);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (aditionalmenu.current && !aditionalmenu.current.contains(event.target)) {
        setAdditionMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const [createcoupon, setCreatecoupon] = useState(false);

  const handleCreateCoupons = () => {
    setCreatecoupon(true);
  };

  const [updatecoupon, setUpdatecoupon] = useState(false);
  const [updatecouponid, setUpdatecouponid] = useState(null);

  const handleUpdateCoupons = (item) => {
    setUpdatecoupon(true);
    setUpdatecouponid(item);
  };

  const [createCollectioncoupon, setCreateCollectioncoupon] = useState(false);

  const handleCreateCollectionCoupon = () => {
    setCreateCollectioncoupon(true);
  };


  const [filtermenu, setFiltermenu] = useState(false);

  const handleFiltermenu = () => {
    setFiltermenu(true);
  };

  const [marketingCoupon, setMarketingCoupon] = useState(false);

  const handleMarketingCoupon = () => {
    setMarketingCoupon(true);
  };

  const [statisticsModal, setStatisticsModal] = useState(false);

  const handleStatisticsModal = () => {
    setStatisticsModal(true);
  };

  
  const resPerPage = 30;

  const [currentPage, setCurrentPage] = useState(1);
  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const {error, data, isLoading, refetch} = useCouponlistQuery({size: resPerPage, page: currentPage });

  const [switchStatus, setSwitchStatus] = useState({});

  useEffect(() => {
    if (data) {
      const initialStatus = data.data.reduce((acc, coupon) => {
        acc[coupon.id] = coupon.status === 'active';
        return acc;
      }, {});
      setSwitchStatus(initialStatus);
    }
  }, [data]);

  const handleSwitchChange = (id, nextChecked) => {
    // Update the switch status in the state
    setSwitchStatus((prevStatus) => ({
      ...prevStatus,
      [id]: nextChecked
    }));

  };

  return (
    <>
      <UpdateDiscountModal updatecoupon = { updatecoupon } setUpdatecoupon = { setUpdatecoupon } updatecouponid = { updatecouponid } refetch = { refetch } />
      <DiscountCouponsFilter filtermenu = { filtermenu } setFiltermenu = { setFiltermenu } />
      <CreateDiscountModal createcoupon = { createcoupon } setCreatecoupon = { setCreatecoupon } refetch = { refetch } />
      <MarketingCouponModal marketingCoupon = { marketingCoupon } setMarketingCoupon = { setMarketingCoupon } />
      <StatisticsModal statisticsModal = { statisticsModal } setStatisticsModal = { setStatisticsModal } />
      <CreatedisCouponcollectionModal createCollectioncoupon = { createCollectioncoupon } setCreateCollectioncoupon = { setCreateCollectioncoupon } />
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'w-full py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Discount coupons</span></li>
          </ul>
          <div className = 'flex justify-between mt-5'>
            <p className = 'text-sm cursor-pointer self-center' onClick = { handleFiltermenu }><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '22px'} } /></span>Filtering</p>
            <div className = 'relative'>
              <button onClick = { handleAdditionMenu } className = 'self-center inline-block pl-3 pr-4 py-2 bg-[#f0f3f3] rounded text-sm'><span><KeyboardArrowDownOutlinedIcon style = { {fontSize: '20px'} } /></span>  <span><AddOutlinedIcon style = { {fontSize: '20px'} } /> </span> addition</button>
              {
                additionMenu && <div
                  className = "absolute right-0 top-10 z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role = "menu"
                  aria-orientation = "vertical"
                  aria-labelledby = "menu-button"
                  tabIndex = "-1"
                  ref = { aditionalmenu }
                >
                  <div className = "py-1" role = "none">
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { handleCreateCoupons }><span className = 'text-secondary'><DiscountOutlinedIcon style = { {fontSize: '18px'} } /></span> New coupon</Link>
                    <Link to = "#" className = "text-blacklight block px-4 py-1.5 text-sm" onClick = { handleCreateCollectionCoupon }><span className = 'text-secondary'><DiscountOutlinedIcon style = { {fontSize: '18px'} } /></span> Coupon collection</Link> 
                  </div>
                </div>
              }
            </div>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-blacklight text-lg'><span><ConfirmationNumberOutlinedIcon style = { {fontSize: '20px'} } /></span>  Discount coupons</h3>
            <div className = 'mt-2'>
              <span className = 'bg-[#73e7cc] inline-block w-6 h-2 rounded-md'></span> <span className = 'text-sm text-secondary'>Enabled</span>
              <span className = 'bg-[#f7ca68] inline-block w-6 h-2 ml-2 rounded-md'></span> <span className = 'text-sm text-secondary'>Expired</span>
              <span className = 'bg-[#ddd] inline-block w-6 h-2 ml-2 rounded-md'></span> <span className = 'text-sm text-secondary'>closed</span>
            </div>
            <div className = 'custom-scroll mt-3'>
              <table className = "min-w-full leading-normal">
                <thead>
                  <tr>
                    <th
                      className = "px-5 py-3 bg-[#f0f3f3] text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                      Coupon address
                    </th>
                    <th
                      className = "px-5 py-3 bg-[#f0f3f3] text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                      Coupon start date
                    </th>
                    <th
                      className = "px-5 py-3 bg-[#f0f3f3] text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                     Coupon expiration date
                    </th>
                    <th
                      className = "px-5 py-3 bg-[#f0f3f3] text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                      Statistics
                    </th>
                    <th
                      className = "px-5 py-3 bg-[#f0f3f3] text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                    >
                     Switch
                    </th>     
                  </tr>
                </thead>
                <tbody>
                  { isLoading && <p>Loading...</p> }
                  { error && <p>An error occurred: { error?.data?.message }</p> }
                  {
                    data?.data?.map((item) => (
                      <tr key = { item?.id }>
                        <td className = "px-5 py-3 border-b border-borderColor bg-white text-sm">
                          <Link to = '#' className = 'text-sm text-blacklight'>
                            <span onClick = { () => handleUpdateCoupons(item?.id) }>
                              <input className = 'align-middle' type = 'checkbox' />
                              <span className = 'align-middle text-sm inline-block px-2'>{ item?.code }</span>
                            </span>
                            <span className = 'inline-block border border-boderColor rounded-full text-xs px-2' onClick = { handleMarketingCoupon }>Marketing <span><ShareOutlinedIcon style = { {fontSize: '16px'} } /></span></span>
                          </Link>
                        </td>
                        <td className = "px-5 py-3 border-b border-borderColor bg-white text-sm">
                          <p className = 'text-sm text-blacklight'>{ item?.start_date }</p>
                        </td>
                        <td className = "px-5 py-3 border-b border-borderColor bg-white text-sm">
                          <p className = "text-blacklight whitespace-no-wrap">{ item?.end_date }</p>
                        </td>
                        <td className = "px-5 py-3 border-b border-borderColor bg-white text-sm">
                          <Link className = "block text-blacklight whitespace-no-wrap text-sm" onClick = { handleStatisticsModal }><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                        </td>
                        <td className = "px-5 py-3 border-b border-borderColor bg-white text-sm">
                          <Switch
                            onChange = { (checked) => handleSwitchChange(item.id, checked) }
                            checked = { switchStatus[item.id] || false }
                            className = "react-switch"
                            onColor = "#474091"
                            width = { 40 }
                            height = { 20 }
                            handleDiameter = { 16 }
                          />
                        </td>
      
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <DiscountCustomPagination resPerPage = { resPerPage }
            filteredProductsCount = { data?.total || 0 }
            onChange = { setCurrentPageNo } />
        </div>
      </div>
    </>
  );
};

export default DiscountCoupons;
