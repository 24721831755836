import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../../components/Sidebar';
import TopHeader from '../../../components/TopHeader';
import SpecialofferFilter from './SpecialofferFilter';


const SpecialOffer = () => {

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const [filtermenu, setFiltermenu] = useState(false);

  const handleFiltermenu = () => {
    setFiltermenu(true);
  };

  return (
    <>
      <SpecialofferFilter filtermenu = { filtermenu } setFiltermenu = { setFiltermenu } />
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'w-full py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Special offers</span></li>
          </ul>
          <div className = 'flex justify-between mt-5'>
            <p className = 'text-sm cursor-pointer self-center' onClick = { handleFiltermenu }><span className = 'text-secondary'><FilterAltOutlinedIcon style = { {fontSize: '22px'} } /></span>Filtering</p>
            <div className = 'relative'>
              <Link to = '/specialoffer-create' className = 'self-center inline-block pl-3 pr-4 py-2 bg-[#f0f3f3] rounded text-sm'><span><AddOutlinedIcon style = { {fontSize: '20px'} } /> </span> Create a new view</Link>
            </div>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-blacklight text-lg'><span><ConfirmationNumberOutlinedIcon style = { {fontSize: '20px'} } /></span>   Special offers  </h3>
            
            <div className = 'custom-scroll mt-3'>
              <table className = "min-w-full leading-normal">
                
                <tbody>
                  <tr>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <div>
                        <Link to = '#' className = 'text-base text-blacklight'>Ramadan offers</Link>
                        <p className = 'text-sm text-secondary'>A percentage of the value of the customer’s purchases</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                      </div>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Link className = "block text-blacklight whitespace-no-wrap text-sm"><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </td>
                   
                  </tr>
                  <tr>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <div>
                        <Link to = '#' className = 'text-base text-blacklight'>Ramadan offers</Link>
                        <p className = 'text-sm text-secondary'>A percentage of the value of the customer’s purchases</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                      </div>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Link className = "block text-blacklight whitespace-no-wrap text-sm"><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </td>
                   
                  </tr>
                  <tr>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <div>
                        <Link to = '#' className = 'text-base text-blacklight'>National Day offers 2023</Link>
                        <p className = 'text-sm text-secondary'>A percentage of the value of the customer’s purchases</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                      </div>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Link className = "block text-blacklight whitespace-no-wrap text-sm"><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </td>
                   
                  </tr>
                  <tr>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <div>
                        <Link to = '#' className = 'text-base text-blacklight'>November offers</Link>
                        <p className = 'text-sm text-secondary'>A percentage of the value of the customer’s purchases</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                      </div>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Link className = "block text-blacklight whitespace-no-wrap text-sm"><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </td>
                   
                  </tr>
                  <tr>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <div>
                        <Link to = '#' className = 'text-base text-blacklight'>Establishment offers 2024</Link>
                        <p className = 'text-sm text-secondary'>A percentage of the value of the customer’s purchases</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                        <p className = 'text-xs text-secondary'><span><CalendarTodayOutlinedIcon style = { {fontSize: '14px'} } /></span> Starts on: 2023-04-04 00:00</p>
                      </div>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Link className = "block text-blacklight whitespace-no-wrap text-sm"><InsertChartOutlinedOutlinedIcon style = { {fontSize: '16px'} } /> statistics</Link>
                    </td>
                    <td className = "py-3 border-b border-borderColor bg-white text-sm">
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialOffer;
