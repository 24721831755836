import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const MarketingTool = () => {

  
  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Marketing tools</span></li>
          </ul>
          <div className = 'flex mt-5'>
            <p className = 'text-sm text-blacklight cursor-pointer'><span className = 'text-secondary'><BusinessCenterOutlinedIcon style = { {fontSize: '20px'} } /></span> Services</p>
            <Link to = '/marketing-settings' className = 'block text-sm text-blacklight cursor-pointer'><span className = 'text-secondary ml-8'><SettingsOutlinedIcon style = { {fontSize: '20px'} } /></span> Marketing settings</Link>
          </div>
          { /* <h3 className = 'text-lg font-medium text-secondary mt-6'>Discounts</h3> */ }
          <div className = 'flex justify-between mt-5'>
            <div className = 'w-width24%'>
              <Link to = '/coupons' className = 'block border border-borderColor p-5 rounded-md text-center'>
                <p><span className = 'text-secondary'> <ConfirmationNumberOutlinedIcon style = { {fontSize: '30px'} } /> </span></p>
                <h3 className = 'text-base font-medium'>Discount coupons</h3>
                <p className = 'text-sm text-secondary mt-2'>Create a coupon or group of coupons</p>
              </Link>
            </div>
            <div className = 'w-width24%'>
              <Link to = '/special-offer' className = 'block border border-borderColor p-5 rounded-md text-center'>
                <p><span className = 'text-secondary'> <DiscountOutlinedIcon style = { {fontSize: '30px'} } /> </span></p>
                <h3 className = 'text-base font-medium'>Special offers</h3>
                <p className = 'text-sm text-secondary mt-2'>Activate and manage offers and follow up on their statistics</p>
              </Link>
            </div>
            <div className = 'w-width24%'>
              <Link to = '#' className = 'block border border-borderColor p-5 rounded-md text-center'>
                <p><span className = 'text-secondary'> <CardGiftcardOutlinedIcon style = { {fontSize: '30px'} } /> </span></p>
                <h3 className = 'text-base font-medium'>Digital gifting</h3>
                <p className = 'text-sm text-secondary mt-2'>Offering the option of digital purchases</p>
              </Link>
            </div>
            <div className = 'w-width24%'>
              <Link to = '#' className = 'block border border-borderColor p-5 rounded-md text-center'>
                <p><span className = 'text-secondary'> <Diversity1OutlinedIcon style = { {fontSize: '30px'} } /> </span></p>
                <h3 className = 'text-base font-medium'>Customer loyalty</h3>
                <p className = 'text-sm text-secondary mt-2'>Win customers through loyalty points and rewards</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingTool;
