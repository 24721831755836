/* eslint-disable react/no-unescaped-entities */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ShipingTicket = () => {

  const [newcomplaintModal, setNewcomplaintModal] = useState(false);


  const onOpenNewcomplaintModal = () => setNewcomplaintModal(true);
  const onCloseNewcomplaintModal = () => setNewcomplaintModal(false);

  return(
    <>
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div>
            <button className = 'inline-block px-5 py-2 bg-[#f0f3f3] rounded' onClick = { onOpenNewcomplaintModal }>+ New complaint</button>
          </div>
          <div className = 'px-5 pt-6 pb-12 border border-borderColor rounded mt-3'>
            <div className = 'text-sm'><span>(0)</span><span className = 'text-lg'> Shipping complaints</span> <span><QuestionAnswerOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
            <div className = 'text-center mt-12'>
              <span className = 'text-secondary'><QuestionAnswerOutlinedIcon style = { {fontSize: '60px'} } /></span>
              <h3 className = 'text-xl text-blacklight font-medium'>There are no complaints</h3>
              <p className = 'text-secondary text-sm mt-2'>Address your shipping challenges by submitting a complaint to the shipping company in question</p>
            </div>
          </div>
        </div>
      </div>

      <Modal open = { newcomplaintModal } showCloseIcon = { false } onClose = { onCloseNewcomplaintModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Submit a new complaint</h3>
            <div className = 'self-center'><span onClick = { onCloseNewcomplaintModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <ul>
              <li>
                <label className = 'text-sm text-blacklight'>Waybill *</label>
                <div className = 'flex border border-borderColor rounded mt-1'>
                  <input placeholder = 'Search by waybill number...' className = 'w-width97% ml-3 text-sm outline-none' />
                  <button className = 'text-white bg-primary inline-block w-width3% py-1 px-5 rounded-e'><span className = 'relative right-3'><SearchOutlinedIcon style = { {fontSize: '25px'} } /></span></button>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between border border-borderColor rounded p-2'>
                  <div>
                    <span className = 'text-secondary'><LocalPhoneIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>Customer's mobile phone</span>
                  </div>
                  <div>
                    <span className = 'text-secondary'><PersonOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>Customer name</span>
                  </div>
                  <div>
                    <span className = 'text-secondary'><PolicyOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>Policy number</span>
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <div className = 'flex justify-between border border-borderColor rounded p-2'>
                  <div>
                    <span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>order number</span>
                  </div>
                  <div>
                    <span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>invoice number</span>
                  </div>
                  <div>
                    <span className = 'text-secondary'><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span>
                    <span className = 'text-secondary text-sm inline-block ml-2'>Shipping company</span>
                  </div>
                </div>
              </li>
              <li className = 'mt-8'>
                <div className = 'flex justify-between'>
                  <button className = 'inline-block text-sm bg-primary py-2 px-4 rounded text-white'>Submit a complaint</button>
                  <button className = 'inline-block text-sm bg-[#f0f3f3] py-2 px-4 rounded text-blacklight'>Exit</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>  
    </>
  );
};

export default ShipingTicket;