/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';



const options = [
  { label: 'Grapes', value: 'grapes' },
  { label: 'Mango', value: 'mango' },
  { label: 'Strawberry', value: 'strawberry', disabled: true }
];

const PaymentRecieving = ({paymentRecieving, setPaymentRecieving}) => {

  const [paymentRecievingModal, setpaymentRecievingModal] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {

    if(paymentRecieving == true){
      setpaymentRecievingModal(true);
    }else{
      setpaymentRecievingModal(false);
    }
    
  },[paymentRecieving]);

  const closePaymentRecieving = () => {
    setPaymentRecieving(false);
    setpaymentRecievingModal(false);
  };

  
  const [active, setActive] = useState(1);

  const handleActivefun = (item) => {
    setActive(item);
  };


  return(
    <>
      <Modal open = { paymentRecievingModal } showCloseIcon = { false } onClose = { closePaymentRecieving }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Cash on delivery terms</h3>
            <div className = 'self-center'><span onClick = { closePaymentRecieving } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <p className = 'text-sm text-blacklight'>You can specify the conditions that, when met, will <span className = 'text-[#F55157] font-semibold'>disable</span> the cash on delivery option on the store's payment page.
            </p>
            <div className = 'flex bg-[#edf4fe] border border-borderColor rounded py-2 px-5 justify-center mt-5'>
              <span className = 'text-primary self-center inline-block ml-5'><NotificationsNoneOutlinedIcon style = { {fontSize: '28px'} } /></span> <span className = 'text-primary text-sm self-center'>Note that activating the payment upon delivery option is done through the shipping company settings</span>
              
            </div>
            <div className = 'flex mt-5 border border-borderColor rounded'>
              <div className = { `w-width50% text-center cursor-pointer py-2 ${active == 1? 'bg-[#f0f3f3]': ''}` } onClick = { () => handleActivefun(1) }>
                <span className = 'text-blacklight'><FormatListBulletedOutlinedIcon style = { {fontSize: '20px'} } /></span>
                <button className = 'inline-block text-sm text-blacklight ml-1'>Cash on delivery terms</button>
              </div>
              <div className = { `w-width50% text-center cursor-pointer py-2 ${active == 2? 'bg-[#f0f3f3]': ''}` } onClick = { () => handleActivefun(2) }>
                <span className = 'text-blacklight'><TaskOutlinedIcon style = { {fontSize: '20px'} } /></span>
                <button className = 'inline-block text-sm text-blacklight ml-1'>Excluded from cash on delivery</button>
              </div>
            </div>
            {
              active == 1 && <>
                <ul className = 'mt-5'>
                  <li>
                    <h3 className = 'text-base text-blacklight font-medium'>Cash on delivery terms</h3>
                    <label className = 'text-xs text-secondary'>Minimum purchase (optional)</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3%'><span className = 'text-secondary'><AttachMoneyOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input placeholder = '20' className = 'inline-block text-sm outline-0 w-width97% ml-1' />
                      <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>SAR</span>
                    </div>

                    <label className = 'text-xs text-secondary inline-block mt-4'>Maximum purchase limit (optional)</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3%'><span className = 'text-secondary'><AttachMoneyOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input placeholder = '100' className = 'inline-block text-sm outline-0 w-width97% ml-1' />
                      <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>SAR</span>
                    </div>
                  </li>
                 
                  <li className = 'mt-3'>
                    <label className = 'text-sm text-blacklight'>Maximum weight limit for products in the basket (optional)</label>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'w-width3%'><span className = 'text-secondary'><InboxOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input placeholder = '10' className = 'inline-block text-sm outline-0 w-width97% ml-1' />
                      <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>Kg</span>
                    </div>
                  </li>
                  <li>
                    <div className = 'mt-5'>
                      <input type = 'checkbox' id = 'carts' className = 'align-middle' />
                      <label className = 'align-middle text-sm text-blacklight ml-2 cursor-pointer' htmlFor = 'carts'>
                        Free shipping applies to all products in the cart
                      </label>
                      <p className = 'text-xs text-secondary ml-5'>Cash on delivery on the purchase page will be hidden from customers who have a ban on other basket stores</p>
                    </div>
                  </li>
                  <li className = 'mt-6'>
                    <button className = 'inline-block text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                  </li>
                </ul>
              </>
            }

            {
              active == 2 && <>
                <ul className = 'mt-5'>
                  <li>
                    <h3 className = 'text-base text-blacklight font-medium'>Excluded products</h3>
                    <label className = 'text-sm text-secondary'>Products excluded from cash on delivery (optional)</label>
                    <MultiSelect
                      options = { options }
                      value = { selected }
                      onChange = { setSelected }
                      labelledBy = "Select"
                    />
                  </li>
                  <li className = 'mt-3'>
                    <label className = 'text-base text-blacklight inline-block font-semibold mt-2'>Exceptional Classifications</label>
                    <p className = 'text-sm text-blacklight mt-1'>Categories excluded from cash on delivery (optional)</p>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-2'>
                      <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><InboxOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <select className = 'inline-block text-sm outline-0 w-width97% text-secondary'>
                        <option>Choose excluded categories</option>
                        <option>Perfumes section</option>
                        <option>Appliances</option>
                        <option>Abdullah</option>
                        <option>Devices</option>
                      </select>
                    </div>
                  </li>
                  <li className = 'mt-6'>
                    <button className = 'inline-block text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                  </li>
                </ul>
              </>
            }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentRecieving;