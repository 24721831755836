import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import TopHeader from '../../../components/TopHeader';


const LoyaltySystem = () => {

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'w-full py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Loyalty system</span></li>
          </ul>
         
          <div className = 'border border-borderColor rounded p-4 mt-5 relative'>
            <div className = 'bg-[#fec529] inline-block py-2 px-5 rounded-r-md w-48 text-right absolute top-10 left-0'>
              <h3 className = 'text-black text-base font-medium'> 299 ر.س/شهريا</h3>
            </div>
            <div className = 'text-center mt-6'>
              <h3 className = 'text-black text-base'>Loyalty system</h3>
              <p className = 'text-base text-blacklight'>Promote your store in a way that your customers will love</p>
            </div>
            <div className = 'flex justify-between mt-8'>
              <div className = 'w-width49% mt-5'>
                <p className = 'text-base text-blacklight'>An integrated loyalty system for your store that offers your customers points that they can exchange for rewards specified by you, in exchange for purchases, evaluating the store, completing the profile, and sharing your store link to their family and friends.</p>
                <p className = 'text-base text-blacklight'>Customers can exchange points for one of your products, discount coupons, or get free shipping, with full control from you over the number of points required for each reward, how to collect them, and their validity period, with detailed reports that show how your Loyalty customers invest points, collect the most points, etc.</p>
                <button className = 'text-sm text-white rounded bg-primary py-2 px-5 block w-3/5 mt-5'>Subscribe</button>
              </div>
              <div className = 'w-width49%'>
                <div className = 'text-center'>
                  <div className = 'bg-white shadow-lg inline-block py-1.5 px-2 rounded'>
                    <h3 className = 'text-blacklight text-base'>3X more sales 🤩 <span className = 'text-blacklight'><SsidChartIcon style = { {fontSize: '20px'} } /></span></h3>
                  </div>
                  <div>
                    <img className = 'w-3/4' src = 'https://cdn.assets.salla.network/dash/cp/assets/images/loyal-system.svg' alt = 'leftimg' />
                  </div>
                </div>
              </div>
            </div>
            <div className = 'flex gap-4 mt-10'>
              <div className = 'w-width24% bg-white border border-borderColor rounded p-4'>
                <div className = 'flex justify-between'>
                  <h3 className = 'text-blacklight text-base'>Boost your customer loyalty</h3>
                  <p><span className = 'text-blacklight'><Diversity1OutlinedIcon style = { {fontSize: '25px'} } /></span></p>
                </div>
                <p className = 'text-sm text-secondary mt-3'>Offer rewards to your customers that encourage them to continue purchasing from your store</p>
              </div>
              <div className = 'w-width24% bg-white border border-borderColor rounded p-4'>
                <div className = 'flex justify-between'>
                  <h3 className = 'text-blacklight text-base'>Complete control</h3>
                  <p><span className = 'text-blacklight'><TuneOutlinedIcon style = { {fontSize: '25px'} } /></span></p>
                </div>
                <p className = 'text-sm text-secondary mt-3'>Control all the details of the loyalty system, its rewards and notifications</p>
              </div>
              <div className = 'w-width24% bg-white border border-borderColor rounded p-4'>
                <div className = 'flex justify-between'>
                  <h3 className = 'text-blacklight text-base'>Detailed reports</h3>
                  <p><span className = 'text-blacklight'><TextSnippetOutlinedIcon style = { {fontSize: '25px'} } /></span></p>
                </div>
                <p className = 'text-sm text-secondary mt-3'>Detailed reports of points and prizes to facilitate your management of the loyalty system</p>
              </div>
              <div className = 'w-width24% bg-white border border-borderColor rounded p-4'>
                <div className = 'flex justify-between'>
                  <h3 className = 'text-blacklight text-base'>Automatic reminders</h3>
                  <p><span className = 'text-blacklight'><NotificationsNoneOutlinedIcon style = { {fontSize: '25px'} } /></span></p>
                </div>
                <p className = 'text-sm text-secondary mt-3'>Remind your customers to benefit from their loyalty points automatically</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyaltySystem;
