import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const addcategoryApi = createApi({
  reducerPath: 'addcategoryApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    categoryelist: builder.query({
      query: () => '/store/admin/list-categories/'
    }),
    addategory: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/create-categories/',
          method: 'POST',
          body
        };
      }
    }),
    updatecategory: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/update-categories/${id}/`,
          method: 'PUT',
          body
        };
      }
    }),
    deletecategory: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/delete-categories/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    getcategorybyid: builder.query({
      query: (id) => `/store/admin/retrieve-categories/${id}/`
    }),
    permission: builder.mutation({
      query({body, id}) {
        return {
          url: `/store/admin/product-category/${id}/`,
          method: 'POST',
          body
        };
      }
    })
  })
});

export const { useCategoryelistQuery, useAddategoryMutation, useUpdatecategoryMutation, useDeletecategoryMutation, useGetcategorybyidQuery, usePermissionMutation } = addcategoryApi;
