/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useGetbannerbyidQuery, useUpdatebannerMutation } from '../../redux/api/bannerApi';

const EditbannerModal = ({ EditBannerProduct, currentBanner, setEditBannerProduct, refetch }) => {
  const [url, setUrl] = useState('');
  const [redirecturl, setRedirectUrl] = useState('');
  const [mobileimgurl, setMobileimgurl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');

  const [updatebanner, { error: updatebannererror, isSuccess: updatebannerisSuccess, isLoading: updatebannerisloading }] = useUpdatebannerMutation();

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleMobileimgUrlChange = (e) => {
    setMobileimgurl(e.target.value);
    setErrorMessge('');
  };

  const handleRedirecturlChange = (e) => {
    setRedirectUrl(e.target.value);
    setErrorMessge('');
  };

  useEffect(() => {
    if (updatebannerisSuccess) {
      setErrorMessge('');
      setEditBannerProduct(false);
      refetch();
    }
  }, [updatebannerisSuccess]);

  const handleUpdateSubmit = async () => {
    if (!url || !redirecturl) {
      setErrorMessge('Please provide both Image url and Redirect url.');
      return;
    }

    const formData = new FormData();
    formData.append('title', null);
    formData.append('image_url', url);
    formData.append('mobile_image_url', mobileimgurl);
    formData.append('banner_render_url', redirecturl);
    formData.append('image', '');

    try {
      await updatebanner({ body: formData, id: currentBanner?.id });
    } catch (error) {
      setErrorMessge('Failed to update banner: ', error);
    }
  };

  const { error: getbannerError, data: getbannerdata, isLoading: getbannerisloading } = useGetbannerbyidQuery(currentBanner?.id);

  useEffect(() => {
    if (getbannerdata) {
      setUrl(getbannerdata?.data?.image_url);
      setRedirectUrl(getbannerdata?.data?.banner_render_url);
      setMobileimgurl(getbannerdata?.data?.mobile_image_url);
    }
  }, [getbannerdata, currentBanner?.id]);

  return (
    <>
      <Modal
        open = { EditBannerProduct }
        showCloseIcon = { false }
        onClose = { () => setEditBannerProduct(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Edit banner
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setEditBannerProduct(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { getbannerError && <p className = 'text-sm text-red'>{ getbannerError?.data?.message }</p> }
            { getbannerisloading && <p className = 'text-sm'>{ getbannerisloading ? 'Banner data loading...' : '' }</p> }
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
              Image url
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { url }
                onChange = { handleUrlChange }
              />

              <label className = "text-blacklight text-sm self-center block mt-3">
              Mobile image url
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { mobileimgurl }
                onChange = { handleMobileimgUrlChange }
              />
              { updatebannererror && (
                <div className = "mt-2 text-red text-sm">
                  { updatebannererror?.data?.message?.mobile_image_url }
                </div>
              ) }
              <label className = "text-blacklight text-sm self-center block mt-3">
                Redirect url
              </label>
              <input
                placeholder = "Enter redirect url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { redirecturl }
                onChange = { handleRedirecturlChange }
              />
              { updatebannererror && (
                <div className = "mt-2 text-red text-sm">
                  { updatebannererror?.data?.message?.image_url }
                </div>
              ) }
              { updatebannererror && (
                <div className = "mt-2 text-red text-sm">
                  { updatebannererror?.data?.message?.banner_render_url }
                </div>
              ) }
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatebannerisloading }
              >
                { updatebannerisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditbannerModal;
