/* eslint-disable react/no-unescaped-entities */

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLoginMutation } from '../redux/api/authApi';

const Login = () => {
  const token = localStorage.getItem('token');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
 
  const navigate = useNavigate();
  const [ login, {isLoading, error, data} ] = useLoginMutation();

  useEffect(() => {

    if(error){
      toast(error?.data?.message);
    }
    if(token){
      toast(data?.message);
      setTimeout(() => {
        navigate('/');
      },2000);
    }
  },[error, data,token]);

  const LoginHandler = (e) => {
    e.preventDefault();

    const loginData = {
      email,
      password
    };

    login(loginData);
  };

  const handleEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail);
  
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(trimmedEmail);
  
    setErrorEmail(isValidEmail ? '' : '');
    setIsEmailValid(isValidEmail);
  };
  
  const handleEmailBlur = () => {
    if (!isEmailValid && email.trim()) {
      setErrorEmail('Please enter a valid email');
    }
  };
  

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  


  useEffect(() => {
    document.title = 'Login';
  }, []);

  const isFormValid = isEmailValid && password;


  return (
    <>
      <ToastContainer />
      <div className = 'lg:bg-grey-50'>
        <div>
          <div className = 'flex py-11 lg:py-0 w-full min-h-screen mx-auto box-border justify-center'>
            <div className = 'w-6/12 bg-purple hidden lg:flex justify-end items-center'>
              <img className = 'w-width72% pt-10' src = 'https://cdn.salla.sa/KVlQp/4oA7jtVyZCsqf1LG9ckyhqR117YySPPwSFyjPZ8V.png' alt = 'perfume' />
            </div>
            <div className = 'lg:bg-white box-border w-full lg:w-6/12 lg:pl-20 lg:pr-12 flex items-center'>
              <div className = 'w-11/12 mx-auto lg:mx-0 md:w-3/5'>
                <Link to = "/" className = 'block'>
                  <img className = 'w-60 mx-auto' src = "https://cdn.salla.sa/KVlQp/YWFUbETQIUmVmzxQbje7oanjayllgiRNbpFOhKb3.png" alt = 'logo'/>
                </Link>
                <h3 className = { 'text-2xl font-bold text-black text-center pt-4' }>Login</h3>
                <form onSubmit = { LoginHandler }>
                  <ul>
         
                    <li className = 'pt-8 lg:pt-2.5'>
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800'  }>Email</label>
                      <input className = { `block w-full border border-grey rounded-md px-4 py-2 text-sm ${errorEmail?'outline-red':'outline-primary-600'}` } onChange = { handleEmail }  onBlur = { handleEmailBlur } value = { email } placeholder = 'Enter email' />
                      { errorEmail && <p className = "text-xs text-red">{ errorEmail }</p> }
                    </li>
                    <li className = 'relative pt-6 lg:pt-4'>
                      <label className = { 'text-sm text-grey-500 lg:text-grey-800' }>Password</label>
                      <input
                        onChange = { handlePasswordChange }
                        value = { password }
                        type = { showPassword ? 'text' : 'password' }
                        className = { 'block w-full border border-grey rounded-md px-4 py-2 text-sm outline-primary-600' }
                        placeholder = 'Enter password'
                      />
                      { password && <a href = 'javascript:void(0)' className = 'block absolute bottom-2 right-3.5 w-4' onClick = { toggleShowPassword }>{ showPassword? <VisibilityOffOutlinedIcon className = 'eyecolor' /> : <VisibilityOutlinedIcon className = 'eyecolor' /> }</a> }
                    </li>
                    <li><div className = 'text-right py-4'><Link className = { 'text-sm text-primary font-bold' } to = "/forgot-password">Forgot Password</Link></div></li>
                    <li>
                      <button type = 'submit' className = { `block w-full text-white rounded-3xl lg:rounded-md px-4 py-2.5 text-sm  ${isEmailValid && password? 'bg-gradient-primary': 'bg-gradient-primary-light'}` } disabled = { !isFormValid || isLoading }>
                        { isLoading?'Authenticating...' : 'Login' }
                      </button>
                    </li>
                  </ul>
                </form>
                <ul>
                  <li>
                    <p className = { 'text-sm text-grey text-center pt-4' }>Don't have an account? <Link className = 'text-primary font-bold' to = "/signup">Sign up</Link></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
