import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';


const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const catlogfragranceApi = createApi({
  reducerPath: 'catlogfragranceApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fragrancelist: builder.query({
      query: () => '/store/admin/fragrance/'
    }),
    addfragrancelist: builder.mutation({
      query(body) {
        return {
          url: '/store/admin/fragrance/',
          method: 'POST',
          body
        };
      }
    }),
    deletefragrance: builder.mutation({
      query(id) {
        return {
          url: `/store/admin/fragrance-delete/${id}/`,
          method: 'DELETE'
        };
      }
    }),
    catloglist: builder.query({
      query: ({size, search}) => {
        let url = `/store/admin/list-categories/?size=${size}`;
        if(search){
          url += `&search=${search}`;
        }
        return url;
      }
    })
  })
});

export const { useFragrancelistQuery, useAddfragrancelistMutation, useDeletefragranceMutation, useCatloglistQuery } = catlogfragranceApi;
