/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Slider from 'r-range-slider';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';



const ShippingRateCalculator = ({shippingRate,setShippingRate}) => {

  const [shippingRateModal, setShippingRateModal] = useState(false);

  useEffect(() => {

    if(shippingRate == true){
      setShippingRateModal(true);
    }else{
      setShippingRateModal(false);
    }
    
  },[shippingRate]);

  const closeShippingRate = () => {
    setShippingRateModal(false);
    setShippingRate(false);
  };

  const [value, setValue] = useState(50);



  return(
    <>
      <Modal open = { shippingRateModal } showCloseIcon = { false } onClose = { closeShippingRate }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Shipping rates calculator</h3>
            <div className = 'self-center'><span onClick = { closeShippingRate } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <ul>
              <li>
                <label>Shipping address details</label>
                <div className = 'flex justify-between mt-1.5'>
                  <div className = 'flex w-width32% border border-borderColor px-2 py-1 rounded'>
                    <div className = 'w-width8% self-center'><span className = 'text-secondary align-text-bottom'><PlaceOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width92% text-secondary '>
                      <option>Select city</option>
                      <option>Riyadh</option>
                      <option>grandmother</option>
                      <option>Mecca</option>
                    </select>
                  </div>
                  <div className = 'flex w-width32% border border-borderColor px-2 py-1 rounded'>
                    <div className = 'w-width8% self-center'><span className = 'text-secondary align-text-bottom'><PlaceOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width92% text-secondary '>
                      <option>Select country</option>
                      <option>Saudi Arabia</option>
                      <option>The UAE</option>
                      <option>Kuwait</option>
                    </select>
                  </div>
                  <div className = 'flex w-width32% border border-borderColor px-2 py-1 rounded'>
                    <div className = 'w-width8% self-center'><span className = 'text-secondary align-text-bottom'><GroupOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width92% text-secondary '>
                      <option>Select shipping company</option>
                      <option>DHL Express</option>
                    </select>
                  </div>
                </div>
              </li>
              <li className = 'mt-5'>
                <Slider
                  start = { 0 }
                  end = { 100 }
                  points = { [value] }
                  onChange = { (points) => setValue(points[0]) }
                  direction = 'left'
                  valueStyle = { () => {
                    return {
                      background:'#ccc',
                      width:36,
                      top:-22,
                      height:36,
                      padding:0,
                      border:'2px solid #ddd',
                      borderRadius:'100%',
                      display:'flex',
                      alignItems:'center',
                      justifyContent:'center'
                    };
                  } }
                /> 

                <div className = 'w-3/5 mx-auto bg-[#f0f3f3]'>
                  <div className = 'flex justify-between px-5 py-2'>
                    <div>
                      <p><span className = 'text-secondary'><TableRowsOutlinedIcon style = { {fontSize: '20px'} } /></span><span className = 'text-sm'> The price:</span> 0 SAR</p>
                    </div>
                    <div>
                      <p><span className = 'text-secondary'><ScaleOutlinedIcon style = { {fontSize: '20px'} } /></span><span> The weight:</span> 0 Kg</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className = 'flex bg-[#fff7ec] border border-borderColor rounded py-2 px-5 justify-center mt-5'>
                  <span className = 'text-red text-sm self-center'>The above cost depends on the weight only and may vary upon shipping depending on the volumetric weight of the shipment, the dimensions of the shipment (height - width - length). For more, click here.</span>
                  <span className = 'text-red self-center inline-block ml-5'><WarningAmberOutlinedIcon style = { {fontSize: '28px'} } /></span>
                </div>
              </li>
              <li className = 'mt-6'>
                <button className = 'inline-block text-sm bg-[#f0f3f3] py-2 px-4 rounded text-blacklight'>Save</button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShippingRateCalculator;