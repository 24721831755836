/* eslint-disable react/no-unescaped-entities */

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const PaymentInvoices = () => {

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><Link to = "/wallet" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Portfolio</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Tax invoices</span></li>
          </ul>
          <div className = 'border border-borderColor rounded mt-10'>
            <div className = 'p-5 flex justify-between'>
              <h3 className = 'text-base font-medium text-blacklight'><span><DescriptionOutlinedIcon style = { {fontSize: '24px'} } /></span> Purchase invoices</h3>
              
            </div>
            <div className = 'activatesubscriptions custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>invoice number</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>the total</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>Invoice date</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>Print</div>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default PaymentInvoices;