import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ManageFilter = () => {
  const [switches, setSwitches] = useState({
    activateFilters: false,
    showSearchFilters: false,
    showCategoryFilters: false,
    showCategories: false,
    showBrands: false,
    showPrice: false,
    showRating: false,
    showProductOptions: false
  });
  
  const filtername = [
    'Activate the filters feature in the store',
    'Show filters on the search results page',
    'Show filters on the category page',
    'Show categories as filter',
    'Show brands as filter',
    'Show price as filter',
    'Show rating as filter',
    'Show product options as filters'
  ];

  const handleChange = (switchName) => {
    setSwitches(prevState => ({
      ...prevState,
      [switchName]: !prevState[switchName]
    }));
  };

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "/product" className = 'text-blacklight'>Products</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>Product filters</span></li>
            </ul>
            <p className = 'text-sm text-blacklight' ><span className = 'text-secondary align-text-bottom'><FilterAltOutlinedIcon style = { { fontSize: '20px' } } /></span> Filter settings and filters</p>
          </div>
          <div className = 'border border-borderColor rounded mt-5 px-5 py-5'>
            <ul>
              { Object.keys(switches).map((key, index) => (
                <li key = { index } className = 'mt-5'>
                  <div className = 'flex justify-between'>
                    <div>
                      <h3 className = 'text-sm text-blacklight font-medium'>{ filtername[index] }</h3>
                    </div>
                    <div>
                      <Switch
                        onChange = { () => handleChange(key) }
                        checked = { switches[key] }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </div>
                  </div>
                </li>
              )) }


            </ul>
          </div>
        </div>
      </div>

    </>
  );
};

export default ManageFilter;
