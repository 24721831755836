import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Chart, registerables } from 'chart.js';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
Chart.register(...registerables);

const Appdetails = () => {

  return (
    <>
     
      <Header />
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <div>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "#" className = 'text-blacklight'>Store settings</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>Shipping and delivery options</span></li>
            </ul>

            <div className = 'flex'>
              <div className = 'w-width30%'>
                <div className = 'bg-[#f0f3f3] rounded-md p-3'>
                  <button>stabilizing</button>
                </div>
              </div>
              <div className = 'w-width66%'>two</div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default Appdetails;