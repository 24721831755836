import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Privateroute from './components/PrivateRoute';
import PrivateRouteLogin from './components/PrivateRouteLogin';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import BasketPolicy from './pages/Shiping/BasketPolicy';
import Shiping from './pages/Shiping/Shiping';
import ShipingTicket from './pages/Shiping/ShipingTicket';
import ShippingHistory from './pages/Shiping/ShippingHistory';
import SignUp from './pages/Signup';
import Addcategory from './pages/addcategory/Addcategory';
import Banner from './pages/bannermanagement/Banner';
import Appdetails from './pages/basketappStore/Appdetails';
import BasketappStore from './pages/basketappStore/BasketappStore';
import Category from './pages/categories/Category';
import Customers from './pages/customers/Customers';
import EditCustomer from './pages/customers/EditCustomer';
import ViewCustomer from './pages/customers/ViewCustomer';
import CatlogFragrance from './pages/fragrance/CatlogFragrance';
import Scentfragrance from './pages/fragrance/Scentfragrance';
import Creategiftcard from './pages/giftcards/Creategiftcard';
import ManageReviews from './pages/managereview/ManageReviews';
import DiscountCoupons from './pages/marketingtool/DiscountCoupons';
import MarketingSettings from './pages/marketingtool/MarketingSettings';
import MarketingTool from './pages/marketingtool/MarketingTool';
import LoyaltySystem from './pages/marketingtool/loyalty/LoyaltySystem';
import LoyaltySystemForm from './pages/marketingtool/loyalty/LoyaltySystemForm';
import CreateSpecialoffer from './pages/marketingtool/specialoffer/CreateSpecialoffer';
import SpecialOffer from './pages/marketingtool/specialoffer/SpecialOffer';
import Addnavigation from './pages/navigation/Addnavigation';
import Newarrival from './pages/newarrivals/Newarrival';
import CreateOrder from './pages/order/CreateOrder';
import Order from './pages/order/Order';
import OrderDetails from './pages/order/OrderDetails';
import OrderEdit from './pages/order/OrderEdit';
import OrdersPrint from './pages/order/OrdersPrint';
import OrdersPrintSummary from './pages/order/OrdersPrintSummary';
import OrdersbookingReservations from './pages/order/OrdersbookingReservations';
import ArrangeProducts from './pages/products/ArrangeProducts';
import Brands from './pages/products/Brands';
import ManageFilter from './pages/products/ManageFilter';
import Product from './pages/products/Product';
import ProductClassifications from './pages/products/ProductClassifications';
import ProductInventory from './pages/products/ProductInventory';
import ProductsQuantityEditor from './pages/products/ProductsQuantityEditor';
import ProductsbulkPricing from './pages/products/ProductsbulkPricing';
import Productsbulkeditor from './pages/products/Productsbulkeditor';
import RecordQuantities from './pages/products/RecordQuantities';
import Report from './pages/reports/Report';
import CreateSaleoffers from './pages/saleoffers/CreateSaleoffers';
import Testimonials from './pages/testimonial/Testimonials';
import TrendingNow from './pages/trendingnow/TrendingNow';
import CodTransactionHistory from './pages/walletbills/CodTransactionHistory';
import ElectronicPayments from './pages/walletbills/ElectronicPayments';
import Marketplaceinvoices from './pages/walletbills/Marketplaceinvoices';
import PaymentInvoices from './pages/walletbills/PaymentInvoices';
import WalletBills from './pages/walletbills/WalletBills';
import WalletTransactions from './pages/walletbills/WalletTransactions';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path = '' element = { <Privateroute /> }>
            <Route path = '/' element = { <Dashboard /> } />
            <Route path = '/dashboard' element = { <Dashboard /> } />
            <Route path = '/products-bulk-editor' element = { <Productsbulkeditor /> } />
            <Route path = '/products-quantity-editor' element = { <ProductsQuantityEditor /> } />
            <Route path = '/product-classifications' element = { <ProductClassifications /> } />
            <Route path = '/brands' element = { <Brands /> } />
            <Route path = '/manage-filter' element = { <ManageFilter /> } />
            <Route path = '/arrange-products' element = { <ArrangeProducts /> } />
            <Route path = '/products-bulk-pricing' element = { <ProductsbulkPricing /> } />
            <Route path = '/product-inventory' element = { <ProductInventory /> } />
            <Route path = '/products-log' element = { <RecordQuantities /> } />
            <Route path = '/order/:id' element = { <OrderDetails /> } />
            <Route path = '/order-edit' element = { <OrderEdit /> } />
            <Route path = '/create-order' element = { <CreateOrder /> } />
            <Route path = '/shipment-history' element = { <BasketPolicy /> } />
            <Route path = '/shipping' element = { <Shiping /> } />
            <Route path = '/shipping-history' element = { <ShippingHistory /> } />
            <Route path = '/shipping/ticket' element = { <ShipingTicket /> } />
            <Route path = '/order' element = { <Order /> } />
            <Route path = '/product' element = { <Product /> } />
            <Route path = '/customers' element = { <Customers /> } />
            <Route path = '/edit-customer' element = { <EditCustomer /> } />
            <Route path = '/view-customer/:id' element = { <ViewCustomer /> } />
            <Route path = '/wallet' element = { <WalletBills /> } />
            <Route path = '/marketplace/invoices' element = { <Marketplaceinvoices /> } />
            <Route path = '/payment/invoices' element = { <PaymentInvoices /> } />
            <Route path = '/wallet/transactions' element = { <WalletTransactions /> } />
            <Route path = '/cod-transactions' element = { <CodTransactionHistory /> } />
            <Route path = '/payment/history' element = { <ElectronicPayments /> } />
            <Route path = '/orders-booking-reservations' element = { <OrdersbookingReservations /> } />
            <Route path = '/order' element = { <Order /> } />
            <Route path = '/order-print-summary' element = { <OrdersPrintSummary /> } />
            <Route path = '/order-print' element = { <OrdersPrint /> } />
            <Route path = '/reports' element = { <Report /> } />
            <Route path = '/marketing-tool' element = { <MarketingTool /> } />
            <Route path = '/coupons' element = { <DiscountCoupons /> } />
            <Route path = '/marketing-settings' element = { <MarketingSettings /> } />
            <Route path = '/special-offer' element = { <SpecialOffer /> } />
            <Route path = '/specialoffer-create' element = { <CreateSpecialoffer /> } />
            <Route path = '/loyalty-system-form' element = { <LoyaltySystemForm /> } />
            <Route path = '/loyalty-system' element = { <LoyaltySystem /> } />
            <Route path = '/basket/app-store' element = { <BasketappStore /> } />
            <Route path = '/app/details' element = { <Appdetails /> } />
            <Route path = '/banner' element = { <Banner /> } />
            <Route path = '/testimonials' element = { <Testimonials /> } />
            <Route path = '/categories' element = { <Category /> } />
            <Route path = '/gift-cards' element = { <Creategiftcard /> } />
            <Route path = '/sale-offers' element = { <CreateSaleoffers /> } />
            <Route path = '/fragrance-category' element = { <CatlogFragrance /> } />
            <Route path = '/scent-family' element = { <Scentfragrance /> } />
            <Route path = '/add-category' element = { <Addcategory /> } />
            <Route path = '/add-navigation' element = { <Addnavigation /> } />
            <Route path = '/manage-reviews' element = { <ManageReviews /> } />
            <Route path = '/trending-now' element = { <TrendingNow /> } />
            <Route path = '/new-arrival' element = { <Newarrival /> } />
          </Route>
          <Route path = '' element = { <PrivateRouteLogin /> }>
            <Route path = '/signUp' element = { <SignUp /> } />
            <Route path = '/login' element = { <Login /> } />
          </Route>
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;
