/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const BrandExclude = ({handleChangebrand, selectedBrandOption, brandlist}) => {


  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedBrandOption }
        onChange = { handleChangebrand }
        options = { brandlist }
        isMulti
        placeholder = 'Choose brand'
      />
    </>
  );
};

export default BrandExclude;