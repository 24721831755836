/* eslint-disable react/prop-types */

import { Transition } from '@headlessui/react';
import React, { useState } from 'react';

const SpecialofferFilterAccordian = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return(
    <div className = "w-full">
      <div
        className = "flex justify-between items-center bg-gray-200 cursor-pointer border-b border-boderColor-300"
        onClick = { toggleAccordion }
      >
        <div className = "text-lg self-center mt-1 flex-1">{ question }</div>
        <div className = "text-2xl self-center text-secondary">{ isOpen ? '-' : '+' }</div>
      </div>
      <Transition
        show = { isOpen }
        enter = "transition-opacity duration-300"
        enterFrom = "opacity-0"
        enterTo = "opacity-100"
        leave = "transition-opacity duration-300"
        leaveFrom = "opacity-100"
        leaveTo = "opacity-0"
      >
        <div className = "bg-white">
          <p className = "text-gray-700">{ answer }</p>
        </div>
      </Transition>
    </div>
  );
};

export default SpecialofferFilterAccordian;