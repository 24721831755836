
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PhotoAlbumOutlinedIcon from '@mui/icons-material/PhotoAlbumOutlined';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import Customers from './Customers';
import MostSearched from './MostSearched';
import PaymentShipping from './PaymentShipping';
import ReportProduct from './ReportProduct';
import ReportVisits from './ReportVisits';
import SalesChannels from './SalesChannels';
import Themostwanted from './Themostwanted';

const Report = () => {
  const [value, setValue] = useState(new Date());
  const [reportActive, setReportActive] = useState(1);

  const handleReportActive = (item) => {

    setReportActive(item);
  };


  const sliderRef = useRef(null);

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5
  };

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Reports</span></li>
          </ul>
          <div className = 'flex justify-between mt-3'>
            <div className = 'self-center'>
              <button className = 'border border-borderColor rounded bg-white text-blacklight inline-block py-1.5 px-3 text-sm'><LocalPrintshopOutlinedIcon style = { {fontSize: '20px'} } /> Print</button>
              <button className = 'border border-borderColor rounded bg-white text-blacklight inline-block py-1.5 px-3 text-sm ml-1.5'><OpenInNewOutlinedIcon style = { {fontSize: '20px'} } /> Export</button>
            </div>
            <div className = 'self-center'>
              <ul className = 'flex border border-borderColor rounded bg-white'>
                <li className = 'self-center'>
                  <button className = ' text-blacklight inline-block py-1.5 px-3 text-sm'>
                    <DatePicker value = { value } onChange = { setValue } multiple /> 
                    <ExpandMoreOutlinedIcon style = { {fontSize: '20px'} } /></button>  
                </li>
                <li className = 'text-secondary self-center'>|</li>
                <li className = 'self-center'>
                  <button className = ' text-blacklight inline-block py-1.5 px-3 text-sm'><EventNoteOutlinedIcon style = { {fontSize: '20px'} } />Report date</button>  
                </li>
              </ul>
            </div>
          </div>
          <div className = 'border border-borderColor rounded bg-white p-4 mt-5 mb-5'>
            <div className = 'flex justify-between pb-4'>
              <div>
                <h3 className = 'text-lg text-blacklight font-medium'>Report type</h3>
              </div>
              <div>
                <button onClick = { goToPrevSlide } className = 'text-secondary cursor-pointer'><KeyboardArrowLeftOutlinedIcon style = { {fontSize: '30px'} } /></button>
                <button onClick = { goToNextSlide } className = 'text-secondary cursor-pointer'><KeyboardArrowRightOutlinedIcon style = { {fontSize: '30px'} } /></button>
              </div>
            </div>
            <div className = 'pb-5'>
              <Slider ref = { sliderRef } { ...settings }>
                <div className = { `border ${reportActive == 1?'border-primary': 'border-borderColor'} rounded bg-white text-center` } onClick = { () => handleReportActive(1) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>the sales</span> <span><AccountBalanceWalletOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 2?'border-primary' : 'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(2) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Products</span> <span><PhotoAlbumOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 3?'border-primary' : 'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(3) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Customers</span> <span><PeopleAltOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 4?'border-primary':'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(4) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Visits</span> <span><InsertLinkOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 5?'border-primary':'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(5) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>the most wanted</span> <span><PhotoAlbumOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 6?'border-primary': 'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(6) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Most searched</span> <span><PhotoAlbumOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 7?'border-primary': 'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(7) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Payment and shiping</span> <span><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>
                <div className = { `border ${reportActive == 8?'border-primary':'border-borderColor'} rounded bg-white text-center` }  onClick = { () => handleReportActive(8) }>
                  <h3 className = 'cursor-pointer text-sm text-blacklight py-2 font-medium'><span>Sales channels</span> <span><AccountBalanceWalletOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
                </div>

              </Slider>
            </div>
            {
              reportActive == 1 && <div>
                <h3 className = 'text-base text-blacklight font-medium pb-2'>Choose the subreport</h3>
                <div className = 'flex justify-between'>
                  <select className = 'flex-1 border border-borderColor rounded text-sm text-blacklight mr-3 outline-none px-3'>
                    <option>Product sales</option>
                    <option>Categories sales</option>
                    <option>Brand sales</option>
                    <option>Code sales</option>
                    <option>Discount coupon sales</option>
                    <option>Cities sales</option>
                    <option>Product requests</option>
                    <option>Sponsorship projects</option>
                    <option>summary</option>
                  </select>
                  <button className = 'inline-block px-8 py-1.5 bg-[#f0f3f3] rounded text-sm text-blacklight'>an offer</button>
                </div>
              </div>
            }
            {
              reportActive == 2 && <div>
                <h3 className = 'text-base text-blacklight font-medium pb-2'>Choose the subreport</h3>
                <div className = 'flex justify-between'>
                  <select className = 'flex-1 border border-borderColor rounded text-sm text-blacklight mr-3 outline-none px-3'>
                    <option>The most profitable products</option>
                    <option>Abandoned baskets</option>
                    <option>Notify me when available</option>
                    <option>Best selling products</option>
                  </select>
                  <button className = 'inline-block px-8 py-1.5 bg-[#f0f3f3] rounded text-sm text-blacklight'>an offer</button>
                </div>
              </div>
            }
            
          </div>
          {
            reportActive == 1 && <div className = 'border border-borderColor rounded bg-white'>
              <div className = 'modifyproduct custom-scroll'>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <div className = 'w-96 p-2'><p className = 'text-blacklight text-base'>the product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Brand</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Type</p></div>
                  </li>
                
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>the price</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Quantity sold</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Sales before discount</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Reduction</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Total cost of sales</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Net sales</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Taxes</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Total sales</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
                <ul className = 'flex w-full'>
                  <li className = 'border-b border-borderColor'>
                    <Link className = 'block w-96 p-2'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-16'><img src = 'https://cdn.salla.sa/KVlQp/co1oGJtXVBISWW6MbhgTcfZXOyAXymPW06dM3QMn.jpg' /></div>
                        <h3 className = 'text-blacklight text-sm self-center'>Guerlain Moon Bloom of Rose Eau de Toilette perfume</h3>
                      </div>
                    </Link>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'>
                      <p className = 'text-blacklight text-base'>Guerlain</p>
                    </div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>Ready product</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>1</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>-</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 490</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 422</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 63.30</p></div>
                  </li>
                  <li className = 'pl-2 border-l border-b border-borderColor p-2'>
                    <div className = 'w-44'><p className = 'text-blacklight text-base'>SAR 485.30</p></div>
                  </li>
                </ul>
              </div>
            </div>
          }
          {
            reportActive == 5 && <Themostwanted />
          }
          {
            reportActive == 4 && <ReportVisits />
          }
          {
            reportActive == 2 && <ReportProduct />
          }
          {
            reportActive == 3 && <Customers />
          }
          {
            reportActive == 8 && <SalesChannels />
          }
          {
            reportActive == 6 && <MostSearched />
          }
          {
            reportActive == 7 && <PaymentShipping />
          }
          
        </div>
      </div>
    </>
  );
};

export default Report;