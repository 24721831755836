/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const ShippingProductlist = ({productinputValue, handleproductinputval, handleproductdata, selectedProduct,productdata}) => {

 
  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        value = { selectedProduct }
        onChange = { handleproductdata }
        options = { productdata }
        inputValue = { productinputValue }
        onInputChange = { handleproductinputval }
        isMulti
        placeholder = 'Select product'
      />
    </>
  );
};

export default ShippingProductlist;