/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const Shippingcategorylist = ({catloginputValue, handlecatloginputval, handlecatlogtdata, selectedCatlog,catlogdata}) => {

 
  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        value = { selectedCatlog }
        onChange = { handlecatlogtdata }
        options = { catlogdata }
        inputValue = { catloginputValue }
        onInputChange = { handlecatloginputval }
        isMulti
        placeholder = 'Select category'
      />
    </>
  );
};

export default Shippingcategorylist;