/* eslint-disable react/no-unescaped-entities */

import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const WalletTransactions = () => {

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><Link to = "/wallet" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Portfolio</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Tax invoices</span></li>
          </ul>
          <div className = 'border border-borderColor rounded mt-10'>
            <div className = 'p-5 flex justify-between'>
              <h3 className = 'text-base font-medium text-blacklight'><span><CreditCardOutlinedIcon style = { {fontSize: '24px'} } /></span> Transaction history - store balance <span className = 'text-secondary'>(98)</span></h3>
              <p><button className = 'text-blacklight text-sm'>Export</button></p>
            </div>
           
            <div className = 'activatesubscriptions custom-scroll'>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Operation number</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Portfolio</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Operation type</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Process details</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-44 text-sm font-medium py-2 px-4'>Transaction amount</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Date of operation</div>
                </li>
               
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>   
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>1600719329</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Portfolio</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Deposit/charge balance <span className = 'text-sm bg-[#f0f3f3] rounded-full inline-block px-2'>Implemented</span></div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Direct deposit</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>30 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04/05/2024 - 05:05 AM</div>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTransactions;