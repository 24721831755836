/* eslint-disable react/no-unescaped-entities */
import React from 'react';


const MostSearched = () => {

  return(
    <>
      <div className = 'flex flex-wrap justify-between'>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Most searched products</h3>
            <div className = 'flex justify-between bg-[#f0f3f3] rounded py-1 px-2 mt-3'>
              <h4 className = 'text-base text-blacklight'>the product</h4>
              <h4 className = 'text-base text-blacklight'>Number of searches</h4>
            </div>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Armani Stronger With You Absolutely men's perfume</p>
                  <p className = 'text-sm text-blacklight'>2</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Guerlain Habit Rouge Prive Eau de Parfum 100 ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Cartier Pasha Edition Noir Eau de Toilette</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Mancera Rose Qaridi Eau de Parfum 120ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Mater Falcon Leather Eau de Parfum, 50 ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className = 'w-width49% bg-white shadow-customcard rounded-md'>
          <div className = 'p-3'>
            <h3 className = 'text-base text-blacklight font-medium'>Most searched words</h3>
            <div className = 'flex justify-between bg-[#f0f3f3] rounded py-1 px-2 mt-3'>
              <h4 className = 'text-base text-blacklight'>Search word</h4>
              <h4 className = 'text-base text-blacklight'>Number of searches</h4>
            </div>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Armani Stronger With You Absolutely men's perfume</p>
                  <p className = 'text-sm text-blacklight'>2</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Guerlain Habit Rouge Prive Eau de Parfum 100 ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Cartier Pasha Edition Noir Eau de Toilette</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Mancera Rose Qaridi Eau de Parfum 120ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between'>
                  <p className = 'text-sm text-blacklight'>Mater Falcon Leather Eau de Parfum, 50 ml</p>
                  <p className = 'text-sm text-blacklight'>1</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MostSearched;