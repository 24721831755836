import React, { useState } from 'react';
import Select from 'react-select';


const CustomergroupInclude = () => {

  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedOption }
        onChange = { setSelectedOption }
        options = { options }
        isMulti
        placeholder = 'Choose group'
      />
    </>
  );
};

export default CustomergroupInclude;