import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../../config/index';
import { logout } from '../features/authSlice';

const getToken = () => localStorage.getItem('token');

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  })(args, api, extraOptions);

  if (result.error) {
    const { status } = result.error;
    if (status === 403 || status === 401) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const managereviewsApi = createApi({
  reducerPath: 'managereviewsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    reviewlist: builder.query({
      query: () => '/reviews/admin/list-reviews/'
    }),
    sendpermission: builder.mutation({
      query({body, id}) {
        return {
          url: `/reviews/admin/approve-deapprove/${id}/`,
          method: 'POST',
          body
        };
      }
    })
  })
});


export const { 
  useReviewlistQuery,
  useSendpermissionMutation

} = managereviewsApi;