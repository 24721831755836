/* eslint-disable react/no-unescaped-entities */
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import moment from 'moment';
import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';




const localizer = momentLocalizer(moment);

const OrdersbookingReservations = () => {


  const myEventsList = [
    {
      id: 1,
      title: 'Event 1',
      start: new Date(2024, 3, 15, 10, 0),
      end: new Date(2024, 3, 15, 12, 0)
    },
    {
      id: 2,
      title: 'Event 2',
      start: new Date(2024, 3, 16, 14, 0),
      end: new Date(2024, 3, 16, 16, 0)
    }
    // Add more events as needed
  ];
      

  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "/order" className = 'text-blacklight'>Order</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>Appointments</span></li>
            </ul>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-blacklight text-lg pb-5'><span className = 'align-text-bottom'><EventOutlinedIcon style = { {fontSize: '22px'} } /></span> Appointment agenda</h3>
            <Calendar
              localizer = { localizer }
              events = { myEventsList }
              startAccessor = "start"
              endAccessor = "end"
              style = { { height: 500 } }
            />
          </div>
        </div>
      </div>
      
    </>
  );
};

export default OrdersbookingReservations;
