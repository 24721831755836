/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Switch from 'react-switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBrandlistQuery, useCatloglistQuery, useCreateCouponMutation, usePaymentlistQuery, useProductlistQuery } from '../../redux/api/discountcouponApi';
import BrandExclude from './BrandExclude';
import CatlogInclude from './CatlogInclude';
import Catlogexclude from './Catlogexclude';
import CustomergroupInclude from './CustomergroupInclude';
import PaymentInclude from './PaymentInclude';
import ProductExclude from './ProductExclude';
import ProductInclude from './ProductInclude';

const CreateDiscountModal = ({ createcoupon, setCreatecoupon,refetch }) => {
  const onCloseCreatecouponModal = () => {
    setCreatecoupon(false);
  };

  const [activetab, setActivetab] = useState(1);

  const handleTab = (item) => {
    setActivetab(item);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date(); // Get today's date

  // Function to format date to YYYY-MM-DD
  const formatDate = (date) => date ? format(date, 'yyyy-MM-dd') : '';

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const [couponCode, setCouponCode] = useState('');
  const [discounttype, setDiscounttype] = useState(null);
  const [freeshipping, setFreeshipping] = useState('');
  const [discountValuePercentage, setDiscountValuePercentage] = useState('');
  const [discountValueFixed, setDiscountValueFixed] = useState('');
  const [minimumPurchases, setMinimumPurchases] = useState();
  const [timeusedforall, setTimeusedforall] = useState();
  const [timeused, setTimeused] = useState();


  const { error: catlogerror, data: catlogdata, isSuccess: catlogisSuccess } = useCatloglistQuery();

  // catlog include
  const [selectedCatlogOption, setSelectedCatlogOption] = useState(null);
  const [catloglist, setCatloglist] = useState([]);

  useEffect(() => {
    if (catlogerror) {
      toast(catlogerror?.data?.message);
    }

  }, [catlogerror]);

  useEffect(() => {

    if (catlogisSuccess) {
      const options = catlogdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setCatloglist(options || []);
      setSelectedCatlogOption(null);
    }

  }, [catlogisSuccess, catlogdata]);

  const handleChangecatlog = (selectedOption) => {
    setSelectedCatlogOption(selectedOption);
  };

  // catloginclude


  // catlogexlude
  const [selectedCatlogExcludeOption, setSelectedCatlogExcludeOption] = useState(null);
  const [catlogExcludelist, setCatlogExcludelist] = useState([]);

  useEffect(() => {
    if (catlogerror) {
      toast(catlogerror?.data?.message);
    }

  }, [catlogerror]);

  useEffect(() => {

    if (catlogisSuccess) {
      const options = catlogdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setCatlogExcludelist(options || []);
      setSelectedCatlogExcludeOption(null);
    }

  }, [catlogisSuccess, catlogdata]);

  const handleChangecatlogExclude = (selectedOption) => {
    setSelectedCatlogExcludeOption(selectedOption);
  };
  // catlogexlude

  // product include

  const { error: producterror, data: productdata, isSuccess: productisSuccess } = useProductlistQuery();
  const [selectedProductOption, setSelectedProductOption] = useState(null);
  const [Productlist, setProductlist] = useState([]);
  useEffect(() => {
    if (producterror) {
      toast(producterror?.data?.message);
    }

  }, [producterror]);

  useEffect(() => {

    if (productisSuccess) {
      const options = productdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setProductlist(options || []);
      setSelectedProductOption(null);
    }

  }, [productisSuccess, productdata]);

  const handleChangeproduct = (selectedOption) => {
    setSelectedProductOption(selectedOption);
  };

  // product include closeed


  // product exclude open here

  const [selectedProductExcludeOption, setSelectedProductExcludeOption] = useState(null);
  const [ProductExcludelist, setProductExcludelist] = useState([]);
  useEffect(() => {
    if (producterror) {
      toast(producterror?.data?.message);
    }

  }, [producterror]);

  useEffect(() => {

    if (productisSuccess) {
      const options = productdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setProductExcludelist(options || []);
      setSelectedProductExcludeOption(null);
    }

  }, [productisSuccess, productdata]);

  const handleChangeproductExclude = (selectedOption) => {
    setSelectedProductExcludeOption(selectedOption);
  };

  // product include closeed

  // product exclude closed here


  // payment include

  const { error: paymenterror, data: paymentdata, isSuccess: paymentisSuccess } = usePaymentlistQuery();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [paymentlist, setPaymentlist] = useState([]);
  useEffect(() => {
    if (paymenterror) {
      toast(paymenterror?.data?.message);
    }

  }, [paymenterror]);

  useEffect(() => {

    if (paymentisSuccess) {
      const options = paymentdata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setPaymentlist(options || []);
      setSelectedPaymentOption(null);
    }

  }, [paymentisSuccess, paymentdata]);

  const handleChangepayment = (selectedOption) => {
    setSelectedPaymentOption(selectedOption);
  };

  // payment include

  // brand exclude open

  const { error: branderror, data: branddata, isSuccess: brandisSuccess } = useBrandlistQuery();
  const [selectedBrandOption, setSelectedBrandOption] = useState(null);
  const [brandlist, setBrandlist] = useState([]);
  useEffect(() => {
    if (branderror) {
      toast(branderror?.data?.message);
    }

  }, [branderror]);

  useEffect(() => {

    if (brandisSuccess) {
      const options = branddata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setBrandlist(options || []);
      setSelectedBrandOption(null);
    }

  }, [brandisSuccess, branddata]);

  const handleChangebrand = (selectedOption) => {
    setSelectedBrandOption(selectedOption);
  };

  // brand exclude closed

  const [createCoupon, { error, isLoading, isSuccess }] = useCreateCouponMutation();

  useEffect(() => {
    if (error) {
      const errorMessages = [
        error?.data?.message?.discount_value?.[0],
        error?.data?.message?.start_date?.[0],
        error?.data?.message?.end_date?.[0],
        error?.data?.message?.min_order_value?.[0],
        error?.data?.message?.free_shipping?.[0]
      ];
  
      errorMessages.forEach((message) => {
        if (message) {
          toast(message);
        }
      });
    }
  }, [error]);

  useEffect(() => {

    if (isSuccess) {
      setCreatecoupon(false);
      refetch();
    }

  }, [isSuccess]);

  const handlecreatecoupon = (e) => {
    e.preventDefault();

    const data = {
      'included_products': selectedProductOption?.map(option => option.value),
      'included_categories': selectedCatlogOption?.map(option => option.value),
      'included_groups': [],
      'included_payment_methods': selectedPaymentOption?.map(option => option.value),
      'excluded_products': selectedProductExcludeOption?.map(option => option.value),
      'excluded_categories': selectedCatlogExcludeOption?.map(option => option.value),
      'excluded_groups': [],
      'excluded_brands': selectedBrandOption?.map(option => option.value),
      'code': couponCode,
      'discount_type': discounttype,
      'discount_value': discountValuePercentage?discountValuePercentage: discountValueFixed,
      'start_date': formatDate(startDate),
      'end_date': formatDate(endDate),
      'max_user_limit': Number(timeused),
      'max_usage_limit': Number(timeusedforall),
      'min_order_value': Number(minimumPurchases),
      'free_shipping': freeshipping
    };

    createCoupon(data);
  };

  const isFormValid = couponCode && discounttype && (discountValuePercentage || discountValueFixed) && startDate && endDate && timeused && timeusedforall && minimumPurchases && freeshipping;


  const handleDiscountType = (item) => {
    setDiscounttype(item);
  };

  return (
    <>
      <ToastContainer />
      <Modal open = { createcoupon } showCloseIcon = { false } onClose = { onCloseCreatecouponModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Create discount coupons</h3>
            <div className = 'self-center'><span onClick = { onCloseCreatecouponModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = 'flex border border-borderColor rounded justify-between'>
              <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 1 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(1) }>
                <h3 className = 'text-sm text-blacklight'><span><ListOutlinedIcon style = { { fontSize: '20px' } } /></span> Coupon data</h3>
              </div>
              <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 2 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(2) }>
                <h3 className = 'text-sm text-blacklight'><span><FileDownloadDoneOutlinedIcon style = { { fontSize: '20px' } } /></span> Included in the coupon</h3>
              </div>
              <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 3 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(3) }>
                <h3 className = 'text-sm text-blacklight'><span><FolderOffOutlinedIcon style = { { fontSize: '20px' } } /></span> Excluded from the coupon</h3>
              </div>
            </div>
            <form onSubmit = { handlecreatecoupon }>
              {
                activetab === 1 && <>

                  <ul>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Coupon code (English letters, numbers, without spaces)</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input value = { couponCode } onChange = { (e) => setCouponCode(e.target.value) } placeholder = 'Coupon code (English letters, numbers, without spaces)' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Discount type for the customer</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><MenuOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <select onChange = { (e) => handleDiscountType(e.target.value) } className = 'inline-block text-sm outline-0 w-width97% text-secondary' value = { discounttype }>
                          <option>Discount type for the customer</option>
                          <option value = 'percentage'>A percentage of the customer's total purchases</option>
                          <option value = 'fixed'>A fixed amount of the customer's total purchases</option>
                        </select>
                      </div>
                      {
                        discounttype === 'percentage' && <><label className = 'text-sm text-blacklight inline-block mt-2'>Discount percentage</label> <input placeholder = 'Write percentage discount value' value = { discountValuePercentage } onChange = { (e) => setDiscountValuePercentage(e.target.value) } className = 'inline-block text-sm outline-0 w-full self-center border border-borderColor px-2 py-2 rounded mt-1' /></>
                      }
                      {
                        discounttype === 'fixed' && <><label className = 'text-sm text-blacklight inline-block mt-2'>Discount fixed amount</label> <input placeholder = 'Write fixed discount value' value = { discountValueFixed } onChange = { (e) => setDiscountValueFixed(e.target.value) } className = 'inline-block text-sm outline-0 w-full self-center border border-borderColor px-2 py-2 rounded mt-1' /></>
                      }
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between'>
                        <div className = 'w-width48%'>
                          <label className = 'text-sm text-blacklight inline-block'>Coupon start date</label>
                          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                            <div className = 'width5% self-center'>
                              <span className = 'text-secondary inline-block mr-1'>
                                <CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } />
                              </span>
                            </div>
                            <div className = 'w-width95% text-sm self-center'>
                              <DatePicker
                                selected = { startDate }
                                onChange = { (date) => setStartDate(date) }
                                className = 'outline-none'
                                placeholderText = 'Coupon start date'
                                minDate = { today } // Prevent selecting previous dates
                              />
                            </div>
                          </div>
                        </div>
                        <div className = 'w-width48%'>
                          <label className = 'text-sm text-blacklight inline-block'>Coupon expiration date</label>
                          <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                            <div className = 'width5% self-center'>
                              <span className = 'text-secondary inline-block mr-1'>
                                <CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } />
                              </span>
                            </div>
                            <div className = 'w-width95% text-sm self-center'>
                              <DatePicker
                                selected = { endDate }
                                onChange = { (date) => setEndDate(date) }
                                className = 'outline-none'
                                placeholderText = 'Coupon expiration date'
                                minDate = { startDate || today } // Ensure expiration date is after the start date
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>With free shipping?</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalShippingOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <select value = { freeshipping } onChange = { (e) => setFreeshipping(e.target.value) } className = 'inline-block text-sm outline-0 w-width97% text-secondary'>
                          <option>With free shipping?</option>
                          <option value = "false">no</option>
                          <option value = "true">Yes</option>
                        </select>
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Discounted products excluded</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalOfferOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                          <option>Excluding discounted products?</option>
                          <option value = "false">no</option>
                          <option value = "true">Yes</option>
                        </select>
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>(minimum purchases do not include tax value)</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalAtmOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input value = { minimumPurchases } onChange = { (e) => setMinimumPurchases(e.target.value) } placeholder = 'Minimum purchases' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Number of times used for all</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><GroupOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input value = { timeusedforall } onChange = { (e) => setTimeusedforall(e.target.value) } placeholder = 'Number of times used for all' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Number of times of use per customer</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><PermIdentityOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input value = { timeused } onChange = { (e) => setTimeused(e.target.value) } placeholder = 'Number of times of use per customer' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between'>
                        <div>
                          <h3 className = 'text-base text-blacklight'>Marketing coupon</h3>
                          <p className = 'text-xs text-secondary'>Enter the marketer’s data and his commission from sales. You can also share the statistics link after creating the coupon</p>
                        </div>
                        <div className = 'self-center'>
                          <Switch
                            onChange = { handleChange }
                            checked = { checked }
                            className = "react-switch"
                            onColor = "#474091"
                            width = { 40 }
                            height = { 20 }
                            handleDiameter = { 16 }
                          />
                        </div>
                      </div>
                    </li>

                  </ul>
                </>
              }
              {
                activetab === 2 && <>
                  <ul>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Payment methods included in the coupon</label>
                      <div className = 'mt-1'>
                        <PaymentInclude handleChangepayment = { handleChangepayment } selectedPaymentOption = { selectedPaymentOption } paymentlist = { paymentlist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Categories included</label>
                      <div className = 'mt-1'>
                        <CatlogInclude handleChangecatlog = { handleChangecatlog } selectedCatlogOption = { selectedCatlogOption } catloglist = { catloglist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Products included</label>
                      <p className = 'text-xs text-secondary'>You can search for ready-made products that do not have options</p>
                      <div className = 'mt-1'>
                        <ProductInclude handleChangeproduct = { handleChangeproduct } selectedProductOption = { selectedProductOption } Productlist = { Productlist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Customer group covered</label>
                      <div className = 'mt-1'>
                        <CustomergroupInclude />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Customer email domain</label>
                      <p className = 'text-xs text-secondary'>You can search for ready-made products that do not have options</p>
                      <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                        <input placeholder = 'beautyicon.store' className = 'inline-block text-sm outline-0 w-full self-center ml-1' />
                      </div>
                    </li>
                  </ul>
                </>
              }
              {
                activetab === 3 && <>
                  <ul>

                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Categories excluded</label>
                      <div className = 'mt-1'>
                        <Catlogexclude handleChangecatlogExclude = { handleChangecatlogExclude } selectedCatlogExcludeOption = { selectedCatlogExcludeOption } catlogExcludelist = { catlogExcludelist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Brands excluded</label>
                      <div className = 'mt-1'>
                        <BrandExclude handleChangebrand = { handleChangebrand } selectedBrandOption = { selectedBrandOption } brandlist = { brandlist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Products excluded</label>
                      <div className = 'mt-1'>
                        <ProductExclude handleChangeproductExclude = { handleChangeproductExclude } selectedProductExcludeOption = { selectedProductExcludeOption } ProductExcludelist = { ProductExcludelist } />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Excluded customer group</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                        <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                          <option>Choose group</option>
                        </select>
                      </div>
                    </li>
                    
                  </ul>
                </>
              }
              <button className = { `text-sm ${!isFormValid ? 'bg-primary-300' : 'bg-primary'} py-2 px-4 mt-5 rounded text-white` } disabled = { !isFormValid } >{ isLoading ? 'Saving' : 'Save' }</button>
            </form>
            
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateDiscountModal;
