/* eslint-disable react/no-unescaped-entities */

import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const ElectronicPayments = () => {

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><Link to = "/wallet" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Portfolio</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Transaction record - balance of electronic payments</span></li>
          </ul>
          <div className = 'border border-borderColor rounded bg-white p-4 mt-5 mb-5'>
            <div className = 'flex'>
              <span className = 'text-red inline-block mt-5 text-center'><InfoOutlinedIcon style = { {fontSize: '30px'} } /></span>
              <div className = 'ml-3 self-center'>
                <p className = 'text-red text-sm'>
                 I acknowledge that I have received all the sums of money paid by my clients using the electronic payment gateway, and I disclaim the basket platform’s responsibility for any other sums before this day.</p>
                <Link className = 'text-red text-sm underline'>Confirm receipt of previous payments</Link>
              </div>
            </div>
          </div>
          <div className = 'border border-borderColor rounded mt-10'>
            <div className = 'p-5 flex justify-between'>
              <h3 className = 'text-base font-medium text-blacklight'><span><CreditCardOutlinedIcon style = { {fontSize: '24px'} } /></span> Transaction record - balance of electronic payments</h3>
            
            </div>
           
            <div className = 'activatesubscriptions custom-scroll'>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Operation number</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Transaction amount</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Date of operation</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Viewer history</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-44 text-sm font-medium py-2 px-4'>the condition</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-60 text-sm font-medium py-2 px-4'>Action</div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Process #2995823</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>190.07 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-10-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'><span className = 'text-white bg-[#aeb3b3] rounded-full inline-block px-2 py-1 uppercase text-xs'>Paid</span></div>
                </li>
                <li>
                  <div className = 'w-60 text-sm py-2 px-4 text-blacklight'>
                    <button className = 'bg-[#f0f3f3] text-sm px-5 py-1.5 rounded-full'>receipt confirmation</button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ElectronicPayments;