/* eslint-disable react/no-unescaped-entities */
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import OutlinedFlagSharpIcon from '@mui/icons-material/OutlinedFlagSharp';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const CreateOrder = () => {

  const [modifycustomerdataModal, setModifycustomerdataModal] = useState(false);

  const onOpenModifycustomerdataModal = () => setModifycustomerdataModal(true);
  const onCloseModifycustomerdataModal = () => setModifycustomerdataModal(false);


  const [addclient, setAddclient] = useState(false);

  const handleAddClient = () => {
    setAddclient(!addclient);

  };


  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };


  const [editshippinginfoModal, setEditshippinginfoModal] = useState();
  const onOpenEditshippinginfoModal = () => setEditshippinginfoModal(true);
  const onCloseEditshippinginfoModal = () => setEditshippinginfoModal(false);

  const [modifypaymentinformationModal, setModifypaymentinformationModal] = useState(false);

  const onOpenModifypaymentinformationModal = () => setModifypaymentinformationModal(true);
  const onCloseModifypaymentinformationModal = () => setModifypaymentinformationModal(false);

  const [paymentselection, setPaymentselection] = useState();

  const handlePaymentselection = (e) => {
    setPaymentselection(e.target.value);
  };


  const [addproductModal, setAddproductModal] = useState(false);

  const onOpenAddproductModal = () => setAddproductModal(true);
  const onCloseAddproductModal = () => setAddproductModal(false);

  const [activeProductTab, setActiveProductTab] = useState(1);


  const handleActiveProductTab = (item) => {

    setActiveProductTab(item);
  };
  
  return (
    <>
      <div className = 'flex bg-white'>
        <div className = 'w-1/5'>
          <Sidebar />
        </div>
        <div className = 'flex-1 py-5 pl-5 pr-8'>
          <TopHeader />
          <div className = 'flex justify-between'>
            <ul className = 'flex text-sm'>
              <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li className = 'mr-2'><Link to = "/order" className = 'text-blacklight'>Order</Link></li>
              <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
              <li><span className = 'text-secondary'>Create a request</span></li>
            </ul>
          </div>
          <div className = 'flex justify-between border border-borderColor rounded mt-5 px-4 py-3'>
            <div>
              <h3 className = 'text-lg text-secondary font-medium'><span><OutlinedFlagSharpIcon style = { {fontSize: '20px'} } /></span> Order status</h3>
              <p className = 'text-base text-blacklight'>New</p>
            </div>
            <div className = 'text-center'>
              <h3 className = 'text-base text-secondary'>The date of application <span><CalendarMonthOutlinedIcon style = { {fontSize: '20px'} } /></span></h3>
              <p className = 'text-base text-blacklight mt-3'>Friday 16 November 2018 | 05:30 PM</p>
            </div>
            <div>
              <h3 className = 'text-base text-secondary'>#Modify order no</h3>
              <p className = 'text-base text-blacklight mt-2'>131293</p>
            </div>
          </div>
          <div className = 'flex justify-between mt-8'>
            <div className = 'border border-borderColor rounded px-4 py-3 w-width32%'>
              <div className = 'flex justify-between'>
                <h3 className = 'text-base text-blacklight font-medium self-center'>Customer</h3>
                <div>
                  <button onClick = { onOpenModifycustomerdataModal } className = 'text-blacklight border border-borderColor rounded text-sm inline-block py-1 px-3'><span><CreateOutlinedIcon style = { {fontSize: '18px'} } /></span>amendment</button>
                </div>
              </div>
              <div className = 'text-[#e5e9e9] text-center mt-8'>
                <PermIdentityOutlinedIcon style = { {fontSize: '80px'} } />
              </div>
            </div>
            <div className = 'border border-borderColor rounded px-4 py-3 w-width32%'>
              <div className = 'flex justify-between'>
                <h3 className = 'text-base text-blacklight font-medium self-center'>Shipping</h3>
                <div>
                  <button onClick = { onOpenEditshippinginfoModal } className = 'text-blacklight border border-borderColor rounded text-sm inline-block py-1 px-3'><span><CreateOutlinedIcon style = { {fontSize: '18px'} } /></span>amendment</button>
                </div>
              </div>
              <p className = 'text-sm text-secondary mt-5'>No charging/connection required</p>
            </div>
            <div className = 'border border-borderColor rounded px-4 py-3 w-width32%'>
              <div className = 'flex justify-between'>
                <h3 className = 'text-base text-blacklight font-medium self-center'>Paying off</h3>
                <div>
                  <button onClick = { onOpenModifypaymentinformationModal } className = 'text-blacklight border border-borderColor rounded text-sm inline-block py-1 px-3'><span><CreateOutlinedIcon style = { {fontSize: '18px'} } /></span>amendment</button>
                </div>
              </div>
              <div className = 'text-[#e5e9e9] text-center mt-8'>
                <AccountBalanceWalletOutlinedIcon style = { {fontSize: '80px'} } />
              </div>
             
            </div>
          </div>
          <div className = 'border border-borderColor rounded px-4 py-3 mt-8'>
            <div className = 'flex justify-between pb-5'>
              <h3 className = 'text-base text-blacklight font-medium self-center'>Products</h3>
              <div className = 'self-center'><button onClick = { onOpenAddproductModal }  className = 'bg-[#f0f3f3] inline-block py-2 px-3 text-sm rounded text-blacklight'><span><AddOutlinedIcon style = { {fontSize: '20px'} } /></span> Add a product</button></div>
            </div>
            <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
              <div
                className = "inline-block min-w-full overflow-hidden"
              >
                <table className = "min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th
                        className = "bg-[#f0f3f3] px-5 py-2 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                      the product
                      </th>
                     
                      <th
                        className = "bg-[#f0f3f3] px-5 py-2 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                     Quantity
                      </th>
                      <th
                        className = "bg-[#f0f3f3] px-5 py-2 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                      the weight
                      </th>
                      <th
                        className = "bg-[#f0f3f3] px-5 py-2 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                     the price
                      </th>
                      <th
                        className = "bg-[#f0f3f3] px-5 py-2 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                      >
                     the total
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                        <Link className = "flex">
                          <div className = "flex-shrink-0 w-16 h-16">
                            <img
                              className = "w-full h-full rounded-full"
                              src = "https://odour-demo.myshopify.com/cdn/shop/products/product_13_large.png?v=1561608483"
                              alt = ""
                            />
                          </div>
                          <div className = "self-center">
                            <p className = "text-blacklight whitespace-no-wrap">
                            Nasser abdul rahman al husain
                            </p>
                          </div>
                        </Link>
                      </td>
                      
                      <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">2</p>
                      </td>
                      <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">0 kg</p>
                      </td>
                      <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = 'text-blacklight whitespace-no-wrap'>8,745 SAR</p>
                      </td>
                      <td className = "px-5 py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = 'text-blacklight whitespace-no-wrap'>17,490 SAR</p>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className = 'border border-borderColor rounded px-4 py-3 mt-8'>
            <h3 className = 'text-base text-blacklight font-medium pb-5 border-b border-boderColor-300'>Order summary</h3>

            <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
              <div
                className = "inline-block min-w-full overflow-hidden"
              >
                <table className = "min-w-full leading-normal">
                  
                  <tbody>
                    <tr>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">Total basket</p>
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                        <p className = "text-blacklight whitespace-no-wrap">0 SAR</p>
                      </td>
                    </tr>
                    <tr>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">Ordering options</p>
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                        <p className = "text-blacklight whitespace-no-wrap">0 SAR</p>
                      </td>
                    </tr>
                    <tr>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">Discount coupons</p>
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                        <p className = "text-blacklight whitespace-no-wrap">
                          <input placeholder = 'Search for a coupon..' className = 'border border-borderColor outline-none rounded-full py-1 px-2' />
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm">
                        <p className = "text-blacklight whitespace-no-wrap">Total demand</p>
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                      </td>
                      <td className = " py-3 border-b border-boderColor-300 bg-white text-sm text-right">
                        <p className = "text-blacklight whitespace-no-wrap">
                        0 SAR
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className = 'flex justify-center mt-10'>
            <div>
              <button className = 'text-sm bg-[#f0f3f3] border border-borderColor inline-block rounded-full text-blacklight py-1.5 px-3 mr-3'><span><FolderCopyOutlinedIcon style = { {fontSize: '18px'} } /></span> Save as draft</button>
            </div>
            <div>
              <button className = 'text-sm border border-borderColor inline-block rounded-full text-blacklight py-1.5 px-3 mr-3'><span><FileDownloadDoneOutlinedIcon style = { {fontSize: '18px'} } /></span> Create order</button>
            </div>
            <div>
              <button className = 'text-sm border border-red inline-block rounded-full text-red py-1.5 px-3'><span><CloseOutlinedIcon style = { {fontSize: '18px'} } /></span> Delete the request</button>
            </div>
          </div>
        </div>
      </div>
      

      <Modal open = { modifycustomerdataModal } showCloseIcon = { false } onClose = { onCloseModifycustomerdataModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Modify customer data</h3>
            <div className = 'self-center'><span onClick = { onCloseModifycustomerdataModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'flex justify-between py-3 px-4'>
            <div className = 'flex-1'>
              <input className = 'inline-block border border-borderColor text-sm w-full py-2 px-3 outline-none' placeholder = 'Search customer list...' />
            </div>
            <div>
              <button onClick = { handleAddClient } className = 'bg-[#f0f3f3] inline-block py-2 px-3 text-sm rounded text-blacklight ml-5'><span><AddOutlinedIcon style = { {fontSize: '20px'} } /></span> Add a new client</button>
            </div>
          </div>
          {
            addclient && <ul className = 'py-3 px-4'>
              <li>
                <label className = 'text-sm text-blacklight'>First Name</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><PersonOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'First Name' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>family name</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><PeopleAltOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'family name' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>E-mail</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'width5%'><span className = 'text-secondary'><EmailOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'E-mail' className = 'inline-block text-sm outline-0 w-width95%' />
                </div>
              </li>
           
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Phone</label>
                <div className = 'flex justify-between border border-borderColor px-2 rounded mt-1'>
                  <div className = 'width5%'>
                    <select className = 'outline-0 inline-block pt-1 text-sm'>
                      <option>+966</option>
                      <option>+91</option>
                      <option>+94</option>
                    </select>
                  </div>
                  <input placeholder = 'Phone' type = 'number' className = 'inline-block text-sm outline-0 w-width95% ml-2 py-1.5' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Date of birth</label>
                <div className = ' border border-borderColor px-2 py-1 rounded mt-1'>
                  <div><input type = 'date' className = 'inline-block text-sm text-secondary outline-0 w-full' /></div>
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Select gender</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <select className = 'inline-block text-sm outline-0 w-full text-secondary'>
                    <option>Select gender</option>
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </li>
              <li>
                <div className = 'flex mt-5 border-t border-b py-3 border-borderColor'>
                  <div><Switch
                    onChange = { handleChange }
                    checked = { checked }
                    className = "react-switch"
                    onColor = "#474091"
                    width = { 40 }
                    height = { 20 }
                    handleDiameter = { 16 }
                    id = "switch"
                  /></div>
                  <label htmlFor = 'switch' className = 'text-sm cursor-pointer font-medium text-blacklight inline-block ml-3'>Sending the request to someone else?</label>
                </div>
                {
                  checked && <ul>
                    <li className = 'mt-3'>
                      <h3 className = 'text-base text-blacklight font-medium'>Recipient data</h3>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'width5%'><span className = 'text-secondary'><PeopleAltOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = "The recipient's name" className = 'inline-block text-sm outline-0 w-width95%' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'width5%'><span className = 'text-secondary'><EmailOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = "Recipient's email" className = 'inline-block text-sm outline-0 w-width95%' />
                      </div>
                    </li>
             
                    <li className = 'mt-3'>
                      <div className = 'flex justify-between border border-borderColor px-2 rounded mt-1'>
                        <div className = 'width5%'>
                          <select className = 'outline-0 inline-block pt-1 text-sm'>
                            <option>+966</option>
                            <option>+91</option>
                            <option>+94</option>
                          </select>
                        </div>
                        <input placeholder = 'Phone' type = 'number' className = 'inline-block text-sm outline-0 w-width95% ml-2 py-1.5' />
                      </div>
                    </li>
                  </ul>
                }
                
              </li>
            </ul>
          }
          <div className = 'py-5 px-4'>
            <button className = 'text-sm text-white bg-primary px-3 py-1 rounded'>Save</button>
            <button className = 'text-sm text-blacklight bg-[#f0f3f3] px-3 py-1 rounded ml-2' onClick = { onCloseModifycustomerdataModal }>Cancel</button>
          </div>
        </div>
      </Modal>

      <Modal open = { editshippinginfoModal } showCloseIcon = { false } onClose = { onCloseEditshippinginfoModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Edit shipping information</h3>
            <div className = 'self-center'><span onClick = { onCloseEditshippinginfoModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <ul>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                    <option>Requires shipping/delivery?</option>
                    <option>Yes, it requires charging/delivery</option>
                    <option>No charging/connection required</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalShippingOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                    <option>Is shipping free?</option>
                    <option>No, shipping is not free</option>
                    <option>Yes, shipping is free</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'border border-borderColor px-2 py-1 rounded mt-1'>
                  <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                    <option>Choose the country</option>
                    <option>Saudi Arabia</option>
                    <option>United Arab Emirates</option>
                    <option>Kuwait</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'border border-borderColor px-2 py-1 rounded mt-1'>
                  <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                    <option>Choose city</option>
                    <option>Saudi Arabia</option>
                    <option>United Arab Emirates</option>
                    <option>Kuwait</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'border border-borderColor px-2 py-1 rounded mt-1'>
                  <input placeholder = 'Street name' className = 'text-sm text-secondary outline-0' />
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'border border-borderColor px-2 py-1 rounded mt-1'>
                  <input placeholder = 'Description of the house' className = 'text-sm text-secondary outline-0' />
                </div>
              </li>
              <li className = 'mt-3'>
                <div className = 'border border-borderColor px-2 py-1 rounded mt-1'>
                  <input placeholder = 'Postal code' className = 'text-sm text-secondary outline-0' />
                </div>
              </li>
              <li className = 'mt-3'>
                <p className = 'text-base text-blacklight text-center'>Shipping options</p>
              </li>
              <li className = 'mt-3'>
                <p className = 'text-sm text-blacklight'>Please select an address to view the shipping options available to you</p>
              </li>
            </ul>
            <button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-6'>Save</button>
          </div>
        </div>
      </Modal>

      <Modal open = { modifypaymentinformationModal } showCloseIcon = { false } onClose = { onCloseModifypaymentinformationModal }>
        <div className = 'modalboxsendingmessege'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Modify payment information</h3>
            <div className = 'self-center'><span onClick = { onCloseModifypaymentinformationModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <select onChange = { handlePaymentselection } className = 'text-sm border border-borderColor px-2 py-1 rounded-md mt-3 outline-none w-full'>
              <option value = "1">Has the payment been made?</option>
              <option value = "2">Yes, payment has been made</option>
              <option value = "3">No, waiting for payment</option>
            </select>
            
            { 
              paymentselection == 2 &&  <>
                <div className = 'text-base bg-[#f0f3f3] px-4 py-2 text-center mt-5'>Payment options</div>
                <ul className = 'mt-5'>
                  <li>
                    <div><p className = 'text-sm'><input type = 'radio' name = 'bank' className = 'inline-block align-middle' id = 'bank' /> <label className = 'inline-block align-middle' htmlFor = 'bank' >Bank transfer</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div>
                      <p className = 'text-sm'><input type = 'radio' name = 'bank' className = 'inline-block align-middle' id = 'recieving' /> <label className = 'inline-block align-middle' htmlFor = 'recieving' >Paiement when recieving</label></p>
                      <p className = 'text-xs text-red mt-1'>Cash on delivery is not available due to lack of shipping information.</p>
                    </div>
                  </li>
            
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'radio' name = 'bank' className = 'inline-block align-middle' id = 'Bezel' /> <label className = 'inline-block align-middle' htmlFor = 'Bezel' >Bezel</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'radio' name = 'bank' className = 'inline-block align-middle' id = 'Creditcard' /> <label className = 'inline-block align-middle' htmlFor = 'Creditcard' >Credit card</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'radio' name = 'bank' className = 'inline-block align-middle' id = 'PayPal' /> <label className = 'inline-block align-middle' htmlFor = 'PayPal' >PayPal</label></p></div>
                  </li>
                </ul>
                <div className = 'text-base bg-[#f0f3f3] px-4 py-2 text-center mt-5'>Bank transfer details</div>
                <select className = 'text-sm border border-borderColor px-2 py-1 rounded-md mt-5 outline-none w-full'>
                  <option>Select the bank</option>
                </select>
                <label className = 'block bg-[#f0f3f3] px-4 py-2.5 text-center mt-3' htmlFor = 'file'>
                  <h3 className = 'text-base text-blacklight'>Drag and drop the image or</h3>
                  <p className = 'cursor-pointer text-sm text-secondary mt-1'><input type = 'file' id = "file"  /></p>
                </label>
              </>
            }

            { 
              paymentselection == 3 || paymentselection == 1 ? <>
                <p className = 'text-red text-sm mt-5'>After creating the order, a payment link will be sent to the customer’s mobile phone to complete the payment process by the means you prefer
                </p>
                <div className = 'text-base bg-[#f0f3f3] px-4 py-2 text-center mt-5'>Available payment methods</div>
                <ul className = 'mt-5'>
                  <li>
                    <div><p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'bank' /> <label className = 'inline-block align-middle' htmlFor = 'bank' >Bank transfer</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div>
                      <p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'recieving' /> <label className = 'inline-block align-middle' htmlFor = 'recieving' >Paiement when recieving</label></p>
                      <p className = 'text-xs text-red mt-1'>Cash on delivery is not available due to lack of shipping information.</p>
                    </div>
                  </li>
            
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'Tamara' /> <label className = 'inline-block align-middle' htmlFor = 'Tamara' >Tamara</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'Tabby' /> <label className = 'inline-block align-middle' htmlFor = 'Tabby' >Tabby</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'MESBay' /> <label className = 'inline-block align-middle' htmlFor = 'MESBay' >MES Bay</label></p></div>
                  </li>
                  <li className = 'mt-2'>
                    <div><p className = 'text-sm'><input type = 'checkbox' name = 'bank' className = 'inline-block align-middle' id = 'Knet' /> <label className = 'inline-block align-middle' htmlFor = 'Knet' >Knet</label></p></div>
                  </li>
                </ul>
            
              </>: null
            }
            <button className = 'text-sm text-white bg-primary px-5 py-2 rounded mt-6'>Save</button>
          </div>
        </div>
      </Modal>

      <Modal open = { addproductModal } showCloseIcon = { false } onClose = { onCloseAddproductModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Add a product</h3>
            <div className = 'self-center'><span onClick = { onCloseAddproductModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'w-full px-4 py-3'>
            <div className = 'flex border border-borderColor rounded'>
              <button className = { `block w-width50% py-2 text-sm ${activeProductTab == 1?'bg-[#f0f3f3]' : ''}` } onClick = { () => handleActiveProductTab(1) }>Search for a product</button>
              <button className = { `block w-width50% py-2 text-sm ${activeProductTab == 2?'bg-[#f0f3f3]' : ''}` }  onClick = { () => handleActiveProductTab(2) }>Add a special product</button>
            </div>
            {
              activeProductTab == 1 && <>
                <div className = 'flex border border-borderColor rounded py-1.5 px-3 mt-5'>
                  <button className = 'text-blacklight'><SearchIcon style = { {fontSize: '25px'} } /></button>  
                  <input className = 'outline-none flex-1 inline-block ml-2 text-sm' placeholder = 'Search the product list by product name or SKU' />
                </div>
                <div className = 'text-center mt-8'>
                  <span className = 'text-[#e5e9e9]'><PageviewOutlinedIcon style = { {fontSize: '72px'} } /></span>
                  <p className = 'text-sm text-secondary'>Search for a product to add to the order</p>
                </div>

                <div className = 'flex justify-between mt-6'>
                  <button className = 'block text-sm text-white bg-primary px-5 py-2 rounded'>Addition</button>
                  <button className = 'block bg-[#f0f3f3] text-sm px-5 py-2 rounded'>Close</button>
                </div>
              </>
            }

            {
              activeProductTab == 2 && <>
                <ul>
                  <li>
                    <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                      <div className = 'width5%'><span className = 'text-secondary'><FontDownloadOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                      <input placeholder = 'Product name' className = 'inline-block text-sm outline-0 w-width95%' />
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <div className = 'flex justify-between'>
                      <div className = 'w-width49% flex border border-borderColor px-2 py-1 rounded '>
                        <div className = 'width5%'><span className = 'text-secondary'><MonetizationOnOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'the price' className = 'inline-block text-sm outline-0 w-width95% ml-2' />
                      </div>
                      <div className = 'w-width49% flex border border-borderColor px-2 py-1 rounded '>
                        <div className = 'width5%'><span className = 'text-secondary'><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'Quantity' className = 'inline-block text-sm outline-0 w-width95% ml-2' />
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-3'>
                    <div className = 'flex justify-between'>
                      <div className = 'w-width49% flex border border-borderColor px-2 py-1 rounded '>
                        <div className = 'width5%'><span className = 'text-secondary'><ScaleOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'the weight' className = 'inline-block text-sm outline-0 w-width95% ml-2' />
                      </div>
                      <div className = 'w-width49% flex border border-borderColor px-2 py-1 rounded '>
                        <div className = 'width5%'><span className = 'text-secondary'><ScaleOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                        <input placeholder = 'Cost price' className = 'inline-block text-sm outline-0 w-3/4 ml-2' />
                        <select className = 'w-1/5 outline-none'>
                          <option>Kg</option>
                          <option>Jim</option>
                          <option>ounce</option>
                          <option>pound</option>
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className = 'flex justify-between mt-6'>
                  <button className = 'block text-sm text-white bg-primary px-5 py-2 rounded'>Addition</button>
                  <button className = 'block bg-[#f0f3f3] text-sm px-5 py-2 rounded'>Close</button>
                </div>
              </>
            }
            
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateOrder;
