import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBrandlistQuery, useCatloglistQuery } from '../../redux/api/productApi';
import BrandName from './BrandName';
import Catloglist from './Catloglist';

const AddProduct = () => {

  const [addProductModal, setAddProductModal] = useState(false);
  const [storagecode, setStoragecode] = useState('');
  const [requiresshipping, setRequiresshipping] = useState(true);
  const [productweight, setProductweight] = useState('');
  const [unit, setUnit] = useState('');
  const [gtin, setGtin] = useState('');
  const [mpn, setMpn] = useState('');
  const [maxqntperCustomer, setMaxqntperCustomer] = useState('');
  const [description, setDescription] = useState('');
  console.log('description',description);


  const [startDate, setStartDate] = useState();
  const [selectedCatlogOption, setSelectedCatlogOption] = useState(null);
  const [catloglist, setCatloglist] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  
  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };

  const {
    error: catlogerror,
    data: catlogdata,
    isSuccess: catlogisSuccess
  } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (catlogerror) {
      toast(catlogerror?.data?.message);
    }
  }, [catlogerror]);

  useEffect(() => {
    if (catlogisSuccess) {
      const options = catlogdata?.data.map((c) => ({
        label: c.name_en,
        value: c.id
      }));
      setCatloglist(options || []);
      // setSelectedCatlogOption(null);
    }
  }, [catlogisSuccess, catlogdata]);

  const handleChangecatlog = (selectedOption) => {
    setSelectedCatlogOption(selectedOption);
  };

  // handle brand open

  const {
    error: branderror,
    data: branddata,
    isSuccess: brandisSuccess
  } = useBrandlistQuery();

  const [selectedBrandOption, setSelectedBrandOption] = useState(null);
  const [brandlist, setBrandlist] = useState([]);
  useEffect(() => {
    if (branderror) {
      toast(branderror?.data?.message);
    }
  }, [branderror]);

  useEffect(() => {
    if (brandisSuccess) {
      const options = branddata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setBrandlist(options || []);
      setSelectedBrandOption(null);
    }
  }, [brandisSuccess, branddata]);

  const handleChangebrand = (selectedOption) => {
    setSelectedBrandOption(selectedOption);
  };


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setDescription(contentHtml);
  };

  const onOpenAddProductModal = () => {
    setAddProductModal(true);
  };

  const onCloseAddProductModal = () => setAddProductModal(false);

  const [images, setImages] = useState([]);
  const [addimgModal, setAddimgModal] = useState(false);
  const onOpenAddimgModal = () => setAddimgModal(true);
  const onCloseAddimgModal = () => setAddimgModal(false);


  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles) {
      const imageArray = selectedFiles.map((file) => URL.createObjectURL(file));
      setImages((prevImages) => [...prevImages, ...imageArray]);
    }
  };
  

  return(
    <>
      <ToastContainer />
      <div className = 'pb-5'>
        <span onClick = { onOpenAddProductModal } className = 'inline-block bg-primary text-sm text-white font-semibold py-2 px-4 rounded-md cursor-pointer'>Add new product</span>
      </div>

      <Modal
        open = { addProductModal }
        showCloseIcon = { false }
        onClose = { onCloseAddProductModal }
      >
        <div className = "modaladdproduct">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Add product
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddProductModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <div>
              <ul>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Name
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <MonetizationOnOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <input
                      placeholder = "Product name"
                      className = "inline-block text-sm outline-0 w-width97%"
                    />
                  </div>
                </li>
                <li className = "mt-5">
                  <label className = "text-sm text-blacklight">
                      Requires shipping/delivery?{ ' ' }
                    <span className = "text-red">*</span>
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <LocalShippingOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <select
                      onChange = { (e) => setRequiresshipping(e.target.value) }
                      className = "inline-block text-sm outline-0 w-width97%"
                    >
                      <option
                        selected = { requiresshipping === true ? true : false }
                        value = "true"
                      >
                          Yes, it requires charging
                      </option>
                      <option
                        selected = { requiresshipping === false ? true : false }
                        value = "false"
                      >
                          No charging required
                      </option>
                    </select>
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Product weight
                  </label>
                 
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                    <select onChange = { (e) => setUnit(e.target.value) } className = "inline-block text-sm outline-0 w-width10% border-r border-borderColor">
                      <option selected = { unit?.unit === 'ml' } value = "ml">ML</option>
                      <option selected = { unit?.unit === 'gm' } value = "gm">GM</option>
                      <option selected = { unit?.unit === 'l' } value = "l">L</option>
                      <option selected = { unit?.unit === 'oz' } value = "oz">OZ</option>
                      <option selected = { unit?.unit === 'kg' } value = "kg">KG</option>
                      <option selected = { unit?.unit === 'lb' } value = "lb">LB</option>
                    </select>
                    <div className = "w-width3% ml-1">
                      <span className = "text-secondary">
                        <ScaleOutlinedIcon style = { { fontSize: '18px' } } />
                      </span>
                    </div>
                    <input
                      onChange = { (e) => setProductweight(e.target.value) }
                      value = { productweight }
                      placeholder = "Product weight"
                      className = "inline-block text-sm outline-0 w-width85%"
                    />
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Cost price
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <MonetizationOnOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <input
                      placeholder = "Cost price"
                      className = "inline-block text-sm outline-0 w-width97%"
                    />
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Selling price
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <MonetizationOnOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <input
                      placeholder = "Selling price"
                      className = "inline-block text-sm outline-0 w-width97%"
                    />
                  </div>
                </li>
                <li className = "mt-4">
                  <div className = "flex gap-4">
                    <div className = "w-6/12">
                      <label className = "text-sm text-blacklight">
                          End of reduction
                      </label>
                      <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                        <div className = "width5%">
                          <span className = "text-secondary align-text-top inline-block mr-1">
                            <CalendarMonthOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </div>
                        <div className = "w-width95%">
                          <DatePicker
                            selected = { startDate }
                            onChange = { (date) => setStartDate(date) }
                            placeholderText = "Select a date"
                            className = "outline-none text-sm"
                          />
                        </div>
                      </div>
                    </div>
                    <div className = "w-6/12">
                      <label className = "text-sm text-blacklight">
                          Discounted price
                      </label>
                      <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                        <div className = "w-width5%">
                          <span className = "text-secondary align-text-top inline-block mr-1">
                            <DiscountOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </div>
                        <div className = "w-width95%">
                          <input
                            className = "text-sm outline-none"
                            placeholder = "Discounted price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className = "mt-4">
                  <div className = "flex gap-4">
                    <div className = "w-4/12">
                      <label className = "text-sm text-blacklight">GTIN</label>
                      <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                        <div>
                          <span className = "text-secondary align-text-top inline-block mr-1">
                            <InsertChartOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </div>
                        <div className = "w-width92%">
                          <input onChange = { (e) => setGtin(e.target.value) } value = { gtin } className = "text-sm outline-none" placeholder = "GTIN" />
                        </div>
                      </div>
                    </div>
                    <div className = "w-4/12">
                      <label className = "text-sm text-blacklight">MPN</label>
                      <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                        <div>
                          <span className = "text-secondary inline-block mr-1">
                            <InsertChartOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </div>
                        <div className = "w-width92%">
                          <input onChange = { (e) => setMpn(e.target.value) } value = { mpn } className = "text-sm outline-none" placeholder = "MPN" />
                        </div>
                      </div>
                    </div>
                    <div className = "w-4/12">
                      <label className = "text-sm text-blacklight">
                          SKU <span className = "text-red">*</span>
                      </label>
                      <div className = "flex justify-between border border-borderColor px-2 py-0.5 rounded mt-1">
                        <div>
                          <span className = "text-secondary inline-block mr-1">
                            <InsertChartOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </div>
                        <div className = "w-width92%">
                          <input
                            className = "text-sm outline-none"
                            placeholder = "SKU"
                            value = { storagecode }
                            onChange = { (e) => setStoragecode(e.target.value) }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Maximum quantity per customer
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3% self-center">
                      <span className = "text-secondary align-text-bottom">
                        <PersonOutlineOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <input
                      onChange = { (e) => setMaxqntperCustomer(e.target.value) }
                      value = { maxqntperCustomer }
                      placeholder = "Maximum quantity per customer"
                      className = "inline-block text-sm outline-0 w-width97% self-center"
                    />
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                    Add a category
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <WorkspacePremiumOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <Catloglist
                      handleChangecatlog = { handleChangecatlog }
                      selectedCatlogOption = { selectedCatlogOption }
                      catloglist = { catloglist }
                      setInputValue = { setInputValue }
                      inputValue = { inputValue }
                        
                    />
                  </div>
                </li>
                <li className = "mt-4">
                  <label className = "text-sm text-blacklight">
                      Identify the brand
                  </label>
                  <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                    <div className = "w-width3%">
                      <span className = "text-secondary">
                        <WorkspacePremiumOutlinedIcon
                          style = { { fontSize: '20px' } }
                        />
                      </span>
                    </div>
                    <BrandName
                      handleChangebrand = { handleChangebrand }
                      selectedBrandOption = { selectedBrandOption }
                      brandlist = { brandlist }
                    />
                  </div>
                </li>
                <li>
                  <div className = "border border-borderColor mt-5">
                    <Editor
                      editorState = { editorState }
                      toolbarClassName = "toolbarClassName"
                      wrapperClassName = "wrapperClassName"
                      editorClassName = "editorClassName"
                      onEditorStateChange = { onEditorStateChange }
                    />
                  </div>
                </li>

                <li className = 'mt-5'>
                  <div
                    className = "bg-white inline-block py-1 px-5 cursor-pointer border border-borderColor"
                    onClick = { onOpenAddimgModal }
                  >
                    <span className = "text-secondary">
                      <AddPhotoAlternateOutlinedIcon style = { { fontSize: '16px' } } />
                    </span>
                    <span className = "text-blacklight text-xs">
                      Add a photo
                    </span>
                  </div>
                </li>
                <li> 

                  <div className = "flex justify-between mt-10">
                    <div>
                      <button
                        className = "bg-[#f0f3f3] px-4 py-1.5 text-sm rounded"
                        onClick = { onCloseAddProductModal }
                      >
                        Cancellation
                      </button>
                    </div>
                    <button
                      className = "bg-primary px-4 py-1.5 text-sm rounded text-white ml-3"
                    >
                        Save product data
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>



      <Modal
        open = { addimgModal }
        showCloseIcon = { false }
        onClose = { onCloseAddimgModal }
      >
        <div className = "modalboxsendingmessege">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Product images
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddimgModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3">
            <h3 className = "text-black text-base">Product pictures</h3>
            <p className = "text-sm text-secondary mt-2">
              Size: 1024 × 1024px
            </p>
            <div>
              <div className = "mt-4">
                <label
                  htmlFor = "upload-input"
                  style = { { cursor: 'pointer' } }
                  className = "block bg-[#f0f3f3] rounded text-center py-4 text-base text-blacklight"
                >
                  Click to upload images
                </label>
                <input
                  id = "upload-input"
                  type = "file"
                  accept = "image/*"
                  multiple
                  onChange = { handleImageChange }
                  style = { { display: 'none' } }
                />
              </div>
              <div className = "flex gap-3 flex-wrap mt-3">
                { images.map((image, index) => (
                  <div
                    key = { index }
                    className = "bg-[#f0f3f3] rounded-es rounded-br"
                  >
                    <img
                      src = { image }
                      alt = { `Uploaded ${index}` }
                      style = { { maxWidth: '200px', maxHeight: '200px' } }
                    />
                    <div className = "flex justify-between px-2 py-2">
                      <div className = "self-center">
                        <span className = "cursor-pointer text-red">
                          <DeleteOutlinedIcon style = { { fontSize: '18px' } } />
                        </span>
                      </div>
                      <div className = "self-center">
                        <span className = "text-sm text-blacklight">
                          Basic images
                        </span>
                        <input
                          type = "radio"
                          name = "text"
                          className = "align-text-bottom inline-block ml-1"
                        />
                      </div>
                    </div>
                  </div>
                )) }
              </div>
              <button
                className = "text-sm text-blacklight bg-[#f0f3f3] px-5 py-2 rounded mt-5"
                onClick = { onCloseAddimgModal }
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>


    </>
  );
};

export default AddProduct;