/* eslint-disable react/prop-types */
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddIcon from '@mui/icons-material/Add';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import RemoveIcon from '@mui/icons-material/Remove';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Switch from 'react-switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useDeleteProductoptionMutation,
  useGetproductbyidQuery,
  useUpdateProductoptionMutation
} from '../../redux/api/productApi';
import QuantityManagementAccordian from './QuantityManagementAccordian';

const QuantityManagement = ({ selectedProductId, setSelectedProductId }) => {
  const [quantityProductModal, setQuantityProductModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Arabic');
  const [promotionalArabic, setPromotionalArabic] = useState('');
  const [promotionalEnglish, setPromotionalEnglish] = useState('');
  const [productOption, setproductOption] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [checked, setChecked] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [productoptionid, setProductoptionid] = useState(null);

  const [deleteProductoption,{error: errorDeleteproductoption, isSuccess: isSuccessdeleteProductoption}] = useDeleteProductoptionMutation({ 
    skip: productoptionid === null
  });

  useEffect(() => {
    toast(errorDeleteproductoption?.data?.message);
  },[errorDeleteproductoption]);

  useEffect(() => {

    if(isSuccessdeleteProductoption){
      refetch();
    }

  },[isSuccessdeleteProductoption]);

  useEffect(() => {
    if (productoptionid !== null) {
      deleteProductoption(productoptionid);
    }
  },productoptionid);

  const handleDeleteValue = (id) => {
    setProductoptionid(id);
  };



  const handleSelectChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handlePromotionalArabic = (e) => {
    setPromotionalArabic(e.target.value);
  };

  const handlePromotionalEnglish = (e) => {
    setPromotionalEnglish(e.target.value);
  };


  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  useEffect(() => {
    if (selectedProductId !== null && selectedProductId !== undefined) {
      setQuantityProductModal(true);
    } else {
      setQuantityProductModal(false);
    }
  }, [selectedProductId]);

  const onCloseQuantityProductModal = () => {
    setQuantityProductModal(false);
    setSelectedProductId(null);
  };

  const handleAddValue = () => {
    const newValue = {
      sku: null,
      cost_price: null,
      selling_price: null,
      size: null,
      unit: 'ml',
      stock: 0,
      weight: null,
      description: null,
      discounted_price: null
      
    };
    setproductOption([...productOption, newValue]);
    setQuantity([...quantity, 0]);
  };


  const { error, data, isLoading, isSuccess, refetch } =
    useGetproductbyidQuery(selectedProductId);

  useEffect(() => {
    if (isSuccess && data?.data?.options) {
      const initialQuantities = data.data.options.map(
        (option) => option.stock || 0
      );
      setQuantity(initialQuantities);

      const modifiedOptions = data.data.options.map((option) => ({
        product_option_id: option?.id,
        sku: option?.sku,
        stock: option?.stock,
        cost_price: option?.cost_price,
        selling_price: option?.selling_price,
        size: option?.size,
        unit: option?.unit?option?.unit: 'ml',
        weight: option?.weight,
        discounted_price: option?.discounted_price,
        description: null
      }));

      setproductOption(modifiedOptions);
    }
  }, [isSuccess, data]);

  const incrementQuantity = (index, qnty) => {
    setQuantity((prevQuantity) => {
      const newQuantities = [...prevQuantity];
      newQuantities[index] += qnty;
      return newQuantities;
    });
  };

  const decrementQuantity = (index, qnty) => {
    setQuantity((prevQuantity) => {
      const newQuantities = [...prevQuantity];
      newQuantities[index] =
        newQuantities[index] - qnty > 0 ? newQuantities[index] - qnty : 0;
      return newQuantities;
    });
  };

  const handleQuantityChange = (index, value) => {
    setQuantity((prevQuantity) => {
      const newQuantities = [...prevQuantity];
      newQuantities[index] = Number(value);
      return newQuantities;
    });
  };
  

  const handleInputChange = (index, field, value) => {
    const updatedOptions = productOption.map((option, idx) =>
      idx === index ? { ...option, [field]: value } : option
    );
    setproductOption(updatedOptions);
  };

  const [
    UpdateProductoption,
    {
      error: upadteError,
      isLoading: updateisLoading,
      isSuccess: updateisSuccess
    }
  ] = useUpdateProductoptionMutation();

  useEffect(() => {
    if (upadteError?.data?.message) {
      setErrorMessages(upadteError?.data?.message);
    }
  }, [upadteError]);


  const handleSave = () => {
    const payload = productOption.map((option, index) => ({
      product_option_id: option.product_option_id,
      sku: option.sku,
      stock: quantity[index], // Update the stock from quantity
      cost_price: option.cost_price,
      selling_price: option.selling_price,
      size: option.size,
      unit: option.unit,
      weight: option?.weight,
      discounted_price: option?.discounted_price,
      description: null
    }));
    UpdateProductoption({ id: selectedProductId, body: payload });
  };

  useEffect(() => {
    if (updateisSuccess) {
      onCloseQuantityProductModal();
      setErrorMessages('');
      refetch();
    }
  }, [updateisSuccess]);


  return (
    <>
      <ToastContainer />
      <Modal
        open = { quantityProductModal }
        showCloseIcon = { false }
        onClose = { onCloseQuantityProductModal }
      >
        <div className = "modaladdproduct">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Quantity Management
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseQuantityProductModal }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "px-4 py-3 mt-3">
            <div className = "bg-[#f0f3f3] py-2 px-5 text-primary text-base rounded border border-borderColor">
              <p>You can manage the quantity based on product options</p>
            </div>
            <div className = "flex mt-3 mb-3">
              <h3 className = "text-black text-base self-center font-semibold">
                Activate product options
              </h3>
              <div className = "self-center mt-2 ml-5">
                <Switch
                  onChange = { handleChange }
                  checked = { checked }
                  className = "react-switch"
                  onColor = "#474091"
                  width = { 40 }
                  height = { 20 }
                  handleDiameter = { 16 }
                />
              </div>
            </div>
            { error && <p>{ error?.message }</p> }
            { isLoading && <p>Loading...</p> }
            { checked && (
              <>
                <div className = "bg-[#f7f8f8] rounded border border-borderColor p-4">
                  <div className = "flex gap-3">
                    <div className = "flex w-2/4 justify-between border border-borderColor px-2 py-1 rounded mt-1 bg-white">
                      <div className = "w-width8%">
                        <span className = "text-secondary">
                          <TextSnippetOutlinedIcon
                            style = { { fontSize: '20px' } }
                          />
                        </span>
                      </div>
                      <select className = "inline-block text-sm outline-0 w-width92% bg-white">
                        <option>text</option>
                        <option>the color</option>
                        <option>Picture</option>
                      </select>
                    </div>
                    <div className = "w-2/4">
                      <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5 bg-white">
                        <select
                          className = "inline-block text-sm outline-0 border-r border-borderColor bg-white"
                          value = { selectedLanguage }
                          onChange = { handleSelectChange }
                        >
                          <option value = "Arabic">Arabic</option>
                          <option value = "English">English</option>
                        </select>
                        <div className = " ml-1">
                          <span className = "text-secondary">
                            <SubtitlesOutlinedIcon
                              style = { { fontSize: '18px' } }
                            />
                          </span>
                        </div>
                        { selectedLanguage === 'Arabic' && (
                          <input
                            placeholder = "Option name (e.g. color, size)"
                            className = "inline-block text-sm outline-0 flex-1 ml-2 bg-white"
                            value = { promotionalArabic }
                            onChange = { handlePromotionalArabic }
                          />
                        ) }
                        { selectedLanguage === 'English' && (
                          <input
                            placeholder = "Option name (e.g. color, size)"
                            className = "inline-block text-sm outline-0 flex-1 ml-1 bg-white"
                            value = { promotionalEnglish }
                            onChange = { handlePromotionalEnglish }
                          />
                        ) }
                      </div>
                    </div>
                  </div>
                  { productOption.map((value, index) => (
                    <div key = { value.id } className = "flex" id = { value?.id }>
                      { productOption.length > 1 && (
                        <div className = " cursor-pointer  self-center w-8 h-8 text-center rounded-full border border-borderColor mt-2 ml-2">
                          <span
                            className = "text-red"
                            onClick = { () => handleDeleteValue(value?.product_option_id?value?.product_option_id: null) }
                          >
                            <DeleteOutlinedIcon style = { { fontSize: '18px' } } />
                          </span>
                        </div>
                      ) }
                      <div className = "self-center flex-1">
                        <div className = "flex border border-borderColor px-2 py-1.5 rounded mt-1.5 bg-white">
                          <div className = "border-r border-borderColor">
                            <select
                              className = "inline-block text-sm outline-0 bg-white"
                              defaultValue = { selectedLanguage }
                              onChange = { (e) => handleSelectChange(e) }
                            >
                              <option value = "Arabic">Arabic</option>
                              <option value = "English">English</option>
                            </select>
                          </div>
                          <div className = "self-center px-2 border-r border-borderColor mr-2 align-middle">
                            <input type = "radio" />
                          </div>
                          <div className = "self-center">
                            <span className = "text-secondary">
                              <SubtitlesOutlinedIcon
                                style = { { fontSize: '18px' } }
                              />
                            </span>
                          </div>
                          <div className = "self-center flex-1">
                            <input
                              placeholder = "Value"
                              className = "inline-block text-sm outline-0 w-full ml-2 bg-white pr-2"
                              defaultValue = { value?.size }
                              onChange = { (e) =>
                                handleInputChange(index, 'size', e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )) }

                  <button
                    className = "block border-dashed border-2 border-primary-300 py-1 px-5 w-full text-base text-blacklight mt-3 hover:bg-[#c2caca] duration-500"
                    onClick = { handleAddValue }
                  >
                    <span>
                      <AddOutlinedIcon style = { { fontSize: '20px' } } />
                    </span>{ ' ' }
                    Add new value
                  </button>
                </div>
                <div className = "border-t-2 border-borderColor mt-5">
                  { productOption?.map((item, index) => (
                    <QuantityManagementAccordian
                      key = { index }
                      question = {
                        <p className = "text-blacklight text-sm font-medium">
                          Available quantity <span>({ item?.stock === null?'0': item?.stock })</span>
                        </p>
                      }
                      valueName = { <p>Size ({ item?.size?item?.size: 'NA' })</p> }
                      answer = {
                        <ul>
                          <li className = "mt-3">
                            <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                              <div className = "w-width3%">
                                <span className = "text-secondary">
                                  <AccountBalanceWalletOutlinedIcon
                                    style = { { fontSize: '20px' } }
                                  />
                                </span>
                              </div>
                              <input
                                defaultValue = { item?.selling_price }
                                placeholder = "The price"
                                className = "inline-block text-sm outline-0 w-width97% ml-1"
                                onChange = { (e) =>
                                  handleInputChange(
                                    index,
                                    'selling_price',
                                    e.target.value
                                  )
                                }
                              />
                              <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                                SAR
                              </span>
                            </div>
                          </li>
                          <li className = "mt-2">
                            <div className = "flex justify-between">
                              <div className = "w-width49%">
                                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                                  <div className = "w-width6%">
                                    <span className = "text-secondary">
                                      <AccountBalanceWalletOutlinedIcon
                                        style = { { fontSize: '20px' } }
                                      />
                                    </span>
                                  </div>
                                  <input
                                    defaultValue = { item?.discounted_price }
                                    placeholder = "Discounted price"
                                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'discounted_price',
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                                    SAR
                                  </span>
                                </div>
                              </div>
                              <div className = "w-width49%">
                                <div className = "flex w-full justify-between border border-borderColor px-2 py-1 rounded mt-1">
                                  <div className = "w-width6%">
                                    <span className = "text-secondary">
                                      <AccountBalanceWalletOutlinedIcon
                                        style = { { fontSize: '20px' } }
                                      />
                                    </span>
                                  </div>
                                  <input
                                    placeholder = "Cost price"
                                    defaultValue = { item?.cost_price }
                                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'cost_price',
                                        e.target.value
                                      )
                                    }
                                  />
                                  <span className = "inline-block text-sm text-blacklight border-l border-borderColor pl-2">
                                    SAR
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className = "mt-2">
                            <div className = "flex justify-between">
                              <div className = "w-width49%">
                                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                                  <div className = "w-width6%">
                                    <span className = "text-secondary">
                                      <NotificationsOutlinedIcon
                                        style = { { fontSize: '20px' } }
                                      />
                                    </span>
                                  </div>
                                  <input
                                    placeholder = "Minimum amount of alert"
                                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'min_alert_amount',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className = "w-width49%">
                                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                                  <div className = "w-width6%">
                                    <span className = "text-secondary">
                                      <LocalOfferOutlinedIcon
                                        style = { { fontSize: '20px' } }
                                      />
                                    </span>
                                  </div>
                                  <input
                                    defaultValue = {
                                      item?.sku === null ? '' : item?.sku
                                    }
                                    placeholder = "SKU"
                                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'sku',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className = "mt-2">
                            <div className = "flex justify-between">
                              <div className = "w-width49%">
                                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1">
                                  <div className = "w-width6%">
                                    <span className = "text-secondary">
                                      <QrCode2OutlinedIcon
                                        style = { { fontSize: '20px' } }
                                      />
                                    </span>
                                  </div>
                                  <input
                                    placeholder = "Barcode"
                                    className = "inline-block text-sm outline-0 w-width97% ml-1"
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'barcode',
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className = "w-width49%">
                                <div className = "flex justify-between border border-borderColor px-2 py-1 rounded mt-1.5">
                                  <select 
                                    onChange = { (e) => handleInputChange(index, 'unit', e.target.value) }
                                    className = "inline-block text-sm outline-0 w-width18% border-r border-borderColor"
                                  >
                                    <option selected = { item?.unit === 'ml' } value = "ml">ML</option>
                                    <option selected = { item?.unit === 'gm' } value = "gm">GM</option>
                                    <option selected = { item?.unit === 'l' } value = "l">L</option>
                                    <option selected = { item?.unit === 'oz' } value = "oz">OZ</option>
                                    <option selected = { item?.unit === 'kg' } value = "kg">KG</option>
                                    <option selected = { item?.unit === 'lb' } value = "lb">LB</option>
                                  </select>
                                  <div className = "w-width6% ml-1">
                                    <span className = "text-secondary">
                                      <ScaleOutlinedIcon style = { { fontSize: '18px' } } />
                                    </span>
                                  </div>
                                  <input
                                    defaultValue = { item?.weight }
                                    onChange = { (e) =>
                                      handleInputChange(
                                        index,
                                        'weight',
                                        e.target.value
                                      )
                                    }
                                    placeholder = "Product weight"
                                    className = "inline-block text-sm outline-0 w-width94% ml-1"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className = "mt-5">
                            <p className = "text-sm font-medium text-blacklight border-b border-borderColor pb-3">
                              Quantity
                            </p>
                            <div className = "flex justify-between py-3">
                              <div className = "self-center">
                                <p className = "text-sm text-blacklight">
                                  Product quantity
                                </p>
                              </div>
                              <div className = "self-center">
                                <div className = "flex items-center border border-borderColor">
                                  <button
                                    type = "button"
                                    className = "px-2 py-1 border-r border-borderColor text-secondary bg-white"
                                    onClick = { () => decrementQuantity(index, 1) }
                                  >
                                    <RemoveIcon style = { { fontSize: '18px' } } />
                                  </button>
                                  <input
                                    type = "number"
                                    className = "mx-2 w-28 inline-block px-3 text-center border-0 outline-none"
                                    value = { quantity[index] }
                                    onChange = { (e) => handleQuantityChange(index, e.target.value) }
                                    min = "0"
                                  />
                                  <button
                                    type = "button"
                                    className = "px-2 py-1 border-l border-borderColor text-secondary bg-white"
                                    onClick = { () => incrementQuantity(index, 1) }
                                  >
                                    <AddIcon style = { { fontSize: '18px' } } />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      }
                    />
                  )) }

                  <div className = "mt-2">
                    { errorMessages?.length > 0 && (
                      <ul className = "text-red text-sm">
                        { errorMessages.map((errorObj, index) => (
                          <li key = { index }>
                            { Object.keys(errorObj).map((field) => (
                              <div key = { field }>
                                { field }: { errorObj[field].join(', ') }
                              </div>
                            )) }
                          </li>
                        )) }
                      </ul>
                    ) }
                  </div>
                  <div className = "flex justify-center mt-4">
                    <button
                      className = "bg-primary text-white font-semibold px-4 py-2 rounded"
                      onClick = { handleSave }
                      disabled = { updateisLoading }
                    >
                      { updateisLoading ? 'Saving...' : 'Save' }
                    </button>
                  </div>

                  { /* {
                    upadteError?.data?.message && <p className = 'text-red text-center mt-2'>{ upadteError?.data?.message }</p>
                  } */ }
                </div>
              </>
            ) }
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuantityManagement;