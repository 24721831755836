/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Pagination from 'react-js-pagination';

const CustomBrandpagination = ({ resPerPage, filteredProductsCount, onChange }) => {
  const [currentPage, setCurrentPage] = useState(1);


  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
    onChange(pageNumber);
  };

  return <>
    {
      filteredProductsCount > resPerPage && <Pagination 
        activePage = { currentPage }
        itemsCountPerPage = { resPerPage }
        totalItemsCount = { filteredProductsCount }
        pageRangeDisplayed = { 5 }
        onChange = { setCurrentPageNo }
        nextPageText = { 'Next' }
        prevPageText = { 'Prev' }
        firstPageText = { 'first' }
        lastPageText = { 'Last' }
        itemClass = "age-item"
        linkClass = 'page-link'
      />
    }
  </>;
};

export default CustomBrandpagination;