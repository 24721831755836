/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const ProductExclude = ({handleChangeproductExclude, selectedProductExcludeOption,ProductExcludelist}) => {

 
  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedProductExcludeOption }
        onChange = { handleChangeproductExclude }
        options = { ProductExcludelist }
        isMulti
        placeholder = 'Choose product'
      />
    </>
  );
};

export default ProductExclude;