import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddsaleofferlistMutation, useDeletesaleofferMutation, useSaleofferlistQuery } from '../../redux/api/saleofferApi';
import EditSaleoffers from './EditSaleoffers';
const CreateSaleoffers = () => {

  const [Saleoffer, setSaleoffer] = useState(false);
  const onOpenSaleoffer = () => setSaleoffer(true);
  const onCloseSaleoffer = () => setSaleoffer(false);
  
  const { error, data, isLoading, refetch } = useSaleofferlistQuery();

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [redirecturl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  
  const [addsaleofferlist, { error: saleoffererror, isSuccess: saleofferisSuccess, isLoading: saleofferisloading }] = useAddsaleofferlistMutation();


  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };

  const handleRedirecturlChange = (e) => {
    setRedirectUrl(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {

    if (saleofferisSuccess) {
      setErrorMessge('');
      onCloseSaleoffer();
      refetch();
      setName('');
      setUrl('');
      setRedirectUrl('');
    }
  },[saleofferisSuccess]);

  const handleSubmit = async () => {
    if (!url || !redirecturl) {
      setErrorMessge('Please provide Name, Image url and Redirect url.');
      return;
    }
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image_url', url);
    formData.append('render_url', redirecturl);

    try {
      await addsaleofferlist(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add gift card: ', error);
    }
  };


  const [EditSaleoffer, setEditSaleoffer] = useState(false);
  const [currentSaleoffer, setCurrentSaleoffer] = useState(null);

  const OnopenEditSaleoffer = (item) => {
    setCurrentSaleoffer(item);
    setEditSaleoffer(true);
  };


  const [deletesaleoffer,{ error: deletesaleoffererror, isSuccess: deletesaleofferisSuccess }] = useDeletesaleofferMutation();

  useEffect(() => {

    if(deletesaleoffererror){
      toast(deletesaleoffererror?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletesaleofferisSuccess){
      refetch();
    }

  }, [deletesaleofferisSuccess]);

  const handleSaleofferDelete = async (id) => {
    try {
      await deletesaleoffer(id);
    } catch (error) {
      console.error('Error deleting sale & offer:', error);
    }
  };

  return (
    <>
      <EditSaleoffers EditSaleoffer = { EditSaleoffer } currentSaleoffer = { currentSaleoffer } setEditSaleoffer = { setEditSaleoffer } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <button
              onClick = { onOpenSaleoffer }
              className = "inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded"
            >
              <span>
                <AddOutlinedIcon style = { { fontSize: '20px' } } />
              </span>{ ' ' }
                Add Sale & Offers
            </button>

            <div className = "border border-borderColor rounded mt-1 p-3">
              <table className = "min-w-full leading-normal ">
                <thead>
                  <tr>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                      NAME
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                      IMAGES
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                      REDIRECT URL
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300  text-sm  text-gray-700 font-medium uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { error && <p>{ error?.message }</p> }
                  { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
                  { data && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }

                  { data?.data.map((item) => (
                    <tr key = { item?.id }>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        <p className = 'text-sm text-blacklight'>{ item?.name }</p>
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        <img
                          className = "w-16 self-center"
                          src = { item?.image_url }
                          alt = "catlogimg"
                        />
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm">
                        <a target = '_blank'
                          href = { item?.render_url }
                          className = "text-blacklight text-left hover:underline cursor-pointer" rel = "noreferrer"
                        >
                          { item?.render_url === null?'NA': item?.render_url }
                        </a>
                      </td>
                      <td className = "px-2 py-3 bg-white text-left">
                        <button
                          onClick = { () => OnopenEditSaleoffer(item) }
                          className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                        >
                          <span>
                            <EditOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </button>
                        <button onClick = { () => handleSaleofferDelete(item?.id) } className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                          <span>
                            <DeleteOutlineOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open = { Saleoffer }
        showCloseIcon = { false }
        onClose = { onCloseSaleoffer }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add Sale & Offers
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseSaleoffer }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
                    Name
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter categories name"
                value = { name }
                onChange = { handleNameChange }
              />
              { saleoffererror && (
                <div className = "mt-2 text-red text-sm">
                  { saleoffererror?.data?.message?.name }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-3">
              Image url
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { url }
                onChange = { handleUrlChange }
              />
              { saleoffererror && (
                <div className = "mt-2 text-red text-sm">
                  { saleoffererror?.data?.message?.image_url }
                </div>
              ) }
              <label className = "text-blacklight text-sm self-center block mt-3">
              Redirect url
              </label>
              <input
                placeholder = "Enter redirect url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { redirecturl }
                onChange = { handleRedirecturlChange }
              />
              { saleoffererror && (
                <div className = "mt-2 text-red text-sm">
                  { saleoffererror?.data?.message?.category_render_url }
                </div>
              ) }
              
              <div>
               
                
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <button
                  className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                  onClick = { handleSubmit }
                  disabled = { saleofferisloading }
                >
                  { saleofferisloading ? 'Saving...' : 'Save' }
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      
    </>
  );
};

export default CreateSaleoffers;
