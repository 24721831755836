/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { useFragrancelistbyidQuery, useUpdatefragrancelistMutation } from '../../redux/api/scentfamilyApi';


const EditScentFragrance = ({editcategory, currentcategory, setEditcategory,refetch}) => {
  console.log('currentcategory',currentcategory);

  const [enName, setEnName] = useState('');
  const [arName, setArName] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  

  const [updatecategory, { error: updatefragranceerror, isSuccess: updatefragranceisSuccess, isLoading: updatefragranceisloading }] = useUpdatefragrancelistMutation();

  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
    setErrorMessge('');
  };

  const handleARNameChange = (e) => {
    setArName(e.target.value);
    setErrorMessge('');
  };
  


  useEffect(() => {
    if (updatefragranceisSuccess) {
      setErrorMessge('');
      setEditcategory(false);
      refetch();
    }
  }, [updatefragranceisSuccess]);

  const handleUpdateSubmit = async () => {
    if (!enName || !arName) {
      setErrorMessge('Please provide name arabic and english both.');
      return;
    }

    const formData = new FormData();
    formData.append('name_en', enName);
    formData.append('name_ar', arName);

    try {
      await updatecategory({ body: formData, id: currentcategory?.item_id });
    } catch (error) {
      setErrorMessge('Failed to update category: ', error);
    }
  };

  const { error: getfragranceError, data: getfragrancedata, isLoading: getfragranceisloading } = useFragrancelistbyidQuery(currentcategory?.item_id, {skip: !currentcategory?.item_id});

  useEffect(() => {
    if (getfragrancedata) {
      setEnName(getfragrancedata?.data?.name_en);
      setArName(getfragrancedata?.data?.name_ar);
    }
  }, [getfragrancedata, currentcategory?.item_id]);

  
  return (
    <>


      <Modal
        open = { editcategory }
        showCloseIcon = { false }
        onClose = { () => setEditcategory(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
               Edit  Scent Family
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setEditcategory(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { getfragranceError && <p className = 'text-sm text-red'>{ getfragranceError?.data?.message }</p> }
            { getfragranceisloading && <p className = 'text-sm'>{ getfragranceisloading ? 'Fragrance data loading...' : '' }</p> }
            <div className = "text-start">
              <div>
                <label className = "text-blacklight text-sm self-center block">
                EN (Name)
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter categories name"
                  value = { enName }
                  onChange = { handleEnNameChange }
                />
                { updatefragranceerror && (
                  <div className = "mt-2 text-red text-sm">
                    { updatefragranceerror?.data?.message?.name_en }
                  </div>
                ) }
              </div>
              <div className = 'mt-2'>
                <label className = "text-blacklight text-sm self-center block">
                AR (Name)
                </label>
                <input
                  className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter categories name"
                  value = { arName }
                  onChange = { handleARNameChange }
                />
                { updatefragranceerror && (
                  <div className = "mt-2 text-red text-sm">
                    { updatefragranceerror?.data?.message?.name_ar }
                  </div>
                ) }
              </div>
              
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatefragranceisloading }
              >
                { updatefragranceisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditScentFragrance;
