import React from 'react';
import { Link } from 'react-router-dom';

const ReportVisits = () => {

  return(
    <>
      <div className = 'text-center'>
        <div className = 'w-32 mx-auto mt-12'>
          <svg xmlns = "http://www.w3.org/2000/svg" fill = '#9CA3AF' viewBox = "0 0 512 512">
            <g>
              <path d = "M472,128H461.61a72,72,0,1,0-107.22,0H256V48a8,8,0,0,0-8-8H216a8,8,0,0,0-8,8v80h-8V16a8,8,0,0,0-8-8H160a8,8,0,0,0-8,8V128h-8V72a8,8,0,0,0-8-8H104a8,8,0,0,0-8,8v56H40A32.036,32.036,0,0,0,8,160V400a32.036,32.036,0,0,0,32,32H204.9l-10.67,32H168a8,8,0,0,0-8,8v16H80a8,8,0,0,0,0,16H432a8,8,0,0,0,0-16H352V472a8,8,0,0,0-8-8H317.77L307.1,432H472a32.036,32.036,0,0,0,32-32V160A32.036,32.036,0,0,0,472,128ZM416,24.58A56.11,56.11,0,0,1,463.42,72H416ZM411.31,88h52.11a55.962,55.962,0,0,1-88.94,36.83ZM352,80a56.086,56.086,0,0,1,48-55.42V76.69l-36.83,36.83A55.708,55.708,0,0,1,352,80ZM224,56h16V208H224Zm-24,88h8v64h-8ZM168,24h16V208H168ZM144,144h8v64h-8ZM112,80h16V208H112ZM336,480v8H176v-8ZM211.1,464l10.67-32h68.46l10.67,32ZM488,400a16.021,16.021,0,0,1-16,16H40a16.021,16.021,0,0,1-16-16V384H488Zm0-32H24V160a16.021,16.021,0,0,1,16-16H96v64H88V160a8,8,0,0,0-8-8H48a8,8,0,0,0-8,8v56a8,8,0,0,0,8,8H304a8,8,0,0,0,8-8V160a8,8,0,0,0-8-8H272a8,8,0,0,0-8,8v48h-8V144H375.08a71.733,71.733,0,0,0,65.84,0H472a16.021,16.021,0,0,1,16,16ZM72,168v40H56V168Zm208,40V168h16v40Z"></path><path d = "M96,392H48a8,8,0,0,0,0,16H96a8,8,0,0,0,0-16Z"></path><path d = "M128,392h-8a8,8,0,0,0,0,16h8a8,8,0,0,0,0-16Z"></path>
              <path d = "M344,208a7.974,7.974,0,0,0,5.657-2.343L379.313,176H448a8,8,0,0,0,0-16H376a8,8,0,0,0-5.657,2.343l-32,32A8,8,0,0,0,344,208Z"></path>
              <path d = "M448,296a24,24,0,0,0-20.75,11.97L343.5,259.11a24,24,0,0,0-47.11.6l-84.28,63.21a23.979,23.979,0,0,0-41.48,2.18l-83.3-34.71a24.01,24.01,0,1,0-1.96,16.51l83.3,34.71a23.995,23.995,0,0,0,46.94-1.32l84.28-63.21a23.973,23.973,0,0,0,40.86-1.05l83.75,48.86A24,24,0,1,0,448,296Zm-376,.04a8.02,8.02,0,1,1,0-.04ZM192,344a8.011,8.011,0,0,1-8-8v-.04a8,8,0,1,1,8,8.04Zm128-72a8,8,0,1,1,8-8A8.011,8.011,0,0,1,320,272Zm128,56a8,8,0,1,1,8-8A8.011,8.011,0,0,1,448,328Z"></path>
              <path d = "M384,344H328a8,8,0,0,0,0,16h56a8,8,0,0,0,0-16Z"></path>
              <path d = "M384,320H360a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16Z"></path>
              <path d = "M48,248h88a8,8,0,0,0,0-16H48a8,8,0,0,0,0,16Z"></path>
              <path d = "M160,248h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z"></path>
            </g>
          </svg>
        </div>
        <h3 className = 'text-lg text-black font-medium mt-2'>There is no data at the moment</h3>
        <p className = 'text-sm text-secondary mt-2'>To show the number of visits to the store, please link to Google Analytics</p>
        <Link to = '#' className = 'text-sm bg-[#f0f3f3] inline-block py-2 px-6 rounded text-blacklight mt-5'>Activate connectivity</Link>
      </div>
    </>
  );
};

export default ReportVisits;