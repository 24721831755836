/* eslint-disable react/prop-types */
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import { useCatloglistQuery, useGetnavigationbyidQuery, useUpdatenavigationMutation } from '../../redux/api/AddnavigationApi';


const Editnavigation = ({editnavigation, currentnavigation, setEditnavigation,refetch}) => {

  const [Enname, setEnName] = useState('');
  const [Arname, setArName] = useState('');
  const [url, setUrl] = useState('');
  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const [selectedCategoryid, setSelectedCategoryid] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [Catloglist, setCatloglist] = useState([]);
  const [errorMessage, setErrorMessge] = useState('');
  const [updatecategory, { error: updatenavigationerror, isSuccess: updatenavigationisSuccess, isLoading: updatenavigationisloading }] = useUpdatenavigationMutation();

  const handleEnNameChange = (e) => {
    setEnName(e.target.value);
    setErrorMessge('');
  };

  const handleArNameChange = (e) => {
    setArName(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {
    if (updatenavigationisSuccess) {
      setErrorMessge('');
      setEditnavigation(false);
      refetch();
    }
  }, [updatenavigationisSuccess]);

  const handleUpdateSubmit = async () => {
    if (!Enname || !Arname || (!url && !selectedCategoryid)) {
      setErrorMessge('Please provide name and category.');
      return;
    }

    const formData = new FormData();
    formData.append('name_in_english', Enname);
    formData.append('name_in_arabic', Arname);
    formData.append('url', selectedCategoryid === 'Custom URL'?url:selectedCategoryid);

    try {
      await updatecategory({ body: formData, id: currentnavigation?.id });
    } catch (error) {
      setErrorMessge('Failed to update navigation: ', error);
    }
  };


  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: categoryisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (categoryisSuccess) {
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };
      const options = categorydata?.data.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setCatloglist([customOption, ...(options || [])]);
    }
  }, [categoryisSuccess, categorydata]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChangecategory = (selectedOption) => {
    setSelectedCategoryOption(selectedOption);
    setSelectedCategoryid(selectedOption?.label);
  };

  const { error: getnavigationError, data: getnavigationdata, isLoading: getnavigationisloading } = useGetnavigationbyidQuery(currentnavigation?.id, {skip: !currentnavigation?.id});
  useEffect(() => {
    if (getnavigationdata) {
      setEnName(getnavigationdata?.data?.name_in_english);
      setArName(getnavigationdata?.data?.name_in_arabic);
      setUrl(getnavigationdata?.data?.url);

      const matchedOption = Catloglist.find(option => option.label === getnavigationdata?.data.url);
      if (matchedOption) {
        setSelectedCategoryOption(matchedOption);
        setSelectedCategoryid(matchedOption?.label);
      }else{
        const customOption = {
          label: 'Custom URL',
          value: 'customurl'
        };

        setSelectedCategoryOption(customOption);
        setSelectedCategoryid(customOption?.label);
      }
    }
  }, [getnavigationdata, currentnavigation?.id, Catloglist]);

  

  
  return (
    <>


      <Modal
        open = { editnavigation }
        showCloseIcon = { false }
        onClose = { () => setEditnavigation(false) }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
              Edit Navigation
            </h3>
            <div className = "self-center">
              <span
                onClick = { () => setEditnavigation(false) }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
         
          <div className = "flex-col items-center py-8 px-8 text-center">
            { getnavigationError && <p className = 'text-sm text-red'>{ getnavigationError?.data?.message }</p> }
            { getnavigationisloading && <p className = 'text-sm'>{ getnavigationisloading ? 'Fragrance data loading...' : '' }</p> }
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
              En (name)
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter navigation name"
                value = { Enname }
                onChange = { handleEnNameChange }
              />
              { updatenavigationerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatenavigationerror?.data?.message?.name_in_english }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-2">
              Ar (name)
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter navigation name"
                value = { Arname }
                onChange = { handleArNameChange }
              />
              { updatenavigationerror && (
                <div className = "mt-2 text-red text-sm">
                  { updatenavigationerror?.data?.message?.name_in_arabic }
                </div>
              ) }


              <div>
                <Select
                  className = 'inline-block text-sm mt-3 outline-0 w-full text-secondary'
                  value = { selectedCategoryOption }
                  onChange = { handleChangecategory }
                  options = { Catloglist }
                  inputValue = { inputValue }
                  onInputChange = { handleInputChange }
                  placeholder = 'Choose category'
                />
              </div>
              {
                selectedCategoryid === 'Custom URL' && <input
                  className = "mt-3 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter url"
                  value = { url }
                  onChange = { handleUrlChange }
                />
              }
              
              
              <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
              <button
                className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                onClick = { handleUpdateSubmit }
                disabled = { updatenavigationisloading }
              >
                { updatenavigationisloading ? 'Updating...' : 'Update' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Editnavigation;
