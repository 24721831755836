import React from 'react';
import { Link } from 'react-router-dom';

const HighestPayingCustomers = () => {

  return(
    <>
      <ul>
        <li className = 'mt-6'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
        <li className = 'mt-5'>
          <div className = 'flex justify-between'>
            <Link className = 'flex self-center'>
              <div className = 'w-10 h-10'>
                <img className = 'w-full h-full rounded-full' src = 'https://cdn.assets.salla.network/prod/admin/cp/assets/images/avatar_male.png' alt = 'user' />
              </div>
              <h3 className = 'text-sm text-blacklight font-medium self-center ml-2'>Old jalal</h3>
            </Link>
            <div className = 'self-center'>
              <p className = 'text-sm text-secondary'>1 order</p>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default HighestPayingCustomers;