import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddfragrancelistMutation, useCatloglistQuery, useDeletefragranceMutation, useFragrancelistQuery } from '../../redux/api/catlogfragranceApi';

const CatlogFragrance = () => {
  const [selectedProductOption, setSelectedProductOption] = useState([]);
  const [selectedProductid, setSelectedProductid] = useState([]);
  const [Productlist, setProductlist] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: productisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (productisSuccess) {
      const options = categorydata?.data.map((c) => ({
        label: c.name,
        value: c.id
      }));
      setProductlist(options || []);
    }
  }, [productisSuccess, categorydata]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleChangeproduct = (selectedOption) => {
    setSelectedProductOption(selectedOption);
    const transformedOption = selectedOption.map(option => ({
      product_id: option.value
    }));
    setSelectedProductid(transformedOption);
  };

  const { error: errortrendingitemlist, data: datatrendingitemlist, isLoading: isLoadingtrendingitemlist, refetch } = useFragrancelistQuery();

  const [addfragrancelist, { error: errorcatlogfragrance, data: datacatlogfragrance, isLoading: isLoadingcatlogfragrance, isSuccess: isSuccesscatlogfragrance }] = useAddfragrancelistMutation();

  useEffect(() => {
    if (errorcatlogfragrance) {
      toast.error(errorcatlogfragrance?.message);
    }
  }, [errorcatlogfragrance]);

  const handlecatlogfragrance = (e) => {
    e.preventDefault();

    const data = {
      'products': selectedProductid
    };

    addfragrancelist(data);
  };

  useEffect(() => {
    if (isSuccesscatlogfragrance) {
      toast.success(datacatlogfragrance?.message);
      refetch();
      setSelectedProductOption([]);
      setSelectedProductid([]);
    }
  }, [isSuccesscatlogfragrance, datacatlogfragrance, refetch]);

  const [deletepopularitem, { error: errordeleteProductitem, data: datadeleteProductitem, isSuccess: deleteProductitemisSuccess }] = useDeletefragranceMutation();

  useEffect(() => {
    if (errordeleteProductitem) {
      toast.error(errordeleteProductitem?.data?.message);
    }
  }, [errordeleteProductitem]);

  const handleDeleteproductitem = (itemid) => {
    deletepopularitem(itemid);
  };

  useEffect(() => {
    if (deleteProductitemisSuccess) {
      toast.success(datadeleteProductitem?.data?.message);
      refetch();
    }
  }, [deleteProductitemisSuccess, datadeleteProductitem, refetch]);

  

  return (
    <>
      <ToastContainer />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <Select
              className = 'inline-block text-sm outline-0 w-full text-secondary'
              value = { selectedProductOption }
              onChange = { handleChangeproduct }
              options = { Productlist }
              inputValue = { inputValue }
              onInputChange = { handleInputChange }
              isMulti
              placeholder = 'Choose category'
            />
            <div className = "mb-8">
              <form onSubmit = { handlecatlogfragrance }>
                <button className = { `${!selectedProductOption?.length ? 'bg-primary-300' : 'bg-primary'} px-4 py-2 text-sm rounded text-white mt-3` } disabled = { !selectedProductOption?.length }>
                  { isLoadingcatlogfragrance ? 'Loading...' : 'Add Category' }
                </button>
              </form>
            </div>
            <h3 className = "text-blacklight text-lg font-semibold uppercase pb-2">fragrance Category</h3>
            <div className = "shadow-customcard rounded p-3">
              <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className = "inline-block min-w-full overflow-hidden">
                  <table className = "min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Name
                        </th>
                        <th className = "text-right px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { errortrendingitemlist && <p className = 'text-sm text-red'>{ errortrendingitemlist?.data?.message }</p> }
                      { isLoadingtrendingitemlist && <p className = 'text-sm text-blacklight'>{ isLoadingtrendingitemlist ? '...Loading' : '' }</p> }
                      { datatrendingitemlist && <p className = 'text-sm text-blacklight mt-2 ml-5'>{ datatrendingitemlist?.data?.length === 0 && 'No data found' }</p> }
                      { datatrendingitemlist?.data?.map((item) => (
                        <tr key = { item?.id }>
                          <td className = "px-5 py-2 border-b border-boderColor-300 bg-white text-sm">
                            <p className = "text-blacklight whitespace-no-wrap">
                              { item?.product?.name }
                            </p>
                          </td>
                          <td className = "px-5 py-2 border-b border-boderColor-300 bg-white text-sm text-right">
                            <span onClick = { () => handleDeleteproductitem(item?.item_id) } className = "cursor-pointer text-primary inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                              <ClearOutlinedIcon style = { {fontSize: '20px'} } />
                            </span>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatlogFragrance;
