
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import DoneIcon from '@mui/icons-material/Done';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useReviewlistQuery, useSendpermissionMutation } from '../../redux/api/managereviewsApi';


const ManageReviews = () => {

  const {error: errorReviewlist, isLoading: isLoadingReviewlist, data: dataReviewlist, refetch} = useReviewlistQuery();

  const [sendpermission,{error: errorpermission,data: datapermission , isSuccess: isSuccesspermission}] = useSendpermissionMutation();

  useEffect(() => {
    if(errorpermission){
      toast.error(errorpermission?.data?.message);
    }
  }, [errorpermission]);

  useEffect(() => {
    if(isSuccesspermission){
      // toast.success(datapermission?.message);
      refetch();
    }
  },[isSuccesspermission,datapermission]);

  const handlePermission = async (item) => {
    const formdata = new FormData();
    formdata.append('action', item?.is_approved === false ? 'approve' : 'deapprove');

    try {
      await sendpermission({ body: formdata, id: item?.id });
    } catch(error) {
      console.log('Failed to update permission', error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
           
            <h3 className = "text-blacklight text-lg font-semibold uppercase pb-2">Manage reviews</h3>
            <div className = "shadow-customcard rounded p-3">
              <div className = "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className = "inline-block min-w-full overflow-hidden">
                  <table className = "min-w-full leading-normal">
                    <thead>
                      <tr>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Product
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Name
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Rating
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Comment
                        </th>
                        <th className = "px-5 py-3 border-b-2 border-boderColor-300 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { errorReviewlist && <p className = 'text-sm text-red'>{ errorReviewlist?.data?.message }</p> }
                      { isLoadingReviewlist && <p className = 'text-sm text-blacklight'>{ isLoadingReviewlist ? '...Loading' : '' }</p> }
                      { dataReviewlist && <p className = 'text-sm text-blacklight mt-2 ml-5'>{ dataReviewlist?.data?.length === 0 && 'No data found' }</p> }
                      { dataReviewlist?.data?.map((item) => (
                        <tr key = { item?.id }>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <img className = "text-blacklight whitespace-no-wrap w-20" src = { item?.product_images[0] } alt = "productimg" />
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <p className = "text-blacklight whitespace-no-wrap">
                              { item?.product_name }
                            </p>
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <p>{ item?.score }</p>
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                            <p>{ item?.review }</p>
                          </td>
                          <td className = "px-5 py-0 border-b border-boderColor-300 bg-white text-sm">
                           
                            <span onClick = { () => handlePermission(item) }  className = { `cursor-pointer inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2 ${item?.is_approved === false?'text-primary': 'text-red'}` }>
                              { item?.is_approved === false ?<DoneIcon className = 'text-primary' style = { {fontSize: '20px'} } /> : <DoDisturbAltIcon className = 'text-red-400' style = { {fontSize: '20px'} } /> }
                            </span>
                          </td> 
                        </tr>
                      )) }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageReviews;
