/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Switch from 'react-switch';

const CreatedisCouponcollectionModal = ({ createCollectioncoupon, setCreateCollectioncoupon }) => {
  const onCloseCreatecouponModal = () => {
    setCreateCollectioncoupon(false);
  };

  const [activetab, setActivetab] = useState(1);

  const handleTab = (item) => {
    setActivetab(item);
  };

  const [startDate, setStartDate] = useState(null);
  const [startDatewithto, setStartDatewithto] = useState(null);

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const [creationcheck, setCreationcheck] = useState(1);

  const handleAutomaticCreation = (item) => {
    setCreationcheck(item);
  };

  return (
    <Modal open = { createCollectioncoupon } showCloseIcon = { false } onClose = { onCloseCreatecouponModal }>
      <div className = 'modaladdproduct'>
        <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
          <h3 className = 'text-blacklight text-base font-semibold self-center'>Create discount coupons</h3>
          <div className = 'self-center'><span onClick = { onCloseCreatecouponModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
        </div>
        <div className = 'px-4 py-3'>
          <div className = 'flex border border-borderColor rounded justify-between'>
            <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 1 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(1) }>
              <h3 className = 'text-sm text-blacklight'><span><ListOutlinedIcon style = { { fontSize: '20px' } } /></span> Coupon data</h3>
            </div>
            <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 2 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(2) }>
              <h3 className = 'text-sm text-blacklight'><span><FileDownloadDoneOutlinedIcon style = { { fontSize: '20px' } } /></span> Included in the coupon</h3>
            </div>
            <div className = { `w-2/6 text-center py-3 cursor-pointer ${activetab === 3 ? 'bg-[#f0f3f3]' : ''}` } onClick = { () => handleTab(3) }>
              <h3 className = 'text-sm text-blacklight'><span><FolderOffOutlinedIcon style = { { fontSize: '20px' } } /></span> Excluded from the coupon</h3>
            </div>
          </div>
          {
            activetab === 1 && <>
              <ul>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Group name</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><ConfirmationNumberOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <input placeholder = 'group name' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <div>
                    <p className = 'text-sm text-blacklight inline-block mt-2'>How to add coupons *</p>
                    <div className = 'flex mt-1'>
                      <div className = 'border border-borderColor px-4 py-2 rounded cursor-pointer' onClick = { () => handleAutomaticCreation(1) }>
                        <label className = 'text-sm text-blacklight cursor-pointer' htmlFor = 'creation'>Automatic creation</label>
                        <input type = 'radio' name = 'middle' id = 'creation' className = 'align-middle inline-block ml-2' checked = { creationcheck == 1?true: false } />
                      </div>
                      <div className = 'border border-borderColor px-4 py-2 rounded ml-2 cursor-pointer' onClick = { () => handleAutomaticCreation(2) }>
                        <label className = 'text-sm text-blacklight cursor-pointer' htmlFor = 'upload'>File upload is ready</label>
                        <input type = 'radio' name = 'middle' id = 'upload' className = 'align-middle inline-block ml-2' checked = { creationcheck == 2?true: false } />
                      </div>
                    </div>
                  </div>
                </li>
                {
                  creationcheck == 1 && <>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>The coupon begins with the letter: (maximum 5 English letters)</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><StarOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input placeholder = 'The coupon begins with the letter: (maximum 5 English letters)' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                    <li className = 'mt-3'>
                      <label className = 'text-sm text-blacklight inline-block mt-2'>Number of coupons in the group</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><DynamicFeedOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <input placeholder = 'Number of coupons in the group' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                      </div>
                    </li>
                  </>
                }
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Discount type for the customer</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><MenuOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                      <option>Discount type for the customer</option>
                      <option>A percentage of the customer's total purchases</option>
                      <option>A fixed amount of the customer's total purchases</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <div className = 'flex justify-between'>
                    <div className = 'w-width48%'>
                      <label className = 'text-sm text-blacklight inline-block'>Coupon start date</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <div className = 'w-width95% text-sm self-center'>
                          <DatePicker
                            selected = { startDate }
                            onChange = { (date) => setStartDate(date) }
                            className = 'outline-none'
                            placeholderText = 'Coupon start date'
                          />
                        </div>
                      </div>
                    </div>
                    <div className = 'w-width48%'>
                      <label className = 'text-sm text-blacklight inline-block'>Coupon expiration date</label>
                      <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                        <div className = 'width5% self-center'><span className = 'text-secondary inline-block mr-1'><CalendarMonthOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                        <div className = 'w-width95% text-sm self-center'>
                          <DatePicker
                            selected = { startDatewithto }
                            onChange = { (date) => setStartDatewithto(date) }
                            className = 'outline-none'
                            placeholderText = 'Coupon expiration date'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>With free shipping?</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalShippingOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                      <option>With free shipping?</option>
                      <option>no</option>
                      <option>Yes</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Discounted products excluded</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalOfferOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <select className = 'inline-block text-sm outline-0 w-width97% text-secondary '>
                      <option>Excluding discounted products?</option>
                      <option>no</option>
                      <option>Yes</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>(minimum purchases do not include tax value)</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><LocalAtmOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <input placeholder = 'Minimum purchases' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Number of times used for all</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><GroupOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <input placeholder = 'Number of times used for all' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Number of times of use per customer</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                    <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><PermIdentityOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                    <input placeholder = 'Number of times of use per customer' className = 'inline-block text-sm outline-0 w-width97% self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <div className = 'flex justify-between'>
                    <div>
                      <h3 className = 'text-base text-blacklight'>Marketing coupon</h3>
                      <p className = 'text-xs text-secondary'>Enter the marketer’s data and his commission from sales. You can also share the statistics link after creating the coupon</p>
                    </div>
                    <div className = 'self-center'>
                      <Switch
                        onChange = { handleChange }
                        checked = { checked }
                        className = "react-switch"
                        onColor = "#474091"
                        width = { 40 }
                        height = { 20 }
                        handleDiameter = { 16 }
                      />
                    </div>
                  </div>
                </li>
                <li className = 'mt-6'>
                  <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                </li>
              </ul>
            </>
          }
          {
            activetab === 2 && <>
              <ul>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Payment methods included in the coupon</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>All payment methods</option>
                      <option>credit card</option>
                      <option>PayPal</option>
                      <option>Bezel</option>
                      <option>Bank transfer</option>
                      <option>Paiement when recieving</option>
                      <option>Apple Pay</option>
                      <option>Tamara</option>
                      <option>Tabby</option>
                      <option>Spotii</option>
                      <option>Knet</option>
                      <option>customer::customers.feature_payment.mispay_installment</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Categories included</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>Choose categories</option>
                      <option>Men's perfumes</option>
                      <option>Women's perfumes</option>
                      <option>Unisex perfumes</option>
                      <option>Luxury perfumes "Nish"</option>
                      <option>Body perfumes</option>
                      <option>Hair perfume</option>
                      <option>Oud perfumes</option>
                      <option>Daily deals</option>
                      <option>Mini perfumes</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Products included</label>
                  <p className = 'text-xs text-secondary'>You can search for ready-made products that do not have options</p>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <input placeholder = 'Search for the product...' className = 'inline-block text-sm outline-0 w-full self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Customer group covered</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>Choose group</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Customer email domain</label>
                  <p className = 'text-xs text-secondary'>You can search for ready-made products that do not have options</p>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <input placeholder = 'beautyicon.store' className = 'inline-block text-sm outline-0 w-full self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-6'>
                  <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                </li>
              </ul>
            </>
          }
          {
            activetab === 3 && <>
              <ul>
                
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Categories included</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>Choose categories</option>
                      <option>Men's perfumes</option>
                      <option>Women's perfumes</option>
                      <option>Unisex perfumes</option>
                      <option>Luxury perfumes "Nish"</option>
                      <option>Body perfumes</option>
                      <option>Hair perfume</option>
                      <option>Oud perfumes</option>
                      <option>Daily deals</option>
                      <option>Mini perfumes</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Brands excluded</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>Amouage</option>
                      <option>ESCADA</option>
                      <option>ELIE SAAB</option>
                      <option>Orto Parisi</option>
                      <option>Estée Lauder</option>
                      <option>Azzaro</option>
                      <option>Initio</option>
                    </select>
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Products included</label>
                  <p className = 'text-xs text-secondary'>You can search for ready-made products that do not have options</p>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <input placeholder = 'Search for the product...' className = 'inline-block text-sm outline-0 w-full self-center ml-1' />
                  </div>
                </li>
                <li className = 'mt-3'>
                  <label className = 'text-sm text-blacklight inline-block mt-2'>Excluded customer group</label>
                  <div className = 'flex justify-between border border-borderColor px-2 py-2 rounded mt-1'>
                    <select className = 'inline-block text-sm outline-0 w-full text-secondary '>
                      <option>Choose group</option>
                    </select>
                  </div>
                </li>
                
                <li className = 'mt-6'>
                  <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                </li>
              </ul>
            </>
          }
        </div>
      </div>
    </Modal>
  );
};

export default CreatedisCouponcollectionModal;
