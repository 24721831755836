import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import Sidebar from '../../../components/Sidebar';
import TopHeader from '../../../components/TopHeader';


const LoyaltySystemForm = () => {

  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  return (
    <>
     
      <div className = 'flex bg-white'>
        <div className = 'w-64'>
          <Sidebar />
        </div>
        <div className = 'w-full py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { { fontSize: '20px' } } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Loyalty system form</span></li>
          </ul>
         
          <div className = 'border border-borderColor rounded p-4 mt-5'>
            <h3 className = 'text-blacklight text-lg font-medium'>General options</h3>
            <div className = 'flex justify-between mt-5'>
              <div>
                <h3 className = 'text-base text-blacklight'>Activate the loyalty system</h3>
                <p className = 'text-sm text-secondary'>Activate the loyalty system to be able to make your customers happy and gain their loyalty</p>
              </div>
              <div>
                <Switch
                  onChange = { handleChange }
                  checked = { checked }
                  className = "react-switch"
                  onColor = "#474091"
                  width = { 40 }
                  height = { 20 }
                  handleDiameter = { 16 }
                />
              </div>
            </div>
            <ul>
              <li className = 'mt-4'>
                <label className = 'text-sm text-blacklight inline-block'>System name</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-8 self-center'><span className = 'text-secondary align-text-bottom'><DevicesOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <input placeholder = 'Enter the system name' className = 'inline-block text-sm outline-0 w-full self-center' />
                </div>
              </li>
              <li className = 'mt-4'>
                <label className = 'text-sm text-blacklight inline-block'>Promotional description</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-8 self-center'><span className = 'text-secondary relative top-top10'><InsertCommentOutlinedIcon style = { { fontSize: '20px' } } /></span></div>
                  <textarea placeholder = 'Enter a promotional word for the system' className = 'inline-block text-sm outline-0 w-full self-center' />
                </div>
              </li>
              <li className = 'mt-4'>
                <label className = 'text-sm text-blacklight inline-block'>An image symbolizing the system</label>
                <p className = 'text-xs text-secondary'>Choose an appropriate image to appear on the interface of the loyalty page, with dimensions no less than 1200 pixels wide by 250 pixels high.</p>
                <label className = 'bg-[#f0f3f3] rounded block py-3 px-4 mt-2 text-center'>
                  <h3 className = 'text-blacklight text-base'>Drag and drop the image here</h3>
                  <p className = 'text-sm text-secondary'>Or browse from your device</p>
                  <input type = 'file' style = { {display: 'none'} } />
                </label>
                <p className = 'text-sm text-blacklight mt-1'>Only jpg, jpeg, png images are allowed*</p>
              </li>
            </ul>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-10'>
            <div className = 'flex justify-between'>
              <div>
                <h3 className = 'text-base text-blacklight font-medium'>Points</h3>
                <p className = 'text-xs text-secondary'>Please add a method to obtain points so that the loyalty program appears in your store</p>
              </div>
              <div>
                <p className = 'bg-[#f0f3f3] text-sm text-secondary py-2 px-5 rounded-full'>Add at least one method before activation</p>
              </div>
            </div>
            <div className = 'text-center mt-10'>
              <h3 className = 'text-base font-medium text-blacklight'>Ways to earn points</h3>
              <p className = 'text-sm text-secondary mt-2'>Add the methods that you want the customer to get points if he uses them, specifying the number of points and their date for each method.</p>
              <button className = 'inline-block border border-borderColor rounded py-1.5 px-4 text-sm text-blacklight mt-3'>Add a new method</button>
            </div>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-10'>
            <div className = 'flex justify-between'>
              <div>
                <h3 className = 'text-base text-blacklight font-medium'>Rewards</h3>
                <p className = 'text-xs text-secondary'>Please add at least a reward so that the loyalty program appears in your store</p>
              </div>
              <div>
                <p className = 'bg-[#f0f3f3] text-sm text-secondary py-2 px-5 rounded-full'>Add at least one reward before activation</p>
              </div>
            </div>
            <div className = 'text-center mt-10'>
              <h3 className = 'text-base font-medium text-blacklight'>Rewards</h3>
              <p className = 'text-sm text-secondary mt-2'>Add rewards that the customer can exchange his points for</p>
              <button className = 'inline-block border border-borderColor rounded py-1.5 px-4 text-sm text-blacklight mt-3'>Add a new reward</button>
            </div>
          </div>
          <div className = 'border border-borderColor rounded p-4 mt-10'>
            <div className = 'flex justify-between'>
              <div>
                <h3 className = 'text-base text-blacklight font-medium'>Reminders</h3>
              </div>
              <div>
                <p className = 'bg-[#f0f3f3] text-sm text-secondary py-2 px-5 rounded-full'>Add at least one reminder before activation</p>
              </div>
            </div>
            <div className = 'text-center mt-10'>
              <h3 className = 'text-base font-medium text-blacklight'>Reminders</h3>
              <p className = 'text-sm text-secondary mt-2'>Add automatic reminders to alert your customers that their points are about to expire.</p>
              <button className = 'inline-block border border-borderColor rounded py-1.5 px-4 text-sm text-blacklight mt-3'>Add a new reminder</button>
            </div>
          </div>
          <div className = 'mt-10'>
            <button className = "text-sm bg-primary py-2.5 px-4 rounded text-white w-full">Save</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoyaltySystemForm;
