import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';
import { useAddhomecatloglistMutation, useCatloglistQuery, useDeletehomecatlogMutation, useHomecatloglistQuery } from '../../redux/api/homecatlogApi';
import EdithomecatlogModal from './EdithomecatlogModal';
const Category = () => {

  const [AddCategory, setAddCategory] = useState(false);
  const onOpenAddCategory = () => setAddCategory(true);
  const onCloseAddCategory = () => setAddCategory(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState([]);
  const [selectedCategoryid, setSelectedCategoryid] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [Catloglist, setCatloglist] = useState([]);

  const searchParams = inputValue ? { size: 30, search: inputValue } : { size: 30 };
  const { error: categoryerror, data: categorydata, isSuccess: categoryisSuccess } = useCatloglistQuery(searchParams);

  useEffect(() => {
    if (categoryerror) {
      toast.error(categoryerror?.data?.message);
    }
  }, [categoryerror]);

  useEffect(() => {
    if (categoryisSuccess) {
      const customOption = {
        label: 'Custom URL',
        value: 'customurl'
      };
      const options = categorydata?.data.map((c) => ({
        label: c.name_en,
        value: c.id
      }));

      setCatloglist([customOption, ...(options || [])]);
    }
  }, [categoryisSuccess, categorydata]);

  const handleChangeproduct = (selectedOption) => {
    setSelectedCategoryOption(selectedOption);
    setSelectedCategoryid(selectedOption?.label);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };
  
  const { error, data, isLoading, refetch } = useHomecatloglistQuery();

  const [name, setName] = useState('');
  const [imgurl, setImgurl] = useState('');
  const [url, setUrl] = useState('');
  const [errorMessage, setErrorMessge] = useState('');
  
  const [addhomecatloglist, { error: homecatlogerror, isSuccess: homecatlogisSuccess, isLoading: homecatlogisloading }] = useAddhomecatloglistMutation();


  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrorMessge('');
  };

  const handleimgUrlChange = (e) => {
    setImgurl(e.target.value);
    setErrorMessge('');
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setErrorMessge('');
  };


  useEffect(() => {

    if (homecatlogisSuccess) {
      setErrorMessge('');
      onCloseAddCategory();
      refetch();
      setName('');
      setUrl('');
      setImgurl('');
      setSelectedCategoryOption([]);
    }
  },[homecatlogisSuccess]);

  const handleSubmit = async () => {
    if (!imgurl || !name || (!url && !selectedCategoryid)) {
      setErrorMessge('Please fill all fields.');
      return;
    }
    
    const formData = new FormData();
    formData.append('name', name);
    formData.append('image_url', imgurl);
    formData.append('category_render_url', selectedCategoryid === 'Custom URL'?url:selectedCategoryid);

    try {
      await addhomecatloglist(formData);
      
    } catch (error) {
      setErrorMessge('Failed to add home category: ', error);
    }
  };


  const [EditCategory, setEditCategory] = useState(false);
  const [currentCatlog, setCurrentCatlog] = useState(null);

  const OnopenEditCategory = (item) => {
    setCurrentCatlog(item);
    setEditCategory(true);
  };


  const [deletehomecatlog,{ error: deletecatlogerror, isSuccess: deletecatlogisSuccess }] = useDeletehomecatlogMutation();

  useEffect(() => {

    if(deletecatlogerror){
      toast(deletecatlogerror?.message);
    }

  }, [error]);

  useEffect(() => {

    if(deletecatlogisSuccess){
      refetch();
    }

  }, [deletecatlogisSuccess]);

  const handleCatlogDelete = async (id) => {
    try {
      await deletehomecatlog(id);
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  return (
    <>
      <EdithomecatlogModal EditCategory = { EditCategory } currentCatlog = { currentCatlog } setEditCategory = { setEditCategory } refetch = { refetch } />
      <div className = "flex bg-white">
        <Sidebar />
        <div className = "flex-1 py-5 pl-5 pr-8">
          <TopHeader />
          <div className = "w-full">
            <button
              onClick = { onOpenAddCategory }
              className = "inline-block px-5 py-2 mb-5 bg-[#f0f3f3] rounded"
            >
              <span>
                <AddOutlinedIcon style = { { fontSize: '20px' } } />
              </span>{ ' ' }
                Add Category
            </button>

            <div className = "border border-borderColor rounded mt-1 p-3">
              <table className = "min-w-full leading-normal ">
                <thead>
                  <tr>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm  text-gray-700 font-medium uppercase">
                      NAME
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                      IMAGES
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300 text-sm text-gray-700 font-medium uppercase ">
                      REDIRECT URL
                    </th>
                    <th className = "text-left px-5 py-3 border-b-2 border-boderColor-300  text-sm  text-gray-700 font-medium uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { error && <p>{ error?.message }</p> }
                  { isLoading && <p>{ isLoading ? 'Loading...' : null }</p> }
                  { data && <p className = 'text-sm text-blacklight ml-5 mt-2'>{ data?.data?.length === 0 ? 'No data found' : '' }</p> }

                  { data?.data.map((item) => (
                    <tr key = { item?.id }>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        <p className = 'text-sm text-blacklight'>{ item?.name }</p>
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm text-left">
                        <img
                          className = "w-16 self-center"
                          src = { item?.image_url }
                          alt = "catlogimg"
                        />
                      </td>
                      <td className = "px-5 py-3 bg-white text-sm">
                        <a target = '_blank'
                          href = { item?.category_render_url }
                          className = "text-blacklight text-left hover:underline cursor-pointer" rel = "noreferrer"
                        >
                          { item?.category_render_url === null?'NA': item?.category_render_url }
                        </a>
                      </td>
                      <td className = "px-2 py-3 bg-white text-left">
                        <button
                          onClick = { () => OnopenEditCategory(item) }
                          className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded"
                        >
                          <span>
                            <EditOutlinedIcon style = { { fontSize: '20px' } } />
                          </span>
                        </button>
                        <button onClick = { () => handleCatlogDelete(item?.id) } className = "cursor-pointer text-blacklight inline-block border border-boderColor-300 w-8 h-8 p-1 rounded ml-2">
                          <span>
                            <DeleteOutlineOutlinedIcon
                              style = { { fontSize: '20px' } }
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open = { AddCategory }
        showCloseIcon = { false }
        onClose = { onCloseAddCategory }
      >
        <div className = "bannermodal">
          <div className = "flex justify-between bg-[#f0f3f3] px-4 py-3">
            <h3 className = "text-blacklight text-base font-semibold self-center">
            Add Category
            </h3>
            <div className = "self-center">
              <span
                onClick = { onCloseAddCategory }
                className = "cursor-pointer text-blacklight"
              >
                <CloseOutlinedIcon style = { { fontSize: '20px' } } />
              </span>
            </div>
          </div>
          <div className = "flex-col items-center py-8 px-8 text-center">
            <div className = "text-start">
              <label className = "text-blacklight text-sm self-center block">
                    Name
              </label>
              <input
                className = "mt-1 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                type = "text" placeholder = "Enter categories name"
                value = { name }
                onChange = { handleNameChange }
              />
              { homecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { homecatlogerror?.data?.message?.name }
                </div>
              ) }

              <label className = "text-blacklight text-sm self-center block mt-3">
              Image url (size: 540 × 540px)
              </label>
              <input
                placeholder = "Enter image url"
                className = "inline-block text-sm outline-0 border border-borderColor px-2 py-2 rounded mt-1 w-full"
                value = { imgurl }
                onChange = { handleimgUrlChange }
              />
              { homecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { homecatlogerror?.data?.message?.image_url }
                </div>
              ) }

              <div>
                <Select
                  className = 'inline-block text-sm mt-3 outline-0 w-full text-secondary'
                  value = { selectedCategoryOption }
                  onChange = { handleChangeproduct }
                  options = { Catloglist }
                  inputValue = { inputValue }
                  onInputChange = { handleInputChange }
                  placeholder = 'Choose category'
                />
              </div>
              {
                selectedCategoryid === 'Custom URL' && <input
                  className = "mt-3 inline-block w-full text-sm px-2 py-2 rounded outline-0 border border-borderColor"
                  type = "text" placeholder = "Enter url"
                  value = { url }
                  onChange = { handleUrlChange }
                />
              }
              
              { homecatlogerror && (
                <div className = "mt-2 text-red text-sm">
                  { homecatlogerror?.data?.message?.category_render_url }
                </div>
              ) }
              
              <div>  
                <p className = "mt-2 text-red text-sm">{ errorMessage }</p>
                <button
                  className = "text-sm inline-block mt-5 text-white bg-primary px-5 py-2 rounded"
                  onClick = { handleSubmit }
                  disabled = { homecatlogisloading }
                >
                  { homecatlogisloading ? 'Saving...' : 'Save' }
                </button>
              
              </div>
            </div>
          </div>
        </div>
      </Modal>

      
    </>
  );
};

export default Category;
