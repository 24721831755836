/* eslint-disable react/no-unescaped-entities */

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import TopHeader from '../../components/TopHeader';

const WalletBills = () => {

  const [defaultpaymentmethodModal, setDefaultpaymentmethodModalModal] = useState(false);

  const onOpenDefaultpaymentmethodModal = () => setDefaultpaymentmethodModalModal(true);
  const onCloseDefaultpaymentmethodModal = () => setDefaultpaymentmethodModalModal(false);


  const [creditCardModal, setCreditCardModal] = useState(false);

  const onOpenCreditCardModal = () => setCreditCardModal(true);
  const onCloseCreditCardModal = () => setCreditCardModal(false);

  const [addaNewcard, setAddaNewcard] = useState(false);

  const handleAddaNewcard = () => {
    setAddaNewcard(!addaNewcard);
  };


  const [bankAccountModal, setBankAccountModal] = useState(false);

  const onOpenBankAccountModal = () => setBankAccountModal(true);
  const onCloseBankAccountModal = () => setBankAccountModal(false);

  
  const [emailReceiveinvoicesModal, setEmailReceiveinvoicesModal] = useState(false);

  const onOpenEmailReceiveinvoicesModal = () => setEmailReceiveinvoicesModal(true);
  const onCloseEmailReceiveinvoicesModal = () => setEmailReceiveinvoicesModal(false);

  const [companyAddressModal, setCompanyAddressModal] = useState(false);

  const onOpenCompanyAddressModal = () => setCompanyAddressModal(true);
  const onCloseCompanyAddressModal = () => setCompanyAddressModal(false);


  const [addbalanceModal, setAddbalanceModal] = useState(false);

  const onOpenAddbalanceModal = () => setAddbalanceModal(true);
  const onCloseAddbalanceModal = () => setAddbalanceModal(false);

  const [radioActive, setRadioActive] = useState(1);

  const handleRadionbtn = (item) => {
    setRadioActive(item);
  };


  const [radioaddanewcard, setRadioaddanewcard] = useState(false);

  const handleRadioaddnewcard = () => {
    setRadioaddanewcard(true);
  };
  

  return(
    <>
      <Header />
      <div className = 'flex bg-white'>
        <Sidebar />
        <div className = 'w-4/5 py-5 pl-5 pr-8'>
          <TopHeader />
          <ul className = 'flex text-sm'>
            <li className = 'mr-2'><Link to = "/" className = 'text-blacklight align-middle'><span><HomeOutlinedIcon style = { {fontSize: '20px'} } /> </span>Main</Link></li>
            <li className = 'mr-2'><span className = 'text-blacklight'>/</span></li>
            <li><span className = 'text-secondary'>Portfolio</span></li>
          </ul>
          <div className = 'border border-borderColor rounded bg-white p-4 mt-5 mb-5'>
            <div className = 'flex'>
              <div className = 'bg-[#f0f3f3] w-16 h-16 rounded-full text-center self-center'><span className = 'text-blacklight inline-block mt-5 text-center'><GppGoodOutlinedIcon style = { {fontSize: '30px'} } /></span></div>
              <div className = 'ml-3 self-center'>
                <h3 className = 'text-base text-blacklight font-medium'>Store documentation (complete)</h3>
                <p className = 'text-sm text-secondary'>You can now take full advantage of the platform's capabilities</p>
                <p className = 'text-red text-sm'>Only the store owner can log in to authenticate the store.</p>
              </div>
            </div>
          </div>
          <div className = 'mt-5'>
            <div className = 'flex justify-between'>
              <div className = 'w-width32% border border-borderColor rounded p-5'>
                <p className = 'text-base text-blacklight font-medium'><span className = 'inline-block mr-2'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '20px'} } /></span> Store credit  <Tooltip title = "Through store credit, you can automatically renew your package, purchase from the Basket store, issue shipping policies, and more."><span className = 'inline-block ml-2'><InfoOutlinedIcon style = { {fontSize: '20px'} } /></span></Tooltip></p>
                <h3 className = 'text-5xl text-blacklight font-medium mt-5'><span className = 'text-lg text-secondary'>Rs</span> 30</h3>
                <div className = 'flex justify-between'>
                  <button className = 'bg-[#e7ebeb] rounded inline-block py-2.5 px-5 text-sm text-blacklight mt-5' onClick = { onOpenAddbalanceModal }>Add balance</button>
                  <button className = 'border border-borderColor rounded inline-block py-2.5 px-5 text-sm text-blacklight mt-5'>Withdraw a balance</button>
                </div>
                <Link to = '/wallet/transactions' className = 'inline-block mt-8 underline text-secondary hover:text-blacklight'>Transaction log <span><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></Link>
              </div>
              <div className = 'w-width32% border border-borderColor rounded p-5'>
                <div className = 'flex'>
                  <div>
                    <img src = 'https://cdn.salla.network/images/logo/logo.svg' alt = 'icon' className = 'w-8' />
                  </div>
                  <p className = 'text-base text-blacklight font-medium ml-3'>Electronic payments balance  <Tooltip title = "The current balance of your store's sales. You can benefit from it by transferring it to store credit."><span className = 'inline-block ml-1'><InfoOutlinedIcon style = { {fontSize: '20px'} } /></span></Tooltip></p>
                </div>
                <div className = 'text-[#eaf0f0] text-center mt-5'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '50px'} } /></div>
                <h3 className = 'text-[#eaf0f0] text-center text-3xl font-semibold'>You currently have no balance</h3>
                <div className = 'text-center'><Link to = '/payment/history' className = 'inline-block mt-5 underline text-secondary hover:text-blacklight'>Transaction log <span><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></Link></div>
              </div>
              <div className = 'w-width32% border border-borderColor rounded p-5'>
                <div className = 'flex'>
                  <div>
                    <img src = 'https://cdn.salla.network/images/logo/logo.svg' alt = 'icon' className = 'w-8' />
                  </div>
                  <p className = 'text-base text-blacklight font-medium ml-3'>Cash on delivery balance  <Tooltip title = "Your current payment balance for confirmed COD sales received from customers."><span className = 'inline-block ml-1'><InfoOutlinedIcon style = { {fontSize: '20px'} } /></span></Tooltip></p>
                </div>
                <div className = 'text-[#eaf0f0] text-center mt-5'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '50px'} } /></div>
                <h3 className = 'text-[#eaf0f0] text-center text-3xl font-semibold'>You currently have no balance</h3>
                <div className = 'text-center'><Link to = '#' className = 'inline-block mt-5 underline text-secondary hover:text-blacklight'>Transaction log <span><Inventory2OutlinedIcon style = { {fontSize: '20px'} } /></span></Link></div>
              </div>
            </div>
          </div>
          <div className = 'border border-borderColor rounded mt-10 px-5 pt-5'>
            <ul>
              <li className = 'pb-5'>
                <h3 className = 'text-base font-medium text-blacklight pb-5'><span><TuneIcon style = { {fontSize: '24px'} } /></span> Billing settings</h3>
                <div className = 'flex justify-between cursor-pointer' onClick = { onOpenDefaultpaymentmethodModal }>
                  <div className = 'flex'>
                    <div className = 'text-secondary self-center'><AccountBalanceWalletOutlinedIcon style = { {fontSize: '30px'} } /></div>
                    <div className = 'self-center ml-2'>
                      <h3 className = 'text-base text-blacklight font-medium'>Default payment method</h3>
                      <p className = 'text-sm text-secondary'>Wallet (store balance and electronic payments)</p>
                    </div>
                  </div>
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><ArrowForwardIosIcon style = { {fontSize: '20px'} } /></span>
                  </div>
                </div>
              </li>
              <li className = 'py-5 border-t border-borderColor'>
                <div className = 'flex justify-between cursor-pointer' onClick = { onOpenCreditCardModal }>
                  <div className = 'flex'>
                    <div className = 'text-secondary self-center'><CreditCardIcon style = { {fontSize: '30px'} } /></div>
                    <div className = 'self-center ml-2'>
                      <h3 className = 'text-base text-blacklight font-medium'>Credit card</h3>
                      <p className = 'text-sm text-secondary'>There are no cards added yet</p>
                    </div>
                  </div>
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><ArrowForwardIosIcon style = { {fontSize: '20px'} } /></span>
                  </div>
                </div>
              </li>
              <li className = 'py-5 border-t border-borderColor'>
                <div className = 'flex justify-between cursor-pointer' onClick = { onOpenBankAccountModal }>
                  <div className = 'flex'>
                    <div className = 'text-secondary self-center'><AccountBalanceOutlinedIcon style = { {fontSize: '30px'} } /></div>
                    <div className = 'self-center ml-2'>
                      <h3 className = 'text-base text-blacklight font-medium'>Bank account to receive amounts</h3>
                      <p className = 'text-sm text-secondary'>National Commercial Bank - Dimas Al-Jazeera Trading Establishment - SA8410000001400021971409</p>
                    </div>
                  </div>
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><ArrowForwardIosIcon style = { {fontSize: '20px'} } /></span>
                  </div>
                </div>
              </li>
              <li className = 'py-5 border-t border-borderColor'>
                <div className = 'flex justify-between cursor-pointer' onClick = { onOpenEmailReceiveinvoicesModal }>
                  <div className = 'flex'>
                    <div className = 'text-secondary self-center'><MarkunreadOutlinedIcon style = { {fontSize: '30px'} } /></div>
                    <div className = 'self-center ml-2'>
                      <h3 className = 'text-base text-blacklight font-medium'>Email to receive invoices</h3>
                      <p className = 'text-sm text-secondary'>Info@beautyicon.store</p>
                    </div>
                  </div>
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><ArrowForwardIosIcon style = { {fontSize: '20px'} } /></span>
                  </div>
                </div>
              </li>
              <li className = 'py-5 border-t border-borderColor'>
                <div className = 'flex justify-between cursor-pointer' onClick = { onOpenCompanyAddressModal }>
                  <div className = 'flex'>
                    <div className = 'text-secondary self-center'><FmdGoodOutlinedIcon style = { {fontSize: '30px'} } /></div>
                    <div className = 'self-center ml-2'>
                      <h3 className = 'text-base text-blacklight font-medium'>Company Address</h3>
                      <p className = 'text-sm text-secondary'>7507, King Abdulaziz Road, Riyadh 12434</p>
                    </div>
                  </div>
                  <div className = 'self-center'>
                    <span className = 'text-secondary'><ArrowForwardIosIcon style = { {fontSize: '20px'} } /></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className = 'border border-borderColor rounded mt-10'>
            <div className = 'p-5 flex justify-between'>
              <h3 className = 'text-base font-medium text-blacklight'><span><TuneIcon style = { {fontSize: '24px'} } /></span> Activated subscriptions</h3>
              <p><button className = 'text-blacklight text-sm'>Export</button></p>
            </div>
           
            <div className = 'activatesubscriptions custom-scroll'>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Subscription details</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Subscription duration</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Subscription date</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Next renewal date</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-44 text-sm font-medium py-2 px-4'>Entry Fee</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-48 text-sm font-medium py-2 px-4'>Automatic renewal</div>
                </li>
                <li>
                  <div className = 'bg-[#f0f3f3] w-24 text-sm font-medium py-2 px-4'>Action</div>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li >
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>Basket Pro</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>open</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>03-26-2024</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'>04-26-2024</div>
                </li>
                <li>
                  <div className = 'w-44 text-sm py-2 px-4 text-blacklight'>299.00 SAR</div>
                </li>
                <li>
                  <div className = 'w-48 text-sm py-2 px-4 text-blacklight'><FileDownloadDoneOutlinedIcon style = { {fontSize: '20px'} } /></div>
                </li>
                <li>
                  <div className = 'w-24 text-sm py-2 px-4 text-blacklight'><MoreHorizOutlinedIcon style = { {fontSize: '20px'} } /></div>
                </li>
              </ul>
            </div>
          </div>
          <div className = 'border border-borderColor rounded mt-10'>
            <div className = 'p-5 flex justify-between'>
              <h3 className = 'text-base font-medium text-blacklight'><span><DescriptionOutlinedIcon style = { {fontSize: '24px'} } /></span> Purchase invoices</h3>
              <p><Link className = 'text-blacklight text-sm inline-block py-2 px-5 border border-borderColor rounded' to = '/marketplace/invoices'>Previous invoices</Link></p>
              <p><Link className = 'text-blacklight text-sm underline' to = "/payment/invoices">Record tax invoices</Link></p>
            </div>
            <div className = 'activatesubscriptions custom-scroll'>
              <ul className = 'flex w-full'>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>invoice number</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>the total</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>Invoice date</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'bg-[#f0f3f3] w-full text-sm font-medium py-2 px-4'>Print</div>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full border-b border-borderColor'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
              <ul className = 'flex w-full'>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>#1237832</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>455.33 SAR</div>
                </li>
                <li className = 'w-1/4'>
                  <div className = 'w-full text-sm py-2 px-4 text-blacklight'>03-31-2024</div>
                </li>
                <li className = 'w-1/4'>
                  <Link to = "#" className = 'w-full text-sm py-2 px-4 text-blacklight hover:text-primary-300'>Print</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal open = { defaultpaymentmethodModal } showCloseIcon = { false } onClose = { onCloseDefaultpaymentmethodModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Default payment method</h3>
            <div className = 'self-center'><span onClick = { onCloseDefaultpaymentmethodModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = 'flex border border-borderColor rounded bg-[#f8f8f8] p-3'>
              <div className = 'self-center'>
                <span className = 'text-red'><WarningAmberOutlinedIcon style = { {fontSize: '30px'} } /></span>
              </div>
              <p className = 'text-sm text-red ml-2 self-center font-medium'>If the primary payment method has insufficient funds, the payment will be processed via the alternative payment method.</p>
            </div>
            <ul>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block mt-2'>Choose your primary payment method</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><CreditCardIcon style = { {fontSize: '20px'} } /></span></div>
                  <select className = 'inline-block text-sm outline-0 w-width97% text-blacklight '>
                    <option>Credit card</option>
                    <option>Wallet (store balance and electronic payments)</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block mt-2'>
                 Choose an alternative payment method
                </label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><CreditCardIcon style = { {fontSize: '20px'} } /></span></div>
                  <select className = 'inline-block text-sm outline-0 w-width97% text-blacklight '>
                    <option>Nothing</option>
                    <option>Credit card</option>
                    <option>Wallet (store balance and electronic payments)</option>
                  </select>
                </div>
              </li>
              <div className = 'flex justify-between mt-8'>
                <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-black'>Close</button>
              </div>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal open = { creditCardModal } showCloseIcon = { false } onClose = { onCloseCreditCardModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Credit card</h3>
            <div className = 'self-center'><span onClick = { onCloseCreditCardModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <p className = 'text-blacklight text-base'>The card is used in the automatic renewal process for the package without the need to purchase the package manually.</p>
            <ul className = 'mt-5 list-disc pl-5'>
              <li>
                <p className = 'text-sm text-blacklight'>The card will be used to automatically renew the subscription to the store package and the rest of the activated subscriptions on time, unless you cancel the automatic renewal.</p>
              </li>
              <li>
                <p className = 'text-sm text-blacklight'>You can add a maximum of two cards, and select the primary and alternative payment card.</p>
              </li>
              <li>
                <p className = 'text-sm text-blacklight'>When a subscription cannot be automatically renewed using the primary payment method, the alternative payment method, if available, will be used.</p>
              </li>
            </ul>
            <div className = 'mt-5'>
              <h3 className = 'text-blacklight text-base font-medium'>Added cards</h3>
              <button onClick = { handleAddaNewcard } className = 'border border-borderColor inline-block py-1.5 px-3 text-sm rounded text-blacklight outline-none mt-3'><span>{ addaNewcard == false?<ControlPointOutlinedIcon style = { {fontSize: '18px'} } /> : <RemoveCircleOutlineOutlinedIcon style = { {fontSize: '18px'} } /> } </span> Add a new card</button>
              {
                addaNewcard && <div>
                  <div className = 'flex justify-between mt-5'>
                    <div className = 'w-width48% flex border border-borderColor rounded px-2'>
                      <span className = 'text-secondary self-center'><CreditCardIcon style = { {fontSize: '20px'} } /></span>
                      <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                    </div>
                    <div className = 'w-width24% flex border border-borderColor rounded px-2'>
                      <span className = 'text-secondary self-center'><InsertInvitationOutlinedIcon style = { {fontSize: '20px'} } /></span>
                      <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                    </div>
                    <div className = 'w-width24% flex border border-borderColor rounded px-2'>
                      <span className = 'text-secondary self-center'><GppGoodOutlinedIcon style = { {fontSize: '20px'} } /></span>
                      <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                    </div>
                  </div>
                  <p className = 'mt-3'>
                    <input type = 'checkbox' className = 'align-middle' id = 'payment' />
                    <label className = 'text-sm text-blacklight inline-block ml-2' htmlFor = 'payment'>I agree to the <Link to = '#'>Terms of Use</Link> and <Link to = '#'>Privacy Policy</Link></label>
                  </p>
                  <button className = 'bg-[#f0f3f3] py-2 px-5 rounded text-sm text-blacklight inline-block mt-5'>Add card</button>
                </div>
              }
              <div className = 'mt-5 text-right'>
                <button className = 'bg-[#f0f3f3] py-2 px-5 rounded text-sm text-blacklight inline-block' onClick = { onCloseCreditCardModal }>Cancellation</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { bankAccountModal } showCloseIcon = { false } onClose = { onCloseBankAccountModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Bank account to receive amounts</h3>
            <div className = 'self-center'><span onClick = { onCloseBankAccountModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = 'bg-[#f8f8f8] border border-borderColor rounded p-3'>
              <div className = 'flex'>
                <div className = 'self-center'>
                  <span className = 'text-primary'><InfoOutlinedIcon style = { {fontSize: '30px'} } /></span>
                </div>
                <p className = 'text-sm text-primary ml-2 self-center font-medium'>The bank account data must match the store owner’s data or the company name in the commercial registry</p>
              </div>
              <div className = 'flex justify-between mt-3'>
                <div>
                  <p className = 'text-sm text-primary'><span><PersonOutlineOutlinedIcon style = { {fontSize: '25px'} } /></span>  Obaid Saad Muhammad Al-Ajmi</p>
                </div>
                <div>
                  <Link to = '#' className = 'underline text-sm text-primary'>Change documentation data</Link>
                </div>
                <div>
                  <p className = 'text-sm text-primary'><span><BusinessOutlinedIcon style = { {fontSize: '25px'} } /></span> Dimas Al Jazeera Trading Est</p>
                </div>
              </div>
            </div>
            <ul>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight inline-block mt-2'>the bank</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3% self-center'><span className = 'text-secondary align-text-bottom'><AccountBalanceOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <select className = 'inline-block text-sm outline-0 w-width97% text-blacklight '>
                    <option>National Commercial Bank</option>
                    <option>Al Rajhi Bank</option>
                    <option>First Bank</option>
                    <option>Sub</option>
                    <option>Samba</option>
                    <option>Arab national Bank</option>
                    <option>the National Bank</option>
                    <option>Al Jazeera Bank</option>
                    <option>Development Bank</option>
                  </select>
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Account Holder's Name</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><PersonOutlineOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter the name of the account holder' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Account number</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><AccountBalanceOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter the account number' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>IBAN number</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><AccountBalanceOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter the IBAN number' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
              </li>
              <li className = 'mt-3'>
                <p className = 'text-sm text-secondary'>After saving, the amendment request will be reviewed by the relevant team within 1-2 working days, during which withdrawals from the electronic payments balance will be suspended until the review is completed.</p>
              </li>
              <li>
                <div className = 'flex justify-between mt-8'>
                  <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
                  <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-black' onClick = { onCloseBankAccountModal }>Close</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <Modal open = { emailReceiveinvoicesModal } showCloseIcon = { false } onClose = { onCloseEmailReceiveinvoicesModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Email to receive invoices</h3>
            <div className = 'self-center'><span onClick = { onCloseEmailReceiveinvoicesModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <h3 className = 'text-base text-blacklight'>Add the official email to receive invoices</h3>
            <p className = 'text-sm text-secondary'>Monthly invoices and electronic payment invoices will be sent to this email</p>
            <div className = 'mt-4'>
              <label className = 'text-sm text-blacklight'>E-mail</label>
              <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                <div className = 'w-width3%'><span className = 'text-secondary'><EmailOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                <input placeholder = 'Add email' className = 'inline-block text-sm outline-0 w-width97% px-1' />
              </div>
            </div>
            <div className = 'flex justify-between mt-8'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
              <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-black' onClick = { onCloseEmailReceiveinvoicesModal }>Close</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { companyAddressModal } showCloseIcon = { false } onClose = { onCloseCompanyAddressModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Company Address</h3>
            <div className = 'self-center'><span onClick = { onCloseCompanyAddressModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <h3 className = 'text-base text-blacklight'>Add the official company address</h3>
            <p className = 'text-sm text-secondary'>Company address data will be added to the tax invoice</p>
            <ul>
              <li className = 'mt-4'>
                <label className = 'text-sm text-blacklight'>Company Name</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><CorporateFareOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter the company name' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>the address</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><LocationOnOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter the address' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
              </li>
              <li className = 'mt-3'>
                <label className = 'text-sm text-blacklight'>Tax Number</label>
                <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
                  <div className = 'w-width3%'><span className = 'text-secondary'><LocationOnOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
                  <input placeholder = 'Enter tax number' className = 'inline-block text-sm outline-0 w-width97% px-1' />
                </div>
                <p className = 'text-xs mt-2'><Link to = '#' className = 'text-primary'>Click here</Link> To change the tax number</p>
              </li>
            </ul>
            <div className = 'flex justify-between mt-8'>
              <button className = 'text-sm bg-primary py-2 px-4 rounded text-white'>Save</button>
              <button className = 'text-sm bg-[#f0f3f3] py-2 px-4 rounded text-black' onClick = { onCloseEmailReceiveinvoicesModal }>Close</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal open = { addbalanceModal } showCloseIcon = { false } onClose = { onCloseAddbalanceModal }>
        <div className = 'modaladdproduct'>
          <div className = 'flex justify-between bg-[#f0f3f3] px-4 py-3'>
            <h3 className = 'text-blacklight text-base font-semibold self-center'>Current balance</h3>
            <div className = 'self-center'><span onClick = { onCloseAddbalanceModal } className = 'cursor-pointer text-blacklight'><CloseOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
          </div>
          <div className = 'px-4 py-3'>
            <div className = 'text-center pb-10'>
              <h3 className = 'text-blacklight text-2xl'>Current balance</h3>
              <p className = 'text-black text-4xl font-semibold mt-2'><span className = 'text-secondary text-lg font-normal'>Rs</span> 0</p>
            </div>
            <div className = 'flex justify-between border border-borderColor px-2 py-1 rounded mt-1'>
              <div className = 'w-width3%'><span className = 'text-secondary'><DynamicFeedOutlinedIcon style = { {fontSize: '20px'} } /></span></div>
              <input placeholder = 'Enter the amount to add' className = 'inline-block text-sm outline-0 w-width97% ml-1' />
              <span className = 'inline-block text-sm text-blacklight border-l border-borderColor pl-2'>SAR</span>
            </div>
            <div className = 'flex border border-borderColor rounded py-3 px-2 mt-5' onClick = { () => handleRadionbtn(1) }>
              <input className = 'align-middle' type = 'radio' name = 'visa' id = 'visas' checked = { radioActive == 1?true : '' } />
              <label className = 'flex w-full ml-3' htmlFor = 'visas'>
                <img className = 'w-8' src = 'https://cdn.assets.salla.network/dash/cp/assets/images/payment_methods/master_card.svg' alt = 'visa card' />
                <img className = 'w-8 ml-2' src = 'https://cdn.assets.salla.network/dash/cp/assets/images/payment_methods/visa.svg' alt = 'visa card' />
                <img className = 'w-12 ml-2' src = 'https://cdn.assets.salla.network/dash/cp/assets/images/mada.png' alt = 'visa card' />
              </label>
            </div>
            <div className = 'flex border border-borderColor rounded py-3 px-2 mt-3' onClick = { () => handleRadionbtn(2) }>
              <input className = 'self-center' type = 'radio' name = 'visa' id = 'bank' checked = { radioActive == 2?true : '' } />
              <label className = 'self-center ml-3 block w-full' htmlFor = 'bank'>
                <span className = 'text-blacklight'><AccountBalanceOutlinedIcon style = { {fontSize: '20px'} } /> Bank transfer</span>
              </label>
            </div>
            <div className = 'flex border border-borderColor rounded bg-[#f8f8f8] p-3 mt-5'>
              <div className = 'self-center'>
                <span className = 'text-red'><WarningAmberOutlinedIcon style = { {fontSize: '30px'} } /></span>
              </div>
              <p className = 'text-sm text-red ml-2 self-center font-medium'>Bank transfers are confirmed through the Financial and Accounting Department during working hours from Sunday to Thursday from 9 am to 5 pm. We thank you for your patience and good wait.</p>
            </div>
            {
              radioActive == 1 && <div className = 'mt-5'>
                <button onClick = { handleRadioaddnewcard } className = 'border border-borderColor rounded inline-block py-2 px-4 text-sm text-blacklight'><ControlPointOutlinedIcon style = { {fontSize: '20px'} } /> Add a new card </button>
                {
                  radioaddanewcard == true &&  <>
                    <div className = 'flex justify-between mt-5'>
                      <div className = 'w-width48% flex border border-borderColor rounded px-2'>
                        <span className = 'text-secondary self-center'><CreditCardIcon style = { {fontSize: '20px'} } /></span>
                        <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                      </div>
                      <div className = 'w-width24% flex border border-borderColor rounded px-2'>
                        <span className = 'text-secondary self-center'><InsertInvitationOutlinedIcon style = { {fontSize: '20px'} } /></span>
                        <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                      </div>
                      <div className = 'w-width24% flex border border-borderColor rounded px-2'>
                        <span className = 'text-secondary self-center'><GppGoodOutlinedIcon style = { {fontSize: '20px'} } /></span>
                        <input className = 'w-full outline-none py-1.5 px-2 text-sm' placeholder = 'card number' />
                      </div>
                    </div>
                    <div className = 'mt-5'>
                      <input type = 'checkbox' className = 'align-middle' id = 'thecard' />
                      <label htmlFor = 'thecard' className = 'text-sm text-blacklight align-middle inline-block ml-2'>Save the card in the account</label>
                    </div>
                    <div className = 'mt-1'>
                      <input type = 'checkbox' className = 'align-middle' id = 'automatic' />
                      <label htmlFor = 'automatic' className = 'text-sm text-blacklight align-middle inline-block ml-2'>Activate automatic withdrawal to renew the subscription</label>
                      <label htmlFor = 'automatic' className = 'text-xs text-blacklight block ml-6'>If there is not enough balance available in the store’s wallet, the card will be automatically withdrawn to renew your subscriptions</label>
                    </div>
                  </>
                }
              </div>
            }
            {
              radioActive == 2 && <div>
                <ul className = 'bg-[#f0f3f3] rounded mt-5 p-4'>
                  <li>
                    <div className = 'flex justify-between'>
                      <div>
                        <p className = 'text-secondary text-sm'>the bank</p>
                      </div>
                      <div>
                        <p className = 'text-blacklight text-sm'>Al Rajhi Bank</p>
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-2'>
                    <div className = 'flex justify-between'>
                      <div>
                        <p className = 'text-secondary text-sm'>Account owner</p>
                      </div>
                      <div>
                        <p className = 'text-blacklight text-sm'>Basalt Application Foundation for Information Technology</p>
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-2'>
                    <div className = 'flex justify-between'>
                      <div>
                        <p className = 'text-secondary text-sm'>Account number</p>
                      </div>
                      <div>
                        <p className = 'text-blacklight text-sm'>362608016546543</p>
                      </div>
                    </div>
                  </li>
                  <li className = 'mt-2'>
                    <div className = 'flex justify-between'>
                      <div>
                        <p className = 'text-secondary text-sm'>IBAN number</p>
                      </div>
                      <div>
                        <p className = 'text-blacklight text-sm'>SA2680000362608016546543</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className = 'bg-[#f0f3f3] mt-5 px-5 py-2 rounded'>
                  <input type = 'file' />
                </div>
              </div>
            }
           
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WalletBills;