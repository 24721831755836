/* eslint-disable react/prop-types */
import React from 'react';
import Select from 'react-select';


const CatlogInclude = ({handleChangecatlog, selectedCatlogOption, catloglist}) => {


  return(
    <>
      <Select
        className = 'inline-block text-sm outline-0 w-full text-secondary'
        defaultValue = { selectedCatlogOption }
        onChange = { handleChangecatlog }
        options = { catloglist }
        isMulti
        placeholder = 'Choose categories'
      />
    </>
  );
};

export default CatlogInclude;